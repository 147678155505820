import React, { useEffect } from "react";
import {
  useDropzone,
  DropzoneOptions,
  DropEvent,
  FileRejection,
} from "react-dropzone";

// Icons
import UploadIcon from "assets/images/Icon_Upload.png";
import WarningIcon from "assets/images/Icon_Warning.png";

export type DropzoneProps = {
  children?(isDragActive: boolean): React.ReactNode;
  uploadedFiles?: File[];
  onDrop<T extends File>(
    acceptedFiles: T[],
    rejectedFiles: FileRejection[],
    event: DropEvent
  ): void;
  dropzoneOptions?: DropzoneOptions;
  dropzoneTitle?: string;
  name: string;
  viewOnly?: boolean;
  warningMessage?: string | null;
};

type Props = DropzoneProps;

const MultipleDropzone: React.FC<Props> = ({
  uploadedFiles,
  dropzoneOptions,
  children,
  name,
  onDrop,
  dropzoneTitle,
  warningMessage,
  viewOnly,
}) => {
  const [imageBase64, setImageBase64] = React.useState<string[]>([]);
  const [fileNames, setFileNames] = React.useState<string[]>([]);
  const [processedFiles, setProcessedFiles] = React.useState<string[]>([]);

  useEffect(() => {
    const areImages =
      imageBase64 && Array.isArray(imageBase64) && imageBase64.length > 0;
    const areFilenames =
      fileNames && Array.isArray(fileNames) && fileNames.length > 0;
    const areProcessedFiles =
      processedFiles &&
      Array.isArray(processedFiles) &&
      processedFiles.length > 0;
    const noFiles =
      uploadedFiles &&
      Array.isArray(uploadedFiles) &&
      uploadedFiles.length === 0;
    if ((areImages || areFilenames || areProcessedFiles) && noFiles) {
      setImageBase64([]);
      setFileNames([]);
      setProcessedFiles([]);
    }
  }, [uploadedFiles]);

  if (
    uploadedFiles &&
    Array.isArray(uploadedFiles) &&
    uploadedFiles.length > 0
  ) {
    uploadedFiles.map((uploadedFile: File, idx: number) => {
      if (!processedFiles.includes(uploadedFile.name)) {
        if (isImage(uploadedFile)) {
          const reader = new FileReader();
          reader.onloadend = () => {
            const imageBase64 = reader.result;
            setImageBase64(prev => [...prev, imageBase64 as string]);
          };

          reader.readAsDataURL(uploadedFile);
          setProcessedFiles(prev => [...prev, uploadedFile.name]);
        } else {
          setFileNames(prev => [...prev, uploadedFile.name]);
          setProcessedFiles(prev => [...prev, uploadedFile.name]);

          // non image file
        }
      }
    });
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...dropzoneOptions,
    onDrop,
    disabled: viewOnly,
  });

  const className = (() => {
    let className = "Dropzone";

    if (warningMessage) {
      className += " Disabled";
    }

    if (isDragActive) {
      className += " DragActive";
    }

    return className;
  })();
  const acceptedFiles =
    dropzoneOptions && dropzoneOptions.accept
      ? dropzoneOptions.accept.toString()
      : null;
  return (
    <>
      <div className={`${className} MultipleDropzone`} {...getRootProps()}>
        {/* If no files uploaded */}
        {!warningMessage && (
          <>
            <input
              {...getInputProps({
                multiple: true,
                name: name,
              })}
            />
            {children ? (
              children(isDragActive)
            ) : (
              <>
                <div className="MultipleDropzoneTop">
                  <img className="Icon" src={UploadIcon} alt="Upload" />
                </div>
                <p className="Bold">
                  {dropzoneTitle ? dropzoneTitle : "File Upload"}
                </p>
                {isDragActive ? (
                  <p>Drop here to upload!</p>
                ) : (
                  <p>Click or Drag to Upload</p>
                )}
              </>
            )}
          </>
        )}

        {/* If file uploaded, display it */}
        {(imageBase64 &&
          Array.isArray(imageBase64) &&
          imageBase64.length > 0) ||
        (fileNames && Array.isArray(fileNames) && fileNames.length > 0) ? (
          <p className="MultipleDropzoneUploadedFilesTitle">Uploaded Files</p>
        ) : (
          ""
        )}
        {imageBase64 &&
          Array.isArray(imageBase64) &&
          imageBase64.map((image: string, idx: number) => <img src={image} />)}
        {fileNames &&
          Array.isArray(fileNames) &&
          fileNames.map((fileName: string, idx: number) => (
            <span>{fileName}</span>
          ))}

        {warningMessage && (
          <span className="WarningMessage">
            <img className="WarningIcon" src={WarningIcon} alt="Warning" />
            {warningMessage}
          </span>
        )}
      </div>
      {acceptedFiles && (
        <span className="FileDetails">{`Accepted files: ${acceptedFiles}`}</span>
      )}
    </>
  );
};

export { MultipleDropzone };

// Helpers
const isImage = (file: File) => file.type.search("image") > -1;
