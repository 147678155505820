import React, { SetStateAction } from "react";
import PersonalInformationForm from "../../components/SignInComponents/PersonalInformationForm/PersonalInformationForm";
import { useSelector, useDispatch } from "react-redux";
import { useFetch } from "../../helpers/loading";
import { endpoints } from "../../serverDetails";
import Spinner from "react-spinkit";
import axios from "api";
import { Redirect } from "@reach/router";
import { AppState } from "../../reducers/rootReducer";
import Helmet from "react-helmet";

const errorResults = "/content/images/Icon_Error.png";

const PersonalInformation = () => {
  const dispatch = useDispatch();
  const state = useSelector<any, any>(state => state.tableReducer);
  const subscriptionStatus = useSelector(
    (state: AppState) => state.auth.subscriptionStatus
  );
  const requestConfig = React.useMemo(() => {
    return { params: { Id: state.selectedCardIds[0] } };
    //eslint-disable-next-line
  }, []);

  const parseError = (submitError: string) => {
    switch (submitError) {
      case "Account_In_Use":
        return "Account already in use, please use a different email address";
      case "Invalid_Email_Address":
        return "Invalid email address";
      case "Invalid_Telephone_Number":
        return "Invalid telephone number";
      case "Invalid_Company":
        return "Company name already in use";
      case "Invalid_Promo_Code":
        return "There was a problem with your promo code, please update and try again";
      case "Payment_Failed":
        return "There was a problem with your payment card, please update and try again";
      case "Invalid_Password_Length":
        return "Password must be 8-20 characters, contain at least one letter, one number, one uppercase character one non alpa-numeric character";
      case "Company_Already_Exist":
        return "This company name is already in use";
      case "Invalid_VAT_Number":
        return "Invalid VAT number";
      default:
        return "Something went wrong, please refresh and try again";
    }
  };

  const handleSubmit = (
    //payload: PersonalInformationForm,
    payload: any,
    setFieldError: (field: string, val: string) => void,
    setSubmitting: (val: boolean) => void
  ) => {
    setSubmitting(true);
    axios
      .post(endpoints.personalInfo.updatePersonalInfo, { ...payload })
      .then(res => {
        if (res.data.status === "1") {
          setSubmitting(false);
          dispatch({
            type: "SHOW_TOAST",
            message: "Personal Information updated",
          });
          window.setTimeout(() => {
            dispatch({ type: "HIDE_TOAST" });
          }, 3000);
        }
      })
      .catch(err => {
        setFieldError(
          err.response.data.errors[0].field_name,
          parseError(err.response.data.errors[0].message_code)
        );
        setSubmitting(false);
      });
  };

  const { data, loading, error } = useFetch(
    endpoints.personalInfo.detailsPersonalInfo,
    requestConfig
  );
  //const { data: countryData, loading: countryLoading, error: countryError } = useFetch(endpoints.personalInfo.countryList);
  // if (subscriptionStatus !== "Valid" && subscriptionStatus !== "Initial") {
  //   return <Redirect to="/" noThrow={true} />;
  // }

  if (loading) {
    return (
      <span>
        <Spinner
          name="three-bounce"
          className="NotificationsSpin"
          fadeIn="none"
        />
      </span>
    );
  }

  if (error)
    return (
      <div className="ErrorResults">
        <div className="NoResults">
          <img src={errorResults} alt={"Error"} />
          <p>Error</p>
        </div>
      </div>
    );

  if (data) {
    let countryList = data.details.list_countries.map((option: any) => ({
      label: option.country_name,
      key: option.country_id,
    }));

    return (
      <>
        <Helmet>
          <title>Skippy Scout: Personal Information</title>
        </Helmet>
        <div className="PageWrapper">
          <div className="PaddingContainer">
            <h2>Personal Information</h2>
            <PersonalInformationForm
              //companyName={true}
              password={false}
              data={data}
              countryData={countryList}
              //submit={(values: PersonalInformationForm) => { handleSubmit(values) }}
              submit={(
                values: PersonalInformationForm,
                setFieldError: (field: string, val: string) => void,
                setSubmitting: (val: boolean) => void
              ) => handleSubmit(values, setFieldError, setSubmitting)}
              loggedIn={true}
            />
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>Skippy Scout: Personal Information</title>
      </Helmet>
      <div className="PageWrapper">
        <div className="PaddingContainer">
          <h2>Personal Information</h2>
          <PersonalInformationForm
            //companyName={true}
            password={false}
            //submit={(values: PersonalInformationForm) => { handleSubmit(values) }}
            submit={(
              values: PersonalInformationForm,
              setFieldError: (field: string, val: string) => void,
              setSubmitting: (val: boolean) => void
            ) => handleSubmit(values, setFieldError, setSubmitting)}
            loggedIn={true}
          />
        </div>
      </div>
    </>
  );
};

export default PersonalInformation;
