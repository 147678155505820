import React from "react";

export const FieldReportsPagination = ({
  setPageNumber,
  pageNumber,
  pageSize,
  resultsCount,
}: {
  pageNumber: number;
  pageSize: number;
  resultsCount: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const lastPage = Math.ceil(resultsCount / pageSize) - 1;
  const firstOnPage = pageNumber * pageSize + 1;
  const lastOnPage =
    resultsCount < pageSize ? resultsCount : pageNumber * pageSize + pageSize;

  return (
    <div className="TableNavigation">
      <div className="PaginatorBlank" />
      <div className="PaginatorButtons">
        <button
          className="PaginatorBtn"
          onClick={() => setPageNumber(0)}
          disabled={pageNumber === 0}
        >
          First
        </button>
        <button
          className="PaginatorBtn"
          onClick={() => setPageNumber(pageNum => pageNum - 1)}
          disabled={pageNumber === 0}
        >
          Previous
        </button>
        <span>{pageNumber + 1}</span>
        <span>{}</span>
        <button
          className="PaginatorBtn"
          onClick={() => setPageNumber(pageNum => pageNum + 1)}
          disabled={pageNumber === lastPage}
        >
          Next
        </button>
        <button
          className="PaginatorBtn"
          onClick={() => setPageNumber(lastPage)}
          disabled={pageNumber === lastPage}
        >
          Last
        </button>
      </div>
      <div className="PaginatorInfo">
        Showing {firstOnPage}-{lastOnPage} of {resultsCount}
      </div>
    </div>
  );
};
