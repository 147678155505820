import React, { useState } from "react";

const FieldReportsSortItem = ({
  name,
  id,
  selectedItem,
  setSelectedItem,
  closeMenu,
}: {
  name: string;
  id: number;
  selectedItem: number;
  setSelectedItem: React.Dispatch<React.SetStateAction<number>>;
  closeMenu: () => void;
}) => {
  const onClickItem = () => {
    setSelectedItem(id);
    closeMenu();
  };

  return (
    <div
      onClick={onClickItem}
      className={`FieldReportsSortItem ${
        selectedItem === id ? "Selected" : ""
      }`}
    >
      {name}
    </div>
  );
};

export const FieldReportsFilterSort = ({
  heading,
  items,
  selectedItem,
  setSelectedItem,
}: {
  heading: string;
  items: { name: string; id: number }[];
  selectedItem: number;
  setSelectedItem: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [selected, setSelected] = useState(false);

  const SortByIconASC = "/content/images/Icon_SortBy_03.png";
  const SortByIconDESC = "/content/images/Icon_SortBy_02.png";

  const onClickHeader = () => {
    setSelected(prevState => !prevState);
  };

  let selectedName = "";

  if (items && Array.isArray(items)) {
    const match = items.find(({ id }) => id === selectedItem);
    if (match) {
      selectedName = match.name;
    }
  }

  return (
    <div className="FieldReportsFilterSort">
      <div className={`FieldReportsFilter ${selected ? "Selected" : ""} `}>
        <div onClick={onClickHeader} className="FieldReportsFilter__header">
          {`${heading}: ${selectedName}`}
          <div className="FieldReportsFilter__sortImage" />
        </div>
        <div className="FieldReportsFilter__expandableDrawer">
          <div className="FieldReportsFilter__content">
            {items.map(({ id, name }) => (
              <FieldReportsSortItem
                key={id}
                id={id}
                name={name}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                closeMenu={onClickHeader}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
