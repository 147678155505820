import { Reducer, combineReducers } from "redux";
import { Action } from "reducers/rootReducer";
import { ActionsObservable, ofType, combineEpics } from "redux-observable";
import {
  filter,
  switchMap,
  map,
  mapTo,
  delay,
  takeUntil,
  tap,
  ignoreElements,
} from "rxjs/operators";
import { of } from "rxjs";


/////////////////////////////////////////////////////////////////
//                                                             //
//   Error State Reducer                                       //
//                                                             //
//   Used by any component to signal that an error has         //
//   occured that can't be handled,so instead we trigger a     //
//   global modal explaining the error to the user and         //
//   requesting that they refresh the page.                    //
//                                                             //
/////////////////////////////////////////////////////////////////

export type ErrorState = Error | null;
type ErrorAction =
  | { type: "DISPLAY_ERROR"; payload: Error }
  | { type: "ACKNOWLEDGED_ERROR" };

export const errorReducer: Reducer = (
  state: ErrorState = null,
  action: ErrorAction
): ErrorState => {
  if (
    action.type === "DISPLAY_ERROR" &&
    action.payload.message &&
    action.payload.message !== "promise_cancelled"
  ) {
    return action.payload;
  } else if (action.type === "ACKNOWLEDGED_ERROR") {
    return null;
  } else {
    return state;
  }
};

const displayErrorEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    filter(action => action.errorMessage != null),
    map(action => ({
      type: "DISPLAY_ERROR",
      payload: action.errorMessage!.error,
    }))
  );

/////////////////////////////////////////////////////////////////
//                                                             //
//   Modal Display                                             //
//                                                             //
//   Controls which panels/modals, if any, should be visible.  //
//                                                             //
/////////////////////////////////////////////////////////////////

export type ModalDisplayState =
  | "none"
  | "delete"
  | "details"
  | "update"
  | "create"
  | "filter";

type ModalDisplayAction = {
  type:
    | "SHOW_CREATE_MODAL"
    | "SHOW_UPDATE_MODAL"
    | "SHOW_DETAILS_MODAL"
    | "SHOW_DELETE_MODAL"
    | "SHOW_FILTER_MODAL"
    | "HIDE_MENU_MODAL";
};

const modalDisplayReducer: Reducer = (
  state: ModalDisplayState = "none",
  action: ModalDisplayAction
): ModalDisplayState => {
  switch (action.type) {
    case "SHOW_DELETE_MODAL":
      return "delete";
    case "SHOW_UPDATE_MODAL":
      return "update";
    case "SHOW_CREATE_MODAL":
      return "create";
    case "SHOW_DETAILS_MODAL":
      return "details";
    case "SHOW_FILTER_MODAL":
      return "filter";
    case "HIDE_MENU_MODAL":
      return "none";
    default:
      return state;
  }
};

// // When a modal's open, we don't want to be able to scroll.
// function* disableScrolling() {
//   while (true) {
//     yield take([
//       "SHOW_FILTER_MODAL",
//       "SHOW_CREATE_MODAL",
//       "SHOW_DETAILS_MODAL",
//       "SHOW_UPDATE_MODAL",
//       "SHOW_DELETE_MODAL",
//     ]);
//     document.body.classList.add("modal-visible");
//   }
// }

const disableScrollingEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(
      "SHOW_FILTER_MODAL",
      "SHOW_CREATE_MODAL",
      "SHOW_DETAILS_MODAL",
      "SHOW_UPDATE_MODAL",
      "SHOW_DELETE_MODAL"
    ),
    tap(() => document.body.classList.add("modal-visible")),
    ignoreElements()
  );

const enableScrollingEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType("HIDE_MENU_MODAL"),
    tap(() => document.body.classList.remove("modal-visible")),
    ignoreElements()
  );

// function* enableScrolling() {
//   while (true) {
//     yield take(["HIDE_MENU_MODAL"]);
//     document.body.classList.remove("modal-visible");
//   }
// }

export const modalDisplayEpic = combineEpics(
  displayErrorEpic,
  enableScrollingEpic,
  disableScrollingEpic
);

// export function* modalDisplaySaga() {
//   yield all([displayErrorSaga(), enableScrolling(), disableScrolling()]);
// }

/////////////////////////////////////////////////////////////////
//                                                             //
//   Toast Display                                             //
//                                                             //
//   Watches for actions that should display a toast           //
//   (eg, upload completed) and signals that it should be      //
//   displayed.                                                //
//                                                             //
/////////////////////////////////////////////////////////////////

type ToastDisplayAction =
  | { type: "SHOW_TOAST"; message: string }
  | { type: "HIDE_TOAST" };

type ToastDisplayState = string | null;

const toastDisplayReducer: Reducer = (
  state: ToastDisplayState = null,
  action: any
): ToastDisplayState => {
  if (action.toast !== undefined) {
    return action.toast.message;
  }
  if (action.type === "SHOW_TOAST") {
    return action.message;
  } else if (action.type === "HIDE_TOAST") {
    return null;
  } else {
    return state;
  }
};

export const toastDisplayEpic = (action$: ActionsObservable<any>) =>
  action$.pipe(
    filter(a => a.toast != null),
    switchMap(a =>
      of(a.toast).pipe(
        delay(a.toast.milliseconds),
        mapTo({ type: "HIDE_TOAST" }),
        takeUntil(action$.pipe(ofType("HIDE_TOAST")))
      )
    )
  );

// export function* toastDisplaySaga() {
//   while (true) {
//     // Watch every action to see if it has an associated toastMessage.
//     // If it does, display it.
//     const action: Action<unknown> = yield take();
//     if (action.toast != null) {
//       yield put<ToastDisplayAction>({
//         type: "SHOW_TOAST",
//         message: action.toast.message,
//       });

//       // Hide the toast after two seconds, unless it gets hidden
//       // manually, in which case don't send the hide action *again*.
//       const { timeout } = yield race({
//         timeout: delay(action.toast.milliseconds),
//         nevermind: take<ToastDisplayAction>("HIDE_TOAST"),
//       });

//       if (timeout) {
//         yield put<ToastDisplayAction>({ type: "HIDE_TOAST" });
//       }
//     }
//   }
// }

type LeftPanelAction =
  | { type: "SET_LEFT_PANEL"; message: string };

type LeftPanelState = string | null;

const leftPanelReducer: Reducer = (
  state: LeftPanelState = "",
  action: any
): LeftPanelState => {
  if (action.toast !== undefined) {
    return action.toast.message;
  }
  if (action.type === "SET_LEFT_PANEL") {
    return action.message;
  } else {
    return state;
  }
};


export const pageReducer = combineReducers({
  errorReducer,
  modalDisplayReducer,
  toastDisplayReducer,
  leftPanelReducer
});
