type Purchase = {
  actionField: {
    id: number;
    value: number;
    revenue: number;
    tax: number;
    currency: string;
    coupon: string;
  };
};

type Item = {
  name: string;
  id: string;
  price: number;
  quantity: number;
};

type EcommerceRegister = {
  purchase: Purchase;
  items: Item[];
};

export const ecommerceGAEvent = (
  ecommerce: EcommerceRegister,
  event: string
) => {
  try {
    const dataLayer = (window as any).dataLayer;

    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event,
      ecommerce,
    });
  } catch (e) {
    // console.log("Ecommere GA event failed..")
  }
};
