import React from "react";

interface TablePagination {
  loading: boolean;
  error?: any;
  data?: {
    details: {
      firstOnPage: number;
      pageNumber: number;
      lastOnPage: number;
      hasNextPage: boolean;
      hasPrevPage: boolean;
      resultsCount: number;
      pageSize: number;
    };
    actions: {
      goToNextPage(): void;
      goToPrevPage(): void;
      changePageSize(size: number): void;
      goToPage(page: number): void;
    };
  };
}

function Pagination({ table }: { table: TablePagination }) {
  const { data } = table;

  if (data) {
    const {
      firstOnPage,
      pageNumber,
      lastOnPage,
      hasNextPage,
      hasPrevPage,
      resultsCount,
      pageSize,
    } = data.details;
    const { goToNextPage, goToPrevPage, goToPage } = data.actions;
    const lastPage = Math.ceil(resultsCount / pageSize)
    return (
      <div className="TableNavigation">
        <div className="PaginatorBlank" />
        <div className="PaginatorButtons">
          <button
            className="PaginatorBtn"
            onClick={() => goToPage(1)}
            disabled={pageNumber === 1}
          >
            First
          </button>
          <button
            className="PaginatorBtn"
            onClick={goToPrevPage}
            disabled={!hasPrevPage}
          >
            Previous
          </button>
          <span>{pageNumber}</span>
          <button
            className="PaginatorBtn"
            onClick={goToNextPage}
            disabled={!hasNextPage}
          >
            Next
          </button>
          <button
            className="PaginatorBtn"
            onClick={() => goToPage(lastPage)}
            disabled={pageNumber === lastPage}
          >
            Last
          </button>
        </div>
        <div className="PaginatorInfo">
            Showing {firstOnPage}-{lastOnPage} of {resultsCount}
        </div>
      </div>
    )
  }

  return null;
}

export default Pagination;
