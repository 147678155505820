import React from "react";
import { Formik, Form } from "formik";
import styles from "./TwoFactorForm.module.css";

import ChunkyInput from "../ChunkyInput/ChunkyInput";
import { ErrorResponse } from "../../../types/ApiResponseTypes";

import ValidationError from "../../FormComponents/ValidationError/ValidationError";

interface TwoFactorFormProps {
  errors: ErrorResponse[];
  submit(x: string): void;
  resubmit(): void;
}

const TwoFactorFormInner = (props: {
  errors: ErrorResponse[];
  resubmit(): void;
}) => {
  return (
    <Form className={styles.twoFactorForm}>
      <ChunkyInput name="code" placeholder="Enter your code here" />
      <br />
      <ValidationError name="twoFactorAuthCode" errors={props.errors} />
      <div className={styles.buttons}>
        <button className="Btn" type="submit" disabled={false}>
          CONTINUE
        </button>
        {props.errors.length > 0 && (
          <button
            className="Btn BtnLight"
            type="button"
            onClick={props.resubmit}
          >
            Resend code
          </button>
        )}
      </div>
    </Form>
  );
};

const twoFactorForm = (props: TwoFactorFormProps) => {
  return (
    <Formik
      initialValues={{ code: "" }}
      onSubmit={values => props.submit(values.code)}
      render={() => (
        <TwoFactorFormInner errors={props.errors} resubmit={props.resubmit} />
      )}
    />
  );
};

export default twoFactorForm;
