import React from "react";
import { endpoints } from "serverDetails";
import { AxiosRequestConfig } from "axios";
import axios from "api";

const invoiceUrl = endpoints.subscriptionInvoice.invoiceDetails;

export type ApiReturn<T = any> = {
  response: T | null;
  errors: any | null;
};
export type Error = {
  field_name: string;
  message_code: string;
};

const getInvoiceDownload = async (
  id: string | number | string[] | null | undefined,
  token: string | string[] | null | undefined
): Promise<ApiReturn> => {
  const reqConfig: AxiosRequestConfig = {
    params: {
      invoice_id: id,
      token,
    },
  };

  try {
    const res = await axios.get(invoiceUrl, reqConfig);
    if (res.status === 1 || res.status === 200) {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: res.data,
        errors: res.data.errors,
      };
    }
  } catch (error) {
    return {
      response: null,
      errors: error,
    };
  }
};

export { getInvoiceDownload };
