export const zipObject = <T>(names: string[], vals: T[]): object => {
  type ZippedObject = {
    [key: string]: T;
  };

  let obj: ZippedObject = {};

  names.forEach((x, i) => {
    obj[x] = vals[i];
  });

  return obj;
};

export const zip = <T, U>(arr1: T[], arr2: U[]): (T | U)[] => {
  type ZippedArray = (T | U)[];
  let result: ZippedArray = [];
  arr1.forEach((x, i) => {
    result.push(arr1[i]);
    result.push(arr2[i]);
  });
  return result;
};

export const intersperse = <T, I>(arr: T[], item: I): (T | I)[] => {
  type InterspersedArray = (T | I)[];
  const items = new Array(arr.length).fill(item);
  const newArr = zip(arr, items).slice(2, -1);

  return newArr;
};
