import { AxiosRequestConfig } from "axios";
import { ApiReturn } from "pages/InvoicesPage/InvoicesApi";
import { endpoints } from "serverDetails";
import axios from "api";
import { AppplicationMapsInitialValues } from "./ApplicationMaps";

type Fields = {
  id: number;
  field_name: string;
};

type Farms = {
  farm_id: number;
  farm_name: string;
  fields: Fields[];
};

export type FarmListDetails = {
  farms: Farms[];
};

export const getFarmList = async (params: {}): Promise<any> => {
  // return await {
  //   response: [
  //     { label: "Farm 1", value: 1, key: 1 },
  //     { label: "Farm 2", value: 2, key: 2 },
  //     { label: "Farm 3", value: 3, key: 3 },
  //   ],
  //   errors: null,
  // };

  try {
    const res = await axios.get(
      endpoints.applicationMaps.getMapsFieldDetails,
      params
    );
    if (res.data.status === "1" || res.status === 200) {
      return {
        response: res.data.details,
        errors: null,
      };
    } else {
      return {
        response: res.data,
        errors: res.data.errors,
      };
    }
  } catch (error) {
    return {
      response: null,
      errors: error,
    };
  }
};

export const parseFarmListDetails = (details: FarmListDetails) => {
  const farms = details.farms.map(({ farm_id, farm_name, fields }) => ({
    label: `${farm_name}${
      fields === null ? " - This farm has no valid fields" : ""
    }`,
    value: farm_id,
    key: farm_id,
  }));

  const fieldsList: Record<
    string,
    { label: string; value: number; key: number }[]
  > = {};
  details.farms
    .filter(({ fields }) => fields && fields.length > 0)
    .forEach(({ farm_name, fields }) => {
      fieldsList[farm_name] = fields.map(({ field_name, id }) => ({
        label: field_name,
        value: id,
        key: id,
      }));
    });
  return { farms, fieldsList };
};

export const getFieldList = async (params: {}): Promise<ApiReturn> => {
  return await {
    response: {
      1: [
        { label: "Field 1", value: 1, key: 1 },
        { label: "Field 2", value: 2, key: 2 },
        { label: "Field 3", value: 3, key: 3 },
      ],
      2: [
        { label: "Field 4", value: 4, key: 4 },
        { label: "Field 5", value: 5, key: 5 },
        { label: "Field 6", value: 6, key: 6 },
      ],
      3: [
        { label: "Field 7", value: 7, key: 7 },
        { label: "Field 8", value: 8, key: 8 },
        { label: "Field 9", value: 9, key: 9 },
      ],
    },
    errors: null,
  };

  try {
    const res = await axios.get(
      endpoints.subscription.pauseSubscription,
      params
    );
    if (res.status === 1 || res.status === 200) {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: res.data,
        errors: res.data.errors,
      };
    }
  } catch (error) {
    return {
      response: null,
      errors: error,
    };
  }
};

type UploadApplicationMapsPayload = {
  field_id: number;
  base_nitrogen: number;
  previous_average_nitrogen: number;
  sns: number;
  product_nitrogen_percentage: number;
  machinery: string;
};

export const parseUploadApplicationMapsPayload = (
  details: AppplicationMapsInitialValues
): UploadApplicationMapsPayload => ({
  field_id: parseInt(details.fieldName as string),
  base_nitrogen: details.baseNitrogen as number,
  previous_average_nitrogen: details.previousNitrogen as number,
  sns: details.snsValue as number,
  product_nitrogen_percentage: details.productNitrogen as number,
  machinery: details.machineryUsing as string,
});

export const updateApplicationMapsDetails = async (
  payload: UploadApplicationMapsPayload
): Promise<ApiReturn> => {
  try {
    const res = await axios.post(
      endpoints.applicationMaps.uploadMapsDetails,
      payload
    );
    if (res.status === 1 || res.status === 200) {
      return {
        response: res.data.details,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: res.data.errors,
      };
    }
  } catch (error) {
    return {
      response: null,
      errors: error,
    };
  }
};
