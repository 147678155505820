import React from "react";
import PaymentCardAddForm from "../Forms/PaymentCardAddForm/PaymentCardAddForm";
import { Elements, injectStripe } from "react-stripe-elements";
import axios from "api";
import { endpoints } from "../../serverDetails";
import { useFetch } from "../../helpers/loading";
import Spinner from "react-spinkit";
import { useDispatch } from "react-redux";

const errorResults = "/content/images/Icon_Error.png"

export interface PaymentCardAdd {
  class?: string;
  stripe?: any;
}

const PaymentCardAdd = (props: any) => {
  const dispatch = useDispatch()
  const handleToast = (message: string) => {
    dispatch({ type: 'SHOW_TOAST', message: message })
    window.setTimeout(() => {
      dispatch({ type: 'HIDE_TOAST' })
    }, 3000);
  }
  //  const [countries, setCountries] = useState(null)

  //React.useEffect(() => {
  //  axios
  //    .get(endpoints.personalInfo.countryList)
  //    .then(r => {
  //      if (r.status === 200 && r.data.details && r.data.details.length > 0) {
  //        setCountries(r.data.details.list.map((o: any) => ({key: o.country_id, label: o.country_name})))
  //      }
  //    })
  //  .catch(err => console.log(err))

  //},[])



  const handleSubmit = async (
    payload: any,
    country_name: string,
    token: any
  ) => {

    if (payload && token && country_name) {
      let postObject = {
        stripe_token: token.id,
        card_type: token.card.brand,
        card_holder_name: token.card.name,
        card_end_digits: token.card.last4,
        exp_date_month: token.card.exp_month,
        exp_date_year: token.card.exp_year,
        title: payload.title,
        first_name: payload.first_name,
        last_name: payload.last_name,
        telephone: payload.phone_number,
        //address_line_1: token.card.address_line_1,
        address_line_1: payload.address_line_1,
        //address_line_2: token.card.address_line_2,
        address_line_2: payload.address_line_2,
        //town_city: token.card.address_city,
        town_city: payload.city_town,
        //county_state: token.card.address_state,
        county_state: payload.county_state,
        postcode: token.card.address_zip,
        country: token.card.country,
        country_id: payload.country_id,
        email_address: payload.email_address,
        billing_address_line_1: payload.address_line_1,
        billing_address_line_2: payload.address_line_2,
        billing_town_city: payload.city_town,
        billing_county_state: payload.county_state,
        billing_postcode: token.card.address_zip,
        billing_country: country_name,
        billing_country_id: payload.country_id,
      }

      axios
        .post(endpoints.paymentCards.createPaymentCards, postObject)
        .then(res => {
          if (res.data.status === "1") {
            dispatch({ type: "CLOSE_RIGHT_POP_OUT" })
            handleToast("Payment card successfully added")
            dispatch({
              type: "REFRESH_TABLE"
            })
          }
        })
        .catch(err => {
          console.log('fail', err)
        });
    }

  };

  const { data, loading, error } = useFetch(endpoints.paymentCards.createDetailsPaymentCards)



  if (loading) {
    return <span><Spinner name="three-bounce" fadeIn="none" /></span>;
  }

  if (error) return (
    <div className="ErrorResults">
      <div className="NoResults">
        <img src={errorResults} alt={"Error"} />
        <p>Error</p>
      </div>
    </div>
  )

  if (data) {
    return (

      <div className={`PaymentCardAdd ${props.class ? props.class : ''}`}>
        <PaymentCardAddForm
          submit={(values, country_name, token) => {
            handleSubmit(values, country_name, token)
          }}
          data={data}
          saveCard={false}
          stripe={props.stripe}
        />
      </div>

    )
  }

  return (

    <div className={`PaymentCardAdd ${props.class ? props.class : ''}`}>
      <PaymentCardAddForm
        submit={(values, country_name, token) => {
          handleSubmit(values, country_name, token)
        }}
        saveCard={false}
        stripe={props.stripe}
      />
    </div>

  )
};



const PaymentCardAddWithStripe = injectStripe(PaymentCardAdd);

export default (props: PaymentCardAdd) => <Elements><PaymentCardAddWithStripe {...props} /></Elements>;
