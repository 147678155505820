import {
  useEffect,
  useRef,
  useLayoutEffect,
  useState,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import { ResponseState } from "reducers/rootReducer";
import { selectAuthStatus } from "reducers/auth";

/**
 * If the user is authenticated and the fetchStatus for the data in question
 * is "noData" and not loading, then dispatch an action to fetch the data.
 *
 *
 *   @example
 *   useLoadData(
 *     props.myBalance.response,
 *     props.fetchMyBalance,
 *   );
 *
 */
export const useLoadData = <T>(
  responseState: ResponseState<unknown>,
  dispatcher: () => void
) => {
  const authenticated = useSelector(selectAuthStatus);

  // If we're authenticated fetch the data.
  useEffect(() => {
    if (authenticated === "authenticated") {
      dispatcher();
    }
    // eslint-disable-next-line
  }, [authenticated]);
};

/**
 * Declarative approach to setInterval, stolen from Dan Abramov.
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 *  @example
 *  useInterval(() => {
 *    if (props.bets.fetchStatus !== "fetchingData") {
 *      props.loadData();
 *    }
 *  }, 10000)
 *
 *
 */
export const useInterval = (callback: any, delay: number | null) => {
  // This initial () => null will never be called, and is just there
  // to shut TypeScript up.
  const savedCallback = useRef(() => null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // use setInterval within a useEffect block, so it is called appropriately by React.
  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

/**
 * Detect when user has clicked outside a component, and
 * run a callback.
 *
 *  @example
 *  const node = useRef(null);
 *
 *  useOnClickOutside(node, () => setShowDropdown(false));
 *
 *  return (
 *    // This div is presumably some sort of dropdown.
 *    <div ref={node}>...</div>
 *  )
 *
 */
export const useOnClickOutside = (
  node: React.MutableRefObject<any>,
  callback: () => any
) => {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      // If we forgot to set the node, return.
      if (node.current == null) {
        return;
      }
      // If the click is inside the target, return.
      if (node.current.contains(e.target)) {
        return;
      }
      // If the click is a left click and outside the target,
      // run the callback.
      if (e.buttons === 1) {
        callback();
      }
    };
    // Add event listener.
    document.addEventListener("mousedown", handleClick);

    // Tear down event listener on unmount.
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [node, callback]);
};

/**
 * Returns what a div's dimensions were the last time they changed.
 * Used principally to stop a dynamically sized element from changing size when loading data.
 * For example, DynamicCard will shrink to almost nothing when it goes from fetchedData
 * to fetchingData, and then balloon back up again once it has refreshed. No good! When
 * the component's size changes, this hook returns the node's dimensions.
 * Then, in the component, when the fetchStatus is at "fetchingData", we set the node's height and width
 * style attributes so that it doesn't resize.
 */
export const useGetDimensions = (
  node: React.MutableRefObject<HTMLDivElement | null>
) => {
  // Height and width.
  const [dimensions, setDimensions] = useState<[string?, string?]>([]);

  // useLayoutEffect() doesn't like null values.
  const [offsetWidth, offsetHeight] =
    node.current === null
      ? [-1, -1]
      : [node.current.offsetWidth, node.current.offsetHeight];

  useLayoutEffect(() => {
    if (node.current != null) {
      setDimensions([
        node.current.offsetWidth.toString(),
        node.current.offsetHeight.toString(),
      ]);
    }
  }, [offsetWidth, offsetHeight, node]);

  return dimensions;
};

/**
 * If the path has changed but we're still in the app (the user has clicked
 * on a react router Link, probably), then scroll to the top.
 */
export const useScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};

/**
 * If a promise is still outstanding on component unmount, React will complain about
 * a potential memory leak. Wrapping this promise around a promise will give something
 * with the same .then() .catch() .finally() methods, but also adds a .cancel() which
 * you can call on unmount if necessary.
 *
 * Taken from: https://medium.com/@rajeshnaroth/writing-a-react-hook-to-cancel-promises-when-a-component-unmounts-526efabf251f
 *
 */
const makeCancellable = (promise: Promise<any>) => {
  let isCancelled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then(val =>
        isCancelled ? reject(new Error("promise_cancelled")) : resolve(val)
      )
      .catch(error =>
        isCancelled ? reject(new Error("promise_cancelled")) : reject(error)
      );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      isCancelled = true;
    },
  };
};

/**
 * A hook wrapper around makeCancellable that will keep track of all outstanding
 * cancellable promises and cancel them automatically on unmount.
 */
export const useCancellablePromise = (
  cancellable = makeCancellable
): ((p: Promise<any>) => Promise<any>) => {
  const promises = useRef<any>();

  useEffect(() => {
    promises.current = promises.current || [];

    return function cancel() {
      promises.current.forEach((p: any) => p.cancel());
      promises.current = [];
    };
  }, []);

  const cancellablePromise = <T>(p: Promise<T>) => {
    const cPromise = makeCancellable(p);
    promises.current.push(cPromise);
    return cPromise.promise;
  };

  return useCallback(cancellablePromise, []);
};
