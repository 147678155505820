import React, { SetStateAction, Dispatch } from "react";
import { RouteComponentProps } from "@reach/router";
import { Formik, Form } from "formik";
import { PersonalInformationFormRegistrationInner } from "./PersonalInformationFormRegistrationInner";
import { SubscriptionStep } from "./SubscriptionStep";
import StripeRegistration from "./StripeRegistration";
// import { StepOne } from "./StepOne";
import {
  RegistrationSteps,
  InitialValuesObj,
  PersonalDetailsObj,
  PaymentDetailsObj,
  BillingDetailsObj,
} from "./Registration";
import Complete from "../../assets/images/Icon_Asset.png";
import QRCodeForm from "components/SignInComponents/QRCodeForm/QRCodeForm";
import QrReader from "react-qr-reader";
import { SummaryPage } from "./SummaryPage";
import { SuccessPage } from "./SuccessPage";
import { PersonalInformationFormAlreadyAccountRegistrationInner } from "./PersonalInformationFormAlreadyAccountRegistrationInner";
import { AddOnsStep } from "./AddOnsStep";

type LandingPageProps = {
  alreadyHasAccount: boolean;
  data: any;
  setTaxRate: any;
  setAlreadyHasAccount: any;
  setCurrentRegistrationStep: any;
  handleFormSubmit: any;
  values: any;
};

// const LandingPage = ({
//   navigate,
//   ...props
// }: RouteComponentProps & LandingPageProps) => (
//     <div className="Register">
//       <StepOne navigate={navigate} {...props} />
//     </div>
//   );

type RegisterPageProps = {
  initialValues: InitialValuesObj;
  validationSchema: any;
  setValidationSchema(s: string): void;
  handleFormSubmit: any;
  isCurrentStep: (s: string) => boolean;
  isStepComplete: (s: string) => boolean;
  data: any;
  setTaxRate(n: number): void;
  alreadyHasAccount: any;
  setGeneralError: (s: string) => void;
  setAlreadyHasAccount(b: boolean): void;
  registrationStep: RegistrationSteps;
  setCurrentRegistrationStep: any;
  skipPaymentOption: boolean;
  setSkipPaymentOption(b: boolean): void;
  QRError: boolean;
  QRRef: any;
  noCamera: boolean;
  uploadQRImage(): void;
  showBackToQR: boolean;
  generalError: string;
  scannerLoading: boolean;
  hideScanner: any;
  handleError(e: any): void;
  handleScan(e: any): void;
  legacyMode: boolean;
  result: string;
  setActivationToken(p: any): void;
  setActivePage(s: string): void;
  subscription: number | null;
  setSubscription(n: number): void;
  setSubscriptionName(s: string): void;
  setSubscriptionPrice(n: number): void;
  setSubscriptionGross(n: number): void;
  setSubscriptionType(s: string): void;
  subscriptionType: string;
  usersPromoCode: string;
  personalInfoValidationSchema: any;
  paymentValidationSchema: any;
  alreadyAccountPersonalInfoValidationSchema: any;
  setUsersPromoCode(s: string): void;
  promoCodeValue: number;
  setPromoCodeValue(n: number): void;
  setPromoCodeVisible(b: boolean): void;
  promoCodeVisible: boolean;
  setPromoCodeType(s: string): void;
  onLastStep: boolean;
  setDisplayError: Dispatch<SetStateAction<string>>;
  disableCardInput: boolean;
  setDisableCardInput: Dispatch<SetStateAction<boolean>>;
  setFullDiscount: Dispatch<SetStateAction<boolean>>;
  setOnLastStep: Dispatch<SetStateAction<boolean>>;
  promoCodeType: string;
  subscriptionPrice: number;
  subscriptionGross: number;
  setPaymentDetails: any;
  fullDiscount: boolean;
  qrCodeString: string;
  personalDetails: PersonalDetailsObj | null;
  noPaymentDetailsValidationSchema: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  errorType: string;
  displayError: string;
  subscriptionName: string;
  paymentDetails: PaymentDetailsObj | null;
  billingDetails: BillingDetailsObj | null;
  activationToken: string;
  inputError: string;
  handleLoad(): void;
  submitError: string;
  bQueryStringPromoCode: boolean;
};

const RegisterPage = React.forwardRef(
  (props: RouteComponentProps & RegisterPageProps) => {
    const {
      initialValues,
      validationSchema,
      handleFormSubmit,
      isCurrentStep,
      isStepComplete,
      inputError,
      activationToken,
      generalError,
      showBackToQR,
      data,
      setTaxRate,
      alreadyHasAccount,
      setAlreadyHasAccount,
      registrationStep,
      subscription,
      subscriptionGross,
      fullDiscount,
      setCurrentRegistrationStep,
      setSubscription,
      setSubscriptionGross,
      setSubscriptionName,
      setSubscriptionPrice,
      setSubscriptionType,
      subscriptionType,
      setActivePage,
      usersPromoCode,
      setUsersPromoCode,
      setPromoCodeValue,
      personalInfoValidationSchema,
      paymentValidationSchema,
      alreadyAccountPersonalInfoValidationSchema,
      setPromoCodeType,
      setDisableCardInput,
      setDisplayError,
      setFullDiscount,
      setOnLastStep,
      promoCodeValue,
      setPromoCodeVisible,
      promoCodeVisible,
      onLastStep,
      disableCardInput,
      setPaymentDetails,
      promoCodeType,
      setGeneralError,
      qrCodeString,
      personalDetails,
      errorType,
      displayError,
      subscriptionName,
      paymentDetails,
      billingDetails,
      subscriptionPrice,
      QRError,
      QRRef,
      setValidationSchema,
      noPaymentDetailsValidationSchema,
      noCamera,
      uploadQRImage,
      scannerLoading,
      hideScanner,
      handleError,
      submitError,
      handleScan,
      handleLoad,
      legacyMode,
      submitting,
      setSkipPaymentOption,
      skipPaymentOption,
      result,
      setActivationToken,
      bQueryStringPromoCode,
    } = props;

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {(formikProps: any) => (
          <>
            <Form className="RegistrationSection">
              <div className="RegistrationStart">
                {/* {isCurrentStep("stepOne") && (
                  <LandingPage
                    values={formikProps.values}
                    data={data}
                    setTaxRate={setTaxRate}
                    alreadyHasAccount={alreadyHasAccount}
                    setAlreadyHasAccount={setAlreadyHasAccount}
                    handleFormSubmit={handleFormSubmit}
                    setCurrentRegistrationStep={setCurrentRegistrationStep}
                  />
                )} */}
              </div>
              {!isCurrentStep("qr") &&
                !isCurrentStep("successPage") &&
                !isCurrentStep("personalInfoWithAccount") && (
                  <div className="RegisterSection">
                    <div className="RegistrationWrapper">
                      <div className="RegistrationStep">
                        <div className="RegistrationStepHeader">
                          <h2
                            className={
                              !isCurrentStep("personalInfo") ? "Closed" : ""
                            }
                          >
                            <span className="Tick">
                              {isStepComplete("personalInfo") ? (
                                <img src={Complete} />
                              ) : (
                                "1."
                              )}
                            </span>{" "}
                            Personal Information
                          </h2>
                          {isStepComplete("personalInfo") &&
                            registrationStep.currentStepName !==
                              "personalInfo" && (
                              <div
                                className="Btn BtnCopy"
                                onClick={() => {
                                  setCurrentRegistrationStep("personalInfo");
                                  setValidationSchema(
                                    personalInfoValidationSchema
                                  );
                                  window.scrollTo(0, 0);
                                }}
                              >
                                Edit
                              </div>
                            )}
                        </div>
                        {isCurrentStep("personalInfo") && (
                          <PersonalInformationFormRegistrationInner
                            values={formikProps.values}
                            isCurrentStep={isCurrentStep}
                            registrationStep={registrationStep}
                            setCurrentRegistrationStep={
                              setCurrentRegistrationStep
                            }
                            isSubmitting={submitting}
                          />
                        )}
                      </div>
                      <div className="RegistrationStep">
                        <div className="RegistrationStepHeader">
                          <h2
                            className={
                              !isCurrentStep("subscription") ? "Closed" : ""
                            }
                          >
                            <span className="Tick">
                              {isStepComplete("subscription") ? (
                                <img src={Complete} />
                              ) : (
                                "2."
                              )}
                            </span>{" "}
                            Subscription
                          </h2>
                          {isStepComplete("subscription") &&
                            registrationStep.currentStepName !==
                              "subscription" && (
                              <div
                                className="Btn BtnCopy"
                                onClick={() => {
                                  setCurrentRegistrationStep("subscription");
                                  setValidationSchema(paymentValidationSchema);
                                  window.scrollTo(0, 0);
                                }}
                              >
                                Edit
                              </div>
                            )}
                        </div>
                        {isCurrentStep("subscription") && (
                          <SubscriptionStep
                            data={data}
                            subscription={subscription}
                            setSubscription={setSubscription}
                            setSubscriptionName={setSubscriptionName}
                            setSubscriptionPrice={setSubscriptionPrice}
                            setSubscriptionGross={setSubscriptionGross}
                            setSubscriptionType={setSubscriptionType}
                            setActivePage={setActivePage}
                            usersPromoCode={usersPromoCode}
                            setUsersPromoCode={setUsersPromoCode}
                            setPromoCodeValue={setPromoCodeValue}
                            setPromoCodeType={setPromoCodeType}
                            promoCodeVisible={promoCodeVisible}
                            setPromoCodeVisible={setPromoCodeVisible}
                            onLastStep={onLastStep}
                            setDisplayError={setDisplayError}
                            disableCardInput={disableCardInput}
                            setDisableCardInput={setDisableCardInput}
                            setFullDiscount={setFullDiscount}
                            setOnLastStep={setOnLastStep}
                            handleFormSubmit={handleFormSubmit}
                            setSkipPaymentOption={setSkipPaymentOption}
                            bQueryStringPromoCode={bQueryStringPromoCode}
                          />
                        )}
                      </div>
                      {subscriptionType === "Yearly" && (
                        <div className="RegistrationStep">
                          <div className="RegistrationStepHeader">
                            <h2
                              className={
                                !isCurrentStep("addons") ? "Closed" : ""
                              }
                            >
                              <span className="Tick">
                                {isStepComplete("addons") ? (
                                  <img src={Complete} />
                                ) : (
                                  "3."
                                )}
                              </span>{" "}
                              Add-ons
                            </h2>
                            {isStepComplete("addons") &&
                              registrationStep.currentStepName !== "addons" && (
                                <div
                                  className="Btn BtnCopy"
                                  onClick={() => {
                                    setCurrentRegistrationStep("addons");
                                    setValidationSchema(
                                      paymentValidationSchema
                                    );
                                    window.scrollTo(0, 0);
                                  }}
                                >
                                  Edit
                                </div>
                              )}
                          </div>
                          {isCurrentStep("addons") && (
                            <AddOnsStep
                              data={data}
                              handleFormSubmit={handleFormSubmit}
                            />
                          )}
                        </div>
                      )}

                      <div className="RegistrationStep">
                        <h2
                          className={!isCurrentStep("payment") ? "Closed" : ""}
                        >
                          <span className="Tick">
                            {isStepComplete("payment") ? (
                              <img src={Complete} />
                            ) : subscriptionType === "Yearly" ? (
                              "4."
                            ) : (
                              "3."
                            )}
                          </span>{" "}
                          Payment
                        </h2>
                        {isCurrentStep("payment") && (
                          <StripeRegistration
                            submit={handleFormSubmit}
                            setPaymentDetails={setPaymentDetails}
                            alreadyHasAccount={alreadyHasAccount}
                            setActivePage={setActivePage}
                            onLastStep={onLastStep}
                            setDisplayError={setDisplayError}
                            promoCodeValue={promoCodeValue}
                            promoCodeVisible={promoCodeVisible}
                            promoCodeType={promoCodeType}
                            isSubmitting={submitting}
                            subscriptionGross={subscriptionGross}
                            fullDiscount={fullDiscount}
                            setFullDiscount={setFullDiscount}
                            disableCardInput={disableCardInput}
                            setDisableCardInput={setDisableCardInput}
                            skipPaymentOption={skipPaymentOption}
                            usersPromoCode={usersPromoCode}
                            setValidationSchema={setValidationSchema}
                            validationSchema={validationSchema}
                            submitError={submitError}
                            paymentValidationSchema={paymentValidationSchema}
                            noPaymentDetailsValidationSchema={
                              noPaymentDetailsValidationSchema
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="RegistrationSummary">
                      {!alreadyHasAccount && (
                        <SummaryPage
                          values={formikProps.values}
                          promoCodeType={promoCodeType}
                          promoCodeValue={promoCodeValue}
                          fullDiscount={fullDiscount}
                          errorType={errorType}
                          displayError={displayError}
                          isStepComplete={isStepComplete}
                          setCurrentRegistrationStep={
                            setCurrentRegistrationStep
                          }
                          personalInfoValidationSchema={
                            personalInfoValidationSchema
                          }
                          paymentValidationSchema={paymentValidationSchema}
                          personalDetails={personalDetails}
                          usersPromoCode={usersPromoCode}
                          promoCodeVisible={promoCodeVisible}
                          subscriptionName={subscriptionName}
                          subscriptionPrice={subscriptionPrice}
                          subscriptionGross={subscriptionGross}
                          disableCardInput={disableCardInput}
                          paymentDetails={paymentDetails}
                          setOnLastStep={setOnLastStep}
                          setActivePage={setActivePage}
                          billingDetails={billingDetails}
                          setValidationSchema={setValidationSchema}
                        />
                      )}
                    </div>
                  </div>
                )}
              {isCurrentStep("successPage") && (
                <SuccessPage
                  qrCodeString={qrCodeString}
                  personalDetails={personalDetails}
                />
              )}
              {isCurrentStep("personalInfoWithAccount") && (
                <PersonalInformationFormAlreadyAccountRegistrationInner
                  values={formikProps.values}
                  isCurrentStep={isCurrentStep}
                  registrationStep={registrationStep}
                  inputError={inputError}
                  errorType={errorType}
                  setCurrentRegistrationStep={setCurrentRegistrationStep}
                  isSubmitting={submitting}
                  activationToken={activationToken}
                  generalError={generalError}
                  setGeneralError={setGeneralError}
                  showBackToQR={showBackToQR}
                  personalDetails={personalDetails}
                />
              )}
            </Form>
            {isCurrentStep("qr") && (
              <QRCodeScan
                QRError={QRError}
                QRRef={QRRef}
                noCamera={noCamera}
                uploadQRImage={uploadQRImage}
                scannerLoading={scannerLoading}
                hideScanner={hideScanner}
                handleError={handleError}
                handleScan={handleScan}
                handleLoad={handleLoad}
                legacyMode={legacyMode}
                result={result}
                setActivationToken={setActivationToken}
                setActivePage={setActivePage}
                setValidationSchema={setValidationSchema}
                alreadyAccountPersonalInfoValidationSchema={
                  alreadyAccountPersonalInfoValidationSchema
                }
                setCurrentRegistrationStep={setCurrentRegistrationStep}
              />
            )}
          </>
        )}
      </Formik>
    );
  }
);

type QRCodeScanProps = {
  QRError: any;
  QRRef: any;
  noCamera: boolean;
  uploadQRImage(): void;
  scannerLoading: boolean;
  hideScanner: any;
  handleError(e: any): void;
  handleScan(e: any): void;
  handleLoad(): void;
  legacyMode: boolean;
  result: string;
  setActivationToken(p: any): void;
  setActivePage(s: string): void;
  setCurrentRegistrationStep(s: string): void;
  setValidationSchema(s: string): void;
  alreadyAccountPersonalInfoValidationSchema: any;
};

const QRCodeScan = React.forwardRef(
  (props: RouteComponentProps & QRCodeScanProps) => {
    const {
      QRError,
      noCamera,
      hideScanner,
      scannerLoading,
      uploadQRImage,
      result,
      setActivationToken,
      setValidationSchema,
      alreadyAccountPersonalInfoValidationSchema,
      setCurrentRegistrationStep,
      QRRef,
      handleScan,
      handleError,
      handleLoad,
      legacyMode,
    } = props;
    return (
      <div className="Register">
        <h1>Enter your QR code</h1>
        {QRError && (
          <p className="ErrorMessageCenter">
            There was a problem with your QR Code
          </p>
        )}
        <div className={`QRScanner`}>
          {noCamera && (
            <div className="QRScannerContainer">
              <h4>
                This device does not have a camera,
                <br />
                or your browser has blocked access to your camera
              </h4>
              <div className="Btn" onClick={() => uploadQRImage()}>
                Upload a QR code image file
              </div>
            </div>
          )}
          <div style={scannerLoading || noCamera ? hideScanner : {}}>
            <QrReader
              ref={QRRef}
              delay={300}
              onError={handleError}
              onScan={handleScan}
              onLoad={handleLoad}
              legacyMode={legacyMode}
            />
          </div>
        </div>
        <QRCodeForm
          result={result}
          submit={(
            values: any,
            setFieldError: (field: string, val: string) => void,
            setSubmitting: (val: boolean) => void
          ) => {
            setActivationToken(values.code);
            setValidationSchema(alreadyAccountPersonalInfoValidationSchema);
            setCurrentRegistrationStep("personalInfoWithAccount");
          }}
        />
      </div>
    );
  }
);

export { RegisterPage };
