import React from "react";
import { Transition } from "react-transition-group";
import { useOnClickOutside } from "@avamae/hooks";
import Cross from "assets/images/Icon_Modal_Cross.png";
import { AppState } from "../../reducers/rootReducer";
import { useSelector, useDispatch } from "react-redux";
import { SelectInputField } from "../BaseUIComponents/SelectInput/SelectInput";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { dateObjectToString } from "../../helpers/dates";
import { DateTimeRangeField } from "../FormComponents/FilterDatePicker/FilterDatePicker";

export interface TransactionHistoryFilterProps {
  table: any;
}

const TransactionHistoryFilter: React.FC<TransactionHistoryFilterProps> = ({
  table
}) => {
  const bind = useOnClickOutside(() => dispatch({ type: "CLOSE_RIGHT_POP_OUT" }));
  const rightPopOutState = useSelector((state: AppState) => state.rightPopOutReducer)
  const dispatch = useDispatch()

  const handleSubmit = async (values: any, setFieldError: any, setSubmitting: any, setStatus: any) => {
    let textValues = Object.keys(values).filter(k => !k.includes("_filter"))
    let filters = []

    for (const v of textValues) {
      if (v === "SubscriptionTransactionHistory__AddDateUTC") {
        if (values[v]) {
          if (values[v].filters[0].value.includes("NULL")) {
            setFieldError("SubscriptionTransactionHistory__AddDateUTC", "Please select both a start and end date")
          } else {
            filters.push(
              {
                columnKey: v,
                operator: "BETWEEN",
                value: dateObjectToString(values[v].filters[0].value)
              }
            )
          }
        }
      } else {
        if (values[v]) {
          filters.push(
            {
              columnKey: v,
              operator: values[`${v}_filter`],
              value: values[v]
            }
          )
        }
      }
    }

    if (table.data && filters.length > 0) {
      table.data.actions.setFilters(filters)
      dispatch({ type: "CLOSE_RIGHT_POP_OUT" })
    }

    if (filters.length === 0) {
      setStatus({ error: 'Error' })
    }

    /*
     {
      columnKey: string; field name
      operator: Omit<Operator, "INSTRINGARRAY">; - sort (CONTAINS)
      value: string; text value
     }
     */

  }

  const initialValues = {
    SubscriptionTransactionHistory__GrossAmount: "",
    SubscriptionTransactionHistory__GrossAmount_filter: "CONTAINS",
    SubscriptionTransactionHistory__Title: "",
    SubscriptionTransactionHistory__Title_filter: "CONTAINS",
    SubscriptionTransactionHistory__AddDateUTC: "",
  }

  let textFilterOptions = [{ value: "CONTAINS", label: "Contains" }, { value: "EQ", label: "Equals" }]
  let intFilterOptions = [{ value: "CONTAINS", label: "Contains" }, { value: "EQ", label: "Equals" }, { value: "GT", label: "Greater than" }, { value: "GEQ", label: "Greater or equal to" }, { value: "LT", label: "Less than" }, { value: "LEQ", label: "Less than or equal to" },]

  return (
    <Transition in={rightPopOutState.rightPopOutOpen === "TRANSACTION_HISTORY_FILTER"} timeout={100} mountOnEnter unmountOnExit>
      {state => (
        <>
          <div className={"RightPopOutUnderlay " + state}>
          </div>

          <div className={"RightPopOut " + state} {...bind}>
            <div className="Inner">
              <div className="Top">
                <h2 className="Title">Filter Transaction History</h2>
                <div className="CloseButton">
                  <img className="CloseButton" alt="Logo" src={Cross} onClick={() => dispatch({ type: "CLOSE_RIGHT_POP_OUT" })} />
                </div>
              </div>
              <div className="Children">
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values, { setFieldError, setSubmitting, setStatus }) => {
                    handleSubmit(values, setFieldError, setSubmitting, setStatus)
                  }}
                  render={({ isValid, values, isSubmitting, status }) => (
                    <Form>
                      <div className={`TransactionHistoryFilter`}>
                        {status && status.error && <div className="ErrorMessageLeft" style={{ marginBottom: "5px" }}>Please complete at least one filter type</div>}
                        <div className="FormBox">
                          
                          <div className="FormSideBySide NoMargin">
                            <div className="FormLabel FormLabelFilters">
                              <label>Description</label>
                            </div>
                            <div className="FormField FormFieldFilters">

                              <SelectInputField
                                options={textFilterOptions}
                                name="SubscriptionTransactionHistory__Title_filter"
                                placeholder="Contains"
                                errors={[]}
                              />
                            </div>
                            <div className="FormField FormFieldFilters">
                              <Field
                                type="text"
                                name="SubscriptionTransactionHistory__Title"
                                id="SubscriptionTransactionHistory__Title"
                                className="EditBox"
                              />
                            </div>
                          </div>

                          <div className="FormSideBySide NoMargin">
                            <div className="FormLabel FormLabelFilters">
                              <label>Gross amount</label>
                            </div>
                            <div className="FormField FormFieldFilters">
                              <SelectInputField
                                options={intFilterOptions}
                                name="SubscriptionTransactionHistory__GrossAmount_filter"
                                placeholder="Contains"
                                errors={[]}
                              />
                            </div>
                            <div className="FormField FormFieldFilters">
                              <Field
                                type="text"
                                name="SubscriptionTransactionHistory__GrossAmount"
                                id="SubscriptionTransactionHistory__GrossAmount"
                                className="EditBox"
                              />
                            </div>
                          </div>

                          <div className="FormSideBySide NoMargin">
                            <DateTimeRangeField
                              name="SubscriptionTransactionHistory__AddDateUTC"
                              label="Add date range"
                            />
                          </div>
                          <ErrorMessage name={"SubscriptionTransactionHistory__AddDateUTC"} component="div">
                            {msg => <div className="ErrorMessageLeft">{msg}</div>}
                          </ErrorMessage>

                        </div>
                      </div>
                      <div className="Bottom">
                        <button className="Btn BtnWhite" onClick={() => dispatch({ type: "CLOSE_RIGHT_POP_OUT" })}>
                          Cancel
                        </button>
                        <button className="Btn" type="submit">
                          Filter
                        </button>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </Transition>

  ) 
};

export default TransactionHistoryFilter;
