import React, { useState } from "react";
import queryString from "query-string";
import { RouteComponentProps } from "@reach/router";
import { endpoints } from "../../serverDetails";
import Spinner from "react-spinkit"
import axios from "api"
import Helmet from "react-helmet";

const errorResults = "/content/images/Icon_Error.png"

const YourQRCode = (props: RouteComponentProps) => {
  let postObject = queryString.parse(props.location!.search)
  const [loading, setLoading] = useState<boolean>(true)
  const [qrCodeString, setQrCodeString] = useState<string | null>(null)
  const [error, setError] = useState<boolean>(false)

  React.useEffect(() => {
    axios
      .post(endpoints.yourQrCode.generateQR, postObject)
      .then(res => {
        if (res.data.status === "1") {
          setQrCodeString(res.data.details.base64_qrcode)
          setLoading(false)
        }
      })
      .catch(err => {
        setLoading(false)
        setError(true)
      })
    //eslint-disable-next-line
  },[])



  if (loading) {
    return (
      <div className="SignInFormSection WidthContent HeightContent QR">
        <h1>Your QR Code</h1>
        <div className="QRContainer">
          <span><Spinner name="three-bounce" fadeIn="none" /></span>
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className="SignInFormSection WidthContent HeightContent QR">
        <h1>Your QR Code</h1>
        <div className="ErrorResults">
          <div className="NoResults">
            <img src={errorResults} alt={"Error"} />
            <p>Your link is invalid</p>
          </div>
        </div>
      </div>
    )
  }


  if (qrCodeString) {
    return (
      <>
        <Helmet>
          <title>Skippy Scout: Your QR Code</title>
        </Helmet>
        <div className="SignInFormSection WidthContent HeightContent QR">
          <h1>Your QR Code</h1>
          <div className="QRContainer">
            <img src={qrCodeString} className="QRImage" alt={"QR Code"} />
          </div>
        </div>
      </>
    )
  }

  return null
}

export default YourQRCode;