import React, { useRef } from "react";
import { ImagesSectionProps } from "./models";
import mapImageUrlPlaceholder from "../../assets/images/map-image-placeholder_vertical.jpg";
import imageUrlPlaceholder from "../../assets/images/image-placeholder.jpg";
import aiImageUrlPlaceholder from "../../assets/images/ai-image-placeholder.jpg";

const ImagesSection: React.FC<ImagesSectionProps> = ({
  mapImageUrl,
  scoutPointImageUrl,
  aiImageUrl,
  iconImage,
  scoutPointPositionX,
  scoutPointPositionY,
  renderedImage,
  scoutPointNumber,
  finalScoutPoint,
  loadStatus,
  setLoadStatus,
}) => {
  const [imagesLoaded, setImagesLoaded] = React.useState({
    ScoutPointIcon: false,
    ScoutPointImage: false,
    ScoutImage: false,
    AIImage: false,
  })
  const [mapImageErrored, setMapImageErrored] = React.useState<boolean>(false);
  const [scoutPointImageErrored, setScoutPointImageErrored] = React.useState<
    boolean
  >(false);
  const [aiImageErrored, setAiImageErrored] = React.useState<boolean>(false);
  const imagesContainerRef = useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (
      finalScoutPoint && 
      imagesLoaded.ScoutPointIcon && 
      imagesLoaded.ScoutPointImage && 
      imagesLoaded.ScoutImage && 
      imagesLoaded.AIImage && 
      !loadStatus.ScoutPointPages
    ) {
      setLoadStatus((prev) => ({...prev, ScoutPointPages: true}));
    }
  }, [imagesLoaded])

  const handleMapImageSrcUrlErrored = () => {
    setMapImageErrored(true);
  };
  const handleScoutPointImageSrcUrlErrored = () => {
    setScoutPointImageErrored(true);
  };
  const handleAiImageSrcUrlErrored = () => {
    setAiImageErrored(true);
  };

  let finalScoutPointPositionX = (scoutPointPositionX / 1278) * 100;
  let finalScoutPointPositionY = (scoutPointPositionY / 2156) * 100;

  const usePlaceholders =
    window.location &&
    window.location.search &&
    window.location.search.includes("useplaceholders=true");
  const mapImageSrc =
    usePlaceholders || mapImageErrored || !mapImageUrl
      ? mapImageUrlPlaceholder
      : mapImageUrl;
  const scoutPointImageSrc =
    usePlaceholders || scoutPointImageErrored || !scoutPointImageUrl
      ? imageUrlPlaceholder
      : scoutPointImageUrl;
  const aiImageSrc =
    usePlaceholders || aiImageErrored || !aiImageUrl
      ? aiImageUrlPlaceholder
      : aiImageUrl;
  return (
    <div className="ImagesContainer" ref={imagesContainerRef}>
      <div
        className="Image"
        style={{
          marginRight: "2rem",
        }}
      >
        {renderedImage && iconImage && (
          <div
            className="ScoutPointIconWrapper"
            style={{
              left: `${finalScoutPointPositionX}%`,
              top: `${finalScoutPointPositionY}%`,
            }}
          >
            <div className="ScoutPointIconNumber">{scoutPointNumber}</div>
            <img
              className="ScoutPointIcon"
              src={iconImage ? iconImage : ""}
              alt="scoutPointPosition"
              onLoad={() => {
                finalScoutPoint && setImagesLoaded((prev) => ({
                  ...prev,
                  ScoutPointIcon: true,
                }))
              }}
            />
          </div>
        )}
        <img
          className="ScoutPointImage"
          src={renderedImage ? renderedImage : mapImageSrc}
          onError={handleMapImageSrcUrlErrored}
          alt="mapboxImage"
          onLoad={() => {
            finalScoutPoint && setImagesLoaded((prev) => ({
              ...prev,
              ScoutPointImage: true,
            }))
          }}
        />
        {/* {mapImageErrored || !mapImageUrl ? (
          <div>
            <img
              src={mapImageUrlPlaceholder}
              onError={handleMapImageSrcUrlErrored}
              alt="mapboxImage"
            />
          </div>
        ) : (
          <img
            src={mapImageUrl}
            onError={handleMapImageSrcUrlErrored}
            alt="mapboxImage"
          />
        )} */}
      </div>
      <div className="StackImages">
        <img
          src={scoutPointImageSrc}
          onError={handleScoutPointImageSrcUrlErrored}
          alt="scoutImage"
          onLoad={() => {
            finalScoutPoint && setImagesLoaded((prev) => ({
              ...prev,
              ScoutImage: true,
            }))
          }}
        />
        <img
          src={aiImageSrc}
          onError={handleAiImageSrcUrlErrored}
          alt="AIImage"
          style={{ marginTop: "2.2%" }}
          onLoad={() => {
            finalScoutPoint && setImagesLoaded((prev) => ({
              ...prev,
              AIImage: true,
            }))
          }}
        />
      </div>
    </div>
  );
};

export { ImagesSection };
