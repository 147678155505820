import { Redirect } from "@reach/router";
import { useFetch } from "helpers/loading";
import React from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-spinkit";
import { endpoints } from "serverDetails";
import { AppState } from "../../reducers/rootReducer";
import {
  IntegrationProps,
  Integrations,
  ThirdPartyIntegrationsProps,
} from "./models";

const ThirdPartyIntegrations = (props: ThirdPartyIntegrationsProps) => {
  const dispatch = useDispatch();
  const subscriptionStatus = useSelector(
    (state: AppState) => state.auth.subscriptionStatus
  );

  const { data, loading: pending, error } = useFetch(
    endpoints.integrations.getDetails
  );
  console.log({ data, pending, error });
  if (subscriptionStatus !== "Valid" && subscriptionStatus !== "Initial") {
    return <Redirect to="/" noThrow={true} />;
  }

  if (pending) {
    return (
      <span>
        <Spinner
          name="three-bounce"
          className="NotificationsSpin"
          fadeIn="none"
        />
      </span>
    );
  }

  if (error) {
    return (
      <div className="ErrorResults">
        <div className="NoResults">
          <p>An Error has occurred</p>
        </div>
      </div>
    );
  }

  if (data) {
    return (
      <>
        <Helmet>
          <title>Skippy Scout: Upload fields</title>
        </Helmet>
        <div className="PageWrapper">
          <div className="ThirdPartyIntegrations WidthContent HeightContent">
            {data.details.list_integrations.length > 0 ? (
              data.details.list_integrations.map((item: Integrations) => {
                return (
                  <IntegrationItem
                    logo={item.image_url}
                    title={item.display_name}
                    description={item.description}
                    url={item.url}
                  />
                );
              })
            ) : (
              <p>
                There are no integrations available to you at this point, please
                try again later.
              </p>
            )}
          </div>
        </div>
      </>
    );
  }

  return null;
};

export default ThirdPartyIntegrations;

const IntegrationItem = (props: IntegrationProps) => {
  return (
    <div className="IntegrationContainer">
      <div className="IntegrationItem">
        {props.logo && <img src={props.logo} alt="Company-Logo" />}
        <div className="IntegrationItemContent">
          <h2>{props.title}</h2>
          <p>
            <span dangerouslySetInnerHTML={{ __html: props.description }} />
          </p>
        </div>
      </div>
      {props.url && (
        <a
          onClick={() =>
            window.open(props.url, "newwindow", "width=1024,height=768")
          }
          className="Btn"
        >
          Connect
        </a>
      )}
    </div>
  );
};
