import { connect } from "react-redux";
import Page, { PageProps } from "./Page";
import { AppState } from "reducers/rootReducer";
import { selectIsAuthenticated } from "reducers/auth";
import { RouteComponentProps } from "@reach/router";
import { compose } from "recompose";

export const mapStateToProps = (
  state: AppState
): { isAuthenticated: boolean } => ({
  isAuthenticated: selectIsAuthenticated(state),
});

export default compose<PageProps & RouteComponentProps, {}>(
  connect(mapStateToProps)
)(Page);
