import React from "react";
import axios from "api";
import { useDispatch, useSelector } from "react-redux";
import { endpoints } from "../../serverDetails";
import { AppState } from "../../reducers/rootReducer";
import { removeHasNotes } from "reducers/reportSection";

export interface UsersDelete {
  class?: string;
}

const UsersDelete = (props: UsersDelete) => {
  const dispatch = useDispatch();

  const id = useSelector((state: AppState) => state.modalReducer.modalId);

  const handleToast = (message: string) => {
    dispatch({ type: "SHOW_TOAST", message: message });
    window.setTimeout(() => {
      dispatch({ type: "HIDE_TOAST" });
    }, 3000);
  };

  const handleSubmit = () => {
    if (id && id.length === 1) {
      //setSubmitting(true);
      axios
        .post(endpoints.reportsModule.deletereportnotes, { reportsId: id[0] })
        .then(res => {
          if (res.data.status === "1") {
            //setSubmitting(false)
            dispatch(removeHasNotes(id[0]));
            dispatch({ type: "CLOSE_MODAL" });
            handleToast("Notes successfully deleted");
          }
        })
        .catch(err => {
          handleToast("Notes failed to be deleted, please try again");
          //setError(true)
          //setSubmitting(false)
        });
    }
  };

  return (
    <>
      <div className={`Delete ${props.class || ""}`}>
        <h3>Permanently Delete</h3>

        <p>Are you sure you want to permanently delete these notes?</p>

        <div className="WarningText">
          <img src="/content/images/Icon_Warning.png" alt="Warning" />
          <p>This action cannot be undone</p>
        </div>
      </div>
      <div className="Bottom">
        <button
          className="Btn BtnWhite"
          onClick={() => dispatch({ type: "CLOSE_MODAL" })}
        >
          Cancel
        </button>
        <button
          className="Btn BtnRed"
          onClick={() => {
            handleSubmit();
          }}
        >
          Delete
        </button>
      </div>
    </>
  );
};

export default UsersDelete;
