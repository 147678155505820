import * as React from "react";
// import styles from "./ForgotPasswordPage.module.css";

import { RouteComponentProps, Link } from "@reach/router";
import { useFetch } from "helpers/loading";
import { endpoints } from "serverDetails";
import { AxiosRequestConfig } from "axios";
import { getInvoiceDownload } from "./InvoicesApi";
import { useState } from "react";
import { selectAuthErrors } from "reducers/auth";
import Helmet from "react-helmet";
import Spinner from "react-spinkit";
import queryString from "query-string";
import Paid from "../../../src/assets/images/paidstamp.jpg";
import SSLogo from "../../../src/assets/images/Logo_PDF.svg";
const Logo = "/content/images/Icon_NoResult.png";
const ErrorLogo = "/content/images/Icon_Warning.png";

type ClientUserData = {
  first_name: string;
  last_name: string;
};

type InvoiceData = {
  invoice_id: number;
  invoice_code: string;
  client_company_name: string;
  vat_registration_number: string;
  subscription_name: string;
  invoice_net_amount: string | number;
  invoice_sub_amount: string | number;
  invoice_tax_amount: string | number;
  invoice_tax_rate: string | number;
  invoice_gross_amount: string | number;
  client_user: any;
  invoice_StartDateDisplay: string;
  invoice_EndDateDisplay: string;
  invoice_PaymentDateDisplay: string;
  invoice_address: string[];
  addon_list: AddOns[];
};

type AddOns = {
  addon_name: string;
  addon_short_description: string;
  addon_net_price: number;
};

const InvoicesPage = (props: RouteComponentProps) => {
  const [data, setData] = useState<InvoiceData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [tokenError, setTokenError] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const search = props.location ? props.location.search : "";
  const parsed = queryString.parse(search);
  const id = parsed.invoice_id as string;
  const token = parsed.token;

  React.useEffect(() => {
    if (parsed) {
      (async () => {
        if (id && token) {
          const result = await getInvoiceDownload(parseInt(id, 10), token);
          if (result.response) {
            setData(result.response.details);
          }
          if (result.errors) {
            switch (result.errors.response.data.errors[0].message_code) {
              case "Invalid_Token":
                setGeneralError(true);
                setErrorMessage(
                  "An  invalid token has been used. Please try again."
                );
                break;
              case "Invalid_Invoice_ID":
                setGeneralError(true);
                setErrorMessage(
                  "An  invalid invoice ID has been used. Please try again."
                );
                break;
              default:
                setGeneralError(true);
                setErrorMessage(
                  "Oops, something went wrong. Please try again."
                );
            }
          }
        } else {
          // handle missing id / token
          if (!id) {
            setGeneralError(true);
            setErrorMessage("Invalid ID. Please try again.");
          } else if (!token) {
            setGeneralError(true);
            setErrorMessage("Invalid token. Please try again.");
          } else {
            setGeneralError(true);
            setErrorMessage("Oops, something went wrong. Please try again.");
          }
        }
      })();
    }
  }, []);

  const error = false;

  return (
    <>
      <Helmet>
        <title>Skippy Scout: Invoice</title>
      </Helmet>
      {loading && (
        <span>
          <Spinner name="three-bounce" fadeIn="none" />
        </span>
      )}
      {data && (
        <form>
          <div className="InvoiceContainer">
            <div className="PageContentBox">
              <div className="HeaderContainer">
                <div className="LeftSide">
                  <h1>INVOICE</h1>
                  <div className="SubTitle">{data.client_company_name}</div>
                  <div className="SubTitle">
                    Invoice Code: {data.invoice_code}
                  </div>
                  <div className="TimeDate">
                    Start Date: {data.invoice_StartDateDisplay}
                  </div>
                  <div className="TimeDate">
                    End Date: {data.invoice_EndDateDisplay}
                  </div>
                </div>

                <div className="RightSide">
                  <img src={SSLogo} alt="Skippy Scout Logo" />
                </div>
              </div>
              <div className="DetailsContainer">
                <div className="LeftSideContainer">
                  <div className="LeftSide">
                    <h2>Invoice From: </h2>
                    {data.invoice_address.map(item => {
                      return <p>{item}</p>;
                    })}
                    <p>
                      <span>VAT Registration Number: </span>
                      {data.vat_registration_number}
                    </p>
                  </div>
                  <div className="LeftSide LeftSideRight">
                    <h2>Invoice To: </h2>
                    <p>{data.client_company_name}</p>
                    {data.client_user &&
                      data.client_user[0].first_name &&
                      data.client_user[0].last_name && (
                        <p>
                          {data.client_user[0].first_name}{" "}
                          {data.client_user[0].last_name}
                        </p>
                      )}
                  </div>
                </div>
                <div className="RightSide">
                  <h2>Payment Date</h2>
                  <p>{data.invoice_PaymentDateDisplay}</p>
                  <img src={Paid} alt="Paid Logo" />
                </div>
              </div>
              <div className="SummaryContainer">
                <div className="TableContainer">
                  <table>
                    <tr>
                      <th className="Left">Subscription</th>
                      <th className="Middle">Company name</th>
                      <th className="Right">Amount</th>
                    </tr>
                    <tr>
                      <td className="Left">{data.subscription_name}</td>
                      <td className="Middle">{data.client_company_name}</td>
                      <td className="Right">{data.invoice_net_amount}</td>
                    </tr>
                  </table>
                </div>
                <div className="TableContainer">
                  <table>
                    <tr>
                      <th className="Left">Addon</th>
                      <th className="Right">Amount</th>
                    </tr>
                    {data.addon_list.map(({ addon_name, addon_net_price }) => (
                      <tr>
                        <td className="Left">{addon_name}</td>
                        <td className="Right">{addon_net_price}</td>
                      </tr>
                    ))}
                  </table>
                </div>
                <div className="PriceSummary">
                  <div className="SubTotal">
                    SUB TOTAL: <span>{data.invoice_sub_amount}</span>
                  </div>
                  <div className="SubTotal">
                    VAT ({data.invoice_tax_rate}):{" "}
                    <span>{data.invoice_tax_amount}</span>
                  </div>
                  <div className="SubTotal">
                    TOTAL (INCL VAT): <span>{data.invoice_gross_amount}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      {tokenError && (
        <div className="ErrorPageContainer">
          <img
            src={Logo}
            alt="Skippy Scout"
            style={{ height: "80px", width: "80px" }}
          />
          <h1>Sorry, that page doesn't exist</h1>
          <Link to="/" className="Btn">
            Return to Login
          </Link>
        </div>
      )}
      {generalError && (
        <div className="ErrorPageContainer">
          <img
            src={ErrorLogo}
            alt="Skippy Scout"
            style={{ height: "60px", width: "60px" }}
          />
          <h2>{errorMessage}</h2>
          <Link to="/" className="Btn">
            Return to Login
          </Link>
        </div>
      )}
    </>
  );
};

export default InvoicesPage;
