import React from "react";
import { useFetch } from "../../helpers/loading";
import { endpoints } from "../../serverDetails";
import Spinner from "react-spinkit";
import { useSelector, useDispatch } from "react-redux";
import { formatUTCDateString } from "../../helpers/dates";

const errorResults = "/content/images/Icon_Error.png"

export interface PaymentCardView {
  class?: string;
}

type Data = {
  details: {
    id: number,
    name_on_card: string,
    card_type: string,
    card_end_digits: string,
    expiry_date: string,
    title: string,
    first_name: string,
    last_name: string,
    email: string,
    telephone: string,
    address_line_1: string,
    address_line_2: string | null,
    town_city: string,
    county_state: string | null,
    postcode: string,
    country: string,
    add_date_utc: string,
    edit_date_utc: string | null,
    added_by: string,
  }
};

const PaymentCardView: React.FC<PaymentCardView> = (props: PaymentCardView) => {
  const dispatch = useDispatch();
  const state = useSelector<any, any>(state => state.tableReducer)

  const requestConfig = React.useMemo(() => {
    return { params: { Id: state.selectedCardIds[0] } };
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    return () => {
      // if opened from ... menu (not from input selection => right menu)
      if (!state.showMenu) {
        dispatch({
          type: "SET_SELECTED_CARDS",
          selectedCardIds: []
        })
      }
    }
    //eslint-disable-next-line
  },[])

  const { data, loading, error } = useFetch<Data>(endpoints.paymentCards.viewPaymentCard, requestConfig);

  if (loading) {
    return <span><Spinner name="three-bounce" fadeIn="none"/></span>;
  }

  if (error) return (
    <div className="ErrorResults">
      <div className="NoResults">
        <img src={errorResults} alt={"Error"} />
        <p>Error</p>
      </div>
    </div>
  )

  if (data) {
    const d = data.details;
    return (
      <div className={`PaymentCardView ${props.class}`}>
        <div className="DisplayRow">
          <div className="DisplayLabel">Name on card</div>
          <div className="DisplayValue">{d.name_on_card}</div>
        </div>

        <div className="DisplayRow">
          <div className="DisplayLabel">Card type</div>
          <div className="DisplayValue">{d.card_type}</div>
        </div>

        <div className="DisplayRow">
          <div className="DisplayLabel">Card number</div>
          <div className="DisplayValue">**** **** **** {d.card_end_digits}</div>
        </div>

        <div className="DisplayRow">
          <div className="DisplayLabel">Expiry date</div>
          <div className="DisplayValue">{d.expiry_date}</div>
        </div>

        <div className="DisplayRow">
          <div className="DisplayLabel">Add date</div>
          <div className="DisplayValue">{formatUTCDateString(d.add_date_utc)}</div>
        </div>

        <h3>Billing Address</h3>

        <div className="DisplayRow">
          <div className="DisplayLabel">Title</div>
          <div className="DisplayValue">{d.title}</div>
        </div>

        <div className="DisplayRow">
          <div className="DisplayLabel">First name</div>
          <div className="DisplayValue">{d.first_name}</div>
        </div>

        <div className="DisplayRow">
          <div className="DisplayLabel">Last name</div>
          <div className="DisplayValue">{d.last_name}</div>
        </div>

        {/*<div className="DisplayRow">
          <div className="DisplayLabel">Phone number</div>
          <div className="DisplayValue">{d.telephone}</div>
        </div>

        <div className="DisplayRow">
          <div className="DisplayLabel">Email address</div>
          <div className="DisplayValue">{d.email}</div>
        </div>*/}

        <div className="DisplayRow">
          <div className="DisplayLabel">Address line 1</div>
          <div className="DisplayValue">{d.address_line_1}</div>
        </div>

        <div className="DisplayRow">
          <div className="DisplayLabel">Address line 2</div>
          <div className="DisplayValue">{d.address_line_2 || "-"}</div>
        </div>

        <div className="DisplayRow">
          <div className="DisplayLabel">City/Town</div>
          <div className="DisplayValue">{d.town_city}</div>
        </div>

        <div className="DisplayRow">
          <div className="DisplayLabel">County/State</div>
          <div className="DisplayValue">{d.county_state || "="}</div>
        </div>

        <div className="DisplayRow">
          <div className="DisplayLabel">Postcode</div>
          <div className="DisplayValue">{d.postcode}</div>
        </div>

        <div className="DisplayRow">
          <div className="DisplayLabel">Country</div>
          <div className="DisplayValue">{d.country}</div>
        </div>

      </div>
    ) 
  }
  return null

};

export default PaymentCardView;
