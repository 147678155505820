import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../reducers/rootReducer";
import { Link, Redirect } from "@reach/router";
import Helmet from "react-helmet";
import ShareQREmailOrIMessage from "pages/Categories/QRCode/ShareQREmailOrIMessage";
import { ActivateQRNewDevice } from "./ActivateQRNewDevice";
import { ActivateQRNewUser } from "./ActivateQRNewUser";
const Logo = "/content/images/Icon_NoResult.png";

export interface ActivateNewDeviceProps {}

const ActivateNewDevice = (props: ActivateNewDeviceProps) => {
  const subscriptionStatus = useSelector(
    (state: AppState) => state.auth.subscriptionStatus
  );
  const enterpriseUser = useSelector(
    (state: AppState) => state.auth.client_type
  );
  const dispatch = useDispatch();

  const handleToast = (message: string) => {
    dispatch({ type: "SHOW_TOAST", message: message });
    window.setTimeout(() => {
      dispatch({ type: "HIDE_TOAST" });
    }, 3000);
  };

  React.useEffect(() => {
    if (subscriptionStatus !== "Valid" && subscriptionStatus !== "Initial") {
      handleToast(
        "Please renew your subscription to access the 'Activate new device' section"
      );
    }
  }, [subscriptionStatus]);

  if (subscriptionStatus !== "Valid" && subscriptionStatus !== "Initial") {
    return <Redirect to="/" noThrow={true} />;
  }

  return (
    <>
      <Helmet>
        <title>Skippy Scout: Activate New Device</title>
      </Helmet>
      <div className="PageWrapper">
        <div className="PaddingContainer ActivateQRContainer">
          <ActivateQRNewDevice />
          {/* {enterpriseUser === "Enterprise" ?
            <>
              <ActivateQRNewUser />
              <ActivateQRNewDevice />
            </>
            :
            <ActivateQRNewDevice />
          } */}
        </div>
      </div>
    </>
  );
};

export default ActivateNewDevice;
