import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import ChunkyInput from "../ChunkyInput/ChunkyInput";
import { useDispatch, useSelector } from "react-redux";
import { passwordRegex } from "../../../helpers/regex";
import { DropdownField } from "../../FormComponents/Dropdown/Dropdown";
import { PhoneNumberField } from "../../FormComponents/PhoneNumber/PhoneNumber";
import { isValidPhoneNumber } from "react-phone-number-input";
import Spinner from "react-spinkit";
import { RegistrationSteps } from "pages/Registration/Registration";
import { AppState } from "reducers/rootReducer";

type Data = {
  details: PersonalInformationForm;
};

type PersonalInformationForm = {
  title: string;
  first_name: string;
  last_name: string;
  company_name: string;
  vat_number: string;
  telephone: string;
  email_address: string;
  mobile: string;
  address_line_1: string;
  address_line_2: string;
  town_city: string;
  county_state: string;
  postcode: string;
  country_id: number | string;
};

export interface PersonalInformationFormInnerProps {
  password?: boolean;
  countryData?: any;
  isSubmitting?: boolean;
  errorType?: string;
  inputError?: string;
  personalDetails?: any;
  isValid?: boolean;
  isCurrentStep?: (val: string) => void;
  values: any;
  onLastStep?: boolean;
  enterpriseActivation?: boolean;
  registrationStep?: RegistrationSteps;
  setCurrentRegistrationStep?: (val: string) => void;
  activationToken?: any;
  generalError?: string;
  setGeneralError?(val: string): void;
  showBackToQR?: boolean;
}

interface PersonalInformationFormProps {
  password?: boolean;
  data?: Data;
  countryData?: any;
  //submit: (values: any) => void;
  submit: (
    values: any,
    setFieldError: (field: string, val: string) => void,
    setSubmitting: (val: boolean) => void
  ) => void;
  isSubmitting?: boolean;
  isValid?: boolean;
  loggedIn: boolean;
  intialVal?: any;
  onLastStep?: boolean;
  enterpriseActivation?: boolean;
  generalError?: string;
  setGeneralError?(val: string): void;
}

const validationSchema = Yup.object({
  title: Yup.string(),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  company_name: Yup.string()
    .max(75, "Company name must be no longer than 75 characters")
    .required("Company name is required"),
  vat_number: Yup.string(),
  telephone: Yup.mixed()
    .required("A telephone number is required")
    .test(
      "phoneTest",
      "Please enter a valid telephone number",
      (value) => {
        if (!value) return false
        return isValidPhoneNumber(value)
      },
    ),
  email_address: Yup.string()
    .max(75, "Email address must be no longer than 75 characters")
    .required("Email address is required"),
  mobile: Yup.mixed().test(
    "mobileTest",
    "Please enter a valid mobile number",
    (value) => {
      if (!value) return false
      return isValidPhoneNumber(value)
    },
  ),
  password: Yup.string()
    .matches(
      passwordRegex,
      "Password must be 8-20 characters and contain at least one 1 letter and 1 number"
    )
    .required("Password is required"),
  bTermsConditions: Yup.bool().test(
    "bTermsConditions",
    "Please agree to the terms and conditions",
    val => val === true
  ),
});

// Registering an enterprise user
const validationSchemaEnterprise = Yup.object({
  title: Yup.string(),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  telephone: Yup.mixed()
    .required("A telephone number is required")
    .test(
      "phoneTest",
      "Please enter a valid telephone number",
      (value) => {
        if (!value) return false
        return isValidPhoneNumber(value)
      },
    ),
  email_address: Yup.string()
    .max(75, "Email address must be no longer than 75 characters")
    .required("Email address is required"),
  mobile: Yup.mixed().test(
    "mobileTest",
    "Please enter a valid mobile number",
    (value) => {
      if (!value) return false
      return isValidPhoneNumber(value)
    },
  ),
  password: Yup.string()
    .matches(
      passwordRegex,
      "Password must be 8-20 characters and contain at least one 1 letter and 1 number"
    )
    .required("Password is required"),
  bTermsConditions: Yup.bool().test(
    "bTermsConditions",
    "Please agree to the terms and conditions",
    val => val === true
  ),
});

const PersonalInformationFormInner = (
  props: PersonalInformationFormInnerProps
) => {
  const dispatch = useDispatch();
  const [buttonText, setButtonText] = useState<string>(
    "Save personal information"
  );
  //const { data, loading, error } = useFetch(endpoints.personalInfo.countryList);

  const primaryUser = useSelector((state: AppState) => state.auth.primary_user);

  useEffect(() => {
    if (props.enterpriseActivation) {
      setButtonText("Register");
    } else if (!props.enterpriseActivation && props.password) {
      setButtonText("Save and continue");
    }
  }, [setButtonText, props.enterpriseActivation, props.password]);

  return (
    <Form className="PersonalInfoForm">
      <div className="FormRow">
        <div className="FormBox MultiInput">
          <ChunkyInput
            name="title"
            id="title"
            placeholder="Title"
            optional={true}
            maxLength={10}
          />
          <ErrorMessage name="title">
            {message => <span className="ErrorMessageLeft">{message}</span>}
          </ErrorMessage>
        </div>

        <div className="FormBox MultiInput">
          <ChunkyInput
            type="first_name"
            name="first_name"
            id="first_name"
            placeholder="First Name"
            maxLength={50}
          />
          <ErrorMessage name="first_name">
            {message => <span className="ErrorMessageLeft">{message}</span>}
          </ErrorMessage>
        </div>

        <div className="FormBox MultiInput">
          <ChunkyInput
            type="last_name"
            name="last_name"
            id="last_name"
            placeholder="Last name"
            maxLength={50}
          />
          <ErrorMessage name="last_name">
            {message => <span className="ErrorMessageLeft">{message}</span>}
          </ErrorMessage>
        </div>
      </div>

      {!props.enterpriseActivation && (
        <div className="FormBox">
          <ChunkyInput
            type="company_name"
            name="company_name"
            id="company_name"
            placeholder="Company name"
            maxLength={75}
            disabled={!primaryUser}
          />
          <ErrorMessage name="company_name">
            {message => <span className="ErrorMessageLeft">{message}</span>}
          </ErrorMessage>
        </div>
      )}

      {!props.enterpriseActivation && (
        <div className="FormBox">
          <ChunkyInput
            type="vat_number"
            name="vat_number"
            id="vat_number"
            placeholder="VAT number"
            maxLength={50}
            optional
          />
          <ErrorMessage name="vat_number">
            {message => <span className="ErrorMessageLeft">{message}</span>}
          </ErrorMessage>
        </div>
      )}

      <div className="FormBox">
        <PhoneNumberField name={"telephone"} label={"Phone number"} />
        <ErrorMessage name="telephone">
          {message => <span className="ErrorMessageLeft">{message}</span>}
        </ErrorMessage>
      </div>

      <div className="FormBox">
        <PhoneNumberField name={"mobile"} label={"Mobile number"} />
        <ErrorMessage name="mobile">
          {message => <span className="ErrorMessageLeft">{message}</span>}
        </ErrorMessage>
      </div>

      <div className="FormBox">
        <ChunkyInput
          type="email_address"
          name="email_address"
          id="email_address"
          placeholder="Email address"
          maxLength={75}
        />
        <ErrorMessage name="email_address">
          {message => <span className="ErrorMessageLeft">{message}</span>}
        </ErrorMessage>
      </div>

      {!props.password && (
        <>
          <h3>Company Address</h3>

          <div className="FormBox">
            <ChunkyInput
              type="address_line_1"
              name="address_line_1"
              id="address_line_1"
              placeholder="Address line 1"
              maxLength={50}
              disabled={!primaryUser}
            />
            <ErrorMessage name="address_line_1">
              {message => <span className="ErrorMessageLeft">{message}</span>}
            </ErrorMessage>
          </div>

          <div className="FormBox">
            <ChunkyInput
              type="address_line_2"
              name="address_line_2"
              id="address_line_2"
              placeholder="Address line 2"
              maxLength={50}
              optional={true}
              disabled={!primaryUser}
            />
            <ErrorMessage name="address_line_2">
              {message => <span className="ErrorMessageLeft">{message}</span>}
            </ErrorMessage>
          </div>

          <div className="FormRow">
            <div className="FormBox MultiInput EvenWidth">
              <ChunkyInput
                type="town_city"
                name="town_city"
                id="town_city"
                placeholder="Town/city"
                maxLength={50}
                disabled={!primaryUser}
              />
              <ErrorMessage name="town_city">
                {message => <span className="ErrorMessageLeft">{message}</span>}
              </ErrorMessage>
            </div>

            <div className="FormBox MultiInput EvenWidth">
              <ChunkyInput
                type="county_state"
                name="county_state"
                id="county_state"
                placeholder="County/state"
                maxLength={50}
                disabled={!primaryUser}
              />
              <ErrorMessage name="county_state">
                {message => <span className="ErrorMessageLeft">{message}</span>}
              </ErrorMessage>
            </div>
          </div>

          <div className="FormRow">
            <div className="FormBox MultiInput EvenWidth">
              <ChunkyInput
                type="postcode"
                name="postcode"
                id="postcode"
                placeholder="Postcode"
                maxLength={10}
                disabled={!primaryUser}
              />
              <ErrorMessage name="postcode">
                {message => <span className="ErrorMessageLeft">{message}</span>}
              </ErrorMessage>
            </div>

            {props.countryData && (
              <DropdownField
                name="country_id"
                label="Country"
                errors={[]}
                disabled={!primaryUser}
              >
                {props.countryData}
              </DropdownField>
            )}
          </div>
        </>
      )}

      {props.password && (
        <>
          <div className="FormBox">
            <ChunkyInput
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              maxLength={20}
            />
            <ErrorMessage name="password">
              {message => <span className="ErrorMessageLeft">{message}</span>}
            </ErrorMessage>
            <div className="FormHint">At least 8 characters long</div>
          </div>

          <div className="FormBox">
            <div className="RememberForgot">
              <div className="Checkbox">
                <Field
                  name="bTermsConditions"
                  type="checkbox"
                  id="bTermsConditions"
                  checked={props.values.bTermsConditions}
                />
                <label className="CustomInput" htmlFor="bTermsConditions">
                  I agree with the &nbsp;
                  <a
                    href="https://www.skippy.farm/terms-conditions-privacy-and-cookies/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms and conditions
                  </a>
                </label>
              </div>
              <ErrorMessage name="bTermsConditions">
                {message => <span className="ErrorMessageLeft">{message}</span>}
              </ErrorMessage>
            </div>
          </div>
        </>
      )}
      <div className="FormBottom">
        <button
          className={`Btn BtnFullWidth ${props.password ? "" : "BtnMargin"}`}
          type="submit"
          //disabled={props.isSubmitting || !props.isValid}
          disabled={props.isSubmitting}
          // onClick={() => { props.setCurrentRegistrationStep && props.setCurrentRegistrationStep("successPage") }}
        >
          {props.isSubmitting ? (
            <Spinner
              name="three-bounce"
              className="BtnSpinner"
              color="white"
              fadeIn="none"
            />
          ) : (
            buttonText
          )}
        </button>
        {!props.password && !primaryUser && (
          <div
            className={"Btn BtnWhite BtnFullWidth"}
            style={{ marginBottom: "20px" }}
            onClick={() => {
              dispatch({
                type: "OPEN_MODAL",
                modalOpen: "INDIVIDUAL_ACCOUNT_DELETE",
              });
            }}
          >
            Delete individual account
          </div>
        )}
        {!props.password && primaryUser && (
          <div
            className={"Btn BtnWhite BtnFullWidth"}
            onClick={() => {
              dispatch({
                type: "OPEN_MODAL",
                modalOpen: "ACCOUNT_DELETE",
              });
            }}
          >
            Delete account
          </div>
        )}
      </div>
    </Form>
  );
};

const PersonalInformationForm: React.FC<PersonalInformationFormProps> = (
  props: PersonalInformationFormProps
) => {
  const primaryUser = useSelector((state: AppState) => state.auth.primary_user);

  // when user logged in
  const validationSchemaNoPassword = Yup.object({
    title: Yup.string(),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    ...(primaryUser
      ? {
          company_name: Yup.string()
            .max(75, "Company name must be no longer than 75 characters")
            .required("Company name is required"),
        }
      : {}),
    vat_number: Yup.string(),
    telephone: Yup.mixed()
      .required("A telephone number is required")
      .test(
        "phoneTest",
        "Please enter a valid telephone number",
        (value) => {
          if (!value) return false
          return isValidPhoneNumber(value)
        },
      ),
    email_address: Yup.string()
      .max(75, "Email address must be no longer than 75 characters")
      .required("Email address is required"),
    ...(primaryUser
      ? { address_line_1: Yup.string().required("Address line 1 is required") }
      : {}),
    ...(primaryUser
      ? { town_city: Yup.string().required("Town/city is required") }
      : {}),
    ...(primaryUser
      ? { county_state: Yup.string().required("County/state is required") }
      : {}),
    ...(primaryUser
      ? { postcode: Yup.string().required("Postcode is required") }
      : {}),
    mobile: Yup.mixed().test(
      "mobileTest",
      "Please enter a valid mobile number",
      (value) => {
        if (!value) return false
        return isValidPhoneNumber(value)
      },
    ),
  });

  if (props.enterpriseActivation) {
    const initialValues = {
      title:
        props.intialVal && props.intialVal.title ? props.intialVal.title : "",
      first_name:
        props.intialVal && props.intialVal.first_name
          ? props.intialVal.first_name
          : "",
      last_name:
        props.intialVal && props.intialVal.last_name
          ? props.intialVal.last_name
          : "",
      telephone:
        props.intialVal && props.intialVal.telephone
          ? props.intialVal.telephone
          : "",
      mobile:
        props.intialVal && props.intialVal.mobile ? props.intialVal.mobile : "",
      email_address:
        props.intialVal && props.intialVal.email_address
          ? props.intialVal.email_address
          : "",
      password:
        props.intialVal && props.intialVal.password
          ? props.intialVal.password
          : "",
      bTermsConditions:
        props.intialVal && props.intialVal.bTermsConditions
          ? props.intialVal.bTermsConditions
          : "",
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemaEnterprise}
        onSubmit={(values, { setFieldError, setSubmitting }) => {
          props.submit(values, setFieldError, setSubmitting);
        }}
        render={({ isValid, values, isSubmitting }) => (
          <PersonalInformationFormInner
            password={props.password}
            isSubmitting={isSubmitting}
            isValid={isValid}
            values={values}
            enterpriseActivation
          />
        )}
      />
    );
  }

  if (props.data && props.loggedIn) {
    const initialValues = {
      title: props.data.details.title || "",
      first_name: props.data.details.first_name || "",
      last_name: props.data.details.last_name || "",
      company_name: props.data.details.company_name || "",
      vat_number: props.data.details.vat_number || "",
      telephone: props.data.details.telephone || "",
      email_address: props.data.details.email_address || "",
      mobile: props.data.details.mobile || "",
      address_line_1: props.data.details.address_line_1 || "",
      address_line_2: props.data.details.address_line_2 || "",
      town_city: props.data.details.town_city || "",
      county_state: props.data.details.county_state || "",
      postcode: props.data.details.postcode || "",
      country_id: props.data.details.country_id || props.countryData[0].key,
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemaNoPassword}
        //onSubmit={values => {
        //  props.submit(values);
        //}}
        onSubmit={(values, { setFieldError, setSubmitting }) => {
          let modifiedValues = values;
          if (!primaryUser) {
            modifiedValues = {
              ...values,
              company_name: values.company_name ? values.company_name : "-",
              address_line_1: values.address_line_1
                ? values.address_line_1
                : "-",
              town_city: values.town_city ? values.town_city : "-",
              county_state: values.county_state ? values.county_state : "-",
              postcode: values.postcode ? values.postcode : "-",
            };
          }
          props.submit(modifiedValues, setFieldError, setSubmitting);
        }}
        render={({ isValid, values, isSubmitting }) => (
          <PersonalInformationFormInner
            password={props.password}
            countryData={props.countryData}
            isSubmitting={isSubmitting}
            isValid={isValid}
            values={values}
          />
        )}
      />
    );
  }

  const initialValues = {
    title:
      props.intialVal && props.intialVal.title ? props.intialVal.title : "",
    first_name:
      props.intialVal && props.intialVal.first_name
        ? props.intialVal.first_name
        : "",
    last_name:
      props.intialVal && props.intialVal.last_name
        ? props.intialVal.last_name
        : "",
    telephone:
      props.intialVal && props.intialVal.telephone
        ? props.intialVal.telephone
        : "",
    mobile:
      props.intialVal && props.intialVal.mobile ? props.intialVal.mobile : "",
    company_name:
      props.intialVal && props.intialVal.company_name
        ? props.intialVal.company_name
        : "",
    vat_number:
      props.intialVal && props.intialVal.vat_number
        ? props.intialVal.vat_number
        : "",
    email_address:
      props.intialVal && props.intialVal.email_address
        ? props.intialVal.email_address
        : "",
    address_line_1:
      props.intialVal && props.intialVal.address_line_1
        ? props.intialVal.address_line_1
        : "",
    address_line_2:
      props.intialVal && props.intialVal.address_line_2
        ? props.intialVal.address_line_2
        : "",
    town_city:
      props.intialVal && props.intialVal.town_city
        ? props.intialVal.town_city
        : "",
    county_state:
      props.intialVal && props.intialVal.county_state
        ? props.intialVal.county_state
        : "",
    postcode:
      props.intialVal && props.intialVal.postcode
        ? props.intialVal.postcode
        : "",
    country_id:
      props.intialVal && props.intialVal.country_id
        ? props.intialVal.country_id
        : props.countryData[0].key,
    bBillingAddress:
      props.intialVal && props.intialVal.bBillingAddress
        ? props.intialVal.bBillingAddress
        : "",
    password:
      props.intialVal && props.intialVal.password
        ? props.intialVal.password
        : "",
    bTermsConditions:
      props.intialVal && props.intialVal.bTermsConditions
        ? props.intialVal.bTermsConditions
        : "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setFieldError, setSubmitting }) => {
        let modifiedValues = values;
        if (!primaryUser) {
          modifiedValues = {
            ...values,
            company_name: values.company_name ? values.company_name : "-",
            address_line_1: "-",
            town_city: values.town_city ? values.town_city : "-",
            county_state: values.county_state ? values.county_state : "-",
            postcode: values.postcode ? values.postcode : "-",
          };
        }
        props.submit(modifiedValues, setFieldError, setSubmitting);
      }}
      render={({ isValid, values, isSubmitting }) => (
        <PersonalInformationFormInner
          password={props.password}
          isSubmitting={isSubmitting}
          isValid={isValid}
          values={values}
          onLastStep={props.onLastStep}
          countryData={props.countryData}
        />
      )}
    />
  );
};

export default PersonalInformationForm;
