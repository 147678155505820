import * as React from "react";
import Helmet from "react-helmet";
import { useFetch } from "../../helpers/loading";
import { endpoints } from "../../serverDetails";
import Spinner from "react-spinkit";
import { RouteComponentProps, Link } from "@reach/router";
const Logo = "/content/images/Icon_NoResult.png";

export interface CMSGeneratedContentPageProps {}

type CMSGeneratedContentPartialProps = {
  customURL?: string;
};

const CMSGeneratedContentPage = (
  props: CMSGeneratedContentPageProps & RouteComponentProps
) => {
  const requestConfig = React.useMemo(() => {
    return { params: { pageUrl: window.location.pathname } };
    //eslint-disable-next-line
  }, [window.location.pathname]);

  const { data, loading, error } = useFetch(
    endpoints.cms.cmsPageDetails,
    requestConfig
  );

  if (loading) {
    return (
      <div className="CMSContent WidthContent HeightContent">
        <span>
          <Spinner name="three-bounce" fadeIn="none" />
        </span>
      </div>
    );
  }

  if (error)
    return (
      <div className="ErrorPageContainer">
        <img
          src={Logo}
          alt="Skippy Scout"
          style={{ height: "80px", width: "80px" }}
        />
        <h1>Sorry, that page doesn't exist</h1>

        <Link to="/home" className="Btn">
          Return home
        </Link>
      </div>
    );

  if (data) {
    return (
      <>
        <Helmet>
          <title>{data.details.web_page_name}</title>
        </Helmet>
        <div className="CMSContent WidthContent HeightContent">
          <h1>{data.details.page_title}</h1>
          <div
            dangerouslySetInnerHTML={{ __html: data.details.page_content }}
          ></div>
        </div>
      </>
    );
  }

  return null;
};
const CMSGeneratedContent = () => {
  const requestConfig = React.useMemo(() => {
    return { params: { pageUrl: window.location.pathname } };
    //eslint-disable-next-line
  }, [window.location.pathname]);

  const { data, loading, error } = useFetch(
    endpoints.cms.cmsPageDetails,
    requestConfig
  );

  if (loading) {
    return (
      <div className="CMSContent WidthContent HeightContent">
        <span>
          <Spinner name="three-bounce" fadeIn="none" />
        </span>
      </div>
    );
  }

  if (error)
    return (
      <div className="ErrorPageContainer">
        <img
          src={Logo}
          alt="Skippy Scout"
          style={{ height: "80px", width: "80px" }}
        />
        <h1>Sorry, that page doesn't exist</h1>

        <Link to="/home" className="Btn">
          Return home
        </Link>
      </div>
    );

  if (data) {
    return (
      <>
        <Helmet>
          <title>{data.details.web_page_name}</title>
        </Helmet>
        <div className="CMSContent">
          <div
            dangerouslySetInnerHTML={{ __html: data.details.page_content }}
          ></div>
        </div>
      </>
    );
  }

  return null;
};

const CMSGeneratedContentPartial: React.FC<CMSGeneratedContentPartialProps> = ({
  customURL,
}) => {
  const requestConfig = React.useMemo(() => {
    if (customURL) {
      return { params: { partCode: customURL } };
    } else {
      return { params: { partCode: window.location.pathname.substring(1) } };
    }
    //eslint-disable-next-line
  }, [window.location.pathname, customURL]);

  const { data, loading, error } = useFetch(
    endpoints.cms.cmsPartialPageDetails,
    requestConfig
  );

  if (loading) {
    return (
      <div className="CMSContent WidthContent HeightContent">
        <span>
          <Spinner name="three-bounce" fadeIn="none" />
        </span>
      </div>
    );
  }

  if (error)
    return (
      <div className="ErrorPageContainer">
        <img
          src={Logo}
          alt="Skippy Scout"
          style={{ height: "80px", width: "80px" }}
        />
        <h1>Sorry, that page doesn't exist</h1>

        <Link to="/home" className="Btn">
          Return home
        </Link>
      </div>
    );

  if (data) {
    return (
      <>
        <Helmet>
          <title>{data.details.web_page_name}</title>
        </Helmet>
        <div className="CMSContent">
          <div
            dangerouslySetInnerHTML={{ __html: data.details.page_content }}
          ></div>
        </div>
      </>
    );
  }

  return null;
};

export { CMSGeneratedContentPartial };
export { CMSGeneratedContent };
export default CMSGeneratedContentPage;
