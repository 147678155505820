import { Maybe } from "purify-ts/Maybe";
const errorMessages: { [key: string]: string } = {
  Required: "Need to supply a value.",
  Invalid: "Values is not valid.",
  Invalid_Email: "Email address is invalid.",
  Invalid_URL: "URL is not in the correct format.",
  Account_in_Use:
    "The email address provided is already associated with an existing account.",
  PIN_Min_Length_4: "PIN must be at least 4 digits long.",
  Invalid_Date_Of_Birth: "Date of birth must be a valid date in the past.",
  Invalid_Time_Zone: "Time zone must be a valid IANA time zone.",
  Account_Locked:
    "Account has been locked for ten minutes, due to repeated incorrect password entry.",
  Server_Error: "Unexpected server error occurred.",
  TFA_Code_Incorrect:
    "The Two-factor Authentication code entered was incorrect.",
  TFA_Code_Expired: "The Two-factor Authentication code entered has expired.",
  Invalid_Player: "Player could not be found with the given ID.",
  Invalid_Currency_Code: "Currency is not valid.",
  Invalid_Payment_Type: "Payment type does not exist.",
  Payment_Type_Not_Allowed: "Payment type is not permitted.",
  Invalid_Balance_Type: "Balance type does not exist.",
  Balance_Payment_Type_Mismatch:
    "Selected balance and payment types are incompatible.",
  Invalid_Amount: "Amount provided is not valid.",
  Invalid_Amount_For_Type: "Amount is not valid for provided payment type.",
  Positive_Or_Null_Amount_Required: "Amount provided must be positive.",
  Player_Not_Found: "Player could not be found with the given ID.",
  Date_In_Past: "Date given cannot be in the past",
  Invalid_Date: "Date is invalid.",
  User_Not_Found: "Either username or password is incorrect.",
  Invalid_Image_Extension: "This image is not of the correct filetype",
  File_Size_Invalid: "Files must be 5MB or smaller.",
  Invalid_Access: "User is not authenticated",
  LandscapeImage_OR_AutoImage: "Either upload an image or select one from the auto generated list"
};

export const translateError = (error: string): Maybe<string> =>
  Maybe.fromNullable(errorMessages[error]);

export const toPascal = (name: string): string =>
  name
    .split(".")
    .map(x => x[0].toUpperCase() + x.slice(1))
    .join(".");

export const dePascal = (name: string): string =>
  name
    .split(".")
    .map(x => x[0].toLowerCase() + x.slice(1))
    .join(".");
