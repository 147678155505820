import axiosInstance, { ApiResponse, isSuccessResponse } from "api";
import { AxiosRequestConfig } from "axios";
import { BASE_URL, endpoints } from "serverDetails";

export const uploadFarmFile = async (
  id: number,
  name: string,
  files: File[]
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const formData = new FormData();
  if (files) {
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i], files[i].name);
    }
  }
  try {
    let finalEndpoint = "";
    if (id) {
      finalEndpoint = `${BASE_URL}${endpoints.fieldUploads.uploadField}?farmId=${id}`;
    }
    if (name) {
      finalEndpoint = `${BASE_URL}${endpoints.fieldUploads.uploadField}?newFarmName=${name}`;
    }
    const result = await axiosInstance.post<ApiResponse>(
      finalEndpoint,
      formData,
      config
    );
    return Promise.resolve(result.data);
  } catch (e) {
    return Promise.reject({ status: "0" });
  }
};
