import React from "react";
import { Field } from "formik";

interface RowInputInterface {
  name: string;
  placeholder?: string;
  type?: string;
  id?: string;
  optional?: boolean;
  maxLength?: number;
  readOnly?: boolean;
  disabled?: boolean;
}

const RowInput = (props: RowInputInterface) => {
  return (
    <div className="FormSideBySide">
      <div className="FormLabel">
        <label htmlFor={props.name}>
          {props.placeholder}
          {props.optional && <span className="Optional"> - Optional</span>}
        </label>
      </div>
      <div className="FormField">
        <Field
          className={`EditBox`}
          name={props.name}
          type={props.type ? props.type : "text"}
          id={props.id}
          maxLength={props.maxLength}
          readOnly={props.readOnly}
          disabled={props.disabled}
        />
      </div>
    </div>
  );
};

export default RowInput;
