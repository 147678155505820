import React from "react";
import { FieldProps, Field } from "formik";

import "react-phone-input-2/lib/style.css";
/* const PhoneInput = React.lazy(() => import("react-phone-input-2")); */
import PhoneInput from "react-phone-input-2";

type PhoneNumberProps = FieldProps<string> & {
  label?: string;
  value: string;
  setValue: any;
  optional?: boolean;
};

export function PhoneNumber(props: PhoneNumberProps) {
  return (
    <div>
      <div className="FormLabel" style={{ minWidth: "175px" }}>
        <label>{props.label} {props.optional && <span className="Optional">- optional</span>}</label>
      </div>
      <div className="FormField" style={{ width: "100%" }}>
        <PhoneInput
          country="gb"
          preferredCountries={["gb"]}
          placeholder={""}
          value={props.value}
          onChange={props.setValue}
          inputProps={{
            onFocus: () => props.form.setFieldTouched(props.field.name, true),
          }}
          //error={props.value ? (isValidPhoneNumber(props.value) ? '' : props.setError('Invalid phone number')) : props.setError('Phone number required')}
        />
      </div>
    </div>
  );
}

type PhoneNumberFieldProps = {
  label?: string;
  name: string;
  optional?: boolean;
};

export function PhoneNumberField(props: PhoneNumberFieldProps) {
  return (
    <Field
      name={props.name}
      render={(fieldprops: FieldProps<string>) => {
        let enteredNumber = fieldprops.field.value
          ? fieldprops.field.value
          : "";
        return (
          <React.Suspense fallback={null}>
            <PhoneNumber
              label={props.label}
              value={enteredNumber}
              setValue={(value: any) => {
                fieldprops.form.setFieldValue(fieldprops.field.name, value);
              }}
              optional={props.optional}
              {...fieldprops}
            />
          </React.Suspense>
        );
      }}
    />
  );
}
