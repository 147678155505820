import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import Back from "assets/images/Icon_Btn_07.png";
import { useFetch } from "../../helpers/loading";
import { endpoints } from "../../serverDetails";
import { FieldError } from "../../types/BasicTypes";
import Spinner from "react-spinkit";
import axios from "api";
import { format } from "date-fns";

const errorResults = "/content/images/Icon_Error.png";

export interface UsersViewDetailsProps {}

type Data = {
  id: number;
  details: {
    id: number;
    account_type: string;
    title: string;
    first_name: string;
    last_name: string;
    email_address: string;
    company_name: string;
    telephone: string;
    mobile: string;
    b_suppressed: boolean;
    b_access_all_farms: boolean;
    qrcode_url_link: string;
    base64_qrcode: string;
    add_date_utc: string;
    permissions: FarmPermission[];
  };
  status: string;
  errors: FieldError[];
};

type FarmPermission = {
  farm: string;
};

const UsersViewDetails: React.FC<RouteComponentProps &
  UsersViewDetailsProps> = () => {
  const dispatch = useDispatch();
  const state = useSelector<any, any>(state => state.tableReducer);
  const [submittingEmail, setSubmittingEmail] = React.useState<boolean>(false);
  const [submittingSMS, setSubmittingSMS] = React.useState<boolean>(false);

  const handleToast = (message: string) => {
    dispatch({ type: "SHOW_TOAST", message: message });
    window.setTimeout(() => {
      dispatch({ type: "HIDE_TOAST" });
    }, 3000);
  };

  const requestConfig = React.useMemo(() => {
    return { params: { Id: state.selectedUsersPageIds[0] } };
    //eslint-disable-next-line
  }, []);

  const sendQR = (method: string) => {
    const postObject = {
      id: state.selectedUsersPageIds[0],
    };

    let url =
      method === "Email"
        ? endpoints.users.sendQREmail
        : endpoints.users.sendQRSMS;

    let message = method === "Email" ? "Email sent" : "SMS sent";

    method === "Email" ? setSubmittingEmail(true) : setSubmittingSMS(true);

    axios
      .post(url, postObject)
      .then(res => {
        if (res.data.status === "1") {
          setSubmittingEmail(false);
          setSubmittingSMS(false);
          handleToast(message);
        }
      })
      .catch(err => {
        setSubmittingEmail(false);
        setSubmittingSMS(false);
        handleToast("There was problem, please try again");
      });
  };

  const { data, loading, error } = useFetch<Data>(
    endpoints.users.viewUser,
    requestConfig
  );

  if (loading) {
    return (
      <span>
        <Spinner name="three-bounce" fadeIn="none" />
      </span>
    );
  }

  if (error)
    return (
      <div className="ErrorResults">
        <div className="NoResults">
          <img src={errorResults} alt={"Error"} />
          <p>Error</p>
        </div>
      </div>
    );

  const checkNull = (value: any) => {
    return value != null && value !== "" && value !== "+" ? value : "-";
  };

  if (data) {
    return (
      <div className="UsersViewDetails">
        <div className="UsersViewDetailsContent">
          {data.details.account_type === "Sharing user" && (
            <>
              <div className="QRContainer">
                <img
                  data-testid="data"
                  alt="QR Code"
                  className="QRImage"
                  src={data.details.base64_qrcode}
                />
              </div>
              <div className="ActionButtons">
                <button
                  className="Btn BtnGreen"
                  onClick={() => sendQR("Email")}
                  disabled={submittingEmail}
                >
                  Send by email
                </button>
                <button
                  className="Btn BtnGreen"
                  onClick={() => sendQR("SMS")}
                  disabled={submittingSMS}
                >
                  Send by iMessage
                </button>
              </div>
              <div className="CopyLink">
                <p className="LinkText">{data.details.qrcode_url_link}</p>

                <button
                  className="Btn BtnSmall BtnCopy"
                  onClick={() => {
                    let t = document.createElement("textarea");
                    document.body.appendChild(t);
                    t.value = data.details.qrcode_url_link;
                    t.select();
                    document.execCommand("copy");
                    document.body.removeChild(t);
                  }}
                >
                  Copy
                </button>
              </div>
              <div className="DownloadLink"></div>
            </>
          )}
          <div className="UserDetailsContainer">
            <div className="UserDetailsHeader">
              <h3>USER DETAILS</h3>
            </div>
            <div>
              <div className="DisplayRow">
                <div className="DisplayLabel">User type</div>
                <div className="DisplayValue">
                  {checkNull(data.details.account_type)}
                </div>
              </div>

              <div className="DisplayRow">
                <div className="DisplayLabel">Title</div>
                <div className="DisplayValue">
                  {checkNull(data.details.title)}
                </div>
              </div>

              <div className="DisplayRow">
                <div className="DisplayLabel">First name</div>
                <div className="DisplayValue">
                  {checkNull(data.details.first_name)}
                </div>
              </div>

              <div className="DisplayRow">
                <div className="DisplayLabel">Last name</div>
                <div className="DisplayValue">
                  {checkNull(data.details.last_name)}
                </div>
              </div>

              <div className="DisplayRow">
                <div className="DisplayLabel">Company name</div>
                <div className="DisplayValue">
                  {checkNull(data.details.company_name)}
                </div>
              </div>

              <div className="DisplayRow">
                <div className="DisplayLabel">Email address</div>
                <div className="DisplayValue">
                  {checkNull(data.details.email_address)}
                </div>
              </div>

              <div className="DisplayRow">
                <div className="DisplayLabel">Phone number</div>
                <div className="DisplayValue">
                  {checkNull(data.details.telephone)}
                </div>
              </div>

              <div className="DisplayRow">
                <div className="DisplayLabel">Mobile number</div>
                <div className="DisplayValue">
                  {checkNull(data.details.mobile)}
                </div>
              </div>

              <div className="DisplayRow">
                <div className="DisplayLabel">Suppressed</div>
                <div className="DisplayValue">
                  {data.details.b_suppressed === true ? "Yes" : "No"}
                </div>
              </div>
              <div className="DisplayRow">
                <div className="DisplayLabel">Add date</div>
                <div className="DisplayValue">
                  {checkNull(
                    format(new Date(data.details.add_date_utc), "dd/MM/yyyy")
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="PermissionDetailsContainer">
            <div className="PermissionDetailsHeader">
              <h3>PERMISSION LEVEL</h3>
            </div>
            <div className="PermissionDetailsContent">
              {data.details.b_access_all_farms ? (
                <p>Access all farms</p>
              ) : (
                data.details.permissions.map((p, i) => <p key={i}>{p.farm}</p>)
              )}
              {data.details.permissions.length === 0 &&
                !data.details.b_access_all_farms && (
                  <p>User has no farm permissions</p>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default UsersViewDetails;
