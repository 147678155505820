import React from "react";
import { PanoramaItemProps } from "./models";

const PanoramaItem: React.FC<PanoramaItemProps> = ({ title, link }) => {
  return (
    <div className="PanoramaItem">
      <h2 className="PanoramaItem__title">{title}</h2>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="PanoramaItem__link Btn"
      >
        View Scout Sphere
      </a>
    </div>
  );
};

export default PanoramaItem;
