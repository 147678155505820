import { AxiosRequestConfig } from "axios";
import { format } from "date-fns";
import { downloadFile } from "helpers/downloadFile";
import React, { useEffect, useState } from "react";
import { BASE_URL, endpoints } from "serverDetails";
import Spinner from "react-spinkit";
import closeMenu from "assets/images/Icon_SmallRed_Cross.png";
import { useDispatch, useSelector } from "react-redux";
import axios from "api";
import { selectHasNotes, setHasNotes } from "reducers/reportSection";

export type FieldReportSectionCardDetails = {
  FieldId: number;
  FlightsId: number;
  ReportId: number;
  FieldName: string;
  FieldCrop: string;
  hasNotes: boolean;
  FieldReportDate: string;
  FieldValues: Record<string, string | number>;
  FieldReportLink: string;
  addReportToRegeneratingList: (reportId: number) => void;
  FieldImage: string;
  fieldReportStatus: {
    reports_id: number;
    b_generate_report: boolean;
  }[];
};

const Iframe = ({
  source,
  closeHandler,
}: {
  source: string;
  closeHandler: () => void;
}) => {
  return (
    // basic bootstrap classes. you can change with yours.
    <>
      <div className="FieldReportPopUp">
        <img
          onClick={closeHandler}
          className="FieldReportsSectionCard__closeReport"
          src={closeMenu}
          alt="Close"
        />
        <iframe src={source}></iframe>
      </div>
    </>
  );
};

// AverageCount,
//                                                 AverageWeedCount,
//                                                 AverageHoleCount,
//                                                 AverageCountPerArea,

const fieldDataMap: Record<
  string,
  { text: string; good: string; unit: string; order: number }
> = {
  insect_damage: {
    text: "Insect Damage",
    good: "LESS_THAN",
    unit: "",
    order: 8,
  },
  average_crop_cover: {
    text: "Crop Cover",
    good: "MORE_THAN",
    unit: "%",
    order: 1,
  },
  average_count_per_area: {
    text: "Plants / sqm", // TODO - `Plants / ${bUseAcres ? "acre" : "sqm"}` - need this boolean sent in /clientrole/reportsmodule/getreportpageinfo response
    good: "MORE_THAN",
    unit: "",
    order: 2,
  },
  average_gai: { text: "Crop GAI", good: "MORE_THAN", unit: "", order: 3 },
  average_n_rec: {
    text: "Required N (EST)",
    good: "MORE_THAN",
    unit: "kg/ha",
    order: 10,
  },
  average_weed_cover: { text: "Weeds", good: "LESS_THAN", unit: "%", order: 4 },
  average_unhealthy_leaves: {
    text: "Unhealthy Crop",
    good: "LESS_THAN",
    unit: "%",
    order: 5,
  },
  average_yellow_leaves: {
    text: "Yellow Leaves",
    good: "LESS_THAN",
    unit: "%",
    order: 6,
  },
  average_purple_leaves: {
    text: "Purple Leaves",
    good: "LESS_THAN",
    unit: "%",
    order: 7,
  },
  average_flowering_ratio: {
    text: "Flowering Ratio",
    good: "MORE_THAN",
    unit: "%",
    order: 11,
  },
  crop_uniformity: {
    text: "Crop Uniformity",
    good: "MORE_THAN",
    unit: "%",
    order: 9,
  },
  average_growth_stage_aab: {
    text: "AAB Growth Stage",
    good: "MORE_THAN",
    unit: "GS",
    order: 12,
  },
  average_growth_stage_bbch: {
    text: "BBCH Growth Stage",
    good: "MORE_THAN",
    unit: "GS",
    order: 13,
  },
  average_pod_development_ratio: {
    text: "Pod Development Ratio",
    good: "MORE_THAN",
    unit: "%",
    order: 14,
  },
  average_ripening_ratio: {
    text: "Ripening Ratio",
    good: "MORE_THAN",
    unit: "%",
    order: 15,
  },
  average_senescence_ratio: {
    text: "Senescence",
    good: "LESS_THAN",
    unit: "",
    order: 16,
  },
};

export const FieldReportsSectionCard = ({
  FieldId,
  FieldName,
  FieldCrop,
  FieldReportDate,
  FieldReportLink,
  FieldValues,
  FieldImage,
  FlightsId,
  ReportId,
  fieldReportStatus,
  addReportToRegeneratingList,
  hasNotes,
}: FieldReportSectionCardDetails) => {
  const [showReport, setShowReport] = useState(false);
  const [reportDownloading, setReportDownloading] = useState(false);
  const dispatch = useDispatch();
  const reportsWithNotes = useSelector(selectHasNotes);
  const hasNotesLatest = reportsWithNotes.includes(ReportId);

  let isRegenerating = false;

  if (fieldReportStatus) {
    const matchingReport = fieldReportStatus.find(
      ({ reports_id }) => reports_id === ReportId
    );
    if (matchingReport) {
      isRegenerating = matchingReport.b_generate_report;
    }
  }

  const handleToast = (message: string) => {
    dispatch({ type: "SHOW_TOAST", message: message });
    window.setTimeout(() => {
      dispatch({ type: "HIDE_TOAST" });
    }, 3000);
  };

  useEffect(() => {
    if (hasNotes) {
      dispatch(setHasNotes(ReportId));
    }
  }, [hasNotes]);

  const isGoodOrBad = (text: string, value: number) => {
    if (fieldDataMap[text] === undefined) {
      return "Good";
    }
    if (fieldDataMap[text].good === "MORE_THAN")
      return value > 0 ? "Good" : "Bad";
    if (fieldDataMap[text].good === "LESS_THAN")
      return value > 0 ? "Bad" : "Good";
  };

  const getValueWithUnits = (text: string, value: string, rawValue: number) => {
    let insectDamageColour: string | null = null;
    if (text === "insect_damage") {
      console.log("value", value);
      insectDamageColour = value === "Not detected" ? "Good" : "Bad";
    }
    const setColour = () => {
      return insectDamageColour
        ? insectDamageColour
        : isGoodOrBad(text, rawValue);
    };

    return (
      <>
        <span className={`${setColour()}`}>{value}</span>
      </>
    );
  };

  // Below functionality replaced by download attribute on "a" tag
  // const downloadReport = async () => {
  //   const name = `Report${FlightsId}`;
  //   const reqConfig: AxiosRequestConfig = {
  //     params: {
  //       flightId: FlightsId,
  //     },
  //   };
  //   const [url, fileName, fileType] = [
  //     endpoints.reportsModule.downloadReport,
  //     name,
  //     "PDF",
  //   ];
  //   setReportDownloading(true);
  //   const downloaded = await downloadFile({
  //     url,
  //     fileName,
  //     fileType,
  //     reqConfig,
  //   });
  //   if (downloaded) {
  //     setReportDownloading(false);
  //   }
  // };

  const regenerateReport = () => {
    axios
      .post(endpoints.reportsModule.regeneratereport, { reportsId: ReportId })
      .then(res => {
        if (res.data.status === "1") {
          //setSubmitting(false)
          addReportToRegeneratingList(ReportId);
          handleToast("Report is queued for regeneration");
        }
      })
      .catch(err => {
        handleToast("Report regeneration request failed, please try again");
        //setError(true)
        //setSubmitting(false)
      });
  };

  return (
    <div className="FieldReportsSectionCard">
      <img
        onClick={() => {
          setShowReport(true);
        }}
        src={FieldImage}
        alt="Field Reports Section Thumbnail"
        className="FieldReportsSectionCard__thumbnail"
      />
      <div className="FieldReportsSectionCard__content">
        <div className="FieldReportsSectionCard__header" title={FieldName}>
          {FieldName}
        </div>
        <div className="FieldReportsSectionCard__subHeader">
          {`${FieldCrop}`}
        </div>
        <div className="FieldReportsSectionCard__subHeader">
          {FieldReportDate}
        </div>
        {FieldValues &&
          Array.isArray(Object.entries(FieldValues)) &&
          Object.entries(FieldValues)
            .map(([text, value]) => ({
              text,
              value,
              ...(fieldDataMap[text]
                ? { order: fieldDataMap[text].order }
                : { order: -1 }),
            }))
            .filter(({ order }) => order !== -1)
            .sort((a, b) => (a.order as number) - (b.order as number))
            .map(({ text, value }, idx) => {
              if (
                value !== null &&
                typeof value === "string" &&
                text in fieldDataMap
              ) {
                const fieldRawReference =
                  text === "crop_uniformity" ? `${text}_raw` : `raw_${text}`;
                return (
                  <div className="FieldReportsSectionCard__item" key={idx}>
                    {`${fieldDataMap[text].text}: `}
                    {getValueWithUnits(
                      text,
                      value,
                      FieldValues[fieldRawReference] as number
                    )}
                  </div>
                );
              } else {
                return null;
              }
            })}
        <div className="FieldReportsSectionCard__buttonsContainer">
          <div
            className={`FieldReportsSectionCard__button FieldReportsSectionCard__button-grey Btn ${
              isRegenerating ? "Disabled" : ""
            }`}
            onClick={() => {
              dispatch({
                type: "OPEN_RIGHT_POP_OUT",
                id: ReportId,
                rightPopOutOpen: hasNotesLatest
                  ? "EDIT_REPORT_NOTES"
                  : "ADD_REPORT_NOTES",
              });
            }}
          >
            {hasNotesLatest ? "Edit notes" : "Add notes"}
          </div>
          <div
            className={`FieldReportsSectionCard__button FieldReportsSectionCard__button-grey Btn ${
              isRegenerating ? "Disabled" : ""
            }`}
            onClick={() => {
              dispatch({
                type: "OPEN_MODAL",
                modalId: [ReportId],
                modalOpen: "REPORT_NOTES_DELETE",
              });
            }}
          >
            Delete notes
          </div>
        </div>
        <div className="FieldReportsSectionCard__buttonsContainer">
          <a
            className={`FieldReportsSectionCard__button Btn ${
              isRegenerating || reportDownloading ? "Disabled" : ""
            }`}
            // onClick={downloadReport}
            href={FieldReportLink}
            download={`Report${FlightsId}`} // note: file is opened in new tab on Chrome and Firefox as these only support same origin download links. User then has option to download from new tab.
            target="_blank"
            rel="noopener noreferrer"
          >
            {reportDownloading ? (
              <Spinner
                name="three-bounce"
                className="BtnSpinner BtnSpinnerSmall"
                color="white"
                fadeIn="none"
              />
            ) : (
              "Download"
            )}
          </a>
          <div
            className={`FieldReportsSectionCard__button FieldReportsSectionCard__button-whiteGreen  Btn ${
              isRegenerating ? "Disabled" : ""
            }`}
            onClick={regenerateReport}
          >
            {isRegenerating ? "Regenerating" : "Regenerate"}
          </div>
        </div>
      </div>
      {showReport && (
        <>
          <div className={"RightPopOutUnderlay Iframe"} />
          <Iframe
            source={`${window.location.origin}/template/field-report?flight_id=${FlightsId}&fieldReportsPopup=1&isCardReport=true`}
            closeHandler={() => {
              setShowReport(false);
            }}
          />
        </>
      )}
    </div>
  );
};
