import React from "react";
import Helmet from "react-helmet";
import { BASE_URL } from "serverDetails";
import TopLogo from "./assets/icons/skippy-icon-circle.png";
import LeafLogoGreen from "./assets/icons/SVG/Asset-11.svg";
import AltLeafLogoGreen from "./assets/icons/SVG/Asset-13.svg";
import InsectLogoGreen from "./assets/icons/SVG/Asset-16.svg";
import LeafLogoRed from "./assets/icons/SVG/Asset-18.svg";
import AltLeafLogoRed from "./assets/icons/SVG/Asset-20.svg";
import SatelliteLogoGreen from "./assets/icons/SVG/Asset-22.svg";
import GridLogoGreen from "./assets/icons/SVG/Asset-24.svg";
import LeavesLogoGreen from "./assets/icons/SVG/Asset-26.svg";
import InsectLogoRed from "./assets/icons/SVG/Asset-3.svg";
import SatelliteLogoRed from "./assets/icons/SVG/Asset-5.svg";
import GridLogoRed from "./assets/icons/SVG/Asset-7.svg";
import LeavesLogoRed from "./assets/icons/SVG/Asset-9.svg";
import FlowerRatioRed from "./assets/icons/SVG/Asset-28.svg";
import FlowerRatioGreen from "./assets/icons/SVG/Asset-27.svg";
import CountRed from "./assets/icons/SVG/Asset-1.svg";
import CountGreen from "./assets/icons/SVG/Asset-14.svg";
import UniformityRed from "./assets/icons/SVG/variance_bad.svg";
import UniformityGreen from "./assets/icons/SVG/variance_good.svg";
import NitrogenRec from "./assets/icons/SVG/nitrogenRec.svg";
import OSRGrowthStage from "./assets/icons/SVG/OSRGrowthStage.svg";
import RipeningRatio from "./assets/icons/1x/RipeningRatio.png";
import PodDevelopmentRatio from "./assets/icons/1x/PodDevelopmentRatio.png";
import SenescenceRatio from "./assets/icons/1x/SenescenceRatio.png";
import { DataSection } from "./DataSection";
import mapImageUrlPlaceholder from "../../assets/images/map-image-placeholder_horizontal.jpg";

import {
  hasImproved,
  insectDamageDetected,
  insectDamageImproved,
  isntNull,
  isPositive,
} from "./helpers";
import { Item, SummaryProps } from "./models";
import { format } from "date-fns";

const FieldReportSummaryPage: React.FC<SummaryProps> = ({
  data,
  isKrpano,
  isPdf,
  isCardReport,
  loadStatus,
  setLoadStatus,
}) => {
  const [heatMapErrored, setHeatMapErrored] = React.useState<boolean>(false);
  const [imagesLoaded, setImagesLoaded] = React.useState({
    TopLogo: false,
    ClientLogo: false,
    FieldImage: false,
  })

  React.useEffect(() => {
    if (
      imagesLoaded.TopLogo && 
      imagesLoaded.FieldImage && 
      ((data.client_logo && imagesLoaded.ClientLogo) || !data.client_logo) &&
      !loadStatus.FieldReportSummaryPage
    ) {
      setLoadStatus((prev) => ({...prev, FieldReportSummaryPage: true}));
    }
  }, [imagesLoaded]);

  const handleHeatMapErrored = () => {
    setHeatMapErrored(true);
  };
  const eitherOrDataPoints = (
    dataOne: string | null,
    dataTwo: string | null
  ) => {
    if (dataOne) {
      return isntNull(dataOne) ? dataOne : null;
    }
    if (dataTwo) {
      return isntNull(dataTwo) ? dataTwo : null;
    }
    return isntNull(dataOne) ? dataOne : null;
  };

  const averageStatsData: Item[] = [
    {
      icon: LeavesLogoGreen,
      value: isntNull(data.averageCropCover) ? data.averageCropCover : null,
      description: "Crop Cover",
    },
    {
      icon: CountGreen,
      value: isntNull(data.averagePlantCount) ? data.averagePlantCount : null,
      description: `Plants / ${data.bUseAcres ? "acre" : "sqm"}`,
    },
    {
      icon: GridLogoGreen,
      value: isntNull(data.averageGai) ? data.averageGai : null,
      description: "Crop GAI",
    },
    {
      icon: SatelliteLogoGreen,
      value: isntNull(data.averageNdvi) ? data.averageNdvi : null,
      description: `Field NDVI ${
        data.ndviDate
          ? "as of " + format(new Date(data.ndviDate), "MMM do")
          : ""
      }`,
    },
    {
      icon: AltLeafLogoRed,
      value: isntNull(data.averageWeedCover) ? data.averageWeedCover : null,
      description: "Weeds",
    },
    {
      icon: LeafLogoRed,
      value: isntNull(data.averageUnhealthyCrop)
        ? data.averageUnhealthyCrop
        : null,
      description: "Unhealthy Crop",
    },
    {
      icon: LeafLogoRed,
      value: isntNull(data.averageYellowLeaves)
        ? data.averageYellowLeaves
        : null,
      description: "Yellow Leaves",
    },
    {
      icon: LeafLogoRed,
      value: isntNull(data.averagePurpleLeaves)
        ? data.averagePurpleLeaves
        : null,
      description: "Purple Leaves",
    },
    {
      icon: FlowerRatioGreen,
      value: isntNull(data.averageFloweringRatio)
        ? data.averageFloweringRatio
        : null,
      description: "Flowering Ratio",
    },
    {
      icon: insectDamageDetected(data.insectDamage)
        ? InsectLogoRed
        : InsectLogoGreen,
      value: isntNull(data.insectDamage) ? data.insectDamage : null,
      description: "Insect Damage",
    },
    {
      icon: UniformityGreen,
      value: isntNull(data.cropUniformity) ? data.cropUniformity : null,
      description: "Crop Uniformity",
    },
    {
      icon: NitrogenRec,
      value: isntNull(data.nitrogenRec) ? data.nitrogenRec : null,
      description: "Required N (EST)",
    },
    {
      icon: OSRGrowthStage,
      value: isntNull(data.averageGrowthStageAAB)
        ? `GS${data.averageGrowthStageAAB}`
        : null,
      description: "AAB",
    },
    {
      icon: OSRGrowthStage,
      value: isntNull(data.averageGrowthStageBBCH)
        ? `GS${data.averageGrowthStageBBCH}`
        : null,
      description: "BBCH",
    },
    {
      icon: PodDevelopmentRatio,
      value: isntNull(data.average_pod_development_ratio)
        ? data.average_pod_development_ratio
        : null,
      description: "Pod Development Ratio",
    },
    {
      icon: RipeningRatio,
      value: isntNull(data.average_ripening_ratio)
        ? data.average_ripening_ratio
        : null,
      description: "Ripening Ratio",
    },
    {
      icon: SenescenceRatio,
      value: isntNull(data.average_senescence_ratio)
        ? data.average_senescence_ratio
        : null,
      description: "Senescence Ratio",
    },
  ];

  const eitherOrTrendDataPoints = (
    dataOne: string | null,
    dataTwo: string | null
  ) => {
    if (dataOne) {
      return dataOne;
    }
    if (dataTwo) {
      return dataTwo;
    }
    return dataOne;
  };

  const averageTrendsData: Item[] = [
    {
      icon: hasImproved(data.averageCropCoverChange, false)
        ? LeavesLogoGreen
        : LeavesLogoRed,
      value: isntNull(data.averageCropCoverChange)
        ? data.averageCropCoverChange
        : isntNull(data.averageCropCover)
        ? "N/A"
        : null,
      description: "Crop Cover since last scout",
    },
    {
      icon: hasImproved(data.averagePlantCountChange, false)
        ? CountGreen
        : CountRed,
      value: isntNull(data.averagePlantCountChange)
        ? data.averagePlantCountChange
        : isntNull(data.averagePlantCount)
        ? "N/A"
        : null,
      description: `Plants / ${data.bUseAcres ? "acre" : "sqm"} since last scout`,
    },
    {
      icon: hasImproved(data.averageGaiChange, false)
        ? GridLogoGreen
        : GridLogoRed,
      value: isntNull(data.averageGaiChange)
        ? data.averageGaiChange
        : isntNull(data.averageGai)
        ? "N/A"
        : null,
      description: "Crop GAI since last scout",
    },
    {
      icon: hasImproved(data.averageNdviChange, false)
        ? SatelliteLogoGreen
        : SatelliteLogoRed,
      value: isntNull(data.averageNdviChange)
        ? data.averageNdviChange
        : isntNull(data.averageNdvi)
        ? "N/A"
        : null,
      description: `Field NDVI ${
        data.lastNdviDate
          ? "since " + format(new Date(data.lastNdviDate), "MMM do")
          : "since last scout"
      }`,
    },
    {
      icon: hasImproved(data.averageWeedCoverChange, true)
        ? AltLeafLogoGreen
        : AltLeafLogoRed,
      value: isntNull(data.averageWeedCoverChange)
        ? data.averageWeedCoverChange
        : isntNull(data.averageWeedCover)
        ? "N/A"
        : null,
      description: "Weeds since last scout",
    },
    {
      icon: hasImproved(data.averageUnhealthyCropChange, true)
        ? LeafLogoGreen
        : LeafLogoRed,
      value: isntNull(data.averageUnhealthyCropChange)
        ? data.averageUnhealthyCropChange
        : isntNull(data.averageUnhealthyCrop)
        ? "N/A"
        : null,
      description: "Unhealthy Crop since last scout",
    },
    {
      icon: hasImproved(data.averageYellowLeavesChange, true)
        ? LeafLogoGreen
        : LeafLogoRed,
      value: isntNull(data.averageYellowLeavesChange)
        ? data.averageYellowLeavesChange
        : isntNull(data.averageYellowLeaves)
        ? "N/A"
        : null,
      description: "Yellow Leaves since last scout",
    },
    {
      icon: hasImproved(data.averagePurpleLeavesChange, true)
        ? LeafLogoGreen
        : LeafLogoRed,
      value: isntNull(data.averagePurpleLeavesChange)
        ? data.averagePurpleLeavesChange
        : isntNull(data.averagePurpleLeaves)
        ? "N/A"
        : null,
      description: "Purple Leaves since last scout",
    },
    {
      icon: hasImproved(data.averageFloweringRatioChange, false)
        ? FlowerRatioGreen
        : FlowerRatioRed,
      value: isntNull(data.averageFloweringRatioChange)
        ? data.averageFloweringRatioChange
        : isntNull(data.averageFloweringRatio)
        ? "N/A"
        : null,
      description: "Flowering Ratio since last scout",
    },
    {
      icon: insectDamageImproved(data.averageInsectDamage)
        ? InsectLogoGreen
        : InsectLogoRed,
      value: isntNull(data.averageInsectDamage)
        ? data.averageInsectDamage
        : null,
      description: "Insect Damage since last scout",
    },
    {
      icon: hasImproved(data.cropUniformityChange, false)
        ? UniformityGreen
        : UniformityRed,
      value: isntNull(data.cropUniformityChange)
        ? data.cropUniformityChange
        : null,
      description: "Crop Uniformity since last scout",
    },
    {
      icon: OSRGrowthStage,
      value: isntNull(data.averageGrowthStageAABChange)
        ? data.averageGrowthStageAABChange
        : null,
      description: "AAB Growth Stage since last scout",
    },
    {
      icon: OSRGrowthStage,
      value: isntNull(data.averageGrowthStageBBCHChange)
        ? data.averageGrowthStageBBCHChange
        : null,
      description: "BBCH Growth Stage since last scout",
    },
    {
      icon: PodDevelopmentRatio,
      value: isntNull(data.average_pod_development_ratio_change)
        ? data.average_pod_development_ratio_change
        : null,
      description: "Pod Development Ratio since last scout",
    },
    {
      icon: RipeningRatio,
      value: isntNull(data.average_ripening_ratio_change)
        ? data.average_ripening_ratio_change
        : null,
      description: "Ripening Ratio since last scout",
    },
    {
      icon: SenescenceRatio,
      value: isntNull(data.average_senescence_ratio_change)
        ? data.average_senescence_ratio_change
        : null,
      description: "Senescence Ratio since last scout",
    },
  ];
  const usePlaceholders =
    window.location &&
    window.location.search &&
    window.location.search.includes("useplaceholders=true");
  const mapImageSrc =
    usePlaceholders || heatMapErrored
      ? mapImageUrlPlaceholder
      : data.heatmapUrl
      ? data.heatmapUrl
      : mapImageUrlPlaceholder;

  const filterAverageStatsData = averageStatsData.filter(d => d.value !== null);

  return (
    <>
      <Helmet>
        <title>Skippy Scout: Field Reports</title>
      </Helmet>
      <div
        className={`FieldReportsContainer ${
          isKrpano || isCardReport ? "KrpanoReport" : ""
        } ${isPdf ? "PdfReport" : ""}`}
        style={{ pageBreakBefore: "always", breakBefore: "always" }}
      >
        <img 
          src={TopLogo} 
          alt="logo" 
          className="TopLogo"
          onLoad={() => {
            setImagesLoaded((prev) => ({
              ...prev,
              TopLogo: true,
            }))
          }}
        ></img>
        {data.client_logo && (
          <img
            src={data.client_logo}
            alt="logo"
            className="TopLogo AgriiLogo"
            onLoad={() => {
              setImagesLoaded((prev) => ({
                ...prev,
                ClientLogo: true,
              }))
            }}
          ></img>
        )}

        <div className="PageContentBox">
          <div className="PageTop">
            <div className="PDFHeaderContainer">
              <h2>{data.fieldName}</h2>
              <h3>
                {data.cropName} {data.fieldArea ? <span>//</span> : null}{" "}
                {data.fieldArea ? data.fieldArea + "Ha" : null} <span>//</span>{" "}
                {data.plantDate}{" "}
                {data.nWeeksSincePlant ? <span>//</span> : null}{" "}
                {data.nWeeksSincePlant
                  ? "Week: " + data.nWeeksSincePlant
                  : null}
              </h3>
            </div>
            <div className="DataSectionsContainer"></div>
            <DataSection
              title={"Field Average Stats"}
              items={averageStatsData}
              stats={true}
            />
            {data.bNoOverallTrends ? null : (
              <DataSection
                title={`Field Trends`}
                date={data.lastFlightDate}
                items={averageTrendsData}
                stats={true}
              />
            )}
          </div>
          <div
            className={`SummaryImage${
              filterAverageStatsData.length > 8 && !data.bNoOverallTrends
                ? " SmallerSummaryImage"
                : ""
            }`}
          >
            <img
              src={mapImageSrc}
              onError={handleHeatMapErrored}
              alt="field-img"
              onLoad={() => {
                setImagesLoaded((prev) => ({
                  ...prev,
                  FieldImage: true,
                }))
              }}
            />
          </div>
          {/* <div className="SummaryImage"><img src={`${BASE_URL}/api/v1/publicrole/fieldreportmodule/getmappingimage?field_id=${data.fieldId}&token=${data.token}&summaryImage=true`} alt="field-img"></img></div> */}
        </div>
      </div>
    </>
  );
};

export { FieldReportSummaryPage };
