import React, { useEffect } from "react";

type ReportFooterProps = {
  data: any;
  setLoadStatus: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ReportFooter: React.FC<ReportFooterProps> = ({data, setLoadStatus}) => {
  React.useEffect(() => {
    console.log('Footer component mounted');
    setLoadStatus(true);
  }, []);

  return (
    <div>
      <div className="FieldFooterContainer">
        <div>
          <h3 className="FieldReportsFooter">
            {data.footer_text ? (
              data.footer_text
            ) : (
              <>
                Skippy Scout <span> // </span> Field Report{" "}
                <span> // </span> {data.farm_name}
              </>
            )}
          </h3>
        </div>
        <div className="FooterContainerFieldReports">
          <p className="Copyright">
            <span>
              Copyright {new Date().getFullYear()} Drone Ag Limited. All
              rights reserved{" "}
            </span>
          </p>
        </div>
      </div>
    </div>
  )
};
  