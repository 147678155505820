import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import cross from "assets/images/Icon_SmallWhiteCross.svg";
import { CSSTransition } from "react-transition-group";

type ToastProps = {
  message: string;
  visible: boolean;
};

const Toast: React.FC<ToastProps> = ({
  message,
  visible
}) => {
  const dispatch = useDispatch();
  const acknowledge = useCallback(() => dispatch({ type: "HIDE_TOAST" }), [
    dispatch,
  ]);
  return (
    <CSSTransition
      in={message !== null}
      unmountOnExit
      timeout={200}
      classNames="modal"
    >
      <div className={`Toast ${visible ? 'Show' : ''}`}>
        <div className="ToastContent">
          <div className="ToastMessage">{message}</div>
          <img src={cross} onClick={acknowledge} alt="Close popup" />
        </div>
      </div>
    </CSSTransition>
  )
}

export default Toast;

//export default function Toast() {
//  const dispatch = useDispatch();
//  const message = useSelector(
//    (state: AppState) => state.pageReducer.toastDisplayReducer
//  );

//  const acknowledge = useCallback(() => dispatch({ type: "HIDE_TOAST" }), [
//    dispatch,
//  ]);
//  return (
//    <CSSTransition
//      in={message !== null}
//      unmountOnExit
//      timeout={200}
//      classNames="modal"
//    >
//      <div className={styles.container} data-testid="container">
//        <div className={styles.content} data-testid="content">
//          <div data-testid="message">{message}</div>
//          <button data-testid="closebutton" onClick={acknowledge} type="button">
//            <img src={cross} alt="Close popup" />
//          </button>
//        </div>
//      </div>
//    </CSSTransition>
//  );
//}
