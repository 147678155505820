import { Reducer, combineReducers } from "redux";
import { TransactionHistoryItems, TransactionHistoryItem } from "../pages/TransactionHistory/TransactionHistoryPage";

export type TransactionHistoryState =
  | "view"
  | "email"
  | "none"


export type TransactionHistoryAction = {
  type:
  | "VIEW_TRANSACTION_DETAILS"
  | "EMAIL_STRIPE_RECEIPT"
  | "CLEAR_TRANSACTION_HISTORY"
};

const selected_transaction_state: Reducer = (
  state: TransactionHistoryState = "none",
  action: TransactionHistoryAction
): TransactionHistoryState => {
  switch (action.type) {
    case "VIEW_TRANSACTION_DETAILS":
      return "view"
    case "EMAIL_STRIPE_RECEIPT":
      return "email"
    case "CLEAR_TRANSACTION_HISTORY":
      return "none"
    default:
      return state;
  }
};


export type TransactionHistoryListState = TransactionHistoryItem[];

type TransactionHistoryListAction = {
  type:
  | "GET_TRANSACTION_HISTORY_LIST"
  | "GETTING_TRANSACTION_HISTORY_LIST"
  | "ERROR_TRANSACTION_HISTORY_LIST";
} | { type: "GOT_TRANSACTION_HISTORY_LIST", payload: TransactionHistoryItems }

const list: Reducer = (
  state: TransactionHistoryListState = [],
  action: TransactionHistoryListAction
): TransactionHistoryListState => {
  switch (action.type) {
    case "GET_TRANSACTION_HISTORY_LIST":
    case "GETTING_TRANSACTION_HISTORY_LIST":
    case "ERROR_TRANSACTION_HISTORY_LIST":
      return [];
    case "GOT_TRANSACTION_HISTORY_LIST":
      return [...action.payload];
    default:
      return state;
  }
};


export const transactionHistoryReducer = combineReducers({
  selected_transaction_state,
  list
});
