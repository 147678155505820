import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { PasswordResetDetails } from "../../../types/AuthTypes";
import styles from "./ResetPasswordForm.module.css";
import { Link, Redirect, RouteComponentProps } from "@reach/router";
import queryString from "query-string";
import SignInBox from "../SignInBox/SignInBox";
import ValidationError from "../../FormComponents/ValidationError/ValidationError";
import ChunkyInput from "../ChunkyInput/ChunkyInput";
import { USERS_PUBLIC_URL, endpoints } from "../../../serverDetails";
import { passwordReset } from "reducers/auth";
import Spinner from "react-spinkit"

import api from "api";
import { passwordRegex } from "../../../helpers/regex";

type Path = string | undefined | null | number | string[];
/**
 * The token will be provided as a querystring in the link sent by email,
 * this component takes that, and allows the user to input a password twice.
 * If the two passwords match, they are sent to /api/v1/UsersPublic/ResetPassword
 *
 * Query:
 *
 * 	{
 * 		"resetToken": string;
 * 		"newPassword": string;
 * 	}
 *
 */


const initialValues = {
  new_password: "",
  confirm_new_password: "",
};

const validationSchema = Yup.object().shape({
  new_password: Yup.string().matches(passwordRegex, "Password must be 8-20 characters and contain at least one 1 letter and 1 number").required("Password is required"),
  //new_password: Yup.string().matches(passwordRegex, "Password must be 8-20 characters and contain 1 uppercase character, 1 lowercase character, 1 number, 1 letter and 1 special character").required("New password is required"),
  confirm_new_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Passwords must match")
    .matches(passwordRegex, "Password must be 8-20 characters and contain at least one 1 letter and 1 number")
    .required("The new password must be confirmed"),
});

const ResetPasswordForm = (props: RouteComponentProps) => {
  const [invalidToken, setInvalidToken] = useState(false)

  const dispatch = useDispatch();
  //const authenticated = useSelector(selectAuthStatus);
  const passwordWasReset = () => dispatch(passwordReset());
  //const potentialToken = props.location
  //  ? queryString.parse(props.location.search).token
  //  : "";
  //const access_token =
  //  typeof potentialToken === "string" && potentialToken != null
  //    ? potentialToken
  //    : "";

  const potentialId = props.location
    ? queryString.parse(props.location.search).id
    : "";
  const encrypted_id =
    typeof potentialId === "string" && potentialId != null
      ? potentialId
      : "";

  const potentialKey = props.location
    ? queryString.parse(props.location.search).key
    : "";
  const key =
    typeof potentialKey === "string" && potentialKey != null
      ? potentialKey
      : "";

  React.useEffect(() => {
    api
      .post(USERS_PUBLIC_URL + endpoints.forgottenPassword.validateToken, { encrypted_id: encrypted_id, key: key })
      .then(r => {
        if (r.data.errors.length > 0) {
          setTokenStatus("error");
        } else {
          setTokenStatus("ok");
        }
      })
      .catch((err) => {
        setInvalidToken(true)
        setTokenStatus("error");
      });
    //eslint-disable-next-line
  }, [])



  const [submitStatus, setStatus] = useState<"notSent" | "error" | "sent">(
    "notSent"
  );
  const [tokenStatus, setTokenStatus] = useState<"ok" | "error" >(
    "ok"
  );

  const [validationErrors, setErrors] = useState([]);

  const url = USERS_PUBLIC_URL + endpoints.forgottenPassword.resetPassword;
  //const noTokenUrl = BASE_URL + "/api/v1/AdminUsers/ResetPassword";
  //const submitFormNoToken = (
  //  data: Omit<PasswordResetDetails, "reset_token">
  //) => {
  //  api
  //    .post(noTokenUrl, data, { ...authHeaders() })
  //    .then(r => {
  //      if (r.data.errors.length > 0) {
  //        setStatus("error");
  //        setErrors(r.data.errors);
  //      } else {
  //        setStatus("sent");
  //      }
  //    })
  //    .catch(() => {
  //      setStatus("error");
  //    });
  //};

  const submitForm = (data: PasswordResetDetails, setSubmitting: any) =>
    api
      .post(url, data)
      .then(r => {
        if (r.data.errors.length > 0) {
          setStatus("error");
          setSubmitting(false)
          setErrors(r.data.errors);
        } else {
          setStatus("sent");
        }
      })
      .catch(() => {
        setStatus("error");
        setSubmitting(false)
      });

  if (submitStatus === "sent") {
    passwordWasReset();
    return <Redirect noThrow={true} to="/" />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        //authenticated === "authenticated"
        //  ? submitFormNoToken({
        //      new_password: values.new_password,
        //      access_token: "",
        //    })
        //  : submitForm({
        //      new_password: values.new_password,
        //      encrypted_id,
        //      key,
        //      confirm_new_password: values.confirm_new_password
        //    });
        submitForm({
          new_password: values.new_password,
          encrypted_id,
          key,
          confirm_new_password: values.confirm_new_password
        }, setSubmitting);
      }}
      render={({ errors, status, touched, isSubmitting, isValid }) => {
        return (
          <>
            <div className="SignInBackground" />
            <div className={styles.container}>
              <div className={styles.base}>
                <SignInBox title="Reset Password">

                  <Form className={styles.signInForm}>
                    {submitStatus === "error" && (
                      <div style={{ color: "red", textAlign: "center" }}>
                        There was a problem, please try again. Make sure you have not used this password previously.
                      </div>
                    )}
                    {tokenStatus === "error" && (
                      <div style={{ color: "red", textAlign: "center" }}>
                        There was a problem with your link, please request a new one
                      </div>
                    )}
                    <ValidationError name="token" errors={validationErrors} />
                    <div className="FormBox FormBoxWithIcon">
                      <ChunkyInput
                        type="password"
                        name="new_password"
                        placeholder="Password"
                        icon="/content/images/Icon_Field_01.png"
                        maxLength={20}
                      />
                      <label />
                      <ValidationError
                        name="new_password"
                        errors={validationErrors}
                      />
                    </div>
                    <div className="FormBox FormBoxWithIcon">
                      <ChunkyInput
                        type="password"
                        name="confirm_new_password"
                        placeholder="Retype password"
                        icon="/content/images/Icon_Field_01.png"
                        maxLength={20}
                      />
                      <label />
                      <ValidationError
                        name="confirm_new_password"
                        errors={validationErrors}
                      />
                    </div>
                    
                    <div className={styles.buttons}>
                      <Link to="/" className="Btn Blue">
                        Cancel
                      </Link>
                      <button
                        type="submit"
                        className="Btn Blue"
                        disabled={isSubmitting || !isValid || invalidToken}
                      >
                        {isSubmitting ? <Spinner name="three-bounce" className="BtnSpinner BtnSpinnerSmall" color="white" fadeIn="none" /> : 'Submit'}
                      </button>
                    </div>
                  </Form>
                </SignInBox>
              </div>
            </div>
          </>
        );
      }}
    />
  );
};

export default ResetPasswordForm;
