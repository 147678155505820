import React from "react";
//import styles from "./ChangePassword.module.css";
import { Formik, Form, FormikActions, ErrorMessage } from "formik";
import * as Yup from "yup";
import api from "api";
import { authHeaders } from "helpers/apis";
import { FieldError } from "types/BasicTypes";
import { BASE_URL } from "serverDetails";
import Page from "components/PageComponents/Page/PageContainer";
import ChunkyInput from "components/SignInComponents/ChunkyInput/ChunkyInput";
import { RouteComponentProps, Redirect } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../reducers/rootReducer";
import Helmet from "react-helmet";
import { passwordRegex } from "../../../helpers/regex";
import Spinner from "react-spinkit";

const url = BASE_URL + "/api/v1/clientrole/resetpasswordmodule/update";
type RequestData = {
  current_password: string;
  new_password: string;
  confirm_password: string;
};

type ResponseData = {
  id: number;
  status: string;
  errors: FieldError[];
};

const ChangePassword = (props: RouteComponentProps) => {
  //const [serverErrors, setErrors] = useState<FieldError[]>([]);
  //const [submitStatus, setStatus] = useState<"notSent" | "error" | "sent">(
  //  "notSent"
  //);
  const dispatch = useDispatch();
  const subscriptionStatus = useSelector(
    (state: AppState) => state.auth.subscriptionStatus
  );

  const initialValues = {
    current_password: "",
    new_password: "",
    confirm_password: "",
  };

  type Values = typeof initialValues;
  const validationSchema = Yup.object({
    current_password: Yup.string().required("Current password is required"),
    new_password: Yup.string()
      .matches(
        passwordRegex,
        "Password must be 8-20 characters and contain at least one 1 letter and 1 number"
      )
      .required("New password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("new_password"), null], "Passwords must match")
      .matches(
        passwordRegex,
        "Password must be 8-20 characters and contain at least one 1 letter and 1 number"
      )
      .required("The new password must be confirmed"),
  });

  const parseError = (submitError: string) => {
    switch (submitError) {
      case "Invalid_Current_Password":
        return "Your current password is invalid";
      case "Password_Policy_No_Match":
        return "Your passwords do not match";
      case "Password_Previously_Used":
        return "This password has been used previously";
      default:
        return "Something went wrong, please refresh and try again";
    }
  };

  const handleSubmit = (values: Values, actions: FormikActions<Values>) => {
    //const { confirm_password, ...submission } = values;
    actions.setSubmitting(true);
    api
      .post<ResponseData>(url, values, { ...authHeaders() })
      .then(({ data }) => {
        if (data.errors.length > 0) {
          dispatch({ type: "SHOW_TOAST", message: "Error, please try again" });
          window.setTimeout(() => {
            dispatch({ type: "HIDE_TOAST" });
          }, 3000);
          //setStatus("error");
          //setErrors(data.errors);
        } else {
          //setStatus("sent");
          //setErrors([]);
          dispatch({
            type: "SHOW_TOAST",
            message: "Password successfully changed",
          });
          actions.resetForm();
          window.setTimeout(() => {
            dispatch({ type: "HIDE_TOAST" });
          }, 3000);
        }
      })
      .catch(err => {
        let errors: Record<string, string> = {};

        for (const error of err.response.data.errors) {
          errors[error.field_name] = parseError(error.message_code);
        }
        actions.setErrors(errors);
        dispatch({ type: "SHOW_TOAST", message: "Error, please try again" });
        window.setTimeout(() => {
          dispatch({ type: "HIDE_TOAST" });
        }, 3000);
        //setStatus("error")
      })
      .finally(() => actions.setSubmitting(false));
  };

  //const OK_MSG = "Your password was successfully changed";
  //const ERR_MSG =
  //  "There was a problem changing your password. Please try again.";

  //const message = () => {
  //  switch (submitStatus) {
  //    case "sent":
  //      return OK_MSG
  //    case "error":
  //      return ERR_MSG
  //    default:
  //      return null;
  //  }
  //};

  // if (subscriptionStatus !== "Valid" && subscriptionStatus !== "Initial") {
  //   return <Redirect to="/" noThrow={true} />
  // }

  return (
    <>
      <Helmet>
        <title>Skippy Scout: Change Password</title>
      </Helmet>
      <Page>
        <div className="PageWrapper">
          <div className="PaddingContainer">
            <h1>Change password</h1>
            <div className="">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {({ isValid, isSubmitting }) => (
                  <Form>
                    <div className="FormInner ChangePasswordForm">
                      <div>
                        <div className="FormBox">
                          <ChunkyInput
                            type="password"
                            placeholder="Current password"
                            name="current_password"
                            maxLength={20}
                          />
                          <ErrorMessage name="current_password">
                            {message => (
                              <span className="ErrorMessageLeft">
                                {message}
                              </span>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="FormBox">
                          <ChunkyInput
                            type="password"
                            placeholder="New password"
                            name="new_password"
                            maxLength={20}
                          />
                          <ErrorMessage name="new_password">
                            {message => (
                              <span className="ErrorMessageLeft">
                                {message}
                              </span>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="FormBox">
                          <ChunkyInput
                            type="password"
                            placeholder="Repeat new password"
                            name="confirm_password"
                            maxLength={20}
                          />
                          <ErrorMessage name="confirm_password">
                            {message => (
                              <span className="ErrorMessageLeft">
                                {message}
                              </span>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>

                      <div className="FormBox">
                        <button
                          disabled={!isValid || isSubmitting}
                          type="submit"
                          className="Btn BtnFullWidth"
                        >
                          {isSubmitting ? (
                            <Spinner
                              name="three-bounce"
                              className="BtnSpinner"
                              color="white"
                              fadeIn="none"
                            />
                          ) : (
                            "Set new password"
                          )}
                        </button>
                      </div>
                      {/*<div>
                        <div>{message()}</div>
                      </div>*/}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Page>
    </>
  );
};

export default ChangePassword;
