import * as React from "react";
import styles from "./ForgotPasswordPage.module.css";

import ForgotPasswordForm from "../../components/SignInComponents/ForgotPasswordForm/ForgotPasswordForm";
import SignInBox from "../../components/SignInComponents/SignInBox/SignInBox";
import { RouteComponentProps } from "@reach/router";
import Helmet from "react-helmet";

const ForgotPasswordPage = (props: RouteComponentProps) => {
  return (
    <>
      <Helmet>
        <title>Skippy Scout: Forgotten Password</title>
      </Helmet>
      <div className={styles.centered}>
        <SignInBox title="Forgotten password">
          <ForgotPasswordForm />
        </SignInBox>
      </div>
    </>
  );
};

export default ForgotPasswordPage;
