import React from "react";
import { isDate, parseISO, isValid } from "date-fns";
import { Transition } from "react-transition-group";
import { useOnClickOutside } from "@avamae/hooks";
import { TableInfo } from "api";
import toPairs from "ramda/es/toPairs";
import symmetricDifference from "ramda/es/symmetricDifference";
import { ColumnDetail } from "@avamae/table";
import Spinner from "react-spinkit";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../reducers/rootReducer";

const errorResults = "/content/images/Icon_Error.png"
const viewIcon = "/content/images/Icon_Action_01.png"
const NoResults = "/content/images/Icon_NoResult.png"
const defaultCheckbox = (select: () => void, selected?: boolean) => (
  <input type="checkbox" onClick={select} checked={selected} />
);

const FilterIcon = "/content/images/Icon_Btn_04.png";
const ClearFilterIcon = "/content/images/Icon_Btn_05.png";
const SortByIcon = "/content/images/Icon_SortBy_01.png";
const SortByIconASC = "/content/images/Icon_SortBy_03.png";
const SortByIconDESC = "/content/images/Icon_SortBy_02.png";
function makeRow<T extends { [K: string]: React.ReactNode }>(
  r: T,
  id: number | string,
  columns: ColumnDetail<T>[],
  formatPrimitives: (v: unknown) => unknown,
  selections: Set<string | number>,
  formatRow?: (r: T) => { [K in keyof T]: React.ReactNode },
  rowSelection?: RowSelection<T>,
  rowOptionComponent?: (
    ids: (string | number)[],
    isSummary: boolean
  ) => React.ReactNode,
): React.ReactNode {
  const row = formatRow ? formatRow(r) : r;
  const columnKeys = columns.map(c => c.columnKey.toString().toLowerCase());
  const findOrderNumber = (key: keyof T) => {
    const col = columns.find(
      x => x.columnKey.toString().toLowerCase() === key.toString().toLowerCase()
    );
    if (!col) return 0;
    return col.orderNumber;
  };
  const pairs = toPairs(row)
    .filter(([k]) => columnKeys.includes(k.toLowerCase()))
    .map(([k, v]) => [k, formatPrimitives(v)] as [keyof T, React.ReactNode])
    .sort((a, b) => {
      const aOrder = findOrderNumber(a[0]);
      const bOrder = findOrderNumber(b[0]);
      if (aOrder === bOrder) return 0;
      return aOrder < bOrder ? -1 : 1;
    }) as [keyof T, React.ReactNode][];

  const rowData = pairs.map(p => p[1]);
  const idValue = rowSelection
    ? (r[rowSelection.idColumn] as string | number)
    : "";

  if (
    rowSelection &&
    (typeof idValue === "string" || typeof idValue === "number")
  ) {
    return (
      <Row
        transactionHistory={pairs[0][0] === "SubscriptionTransactionHistory__Id"}
        isSelected={selections.has(idValue)}
        key={id}
        id={pairs[0][1]}
        pairs={pairs}
        row={addCheckboxes(rowData, rowSelection, idValue, (id) => {
          if (rowOptionComponent) {
            return rowOptionComponent(id, false)
          } else {
            return null;
          }
        })}
      />
    );
  }

  return (
    <Row
      transactionHistory={pairs[0][0] === "SubscriptionTransactionHistory__Id"}
      key={id}
      id={pairs[0][1]}
      pairs={pairs}
      row={rowData}
      isSelected={selections.has(id)}
    />
  )
  
}

function addCheckboxes<T>(
  row: React.ReactNode[],
  rowSelection: RowSelection<T>,
  id: string | number,
  rowOptionComponent?: (
    ids: (string | number)[],
    isSummary: boolean
  ) => React.ReactNode
) {
  const checked = rowSelection.selectedIDs.includes(id);
  const select = () => rowSelection.selectRow(id);
  const checkbox = rowSelection.checkbox
    ? rowSelection.checkbox
    : defaultCheckbox;
  const withCheckboxes = rowOptionComponent
    ? [checkbox(select, checked, id), ...row, rowOptionComponent([id], false)]
    : [checkbox(select, checked, id), ...row];

  return withCheckboxes;
}

type PrimitiveFormatters = {
  boolean?(b: boolean): React.ReactNode;
  string?(s: string): React.ReactNode;
  number?(n: number): React.ReactNode;
  date?(d: Date): React.ReactNode;
};

const defaultPrimitiveFormatters: PrimitiveFormatters = {
  boolean: (b: boolean) => (b ? "Yes" : "No"),
  date: (d: Date) => {
    let date = d.toString().split('T')[0].split('-')
    return date[2] + "/" + date[1] + "/" + date[0]
  }
};

type RowSelection<T> = {
  idColumn: keyof T;
  selectRow(id: number | string): void;
  selectAll(ids: (number | string)[]): void;
  selectedIDs: (number | string)[];
  checkbox?(
    select: () => void,
    selected: boolean,
    id: number | string
  ): React.ReactNode;
  actionMenu?(id?: number | string): React.ReactNode;
};

type TableProps<T> = {
  table: TableInfo;
  filterAction: () => void;
  primitiveFormatters?: PrimitiveFormatters;
  rowFormatter?(r: T): { [K in keyof T]: React.ReactNode };
  rowSelection?: RowSelection<T>;
  rowOptionComponent?(
    ids: (string | number)[],
    isSummary: boolean
  ): React.ReactNode;
  selectedRows?: (string | number)[],
};

function DataTable<T>({
  table,
  filterAction,
  primitiveFormatters,
  rowFormatter,
  rowSelection,
  rowOptionComponent,
}: TableProps<T>) {
  const { loading, error, data } = table;
  const selections = new Set(rowSelection ? rowSelection.selectedIDs : [])
  function formatPrimitives(value: any) {
    const formatter = { ...defaultPrimitiveFormatters, ...primitiveFormatters };
    const checkDate = (d: any): d is Date => {
      if (isDate(d)) return true;
      if (typeof d !== "string") return false;
      try {
        const candidate = parseISO(d);
        return isValid(candidate) && String(d).length > 4;
      } catch (error) {
        return false;
      }
    };

    if (checkDate(value) && formatter.date) return formatter.date(value);
    if (typeof value === "string" && formatter.string)
      return formatter.string(value);
    if (typeof value === "number" && formatter.number)
      return formatter.number(value);
    if (typeof value === "boolean" && formatter.boolean)
      return formatter.boolean(value);

    return value;
  }
  if (loading && data == null) return <span><Spinner name="three-bounce" fadeIn="none"/></span>;
  if (error) return (
    <div className="ErrorResults TableErrorResults">
      <div className="NoResults">
        <img src={errorResults} alt={"Error"} />
        <p>Error</p>
      </div>
    </div>
  )

  if (data) {
    const ids: (string | number)[] = !rowSelection
      ? []
      : data.details.listData.map((l: any) => l[rowSelection.idColumn]);

    const rows = data.details.listData.map((listData, index) =>
      makeRow<any>(
        listData,
        index,
        data.details.columns,
        formatPrimitives,
        selections,
        rowFormatter,
        rowSelection,
        rowOptionComponent,
      )
    );

    return (
      <React.Fragment>
        <div className="DataTableContainer">
          <div className="DataTable">
            <div className="TableContent">
              <Head
                table={table}
                rowOptionComponent={rowOptionComponent}
                ids={ids}
                columns={data.details.columns}
                toggleColumnSort={data.actions.toggleColumnSort}
                rowSelection={rowSelection}
                filterAction={filterAction}
              />
              {
                rows.length > 0 ? rows : 
                <div className="NoResultsList">
                  <img src={NoResults} alt={"No Results"} />
                  <p>No Results</p>
                </div>
              }
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return null;
}

const Head: React.FC<{
  table: TableInfo;
  ids: (string | number)[];
  filterAction: () => void;
  columns: ColumnDetail<unknown>[];
  toggleColumnSort(key: string): void;
  rowSelection?: RowSelection<any>;
  rowOptionComponent?: (
    ids: (string | number)[],
    isSummary: boolean
  ) => React.ReactNode;
}> = ({ table, columns, toggleColumnSort, rowSelection, ids, rowOptionComponent, filterAction }) => {
  

  const columnsFormatted = columns
    .filter(x => x.bVisible !== false)
    .filter(c => c.labelKey !== "Id")
    .sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1))
    .map(column => {
      const sortByValue = table.data && table.data.details.sortBy[String(column.columnKey)];
      let src = SortByIcon;
      if (sortByValue === "ASC") src = SortByIconASC;
      if (sortByValue === "DESC") src = SortByIconDESC;
      //console.log(column)
      return (
        <div className={`Cell ${column.labelKey === "Id" ? 'CellId' : ''} ${column.bSortable ? 'Sortable' : ''}`} key={column.columnKey} onClick={() => { if (column.bSortable) toggleColumnSort(column.columnKey) }}>
          {column.bSortable ? (
            <>
              <span
                className="Tag"
                title={column.labelKey}
              >
                {column.labelValue}
              </span>
              <img className="Icon" src={src} alt="Sort" />
            </>
          ) : (
              column.labelValue
            )}
        </div>
      );
    });

  if (!rowSelection)
    return (
      <div className="Head">
        <div className="Row">{columnsFormatted}</div>
      </div>
    );

  const checkbox = rowSelection.checkbox
    ? rowSelection.checkbox
    : defaultCheckbox;
  const selectAll = () => rowSelection.selectAll(ids);
  const allSelected =
    rowSelection.selectedIDs.length !== 0 &&
    symmetricDifference(ids, rowSelection.selectedIDs).length === 0;

  const CheckBoxCell = (
    <div className="Cell CellWithCheckbox" key={"selectAll"}>
      {checkbox(selectAll, allSelected, "SummaryCheckbox")}
    </div>
  );

  return (
    <div className="Row Head">
      {rowOptionComponent
        ? [CheckBoxCell, ...columnsFormatted,
          <div
            className="Cell CellFilters"
            key={'INeedAKey'}
          >

            <img
              className={`FilterIcon FilterIconHead`}
              alt="Filter"
              onClick={filterAction}
              src={table && table.data && table.data.details.filters.length > 0 ? ClearFilterIcon : FilterIcon}
            />
          </div>
          ] //${state.showMenu ? 'Hidden' : ''}
        : [CheckBoxCell, ...columnsFormatted]}
    </div>
  );
};

const Row: React.FC<{ row: React.ReactNode[], isSelected: boolean, transactionHistory: boolean, id: any, pairs: any}> = ({ row, isSelected, transactionHistory, id, pairs }) => {
  const dispatch = useDispatch()
  const transactionIdState = useSelector((state: AppState) => state.tableReducer.selectedTransactionIds)
  return (
    <div className={`Row ${isSelected ? 'Highlighted' : ''}`}>
      {row.map((r, i) => {
        return (
          i === 1 ? <React.Fragment key={'idKey'}></React.Fragment> : // don't render Id column
            <div className={`Cell ${i === 0 ? 'CellWithCheckbox' : ''}${i === 1 ? 'CellId' : ''}${i === row.length - 1 ? 'CellFilters' : ''} ${isSelected ? 'Highlighted' : ''}`} key={i}>
              <div className="Tag">
                {i === 3 && transactionHistory && r !== null && String.fromCharCode(0x00A3)}{/*i === 4 && !transactionHistory && "xxxx xxxx xxxx xxxx "*/}{r}
              </div>
              {
                // add stripe email button to transaction history cell
                i === 2 && transactionHistory &&
                <div className="EmailStripeButtonContainer">
                  <button
                    className="Btn BtnSmall BtnClear BtnEmail"
                    onClick={() => {
                      if (transactionIdState.length > 0) {
                        dispatch({
                          type: "SET_SELECTED_TRANSACTION",
                          selectedTransactionIds: [id]
                        })
                      } else {
                        dispatch({
                          type: "SET_SELECTED_TRANSACTION_NO_MENU",
                          selectedTransactionIds: [id]
                        })
                      }
                      dispatch({
                        type: "EMAIL_STRIPE_RECEIPT",
                      })
                    }}
                  >
                    <img src={viewIcon} alt="Email" className="EmailIcon" />
                    <span>View stripe receipt</span>
                  </button>
                </div>
              }
            </div>
        )
      })}
    </div>
  );
};

type TableOptionItem = {
  name: string;
  onClick(id: number): any;
};

type TableOptionsProps = {
  id: number;
  className?: string
  visibleOptions: number | null;
  setVisibleOptions(id: number | null): void;
  optionList: TableOptionItem[];
};

type Props = TableOptionsProps;

export const TableOptions: React.FC<Props> = ({
  id,
  className,
  visibleOptions,
  setVisibleOptions,
  optionList
}) => {
  let bind = useOnClickOutside(() => setVisibleOptions(null));
  //const dispatch = useDispatch()
  return (
    <Transition in={visibleOptions === id} timeout={50} mountOnEnter unmountOnExit>
      {state => (
        <div className={`${className} OptionsList ` + state} {...bind}>
          {optionList.map(item => (
            <span
              className="OptionItem"
              onClick={() => {
                item.onClick(id);
                setVisibleOptions(null);
              }}
              key={item.name}
            >
              {item.name}
            </span>
          ))}
        </div>
      )}
    </Transition>
  );
};


export default React.memo<any>(DataTable);
