import React from "react";
import Helmet from "react-helmet";
import { SummaryProps } from "./models";

const FieldReportNotesPage: React.FC<Omit<SummaryProps, "data" | "loadStatus"> & {
  notes: string;
}> = ({ notes, isKrpano, isPdf, isCardReport, setLoadStatus }) => {
  // const notes = [
  //   "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis recusandae laboriosam earum aperiam necessitatibus commodi explicabo quaerat excepturi maiores non consectetur, ex, perspiciatis labore deserunt!",
  //   "Quia molestiae, distinctio non ipsum quos doloribus eaque eligendi tempora. Veniam quis aliquid officia quam molestias nam soluta necessitatibus quisquam,",
  //   "veritatis corporis est numquam adipisci quibusdam reiciendis, hic aperiam recusandae? Illo sint sunt fuga cumque voluptate",
  //   "quibusdam quaerat reiciendis voluptas saepe ratione nisi impedit distinctio nobis eaque, iure modi, quia deserunt explicabo aspernatur debitis? Dignissimos quaerat quibusdam sapiente id",
  //   "reprehenderit non dolore fuga voluptatem quasi distinctio accusantium possimus veritatis dolores necessitatibus neque pariatur ipsa et perferendis, tenetur culpa voluptatibus incidunt.",
  //   "Fugit cumque ipsam adipisci. Ab, perspiciatis? Perspiciatis recusandae nulla fugit eos, laboriosam facilis natus praesentium aut non expedita in ab molestiae aperiam, deserunt, ipsam molestias.",
  //   "Expedita magni quibusdam pariatur veniam necessitatibus quaerat quam laboriosam velit autem asperiores ex natus voluptate laudantium vero, ea perspiciatis aliquid.",
  // ];
  React.useEffect(() => {
    setLoadStatus((prev) => ({...prev, FieldReportNotesPage: true}));
  }, []);


  return (
    <>
      <Helmet>
        <title>Skippy Scout: Field Reports Notes</title>
      </Helmet>
      <div
        className={`FieldReportsContainer ${
          isKrpano || isCardReport ? "KrpanoReport" : ""
        } ${isPdf ? "PdfReport" : "Notes"}`}
        style={{ pageBreakBefore: "always", breakBefore: "always" }}
      >
        <div className="PageContentBox">
          <div className="PageTop">
            <div className="PDFHeaderContainer">
              <span className="FieldReportsContainer__NotesHeading">Notes</span>
              <div className="FieldReportsContainer__NotesContent">
                {/* {notes.map(note => ( */}
                <div dangerouslySetInnerHTML={{ __html: notes }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { FieldReportNotesPage };
