import React from "react";
import { Link } from "@reach/router";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../reducers/rootReducer";
import { useFetch } from "../../../helpers/loading";
import { endpoints } from "../../../serverDetails";

export interface FooterProps {
  class?: string;
  isAuthenticated?: boolean;
  location: any;
}

const Footer = (props: FooterProps) => {
  //const [accessibility, setAccessibility] = useState(false)
  const { data, loading, error } = useFetch(
    endpoints.navigation.navigationLinks
  );
  const accessibility = useSelector(
    (state: AppState) => state.accessibilityReducer.accessibilityMode
  );
  const dispatch = useDispatch();
  React.useEffect(() => {
    accessibility
      ? document.body.classList.add("Accessible")
      : document.body.classList.remove("Accessible");
  }, [accessibility]);

  const toggleAccessibility = () => {
    if (accessibility) {
      dispatch({
        type: "TURN_OFF_ACCESSIBILITY",
      });
    } else {
      dispatch({
        type: "TURN_ON_ACCESSIBILITY",
      });
    }
  };

  if (loading || error) {
    return (
      <footer
        className={`Footer ${
          props.isAuthenticated ? "LoggedIn FooterSideNav" : "LoggedOut"
        }`}
      >
        <div className="FooterContent">
          <p className="Copyright">
            <span>Copyright 2019 Skippy Scout. All rights reserved </span>
            <span className="TextSeparator">|</span>{" "}
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
                href="https://www.avamae.co.uk"
              >
                Website Development
              </a>{" "}
              by AVAMAE
            </span>
          </p>
        </div>
      </footer>
    );
  }

  if (data) {
    return (
      <footer
        className={`Footer ${
          props.isAuthenticated ? "LoggedIn FooterSideNav" : "LoggedOut"
        }`}
      >
        <div className="FooterContent">
          <div className="FlexContainerCenter FooterContentTop">
            {data.details.list_footer_links.map((l: any, i: number) => {
              if (
                l.title === "Terms & conditions" ||
                l.title === "Privacy policy"
              ) {
                return (
                  <React.Fragment key={i}>
                    <a href={l.target_url} className="FooterButton">
                      {l.title}
                    </a>
                  </React.Fragment>
                );
              }
              return (
                <React.Fragment key={i}>
                  <Link to={l.target_url} className="FooterButton">
                    {l.title}
                  </Link>
                </React.Fragment>
              );
            })}
            <p className="FooterButton" onClick={toggleAccessibility}>{`Turn ${
              accessibility ? "off" : "on"
            } accessibility mode`}</p>
          </div>
          <p className="Copyright">
            <span>
              Copyright {new Date().getFullYear()} Skippy Scout. All rights
              reserved{" "}
            </span>
            <span className="TextSeparator">|</span>{" "}
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
                href="https://www.avamae.co.uk"
              >
                Website Development
              </a>{" "}
              by AVAMAE
            </span>
          </p>
        </div>
      </footer>
    );
  }

  return null;
};

export default Footer;
