import React, { useEffect, useState } from "react";
import { FormikActions } from "formik";
import { removeAddons, selectAddons, setAddons } from "reducers/register";
import { useDispatch, useSelector } from "react-redux";

interface AddOnsStepProps {
  data: any;
  handleFormSubmit: (
    values?: any,
    formikActions?: FormikActions<any> | undefined
  ) => Promise<void>;
}

export type AddOn = {
  description: string;
  gross_price: number;
  price: number;
  short_description: string;
  addon_id: number;
  addon_name: string;
};

const AddOnsStep: React.FC<AddOnsStepProps> = ({ data, handleFormSubmit }) => {
  const addOnsList = data && data.details.addons_list;

  const [selectedAddOnsIds, setSelectedAddOnsIds] = useState<number[]>([]);
  const selectedAddOns = useSelector(selectAddons);

  useEffect(() => {
    const selectedAddOnIdsLatest = selectedAddOns.map(
      ({ addon_id }: { addon_id: number }) => addon_id
    );
    setSelectedAddOnsIds(selectedAddOnIdsLatest);
  }, []);

  if (addOnsList) {
    return (
      <div className="Register">
        <ul className="FlexContainer RegistrationOptions">
          {addOnsList.map((addOn: AddOn, index: number) => {
            return (
              <AddOnOption
                key={index}
                addOn={addOn}
                setSelectedAddOnsIds={setSelectedAddOnsIds}
                selectedAddOnsIds={selectedAddOnsIds}
              />
            );
          })}
        </ul>
        <div className="FormBottom">
          <button
            className={`Btn BtnFullWidth `}
            type="submit"
            onClick={e => {
              e.preventDefault();
              handleFormSubmit && handleFormSubmit();
            }}
            id="add-ons-submit"
          >
            Save and continue
          </button>
        </div>
      </div>
    );
  }
  return null;
};

export { AddOnsStep };

type AddOnOptionProps = {
  addOn: AddOn;
  setSelectedAddOnsIds: React.Dispatch<React.SetStateAction<number[]>>;
  selectedAddOnsIds: number[];
};

const AddOnOption = ({
  addOn,
  setSelectedAddOnsIds,
  selectedAddOnsIds,
}: AddOnOptionProps) => {
  const [seeMoreOpen, setSeeMoreOpen] = useState(false);
  const dispatch = useDispatch();

  const toggleAddOn = () => {
    // Toggle add-ons state for UI selection and dispatch selected add-ons to redux
    const { price, gross_price, addon_name, addon_id } = addOn;
    if (selectedAddOnsIds.includes(addOn.addon_id)) {
      setSelectedAddOnsIds(() =>
        selectedAddOnsIds.filter(id => id !== addOn.addon_id)
      );
      dispatch(removeAddons(addon_id));
    } else {
      dispatch(setAddons({ price, gross_price, addon_name, addon_id }));
      setSelectedAddOnsIds(prevState => {
        return [...prevState, addOn.addon_id];
      });
    }
  };

  return (
    <li
      className={`SubscriptionOption 
      SubscriptionNoToggle
      ${selectedAddOnsIds.includes(addOn.addon_id) ? "Highlighted" : ""}`}
      onClick={toggleAddOn}
    >
      <span className="Holder">
        <span className="Top">
          <span className="CheckImg">
            <span className="Circle" />
          </span>
        </span>
        <span className="Middle">
          <span className="">
            <h2>{addOn.addon_name}</h2>
            <div className="PriceMonth">
              <h2 className="Price">
                {String.fromCharCode(0x00a3)}
                {addOn.price}
              </h2>
              &nbsp;
              <h2 style={{ paddingBottom: 0 }}>{` (+VAT)`}</h2>
            </div>
            {seeMoreOpen && (
              <p className="Description AddOnsDescription">
                <div
                  dangerouslySetInnerHTML={{ __html: addOn.description }}
                ></div>
              </p>
            )}
            {!seeMoreOpen && (
              <p>
                {addOn.short_description}{" "}
                <a
                  onClick={() => setSeeMoreOpen(true)}
                  href="javascript:;"
                  id={addOn.addon_id.toString()}
                >
                  See more
                </a>
              </p>
            )}
          </span>
        </span>
      </span>
    </li>
  );
};
