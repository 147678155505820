export type ModalType =
  | "USER_DELETE"
  | "USERS_DELETE"
  | "SUBSCRIPTION_PAUSE"
  | "ACCOUNT_DELETE"
  | "INDIVIDUAL_ACCOUNT_DELETE"
  | "CARD_DELETE"
  | "REPORT_NOTES_DELETE"
  | null;

export type State = {
  //modalId: { modalType: ModalType; selectedId?: number[]; };
  modalId: number[];
  modalOpen: ModalType;
};

export type Action =
  | {
      type: "OPEN_MODAL";
      modalId: number[];
      modalOpen: ModalType;
    }
  | {
      type: "CLOSE_MODAL";
    };

const initialState: State = {
  modalId: [],
  modalOpen: null,
};

export const modalReducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case "OPEN_MODAL":
      return {
        ...state,
        modalId: action.modalId,
        modalOpen: action.modalOpen,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        modalId: [],
        modalOpen: null,
      };

    default:
      return state;
  }
};
