import React from 'react';
import { QRCode } from '../Categories/QRCode/QRCode';
import { useDispatch, useSelector } from "react-redux";
import Helmet from 'react-helmet';
import ShareQREmailOrIMessage from 'pages/Categories/QRCode/ShareQREmailOrIMessage';
import { endpoints } from 'serverDetails';
import { useFetch } from 'helpers/loading';
import { FieldError } from 'types/BasicTypes';

const QREmailNewUserUrl = endpoints.qrCode.sendDeviceQREmail;
const QRSmsNewUserUrl = endpoints.qrCode.sendDeviceQRSms;
const QRDeviceUrl = endpoints.qrCode.generateQR;

type Data = {
    id: number;
    details: {
        base64_qrcode: string;
        qrcode_url_link: string;
        site_url: string;
        url_guid: string;
    };
    status: string;
    errors: FieldError[];
};

const ActivateQRNewDevice = () => {
    const state = useSelector<any, any>(state => state.tableReducer)
    const requestConfig = React.useMemo(() => {
        return { params: { Id: state.selectedUserIds[0] } };
        //eslint-disable-next-line
    }, []);

    const { loading, error, data } = useFetch<Data>(QRDeviceUrl, requestConfig);
    return (
        <div className="ActivateQRCode">
            <h1 className="NoPadding">Activate new device</h1>
            <QRCode
                loading={loading}
                error={error}
                data={data}
            />
            <ShareQREmailOrIMessage
                QREmail={QREmailNewUserUrl}
                QRSms={QRSmsNewUserUrl}
                error={error}
                data={data}
            />
        </div>
    )
}

export { ActivateQRNewDevice };