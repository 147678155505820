import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { AppState } from "../../reducers/rootReducer";
import axios from "api";
import { endpoints } from "../../serverDetails";
import { postPauseSubscription } from "./PauseSubscriptionApi";

export interface PauseSubscription {
  class?: string;
}

const PauseSubscription = (props: PauseSubscription) => {
  const dispatch = useDispatch();

  const handleToast = (message: string) => {
    dispatch({ type: "SHOW_TOAST", message: message });
    window.setTimeout(() => {
      dispatch({ type: "HIDE_TOAST" });
    }, 3000);
  };

  type selectItem = {
    value: number;
    label: string;
  };

  const [selectedOption, setSelectOption] = React.useState<selectItem | null>(
    null
  );
  const [error, setError] = React.useState<boolean>(false);
  const [generalError, setGeneralError] = React.useState<string | null>(null);
  const id = useSelector(
    (state: AppState) =>
      state.subscriptionsReducer.subscriptionDetails.subscription_id
  );

  const handleChange = (e: any) => {
    setSelectOption(e);
    setError(false);
  };

  const handleSubmit = () => {
    if (selectedOption && selectedOption.value) {
      setError(false);
      let postObject = {
        months: selectedOption.value,
        subscription_id: id,
      };

      (async () => {
        if (postObject) {
          const result = await postPauseSubscription(postObject);
          if (result.response) {
            console.log("result.response");
            //setSubmitting(false)
            dispatch({ type: "CLOSE_MODAL" });
            handleToast("Subscription successfully paused");
            dispatch({
              type: "REFRESH_SUBSCRIPTIONS",
            });
          }
          if (result.errors) {
            // console.log({ "result.errors:": result.errors })
            switch (result.errors.response.data.errors[0].message_code) {
              case "Pause_Limit":
                setGeneralError("Subscription pause limit has been reached");
                break;
              case "Cannot_Pause_Subscription_Type":
                setGeneralError("This subscription type cannot be paused");
                break;
              default:
                setGeneralError(
                  "Oops, something went wrong. Please try again."
                );
            }
          }
        } else {
          setError(true);
        }
      })();
    } else {
      setError(true);
    }
  };

  return (
    <>
      <div className={`PauseSubscription ${props.class || ""}`}>
        <div className="FormBox">
          <div className="FormLabel">
            <label>
              Select how many months you want to pause your subscription for
            </label>
          </div>
          <div className="FormField">
            <Select
              value={selectedOption}
              onChange={handleChange}
              options={[
                { value: 1, label: "1" },
                { value: 2, label: "2" },
                { value: 3, label: "3" },
                { value: 6, label: "6" },
                { value: 36, label: "Indefinite" },
              ]}
              className="CustomSelect"
              classNamePrefix="react-select"
              hideSelectedOptions={true}
              placeholder={"Select a number of months"}
              name={"months"}
            />
            {error && (
              <div className="ErrorMessageLeft">
                Please select a number of months
              </div>
            )}
            <div className="NoteMessage">
              Please note you can only pause your subscription once every 30
              days
            </div>
            {generalError && (
              <div className="ErrorMessageLeft NoteMessage">{generalError}</div>
            )}
          </div>
        </div>
      </div>
      <div className="Bottom">
        <button
          className="Btn BtnWhite"
          onClick={() => dispatch({ type: "CLOSE_MODAL" })}
        >
          Cancel
        </button>
        <button
          className="Btn"
          type="submit"
          onClick={e => {
            handleSubmit();
          }}
        >
          Pause
        </button>
      </div>
    </>
  );
};

export default PauseSubscription;
