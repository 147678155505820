import React from "react";

type ItemProps = {
  onClick(): void;
  icon: any;
  name: string;
  imgAlt: string;
};

type Props = ItemProps;

const Item: React.FC<Props> = ({ onClick, icon, name, imgAlt }) => {
  return (
    <div className="Item" onClick={onClick}>
      <img className="Icon" src={icon} alt={imgAlt} />
      <span>{name}</span>
    </div>
  );
};

export { Item };
