import React from "react";
import axios from "api";
import { useDispatch, useSelector } from "react-redux";
import { endpoints } from "../../serverDetails";
import { AppState } from "../../reducers/rootReducer";

export interface CardDelete {
  class?: string;
}

const CardDelete = (props: CardDelete) => {
  const dispatch = useDispatch()
  //const [error, setError] = useState<boolean>(false);
  //const [submitting, setSubmitting] = useState<boolean>(false);
  const selectedIds = useSelector((state: AppState) => state.tableReducer.selectedCardIds)
  const selectedIdFromModal = useSelector((state: AppState) => state.modalReducer.modalId)

  const handleToast = (message: string) => {
    dispatch({ type: 'SHOW_TOAST', message: message })
    window.setTimeout(() => {
      dispatch({ type: 'HIDE_TOAST' })
    }, 3000);
  }

  const handleSubmit = (

  ) => {

    let id: number[];
    if (selectedIds.length > 0) {
      id = selectedIds
    } else if (selectedIdFromModal.length) {
      id = selectedIdFromModal
    } else {
      id = []
    }
    if (id) {
      //setSubmitting(true);
      axios
        .post(endpoints.paymentCards.deletePaymentCard, { ids: id })
        .then(res => {
          if (res.data.status === "1") {
            //setSubmitting(false)
            dispatch({ type: "CLOSE_MODAL" })
            handleToast("Card successfully deleted")
            dispatch({type:"SET_SELECTED_CARDS", selectedCardIds: []})
            dispatch({
              type: "REFRESH_TABLE"
            })
          }
        })
        .catch(err => {
          //setError(true)
          //setSubmitting(false)
        });
    } 
  };


  return (
    <>
      <div className={`Delete ${props.class || ''}`}>
        <h3>Permanently Delete</h3>

        <p>Are you sure you want to permanently delete this card?</p>

        <p className="BoldText">You'll permanently lose your:</p>

        <ul className="InformationList">
          <li>- Name on card</li>
          <li>- Card number</li>
          <li>- Expiry date</li>
          <li>- Billing Address</li>
        </ul>

        <div className="WarningText">
          <img src="/content/images/Icon_Warning.png" alt="Warning" />
          <p>This action cannot be undone</p>
        </div>
      </div>
      <div className="Bottom">
        <button className="Btn BtnWhite" onClick={() => dispatch({ type: "CLOSE_MODAL" })}>
          Cancel
        </button>
        <button className="Btn BtnRed" onClick={() => {
          handleSubmit()
        }}>
          Delete
        </button>
      </div>
    </>
  ) 
};

export default CardDelete;
