import React from "react";
import { Transition } from "react-transition-group";
//import { useDispatch } from "react-redux";
import { useOnClickOutside } from "@avamae/hooks";
import { TableInfo } from "api";
import { useSelectedData } from "./useSelectedData";

type TableOptionItem = {
  name: string;
  onClick(id: number): any;
  hidden?: boolean;
};

type TableOptionsProps = {
  id: number;
  className?: string
  visibleOptions: number | null;
  setVisibleOptions(id: number | null): void;
  optionList: TableOptionItem[];
  table?: TableInfo;
};

type Props = TableOptionsProps;
const idColumn = "ClientUsers__Id";

const TableOptions: React.FC<Props> = ({
  id,
  className,
  visibleOptions,
  setVisibleOptions,
  optionList,
}) => {
  let bind = useOnClickOutside(() => setVisibleOptions(null));
  //const dispatch = useDispatch()

  return (
    <Transition in={visibleOptions === id} timeout={50} mountOnEnter unmountOnExit>
      {state => (
        <div className={`${className} OptionsList ` + state} {...bind}>

          {optionList.filter(item => !item.hidden).map(item =>
            <span
              className="OptionItem"
              onClick={() => {
                item.onClick(id);
                setVisibleOptions(null);
              }}
              key={item.name}
            >
              {item.name}
            </span>
          )}
        </div>
      )}
    </Transition>
  );
};

export { TableOptions };
