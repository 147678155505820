import * as React from "react";
// import styles from "./ForgotPasswordPage.module.css";

import { RouteComponentProps } from "@reach/router";
import Helmet from "react-helmet";
import { useFetch } from "helpers/loading";
import Spinner from "react-spinkit";
import SSLogo from "../../../src/assets/images/Logo_Email.jpg"
import Paid from "../../../src/assets/images/paidstamp.jpg"

const InvoicesFooterPage = (props: RouteComponentProps) => {

    // const { loading, data, error } = useFetch()
    const loading = false;
    const data = true;
    const error = false;

    return (
        <>
            <Helmet>
                <title>Skippy Scout: Invoice</title>
            </Helmet>
            {loading &&
                <span><Spinner name="three-bounce" fadeIn="none" /></span>
            }
            {data &&
                <div>
                    <div className="FooterContainer">
                        <p className="Copyright"><span>Copyright {new Date().getFullYear()} Skippy Scout. All rights reserved </span></p>
                    </div>
                </div>
            }
            {error &&
                <div>

                </div>
            }
        </>
    );
};

export default InvoicesFooterPage;
