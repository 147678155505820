import React from "react";
import { RouteComponentProps } from "@reach/router";

import SignIn from "../../components/SignInComponents/SignIn/SignInContainer";
import Helmet from "react-helmet";

const signInPage = (props: RouteComponentProps) => {
  return (
    <>
      <Helmet>
        <title>Skippy Scout</title>
      </Helmet>
      <SignIn />
    </>
  );
};

export default signInPage;
