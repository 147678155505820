import * as Yup from "yup";
import { RouteComponentProps } from "@reach/router";
import ChunkyInput from "components/SignInComponents/ChunkyInput/ChunkyInput";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Spinner from "react-spinkit";
import {
  getFarmList,
  parseFarmListDetails,
  parseUploadApplicationMapsPayload,
  updateApplicationMapsDetails,
} from "./ApplicationMapsApi";
import { values } from "ramda";
import { useDispatch } from "react-redux";

const validationSchema = Yup.object<any>({
  farmName: Yup.number()
    .required("Farm name is required")
    .typeError("Farm name is required"),
  fieldName: Yup.number()
    .required("Field name is required")
    .typeError("Field name is required"),
  productNitrogen: Yup.number()
    .required("Nitrogen percentage of the product is required")
    .typeError("Nitrogen percentage of the product is required"),
}).test(
  "OneOfThree",
  "Please enter one of the following: the SNS, the base nitrogen, or the previously applied nitrogen",
  (values) => {
    return values.snsValue || values.baseNitrogen || values.previousNitrogen;
  }
);

export type AppplicationMapsInitialValues = {
  baseNitrogen: number | null;
  farmName: string | null;
  fieldName: string | null;
  machineryUsing: string | null;
  previousNitrogen: number | null;
  productNitrogen: number | null;
  snsValue: number | null;
};

const ApplicationMaps: React.FC<RouteComponentProps> = () => {
  type ApplicationMapsDropdown = {
    label: string;
    value: number;
    key: number;
  }[];

  const [farmList, setFarmList] = useState<ApplicationMapsDropdown>();
  const [fieldList, setFieldList] = useState<
    Record<string, ApplicationMapsDropdown>
  >();
  const [loadingDropdownMenus, setLoadingDropdownMenus] = useState(false);
  const [generalError, setGeneralError] = useState("");
  const [disableForm, setDisableForm] = useState(false);
  const dispatch = useDispatch();
  const handleToast = (message: string) => {
    dispatch({ type: "SHOW_TOAST", message: message });
    window.setTimeout(() => {
      dispatch({ type: "HIDE_TOAST" });
    }, 3000);
  };

  const baseValues = {
    baseNitrogen: null,
    farmName: null,
    fieldName: null,
    machineryUsing: null,
    previousNitrogen: null,
    productNitrogen: null,
    snsValue: null,
  };

  const [initialValues, setInitialValues] = useState<
    AppplicationMapsInitialValues
  >(baseValues);

  const populateMenus = async () => {
    setLoadingDropdownMenus(true);
    const farmResult = await getFarmList({});
    if (farmResult.response) {
      const { farms, fieldsList } = parseFarmListDetails(farmResult.response);
      setLoadingDropdownMenus(false);
      setFarmList(farms);
      setFieldList(fieldsList);
    } else {
      setLoadingDropdownMenus(false);
    }
  };

  useEffect(() => {
    populateMenus();
  }, []);

  const handleSubmit = (
    values: AppplicationMapsInitialValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    (async () => {
      const payload = parseUploadApplicationMapsPayload(values);
      const result = await updateApplicationMapsDetails(payload);
      if (result.response) {
        setSubmitting(false);
        handleToast("Application map succesfully requested");
      } else {
        setGeneralError("The upload failed, please try again");
        setSubmitting(false);
      }
    })();
  };

  if (!farmList || !fieldList || loadingDropdownMenus) return <Spinner />;

  return (
    <>
      <div className="PageWrapper">
        <div className="ApplicationMaps WidthContent HeightContent">
          <h1>Application Maps</h1>
          <div className="ApplicationMaps__description">
            <div>
              Please fill in the form to receive a nitrogen application map.
            </div>
            <div>
              The map will only be available for fields with the crop type of
              OSR.
            </div>
            <div>
              You will need to have done a scout flight within the past week,
              ideally with at least one point per hectare.
            </div>
            <div>The map will be emailed to you as an ISOXML file.</div>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values, setSubmitting);
            }}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ errors, isSubmitting, setFieldValue, values }) => {
              let fieldListInner: ApplicationMapsDropdown = [];
              if (values.farmName) {
                const chosenFarm = farmList.find(
                  ({ value }) => value === parseInt(values.farmName as string)
                );
                if (chosenFarm) {
                  fieldListInner = fieldList[chosenFarm.label];
                }
                if (!fieldListInner) {
                  setGeneralError("This farm has no valid fields");
                  setDisableForm(true);
                } else {
                  setGeneralError("");
                  setDisableForm(false);
                }
              }
              return (
                <Form>
                  <div className={`FormInner ${disableForm ? "Disabled" : ""}`}>
                    <div className="FormBox FormField">
                      <div className="FormLabel">
                        <label htmlFor="farmName">Farm Name</label>
                        <span className="Required">{` *`}</span>
                      </div>
                      <Field
                        component="select"
                        name="farmName"
                        className="DropDown"
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          setFieldValue("farmName", e.currentTarget.value);
                          setFieldValue("fieldName", "");
                        }}
                      >
                        <option value="" defaultValue="">
                          Select an existing farm
                        </option>
                        {farmList.map((opt: any, i: number) => (
                          <option key={opt.key} value={opt.key}>
                            {opt.label}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage name="farmName">
                        {message => (
                          <span className="ErrorMessageLeft">{message}</span>
                        )}
                      </ErrorMessage>
                    </div>
                    <div
                      className={`FormBox FormField ${
                        fieldListInner && fieldListInner.length > 0
                          ? ""
                          : "Disabled"
                      }`}
                    >
                      <div className="FormLabel">
                        <label htmlFor="fieldName">Field Name</label>
                        <span className="Required">{` *`}</span>
                      </div>
                      <Field
                        component="select"
                        name="fieldName"
                        className="DropDown"
                      >
                        <option defaultValue="">
                          Select an existing field
                        </option>
                        {fieldListInner &&
                          fieldListInner.map(
                            ({ key, value, label }, i: number) => (
                              <option key={key} value={value}>
                                {label}
                              </option>
                            )
                          )}
                      </Field>
                      <ErrorMessage name="fieldName">
                        {message => (
                          <span className="ErrorMessageLeft">{message}</span>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="FormBox">
                      <ChunkyInput
                        type="number"
                        placeholder="Nitrogen percentage of the product"
                        name="productNitrogen"
                        maxLength={20}
                        inputPlaceholder="Enter a percentage"
                        required={true}
                      />
                      <ErrorMessage name="productNitrogen">
                        {message => (
                          <span className="ErrorMessageLeft">{message}</span>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="FormBox">
                      <ChunkyInput
                        type="text"
                        placeholder="What machinery are you using?"
                        name="machineryUsing"
                        maxLength={100}
                        inputPlaceholder="Enter machinery"
                      />
                      <ErrorMessage name="machineryUsing">
                        {message => (
                          <span className="ErrorMessageLeft">{message}</span>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="ApplicationMaps__description">
                      <div>
                        Please enter the base nitrogen of the soil and the
                        previously applied nitrogen. The soil nitrogen supply
                        (SNS) value is optional.
                      </div>
                    </div>

                    <div className="FormBox">
                      <ChunkyInput
                        type="number"
                        placeholder="Base nitrogen of the soil (Kg/Ha)"
                        name="baseNitrogen"
                        maxLength={20}
                        inputPlaceholder="Enter soil nitrogen"
                        required={true}
                      />
                      <ErrorMessage name="baseNitrogen">
                        {message => (
                          <span className="ErrorMessageLeft">{message}</span>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="FormBox">
                      <ChunkyInput
                        type="number"
                        placeholder="Previously applied nitrogen (Kg/Ha)"
                        name="previousNitrogen"
                        maxLength={20}
                        inputPlaceholder="Enter previous nitrogen"
                        required={true}
                      />
                      <ErrorMessage name="previousNitrogen">
                        {message => (
                          <span className="ErrorMessageLeft">{message}</span>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="FormBox">
                      <ChunkyInput
                        type="number"
                        placeholder="Soil Nitrogen Supply (SNS) value"
                        name="snsValue"
                        maxLength={20}
                        inputPlaceholder="Enter SNS"
                      />
                      <ErrorMessage name="snsValue">
                        {message => (
                          <span className="ErrorMessageLeft">{message}</span>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>

                  {generalError && (
                    <span className="ErrorMessageLeft GeneralError">
                      {generalError}
                    </span>
                  )}
                  <div className="FormBox">
                    <button
                      disabled={
                        (Object.values(errors) &&
                          Object.values(errors).length > 0) ||
                        isSubmitting
                      }
                      type="submit"
                      className="Btn BtnFullWidth"
                    >
                      {isSubmitting ? (
                        <Spinner
                          name="three-bounce"
                          className="BtnSpinner"
                          color="white"
                          fadeIn="none"
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ApplicationMaps;
