import * as React from "react";
import styles from "./LocationThread.module.css";
import { intersperse } from "../../../helpers/arrays";
import { capitaliseWord } from "../../../helpers/strings";
import { Link } from "@reach/router";

export interface LocationThreadProps {
  children?: string;
}

const locationThread = ({ children }: LocationThreadProps) => {
  const partialPath = (items: string[], i: number): string =>
    items.slice(0, i + 1).join("/");

  const items = children ? children.split("/") : [];
  const links = items.map((x, i) => (
    <Link key={i} to={partialPath(items, i)}>
      {x
        .split("-")
        .map(capitaliseWord)
        .join(" ")}
    </Link>
  ));
  const formattedLinks = intersperse(links, " > ");
  return <div className={styles.container}>{formattedLinks}</div>;
};

export default locationThread;
