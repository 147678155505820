import React, { useRef, useState } from "react";
import { PaymentCardItem } from "../../pages/PaymentCards/PaymentCardsPage";
import PaymentCardAddForm from "../Forms/PaymentCardAddForm/PaymentCardAddForm";
import { Elements, injectStripe } from "react-stripe-elements";
import { useFetch } from "../../helpers/loading";
import { endpoints } from "../../serverDetails";
import Spinner from "react-spinkit";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../reducers/rootReducer";
import axios from "api";
import { ecommerceGAEvent } from "helpers/gaFuncs";
const errorResults = "/content/images/Icon_Error.png";

type Errors = {
  details: {};
  errors: { field_name: string; message_code: string }[];
  id: number;
  status: number;
};
export interface SubscriptionUpgrade {
  class?: string;
  stripe?: any;
}

const SubscriptionUpgrade = (props: SubscriptionUpgrade) => {
  //const paymentCards = [
  //  { PaymentCards__Id: 101, PaymentCards__NameOnCard: "Aaron Wallace", PaymentCards__CardEndDigits: "1234", PaymentCards__CardType: "Visa", PaymentCards__AddDateUTC: "2020-01-02T15:25:14.613" },
  //  { PaymentCards__Id: 102, PaymentCards__NameOnCard: "Aaron Wallace", PaymentCards__CardEndDigits: "1234", PaymentCards__CardType: "Visa", PaymentCards__AddDateUTC: "2020-01-02T15:25:14.613" },
  //]

  const state = useSelector(
    (state: AppState) => state.subscriptionsReducer.nextSubscriptionDetails
  );
  const subscriptionStatus = useSelector(
    (state: AppState) =>
      state.subscriptionsReducer.subscriptionDetails.current_subscription_status
  );

  const currentSubscPrice = useSelector(
    (state: AppState) =>
      state.subscriptionsReducer.subscriptionDetails.monthly_price
  );
  const nextSubscPrice = useSelector(
    (state: AppState) =>
      state.subscriptionsReducer.nextSubscriptionDetails.monthly_price
  );

  const dispatch = useDispatch();
  const [selectedOption, setSelectOption] = React.useState<number | null>(-1);
  const form = useRef<any>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [panelErrors, setPanelErrors] = useState<string[]>([]);

  const handleToast = (message: string) => {
    dispatch({ type: "SHOW_TOAST", message: message });
    window.setTimeout(() => {
      dispatch({ type: "HIDE_TOAST" });
    }, 3000);
  };

  const parseError = (submitError: string) => {
    switch (submitError) {
      case "expired_card":
        return "This card has expired";
      default:
        return "There was a problem validating your card";
    }
  };

  const handleSubmit = async (
    payload: any,
    country_name: string,
    token: any,
    previousCard?: boolean,
    skipPayment?: boolean
  ) => {
    setPanelErrors([]);
    if (skipPayment) {
      let postObject = {
        subscription_id: state.subscription_id,
        payment_card_id: selectedOption,
        renewal_type: state.subscription_name,
        skip_for_now_payment_card: true,
      };

      setSubmitting(true);

      axios
        .post(endpoints.subscription.upgradeSubscription, postObject)
        .then(res => {
          if (res.data.status === "1") {
            if (res.data.details.status === "requires_action") {
              // if 3d secure verification required, do so here, else

              props.stripe
                .handleCardAction(res.data.details.client_secret)
                .then((result: any) => {
                  if (result.error) {
                    setSubmitting(false);
                    dispatch({ type: "CLOSE_RIGHT_POP_OUT" });
                    handleToast("There was a problem verifying your card");
                    dispatch({
                      type: "REFRESH_SUBSCRIPTIONS",
                    });
                  }

                  let payload = {
                    t_id: res.data.details.t_id,
                    stripe_payment_intent_id: result.error
                      ? result.error.payment_intent.id
                      : result.paymentIntent.id,
                  };

                  axios
                    .post(endpoints.subscription.upgrade3dSecure, payload)
                    .then(res => {
                      if (res.data.status === "1") {
                        setSubmitting(false);
                        dispatch({ type: "CLOSE_RIGHT_POP_OUT" });
                        if (!result.error) {
                          handleToast("Subscription successfully updated");
                          dispatch({
                            type: "REFRESH_SUBSCRIPTIONS",
                          });
                        }
                      }
                    })
                    .catch(err => {
                      let message = "There was a problem";
                      if (err.response && err.response.data) {
                        message = parseError(
                          err.response.data.errors[0].message_code
                        );
                      }

                      setSubmitting(false);
                      dispatch({ type: "CLOSE_RIGHT_POP_OUT" });
                      handleToast(message);
                      dispatch({
                        type: "REFRESH_SUBSCRIPTIONS",
                      });
                    });
                });
            } else {
              if (postObject.subscription_id === 1000) {
                //user has downgraded to cheaper subscription
                ecommerceGAEvent(
                  {
                    ...res.data.details.ecommerce,
                    items: res.data.details.ecommerce.items.map((p: any) => ({
                      ...p,
                      quantity: 1,
                    })),
                  },
                  "downgrade-subscription"
                );
              } else if (postObject.subscription_id === 1001) {
                //user has upgraded to more expensive subscription
                ecommerceGAEvent(
                  {
                    ...res.data.details.ecommerce,
                    items: res.data.details.ecommerce.items.map((p: any) => ({
                      ...p,
                      quantity: 1,
                    })),
                  },
                  "upgrade-subscription"
                );
              }
              setSubmitting(false);
              dispatch({ type: "CLOSE_RIGHT_POP_OUT" });
              handleToast("Subscription successfully updated");
              dispatch({
                type: "REFRESH_SUBSCRIPTIONS",
              });
            }
          }
          axios
            .get(endpoints.subscription.refreshAccessSubscription)
            .then(res => {
              if (res.data.status === "1") {
                dispatch({
                  type: "SET_SUBSCRIPTION_STATUS",
                  payload: res.data.details.subscription_status,
                });
              }
            });
        })

        .catch(err => {
          setSubmitting(false);
          console.log("fail", err);
          if (err.response && err.response.data && err.response.data.errors) {
            const { errors }: Errors = err.response.data;
            errors.forEach(({ field_name, message_code }) => {
              if (field_name === "Error_Label") {
                if (message_code === "Total_Users_Would_Exceed_Allowance") {
                  setPanelErrors(panelErrors => [
                    ...panelErrors,
                    "Current number of users exceeds the allowance for this subscription tier",
                  ]);
                } else if (
                  message_code === "Total_Area_Would_Exceed_Allowance"
                ) {
                  setPanelErrors(panelErrors => [
                    ...panelErrors,
                    "Current farm area exceeds the allowance for this subscription tier",
                  ]);
                } else if (
                  message_code === "Total_Farms_Would_Exceed_Allowance"
                ) {
                  setPanelErrors(prevState => [
                    ...prevState,
                    "Current farm number exceeds the allowance for this subscription tier",
                  ]);
                } else {
                  setPanelErrors(panelErrors => [
                    ...panelErrors,
                    "An error occured while updating your subscription",
                  ]);
                }
              }
            });
          }
        });
    }
    if (!skipPayment && previousCard) {
      let postObject = {
        subscription_id: state.subscription_id,
        payment_card_id: selectedOption,
        renewal_type: state.subscription_name,
      };

      setSubmitting(true);

      axios
        .post(endpoints.subscription.upgradeSubscription, postObject)
        .then(res => {
          if (res.data.status === "1") {
            if (res.data.details.status === "requires_action") {
              // if 3d secure verification required, do so here, else

              props.stripe
                .handleCardAction(res.data.details.client_secret)
                .then((result: any) => {
                  if (result.error) {
                    setSubmitting(false);
                    dispatch({ type: "CLOSE_RIGHT_POP_OUT" });
                    handleToast("There was a problem verifying your card");
                    dispatch({
                      type: "REFRESH_SUBSCRIPTIONS",
                    });
                  }

                  let payload = {
                    t_id: res.data.details.t_id,
                    stripe_payment_intent_id: result.error
                      ? result.error.payment_intent.id
                      : result.paymentIntent.id,
                  };

                  axios
                    .post(endpoints.subscription.upgrade3dSecure, payload)
                    .then(res => {
                      if (res.data.status === "1") {
                        setSubmitting(false);
                        dispatch({ type: "CLOSE_RIGHT_POP_OUT" });
                        if (!result.error) {
                          handleToast("Subscription successfully updated");
                          dispatch({
                            type: "REFRESH_SUBSCRIPTIONS",
                          });
                        }
                      }
                    })
                    .catch(err => {
                      let message = "There was a problem";
                      if (err.response && err.response.data) {
                        message = parseError(
                          err.response.data.errors[0].message_code
                        );
                      }

                      setSubmitting(false);
                      dispatch({ type: "CLOSE_RIGHT_POP_OUT" });
                      handleToast(message);
                      dispatch({
                        type: "REFRESH_SUBSCRIPTIONS",
                      });
                    });
                });
            } else {
              if (postObject.subscription_id === 1000) {
                //user has downgraded to cheaper subscription
                ecommerceGAEvent(
                  {
                    ...res.data.details.ecommerce,
                    items: res.data.details.ecommerce.items.map((p: any) => ({
                      ...p,
                      quantity: 1,
                    })),
                  },
                  "downgrade-subscription"
                );
              } else if (postObject.subscription_id === 1001) {
                //user has upgraded to more expensive subscription
                ecommerceGAEvent(
                  {
                    ...res.data.details.ecommerce,
                    items: res.data.details.ecommerce.items.map((p: any) => ({
                      ...p,
                      quantity: 1,
                    })),
                  },
                  "upgrade-subscription"
                );
              }
              setSubmitting(false);
              dispatch({ type: "CLOSE_RIGHT_POP_OUT" });
              handleToast("Subscription successfully updated");
              dispatch({
                type: "REFRESH_SUBSCRIPTIONS",
              });
            }
          }
          axios
            .get(endpoints.subscription.refreshAccessSubscription)
            .then(res => {
              if (res.data.status === "1") {
                dispatch({
                  type: "SET_SUBSCRIPTION_STATUS",
                  payload: res.data.details.subscription_status,
                });
              }
            });
        })

        .catch(err => {
          setSubmitting(false);
          console.log("fail", err);
        });
    }
    if (!skipPayment && payload && token && country_name) {
      let postObject = {
        subscription_id: state.subscription_id,
        renewal_type: state.subscription_name,
        new_stripe_token: token.id,
        new_card_type: token.card.brand,
        new_card_holder_name: token.card.name,
        new_card_end_digits: token.card.last4,
        new_exp_date_month: token.card.exp_month.toString(),
        new_exp_date_year: token.card.exp_year.toString(),
        new_title: payload.title,
        new_first_name: payload.first_name,
        new_last_name: payload.last_name,
        new_telephone: payload.phone_number,
        new_email_address: payload.email_address,
        new_address_line_1: payload.address_line_1,
        new_address_line_2: payload.address_line_2,
        new_town_city: payload.city_town,
        new_county_state: payload.county_state,
        new_postcode: token.card.address_zip,
        new_country: token.card.country,
        new_country_id: parseInt(payload.country_id),
        new_billing_address_line_1: payload.address_line_1,
        new_billing_address_line_2: payload.address_line_2,
        new_billing_town_city: payload.city_town,
        new_billing_county_state: payload.county_state,
        new_billing_postcode: token.card.address_zip,
        new_billing_country: country_name,
        new_billing_country_id: parseInt(payload.country_id),
        b_save_card: payload.bSaveCard,
      };

      setSubmitting(true);

      axios
        .post(endpoints.subscription.upgradeSubscription, postObject)
        .then(res => {
          if (res.data.status === "1") {
            if (res.data.details.status === "requires_action") {
              // if 3d secure verification required, do so here, else

              props.stripe
                .handleCardAction(res.data.details.client_secret)
                .then((result: any) => {
                  if (result.error) {
                    setSubmitting(false);
                    dispatch({ type: "CLOSE_RIGHT_POP_OUT" });
                    handleToast("There was a problem verifying your card");
                    dispatch({
                      type: "REFRESH_SUBSCRIPTIONS",
                    });
                  } else {
                    let payload = {
                      t_id: res.data.details.t_id,
                      stripe_payment_intent_id: result.paymentIntent.id,
                    };

                    axios
                      .post(endpoints.subscription.upgrade3dSecure, payload)
                      .then(res => {
                        if (res.data.status === "1") {
                          setSubmitting(false);
                          dispatch({ type: "CLOSE_RIGHT_POP_OUT" });
                          handleToast("Subscription successfully upgraded");
                          dispatch({
                            type: "REFRESH_SUBSCRIPTIONS",
                          });
                        }
                      })
                      .catch(err => {
                        dispatch({ type: "CLOSE_RIGHT_POP_OUT" });
                        handleToast("There was a problem");
                        dispatch({
                          type: "REFRESH_SUBSCRIPTIONS",
                        });
                      });
                  }
                });
            } else {
              setSubmitting(false);
              dispatch({ type: "CLOSE_RIGHT_POP_OUT" });
              handleToast("Subscription successfully upgraded");
              dispatch({
                type: "REFRESH_SUBSCRIPTIONS",
              });
            }
          }
        })
        .catch(err => {
          setSubmitting(false);
          console.log("fail", err);
          if (err.response && err.response.data && err.response.data.errors) {
            const { errors }: Errors = err.response.data;
            errors.forEach(({ field_name, message_code }) => {
              if (field_name === "Error_Label") {
                if (message_code === "Total_Users_Would_Exceed_Allowance") {
                  setPanelErrors(panelErrors => [
                    ...panelErrors,
                    "Current number of users exceeds the allowance for this subscription tier",
                  ]);
                } else if (
                  message_code === "Total_Area_Would_Exceed_Allowance"
                ) {
                  setPanelErrors(panelErrors => [
                    ...panelErrors,
                    "Current farm area exceeds the allowance for this subscription tier",
                  ]);
                } else if (
                  message_code === "Total_Farms_Would_Exceed_Allowance"
                ) {
                  setPanelErrors(prevState => [
                    ...prevState,
                    "Current farm number exceeds the allowance for this subscription tier",
                  ]);
                } else {
                  setPanelErrors(panelErrors => [
                    ...panelErrors,
                    "An error occured while updating your subscription",
                  ]);
                }
              }
            });
          }
        });
    }
  };

  const handleChange = (id: number | null) => {
    setSelectOption(id);
  };

  // const { data: taxRateData } = useFetch(endpoints.register.registerDetails); //not sure where to get tax rate from, hardcode for now

  const requestConfig = React.useMemo(() => {
    return { params: { subscription_id: state.subscription_id } };
    //eslint-disable-next-line
  }, []);

  const { data, loading, error } = useFetch(
    endpoints.subscription.upgradeDetailsSubscription,
    requestConfig
  );

  React.useEffect(() => {
    if (
      data &&
      data.details &&
      !data.details.skip_for_now_payment_card &&
      data.details.list_payment_cards.length === 0
    ) {
      setSelectOption(0);
    }
  }, [data]);

  if (loading) {
    return (
      <span>
        <Spinner name="three-bounce" fadeIn="none" />
      </span>
    );
  }

  if (error)
    return (
      <div className="ErrorResults">
        <div className="NoResults">
          <img src={errorResults} alt={"Error"} />
          <p>Error</p>
        </div>
      </div>
    );

  const discountRemaining = (value: number | null, price: number | null) => {
    if (price && value) {
      if (data.details.promo_code_type === "Amount_Off") {
        if (value > price) {
          const final = price;
          return (
            <div className="PromoCodeValue">
              Promotional discount:{" "}
              <span>
                {String.fromCharCode(0x00a3)}
                {final}
              </span>
            </div>
          );
        } else {
          const final = value;
          return (
            <div className="PromoCodeValue">
              Promotional discount:{" "}
              <span>
                {String.fromCharCode(0x00a3)}
                {final}
              </span>
            </div>
          );
        }
      } else if (data.details.promo_code_type === "Percentage_Off") {
        return (
          <div className="PromoCodeValue">
            Promotional discount:{" "}
            <span>
              {data.details.promo_value}
              {String.fromCharCode(0x0025)}
            </span>
          </div>
        );
      }
    } else {
      return null;
    }
  };

  const finalPrice = (
    netPrice: number,
    promoValue: number | null,
    typeOfDiscount: string | null
  ) => {
    // let tax = taxRateData.details.tax_rate ? taxRateData.details.tax_rate : 0; //not sure where to get tax rate from, hard code for now
    let tax = 20; //not sure where to get tax rate from, hard code for now;
    if (typeOfDiscount === "Percentage_Off" && promoValue) {
      let total = (netPrice - netPrice * (promoValue / 100)) * (1 + tax / 100);
      return total > 0 ? total : 0;
    } else if (typeOfDiscount === "Amount_Off" && promoValue) {
      let amountOffFinal = (netPrice - promoValue) * (1 + tax / 100);
      return amountOffFinal > 0 ? amountOffFinal : 0;
    }
    return netPrice;
  };

  if (data) {
    const { list_payment_cards, ...rest } = data.details;

    return (
      <>
        <div
          className={`SubscriptionUpgrade ${props.class ? props.class : ""}`}
        >
          <div className="HeaderDetails">
            <h3 className="UpgradeHeader">{state.subscription_name}</h3>
            <div className="PriceMonth">
              {state.subscription_name === "Tier 1"
                ? "Free"
                : state.monthly_price !== 0
                ? `£${state.monthly_price}`
                : "Free"}
              {state.subscription_name === "Tier 1" ? (
                <>
                  &nbsp;<span>for the first month then</span>&nbsp;
                  {`£${state.monthly_price}`}
                  <span>
                    {state.payment_interval ? state.payment_interval : ""}
                  </span>
                </>
              ) : (
                <span>
                  {state.payment_interval ? state.payment_interval : ""}
                </span>
              )}
              {state.monthly_price && data.details.promo_value
                ? discountRemaining(
                    data.details.promo_value,
                    state.monthly_price
                  )
                : ""}
              {state.monthly_price && data.details.promo_value ? (
                <div className="PromoCodeValue">
                  Final price after tax:{" "}
                  <span>
                    {String.fromCharCode(0x00a3)}
                    {finalPrice(
                      state.monthly_price,
                      data.details.promo_value,
                      data.details.promo_code_type
                    )}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="ContentDetails">
            <div className="CardList">
              {data.details.list_payment_cards.map(
                (card: PaymentCardItem, i: number) => {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        handleChange(card.payment_card_id);
                      }}
                      className="Checkbox SideBySide"
                    >
                      <input
                        id="bTwo"
                        name={`PaymentCard-${card.payment_card_id}`}
                        type="radio"
                        readOnly
                        checked={selectedOption === card.payment_card_id}
                        value={card.payment_card_id}
                      />
                      <label
                        className={`CustomInput ${
                          selectedOption === card.payment_card_id
                            ? "ShowCVV"
                            : ""
                        }`}
                        htmlFor={`PaymentCard-${card.payment_card_id}`}
                      >
                        <div className="CardDetails">
                          <span className="CardName">{card.card_name}</span>
                          <span>
                            Ending in {card.card_end_digits}
                            {/*, Expired on {card.expiry_date.getMonth().toString()} / {card.expiry_date.getFullYear().toString()}*/}
                          </span>
                        </div>
                      </label>
                    </div>
                  );
                }
              )}
              <div
                onClick={() => {
                  handleChange(0);
                }}
                className="Checkbox SideBySide"
              >
                <input
                  id="bTwo"
                  name={`PaymentCard-add-new`}
                  type="radio"
                  readOnly
                  checked={selectedOption === 0}
                  value={0}
                />
                <label className="CustomInput" htmlFor={`PaymentCard-add-new`}>
                  <div className="CardDetails">
                    <span className="CardName">Add new payment card</span>
                  </div>
                </label>
              </div>
              {data.details.skip_for_now_payment_card ? (
                <div
                  onClick={() => {
                    handleChange(null);
                  }}
                  className="Checkbox SideBySide"
                >
                  <input
                    id="bTwo"
                    name={`PaymentCard-skip-for-now`}
                    type="radio"
                    readOnly
                    checked={selectedOption === null}
                    value={0}
                  />
                  <label
                    className="CustomInput"
                    htmlFor={`PaymentCard-skip-for-now`}
                  >
                    <div className="CardDetails">
                      <span className="CardName">Skip payment for now</span>
                    </div>
                  </label>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {selectedOption === 0 && (
            <PaymentCardAddForm
              submit={(values, countryName, token) =>
                handleSubmit(values, countryName, token, false)
              }
              showCVV
              saveCard={true}
              data={{ details: rest }}
              ref={form}
              stripe={props.stripe}
            />
          )}
        </div>
        <div className="Bottom">
          <div className="PanelErrorsContainer">
            {panelErrors.map(panelError => (
              <span>{panelError}</span>
            ))}
          </div>
          <button
            className="Btn BtnWhite"
            onClick={() => dispatch({ type: "CLOSE_RIGHT_POP_OUT" })}
          >
            Cancel
          </button>
          <button
            className="Btn"
            onClick={() => {
              //dispatch({ type: "CLOSE_RIGHT_POP_OUT" })
              //handleToast("Subscription successfully upgraded")
              //if (form) form.current.props.submit(form.current.props.data.details)
              // selected old card - submit with no details
              if (selectedOption === null) {
                handleSubmit(null, "", null, true, true);
              }
              if (selectedOption && selectedOption > 0) {
                handleSubmit(null, "", null, true);
              }
              if (
                form &&
                selectedOption !== null &&
                selectedOption !== undefined &&
                selectedOption === 0
              ) {
                form.current.submitForm();
              }
            }}
            type="submit"
            disabled={submitting}
          >
            {submitting ? (
              <Spinner
                name="three-bounce"
                className="BtnSpinner"
                color="white"
                fadeIn="none"
              />
            ) : subscriptionStatus === "Auto-Renewal-Failure" ||
              state.isTier3or4 ||
              (nextSubscPrice &&
                currentSubscPrice &&
                nextSubscPrice < currentSubscPrice) ? (
              "Pay"
            ) : state.downgrade ? (
              "Downgrade"
            ) : (
              "Upgrade"
            )}
          </button>
        </div>
      </>
    );
  }
  return null;
};

const SubscriptionUpgradeWithStripe = injectStripe(SubscriptionUpgrade);

export default (props: SubscriptionUpgrade) => (
  <Elements>
    <SubscriptionUpgradeWithStripe {...props} />
  </Elements>
);
