import React from "react";
import { endpoints } from "serverDetails";
import { AxiosRequestConfig } from "axios";
import axios from "api";
import { ApiReturn } from "pages/FieldReportsPage/models";

const fieldReportUrl = endpoints.fieldReport.reportDetails;

export const getAddFieldMarginFarm = async (
  code: string | string[],
  client_guid: string | string[]
): Promise<ApiReturn> => {
  const reqConfig: AxiosRequestConfig = {
    params: {
      code,
      client_guid,
    },
  };

  try {
    const res = await axios.get(
      endpoints.fieldMargin.addFieldMarginFarm,
      reqConfig
    );
    if (res.status === 1 || res.status === 200) {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: res.data,
        errors: res.data.errors,
      };
    }
  } catch (error) {
    return {
      response: null,
      errors: error,
    };
  }
};

export const getThirdPartyIntegrations = async (): Promise<ApiReturn> => {
  try {
    const res = await axios.get(fieldReportUrl);
    if (res.status === 1 || res.status === 200) {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: res.data,
        errors: res.data.errors,
      };
    }
  } catch (error) {
    return {
      response: null,
      errors: error,
    };
  }
};
