import React, { useState, useEffect, useRef, SetStateAction } from "react";
import { RouteComponentProps } from "@reach/router";
import axios from "api";
import { RegistrationSteps, RegistrationStepActions } from "./Registration";
import { endpoints } from "serverDetails";

import Helmet from "react-helmet";
import QrReader from "react-qr-reader";
import { FormikActions } from "formik";
import { T } from "ramda";
import { findReplace } from "helpers/functions";
import { useFetch } from "helpers/loading";
interface SubscriptionStepProps {
  data?: any;
  subscription: any;
  setSubscription: any;
  setSubscriptionName: any;
  setSubscriptionPrice: any;
  setSubscriptionType: any;
  setSubscriptionGross: any;
  setActivePage: any;
  usersPromoCode: any;
  setUsersPromoCode: any;
  setPromoCodeValue: any;
  setPromoCodeType: any;
  promoCodeVisible: any;
  setPromoCodeVisible: any;
  onLastStep: boolean;
  setDisplayError: React.Dispatch<React.SetStateAction<string>>;
  disableCardInput: boolean;
  setDisableCardInput: React.Dispatch<React.SetStateAction<boolean>>;
  setFullDiscount: React.Dispatch<React.SetStateAction<boolean>>;
  setOnLastStep: React.Dispatch<React.SetStateAction<boolean>>;
  setSkipPaymentOption: any;
  handleFormSubmit: (
    values?: any,
    formikActions?: FormikActions<any> | undefined
  ) => Promise<void>;
  bQueryStringPromoCode: boolean;
}
const SubscriptionStep = (
  props: RouteComponentProps & SubscriptionStepProps
) => {
  if (props.data) {
    let list = {
      details: { list: props.data.details.subscription_list },
    };
    return (
      <SubscriptionRegistrationInner
        navigate={props.navigate}
        subscription={props.subscription}
        setSubscription={props.setSubscription}
        setSubscriptionName={props.setSubscriptionName}
        setSubscriptionPrice={props.setSubscriptionPrice}
        setSubscriptionGross={props.setSubscriptionGross}
        setSubscriptionType={props.setSubscriptionType}
        setActivePage={props.setActivePage}
        data={list}
        usersPromoCode={props.usersPromoCode}
        setUsersPromoCode={props.setUsersPromoCode}
        promoCodeVisible={props.promoCodeVisible}
        setPromoCodeVisible={props.setPromoCodeVisible}
        setPromoCodeType={props.setPromoCodeType}
        setPromoCodeValue={props.setPromoCodeValue}
        onLastStep={props.onLastStep}
        setDisplayError={props.setDisplayError}
        disableCardInput={props.disableCardInput}
        setDisableCardInput={props.setDisableCardInput}
        setFullDiscount={props.setFullDiscount}
        setOnLastStep={props.setOnLastStep}
        handleFormSubmit={props.handleFormSubmit}
        setSkipPaymentOption={props.setSkipPaymentOption}
        bQueryStringPromoCode={props.bQueryStringPromoCode}
      />
    );
  }
  return null;
};

type Data = {
  details: SubscriptionDetails;
};

type SubscriptionDetails = {
  current_subscription_id?: number;
  current_subscription_status?: string;
  list: SubscriptionItem[];
};

type SubscriptionItem = {
  subscription_id: number;
  subscription_name: string;
  short_description: string;
  description: string;
  monthly_price: number | null;
  yearly_price: number | null;
  gross_price: number;
  b_recurring_payment_needed: boolean;
  purchase_type: string;
};

interface SubscriptionProps {
  navigate?: any;
  subscription?: number;
  setSubscription?: (x: number) => void;
  setSubscriptionName?: (x: string) => void;
  setSubscriptionPrice?: (x: number | string) => void;
  setSubscriptionGross?: (x: number) => void;
  setSubscriptionType: (s: string) => void;
  setActivePage?: (y: string) => void;
  setPromoCodeType?: (y: string) => void;
  setPromoCodeValue?: (x: number) => void;
  data?: Data;
  reload?: () => void;
  usersPromoCode?: string;
  setUsersPromoCode?: (p: string) => void;
  promoCodeVisible?: boolean;
  setPromoCodeVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  onLastStep?: boolean;
  setDisplayError?: (v: string) => void;
  disableCardInput?: boolean;
  setDisableCardInput?: React.Dispatch<React.SetStateAction<boolean>>;
  setFullDiscount?: React.Dispatch<React.SetStateAction<boolean>>;
  setOnLastStep?: React.Dispatch<React.SetStateAction<boolean>>;
  registrationStep?: RegistrationSteps;
  setRegistrationStep?: React.Dispatch<SetStateAction<RegistrationSteps>>;
  registrationStepActions?: RegistrationStepActions;
  setCurrentRegistrationStep?: (s: string) => void;
  setSkipPaymentOption: (x: boolean) => void;
  handleFormSubmit?: () => void;
  bQueryStringPromoCode: boolean;
}

type SeeMoreState = {
  id: string;
  isOpen: boolean;
};

const SubscriptionRegistrationInner = (props: SubscriptionProps) => {
  let list = { details: { list: props.data && props.data.details.list } };

  const [errorMessage, setErrorMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);

  // const handleToast = (message: string) => {
  //   dispatch({ type: "SHOW_TOAST", message: message });
  //   window.setTimeout(() => {
  //     dispatch({ type: "HIDE_TOAST" });
  //   }, 3000);
  // };

  const verifyPromoCode = (
    subscription_id: number | undefined,
    code: string | undefined
  ) => {
    // TODO add in subscription id as second param and as second field in endpoint call
    axios
      .get(endpoints.register.getTransactionInfo, {
        params: { subscription_id, ...(code && { promo_code: code }) },
      })
      .then(res => {
        if (res.data.status === "1") {
          props.setPromoCodeType &&
            props.setPromoCodeType(res.data.details.promo_code_type);
          props.setPromoCodeValue &&
            props.setPromoCodeValue(res.data.details.promo_value);
          props.setSkipPaymentOption &&
            props.setSkipPaymentOption(
              res.data.details.skip_for_now_payment_card
            );
          props.handleFormSubmit && props.handleFormSubmit();
        }
      })
      .catch(err => {
        setErrorMessage("This code is invalid");
      });
  };
  const [result, setResult] = useState<string>("");
  const [noCamera, setNoCamera] = useState<boolean>(false);
  const [scannerLoading, setScannerLoading] = useState<boolean>(true);
  const [legacyMode, setLegacyMode] = useState<boolean>(false);
  const [QRError, setQRError] = useState<boolean>(false);
  const [scanCode, setScanCode] = useState<boolean>(false);
  const [seeMoreState, setSeeMoreState] = useState<SeeMoreState[]>([]);
  const QRRef = useRef<any>(null);
  const enterpriseTierCMSParams = React.useMemo(() => {
    return { params: { partCode: "subcontactusshort" } };
  }, [window.location.pathname]);
  const enterpriseTierResult = useFetch(
    endpoints.cms.cmsPartialPageDetails,
    enterpriseTierCMSParams
  );
  let enterpriseTierContent;
  if (enterpriseTierResult.data) {
    enterpriseTierContent = enterpriseTierResult.data.details.page_content;
  }

  const enterpriseTierSeeMoreParams = React.useMemo(() => {
    return { params: { partCode: "subcontactusmore" } };
  }, [window.location.pathname]);
  const enterpriseTierSeeMoreResult = useFetch(
    endpoints.cms.cmsPartialPageDetails,
    enterpriseTierSeeMoreParams
  );
  let enterpriseTierSeeMoreContent;
  if (enterpriseTierSeeMoreResult.data) {
    enterpriseTierSeeMoreContent =
      enterpriseTierSeeMoreResult.data.details.page_content;
  }

  const showList = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const target = e.target as HTMLAnchorElement;
    const id = target.id;
    const card = seeMoreState.find(c => c.id === id);

    if (!card) {
      setSeeMoreState(c => [...c, { id: id, isOpen: true }]);
    } else {
      const newSeeMoreState = findReplace(seeMoreState, card, {
        ...card,
        isOpen: !card.isOpen,
      });
      setSeeMoreState(newSeeMoreState);
    }
  };

  const listIsOpen = (id: string) => {
    const card = seeMoreState.find(c => c.id === id);
    if (!card) {
      return false;
    }
    return card.isOpen;
  };

  const hideScanner = {
    display: "none",
  };

  const uploadQRImage = () => {
    QRRef.current && QRRef.current.openImageDialog();
  };

  const handleError = (err: Error) => {
    setNoCamera(true);
    setLegacyMode(true);
  };

  const handleLoad = () => {
    window.setTimeout(() => {
      setScannerLoading(false);
    }, 500);
  };

  const handleScan = (data: any) => {
    if (data) {
      const regex = /promo_code=([\s\S]*)/;
      const t = data.match(regex);
      if (t) {
        setQRError(false);
        setErrorMessage("");
        setScanCode(false);
        setResult(t[1]);
        props.setUsersPromoCode && props.setUsersPromoCode(t[1]);
      } else {
        setQRError(true);
        setErrorMessage("");
      }
    } else {
      if (legacyMode) {
        setErrorMessage("Invalid image type");
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Skippy Scout: Subscription</title>
      </Helmet>
      <div className={`${!props.setActivePage ? "SubscriptionWrapper" : ""}`}>
        <div className="Register">
          {props.setActivePage ? (
            <div className="FlexContainer FlexContainerSpace">
              <h1>Subscription</h1>
            </div>
          ) : (
            <h1>Subscription</h1>
          )}
          <ul className="FlexContainer RegistrationOptions">
            {props.data &&
              props.data.details.list.map((s, i) => {
                const noMonthlyPrice =
                  s.monthly_price === 0 || s.monthly_price === null;
                const noYearlyPrice =
                  s.yearly_price === 0 || s.yearly_price === null;
                const monthlyPrice =
                  s.monthly_price !== 0 && s.monthly_price !== null;
                const yearlyPrice =
                  s.yearly_price !== 0 && s.yearly_price !== null;
                const displayPrice =
                  s.subscription_name === "Tier 1"
                    ? "Free"
                    : noMonthlyPrice && yearlyPrice
                    ? `£${s.yearly_price}`
                    : monthlyPrice && noYearlyPrice
                    ? `£${s.monthly_price}`
                    : noMonthlyPrice && noYearlyPrice
                    ? "Free"
                    : `£${s.monthly_price}`;

                const priceExtraInfo =
                  s.subscription_name === "Tier 1"
                    ? " for the first month then "
                    : "";
                const priceAdditionalCharge =
                  s.subscription_name === "Tier 1" ? `£${s.monthly_price}` : "";
                const paymentInterval =
                  noMonthlyPrice && yearlyPrice
                    ? `/year (+VAT)`
                    : monthlyPrice && noYearlyPrice
                    ? `/month (+VAT)`
                    : noMonthlyPrice && noYearlyPrice
                    ? ""
                    : `/month (+VAT)`;

                if (
                  props.subscription &&
                  props.subscription === s.subscription_id
                ) {
                  props.setSubscriptionName &&
                    props.setSubscriptionName(s.subscription_name);
                  if (s.subscription_name === "Tier 1") {
                    props.setSubscriptionPrice &&
                      props.setSubscriptionPrice(`Free`);
                  } else {
                    props.setSubscriptionPrice &&
                      props.setSubscriptionPrice(
                        `${displayPrice}${paymentInterval}`
                      );
                  }
                  props.setSubscriptionGross &&
                    props.setSubscriptionGross(s.gross_price);
                  props.setSubscriptionType(
                    s.monthly_price ? "Monthly" : s.yearly_price ? "Yearly" : ""
                  );
                }

                return (
                  <li
                    key={i}
                    className={`SubscriptionOption 
                         SubscriptionNoToggle
                         ${
                           props.data &&
                           props.data.details.current_subscription_id ===
                             s.subscription_id
                             ? "Highlighted"
                             : ""
                         }
                         ${
                           props.subscription &&
                           props.subscription === s.subscription_id
                             ? "Highlighted"
                             : ""
                         }`}
                    onClick={() => {
                      if (props.setActivePage) {
                        props.setSubscription &&
                          props.setSubscription(s.subscription_id);
                        props.setSubscriptionName &&
                          props.setSubscriptionName(s.subscription_name);
                        if (props.setSubscriptionPrice) {
                          if (s.subscription_name === "Tier 1") {
                            props.setSubscriptionPrice("Free");
                          } else {
                            props.setSubscriptionPrice(
                              `${displayPrice}${paymentInterval}`
                            );
                          }
                        }

                        props.setSubscriptionGross &&
                          props.setSubscriptionGross(s.gross_price);
                      }
                    }}
                  >
                    <span className="Holder">
                      {props.setActivePage && (
                        <span className="Top">
                          <span className="CheckImg">
                            <span className="Circle" />
                          </span>
                        </span>
                      )}

                      <span className="Middle">
                        <span className="">
                          <h2>{s.subscription_name}</h2>
                          <div
                            className={`PriceMonth ${
                              priceExtraInfo ? "PriceWrap" : ""
                            }`}
                          >
                            <h2 className="Price">{displayPrice}</h2>
                            {priceExtraInfo ? (
                              <>
                                &nbsp;&nbsp;
                                <p className="Month">{priceExtraInfo}</p>
                              </>
                            ) : (
                              ""
                            )}
                            {priceAdditionalCharge ? (
                              <>
                                &nbsp;&nbsp;
                                <h2 className="Price">
                                  {priceAdditionalCharge}
                                </h2>
                              </>
                            ) : (
                              ""
                            )}
                            <p className="Month">{paymentInterval}</p>
                          </div>
                          {listIsOpen(s.subscription_id.toString()) && (
                            <div
                              className="Description"
                              dangerouslySetInnerHTML={{
                                __html: s.description,
                              }}
                            ></div>
                          )}
                          {!listIsOpen(s.subscription_id.toString()) && (
                            <p>
                              {s.short_description}{" "}
                              <a
                                onClick={showList}
                                href="javascript:;"
                                id={s.subscription_id.toString()}
                              >
                                See more
                              </a>
                            </p>
                          )}

                          {/* might be wanted to be added at a later point */}
                          {/* {listIsOpen(s.subscription_id.toString()) &&
                            <p><a onClick={showList} id={s.subscription_id.toString()}> See less</a></p>
                          } */}
                        </span>
                      </span>
                    </span>
                  </li>
                );
              })}
            {enterpriseTierContent && enterpriseTierSeeMoreContent && (
              <li
                className={`SubscriptionOption 
                         SubscriptionNoToggle`}
              >
                <>
                  <div className="Holder CMSGeneratedSubscriptionTier">
                    <div className="Middle">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: enterpriseTierContent,
                        }}
                      ></div>
                      {listIsOpen("-001") && (
                        // Replace with subcontactus content when available
                        <div
                          className="Description"
                          dangerouslySetInnerHTML={{
                            __html: enterpriseTierSeeMoreContent,
                          }}
                        ></div>
                      )}
                      {!listIsOpen("-001") && (
                        <a onClick={showList} href="javascript:;" id="-001">
                          See more
                        </a>
                      )}
                    </div>
                  </div>
                </>
              </li>
            )}
          </ul>
          {
            <>
              <div className="Continue">
                <div className="RememberForgot PromoInput">
                  <div className="Checkbox">
                    <input
                      key={"isPromoVisible"}
                      name="promo_code"
                      type="checkbox"
                      id="promo_code"
                      defaultChecked={props.promoCodeVisible}
                      onChange={() => {
                        return (
                          props.setPromoCodeVisible &&
                          props.setPromoCodeVisible(prev => {
                            props.setDisableCardInput &&
                              props.setDisableCardInput(false);
                            props.setPromoCodeType &&
                              props.setPromoCodeType("");
                            props.setPromoCodeValue &&
                              props.setPromoCodeValue(0);
                            props.setUsersPromoCode &&
                              props.bQueryStringPromoCode === false &&
                              props.setUsersPromoCode("");
                            props.setFullDiscount &&
                              props.setFullDiscount(false);
                            props.setOnLastStep && props.setOnLastStep(false);
                            if (!prev === false) {
                              setResult("");
                            }
                            return !prev;
                          })
                        );
                      }}
                    />
                    <label className="CustomInput" htmlFor="promo_code">
                      I have a code
                    </label>
                  </div>
                </div>
                {props.promoCodeVisible && (
                  <div className="FormBox">
                    <div className="FormLabel">
                      <label
                        htmlFor={"PromoValue"}
                        className="InputContainerLabels"
                      >
                        Please enter your code:
                      </label>
                    </div>
                    <div className="FormField FlexContainer">
                      <input
                        autoFocus={true}
                        key={"promoval"}
                        className={`EditBox`}
                        name={"PromoValue"}
                        type={"text"}
                        id={"PromoValue"}
                        value={result ? result : props.usersPromoCode}
                        onChange={e => {
                          setResult(e.target.value);
                          props.setUsersPromoCode &&
                            props.setUsersPromoCode(e.target.value);
                          e.target.focus();
                        }}
                        spellCheck={false}
                      />
                      <div
                        className="Btn"
                        style={{ width: "200px", marginLeft: "5px" }}
                        onClick={() => setScanCode(true)}
                      >
                        Scan a QR code
                      </div>
                    </div>
                    {scanCode ? (
                      <div className="QRScannerMarginTop">
                        {noCamera ? (
                          <div className="QRScannerContainer">
                            <h4>
                              This device does not have a camera,
                              <br />
                              or your browser has blocked access to your camera
                            </h4>
                            <div
                              className="Btn"
                              onClick={() => uploadQRImage()}
                            >
                              Upload a QR code image file
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div
                          style={scannerLoading || noCamera ? hideScanner : {}}
                        >
                          <QrReader
                            ref={QRRef}
                            delay={300}
                            onError={handleError}
                            onScan={data => {
                              handleScan(data);
                            }}
                            onLoad={handleLoad}
                            legacyMode={legacyMode}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {QRError && (
                      <p className="ErrorMessageCenter">
                        There was a problem with your QR Code
                      </p>
                    )}
                    {errorMessage && (
                      <div className="ErrorMessage">{errorMessage}</div>
                    )}
                  </div>
                )}
                {!props.subscription && (
                  <div className="FormBox" style={{ marginBottom: 0 }}>
                    <span className="ErrorMessage">
                      Please select a subscription
                    </span>
                  </div>
                )}
                <button
                  className={`Btn`}
                  disabled={submitting || !props.subscription}
                  type="submit"
                  onClick={e => {
                    e.preventDefault();
                    verifyPromoCode(
                      props.subscription,
                      result || props.usersPromoCode
                    ); //TODO Add in subscription id as second param and modify func definition
                  }}
                  id="subscription-submit"
                >
                  Save and continue
                </button>
              </div>
            </>
          }
        </div>
      </div>
    </>
  );
};

export { SubscriptionStep };
