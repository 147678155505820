import React from "react";
import { FieldProps, Field } from "formik";

type BoolCheckboxProps<T> = FieldProps<T> & {
  label: string;
};

export function BoolCheckbox<T>(props: BoolCheckboxProps<T>) {
  return (
    <div className="FormBox">
      <div className="InputContainerRow">
        <label className="InputContainerLabels">{props.label}</label>
        <div className="FormCheckbox">
          <div
            className="Checkbox SideBySide NoMargin"
            onClick={() => props.form.setFieldValue(props.field.name, true)}
          >
            <input
              name={props.field.name + "_true"}
              type="radio"
              readOnly
              checked={props.field.value === true}
              value={props.field.value}
            />
            <label className="CustomInput" htmlFor={props.field.name + "_false"}>
              Yes
            </label>
          </div>
          <div
            onClick={() => {
              props.form.setFieldValue(props.field.name, false);
            }}
            className="Checkbox SideBySide NoMargin"
          >
            <input
              id="bTwo"
              name={props.field.name + "_false"}
              type="radio"
              readOnly
              checked={props.field.value === false}
              value={props.field.value}
            />
            <label className="CustomInput" htmlFor={props.field.name + "_false"}>
              No
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

type BoolCheckboxFieldProps<T> = {
  label: string;
  name: string;
};

export function BoolCheckboxField<T>(props: BoolCheckboxFieldProps<T>) {
  return (
    <Field
      name={props.name}
      render={(fieldprops: FieldProps<T>) => (
        <BoolCheckbox label={props.label} {...fieldprops} />
      )}
    />
  );
}
