import { Reducer, combineReducers } from "redux";
import { PaymentCardItems } from "../pages/PaymentCards/PaymentCardsPage";

export type PaymentCardState =
  | "create"
  | "view"
  | "none"
  | "update_details"
  | "delete";


export type PaymentCardAction = {
  type:
  | "ADD_CARD"
  | "VIEW_CARD_DETAILS"
  | "CLOSE_CARD_DETAILS"
  | "UPDATE_CARD_DETAILS"
  | "DELETE_CARD";
};

const selected_card_state: Reducer = (
  state: PaymentCardState = "none",
  action: PaymentCardAction
): PaymentCardState => {
  switch (action.type) {
    case "ADD_CARD":
      return "create";
    case "VIEW_CARD_DETAILS":
      return "view";
    case "CLOSE_CARD_DETAILS":
      return "none";
    case "UPDATE_CARD_DETAILS":
      return "update_details";
    case "DELETE_CARD":
      return "delete";
    default:
      return state;
  }
};



//export type PaymentCardListItem = {
//  PaymentCards__Id: number,
//  PaymentCards__NameOnCard: string,
//  PaymentCards__CardEndDigits: string,
//  PaymentCards__CardType: string,
//  //expiry_date: Date;
//  PaymentCards__AddDateUTC: string;
//}



export type PaymentCardListItem = {
  payment_card_id: number;
  card_name: string;
  card_end_digits: string;
}

export type PaymentCardListState = PaymentCardListItem[];

type PaymentCardListAction = {
  type:
  | "GET_PAYMENT_CARD_LIST"
  | "GETTING_PAYMENT_CARD_LIST"
  | "ERROR_PAYMENT_CARD_LIST";
} | { type: "GOT_PAYMENT_CARD_LIST", payload: PaymentCardItems }

const list: Reducer = (
  state: PaymentCardListState = [],
  action: PaymentCardListAction
): PaymentCardListState => {
  switch (action.type) {
    case "GET_PAYMENT_CARD_LIST":
    case "GETTING_PAYMENT_CARD_LIST":
    case "ERROR_PAYMENT_CARD_LIST":
      return [];
    case "GOT_PAYMENT_CARD_LIST":
      return [...action.payload];
    default:
      return state;
  }
};




export const paymentCardsReducer = combineReducers({
  selected_card_state,
  list
});
