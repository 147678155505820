import { produce } from "immer";
import { AppState } from "reducers/rootReducer";

/* Constants */

const SET_HAS_NOTES = "app/register/SET_HAS_NOTES";
const REMOVE_HAS_NOTES = "app/register/REMOVE_HAS_NOTES";

type ValueOf<T> = T[keyof T];

type reportSectionState = {
  hasNotes: number[];
};

type reportSectionAction =
  | {
      type: typeof SET_HAS_NOTES;
      payload: number;
    }
  | {
      type: typeof REMOVE_HAS_NOTES;
      payload: number;
    };

/* Reducer */

const initialReportSectionState: reportSectionState = {
  hasNotes: [],
};

export const reportSectionReducer = (
  state: reportSectionState = initialReportSectionState,
  action: reportSectionAction
): reportSectionState =>
  produce(state, draft => {
    switch (action.type) {
      case SET_HAS_NOTES:
        draft.hasNotes.push(action.payload);
        break;
      case REMOVE_HAS_NOTES:
        draft.hasNotes = draft.hasNotes.filter(item => item !== action.payload);
        break;
      default:
        break;
    }
  });

/* Action Creators */

export const setHasNotes = (payload: number): reportSectionAction => ({
  type: SET_HAS_NOTES,
  payload,
});

export const removeHasNotes = (payload: number): reportSectionAction => ({
  type: REMOVE_HAS_NOTES,
  payload,
});

/* Selectors */

export const selectHasNotes = (state: AppState) => state.reportSection.hasNotes;
