import React from 'react';
import { FieldProps, Field } from "formik";
import DatePicker from 'react-datepicker';
//import format from "date-fns/parseISO";

import "react-datepicker/dist/react-datepicker.css";

type CalendarDatePickerProps = FieldProps<string> & {
  label?: string;
  excludeDay?: boolean;
  placeholder: string;
  inputPlaceholderText?: string;
  value: Date | null;
  setValue(v: Date | null): void
  rowDisplay?: boolean;
  class?: string;
};

export function CalendarDatePicker(props: CalendarDatePickerProps) {

  return (
    <div className={`${props.rowDisplay ? 'InputContainerRow' : 'InputContainer'} ${props.class || ''} InputContainerOverflow`}>
      {props.label && <div className="FormLabel" style={{ minWidth: '175px' }}>
        <label>
          {props.placeholder}
        </label>
      </div>}
      <div className="FormField">
        <DatePicker
          selected={props.value}
          onChange={props.setValue}
          dateFormat="dd/MM/yyyy"
          //minDate={
          //  new Date(currentTime.getFullYear(), currentTime.getMonth(), 1)
          //} // first day of current month

          minDate={
            new Date()
          }
          showMonthYearPicker={props.excludeDay ? true : false}
          placeholderText={props.inputPlaceholderText}
          className="EditBox CalendarEditBox"
        />
      </div>
    </div>
  );
}

type CalendarDatePickerFieldProps = {
  label?: string;
  name: string;
  placeholder: string;
  excludeDay?: boolean;
  inputPlaceholderText?: string;
  rowDisplay?: boolean;
  class?: string;
};

export function CalendarDatePickerField(props: CalendarDatePickerFieldProps) {
  return (
    <Field
      name={props.name}
      render={(fieldprops: FieldProps<string>) => {
        let chosenDate = fieldprops.field.value ? new Date(fieldprops.field.value) : null //new Date()
        return (
          <CalendarDatePicker
            class={props.class}
            label={props.label}
            placeholder={props.placeholder}
            excludeDay={props.excludeDay}
            value={chosenDate}
            inputPlaceholderText={props.inputPlaceholderText}
            setValue={(value) => fieldprops.form.setFieldValue(fieldprops.field.name, value)}
            rowDisplay={props.rowDisplay}
            {...fieldprops}
          />
        )
      }}
    />
  );
}
