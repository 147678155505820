import React from "react";
import Subscription from "./Subscription";
import { endpoints } from "../../serverDetails";
import { useFetch } from "../../helpers/loading";
import Spinner from "react-spinkit";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../reducers/rootReducer";
import Helmet from "react-helmet";

const errorResults = "/content/images/Icon_Error.png";

const SubscriptionPageLoggedIn = () => {
  const handleSubmit = (payload: any) => {
    //action.setSubmitting(true);
    //axios
    //  .post(endpoints.personalInfo.updatePersonalInfo, { ...payload })
    //  .then(res => {
    //    if (res.data.status === "1") {
    //      dispatch({ type: 'SHOW_TOAST', message: "Personal Information updated" })
    //      window.setTimeout(() => {
    //        dispatch({ type: 'HIDE_TOAST' })
    //      }, 3000);
    //    }
    //  })
    //  .catch(err => console.log("err", err));
  };

  const { data, loading, error, reload } = useFetch(
    endpoints.subscription.listSubscriptions
  );

  const refreshStatus = useSelector(
    (state: AppState) => state.subscriptionsReducer.refreshData
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (reload && refreshStatus) {
      reload();
      dispatch({
        type: "REFRESH_SUBSCRIPTIONS",
      });
      dispatch({
        type: "app/auth/CHECK_ACCESS",
      });
    }
    //eslint-disable-next-line
  }, [refreshStatus]);

  React.useEffect(() => {
    if (data && data.details && data.details.current_subscription_id) {
      const originalMonthlyPrice = data.details.list.find(
        (s: any) => s.subscription_id === data.details.current_subscription_id
      )
        ? data.details.list.find(
            (s: any) =>
              s.subscription_id === data.details.current_subscription_id
          ).monthly_price
        : null;
      const originalYearlyPrice = data.details.list.find(
        (s: any) => s.subscription_id === data.details.current_subscription_id
      )
        ? data.details.list.find(
            (s: any) =>
              s.subscription_id === data.details.current_subscription_id
          ).yearly_price
        : null;
      const noMonthlyPrice =
        originalMonthlyPrice === 0 || originalMonthlyPrice === null;
      const noYearlyPrice =
        originalYearlyPrice === 0 || originalYearlyPrice === null;
      const monthlyPrice =
        originalMonthlyPrice !== 0 && originalMonthlyPrice !== null;
      const yearlyPrice =
        originalYearlyPrice !== 0 && originalYearlyPrice !== null;
      const numericalPrice =
        noMonthlyPrice && yearlyPrice
          ? originalYearlyPrice
          : monthlyPrice && noYearlyPrice
          ? originalMonthlyPrice
          : noMonthlyPrice && noYearlyPrice
          ? 0
          : originalMonthlyPrice;
      dispatch({
        type: "SET_SUBSCRIPTION_DETAILS",
        payload: {
          subscription_id: data.details.current_subscription_id,
          subscription_name:
            data.details.list.find(
              (s: any) =>
                s.subscription_id === data.details.current_subscription_id
            ) &&
            data.details.list.find(
              (s: any) =>
                s.subscription_id === data.details.current_subscription_id
            ).subscription_name
              ? data.details.list.find(
                  (s: any) =>
                    s.subscription_id === data.details.current_subscription_id
                ).subscription_name
              : "",
          monthly_price: numericalPrice,
          gross_price:
            data.details.list.find(
              (s: any) =>
                s.subscription_id === data.details.current_subscription_id
            ) &&
            data.details.list.find(
              (s: any) =>
                s.subscription_id === data.details.current_subscription_id
            ).gross_price
              ? data.details.list.find(
                  (s: any) =>
                    s.subscription_id === data.details.current_subscription_id
                ).gross_price
              : 0,
          current_subscription_status: data.details.current_subscription_status,
        },
      });
    }

    return () => {
      dispatch({
        type: "SET_SUBSCRIPTION_DETAILS",
        payload: {
          subscription_id: null,
          subscription_name: "",
          monthly_price: null,
          gross_price: null,
          current_subscription_status: "",
        },
      });
      dispatch({
        type: "SET_NEXT_SUBSCRIPTION_DETAILS",
        payload: {
          subscription_id: null,
          subscription_name: "",
          monthly_price: null,
          gross_price: null,
        },
      });
    };
    //eslint-disable-next-line
  }, [data]);

  if (loading) {
    return (
      <span>
        <Spinner
          name="three-bounce"
          className="NotificationsSpin"
          fadeIn="none"
        />
      </span>
    );
  }

  if (error)
    return (
      <div className="ErrorResults">
        <div className="NoResults">
          <img src={errorResults} alt={"Error"} />
          <p>Error</p>
        </div>
      </div>
    );

  if (data) {
    return (
      <>
        <Helmet>
          <title>Skippy Scout: Subscription</title>
        </Helmet>
        <Subscription
          setSubscription={handleSubmit}
          data={data}
          reload={reload}
        />
      </>
    );
  }
  return null;
};

export default SubscriptionPageLoggedIn;
