import React from "react";
import ChunkyInput from "../../components/SignInComponents/ChunkyInput/ChunkyInput";
import { PersonalInformationFormInnerProps } from "components/SignInComponents/PersonalInformationForm/PersonalInformationForm";
import { Field, ErrorMessage, Form, Formik } from "formik";
import Spinner from "react-spinkit";
import { Helmet } from "react-helmet";
import { PhoneNumberField } from "components/FormComponents/PhoneNumber/PhoneNumber";
import * as Yup from "yup";

const PersonalInformationFormAlreadyAccountRegistrationInner = (
  props: PersonalInformationFormInnerProps
) => {
  return (
    <>
      <Helmet>
        <title>Skippy Scout: Personal Information</title>
      </Helmet>
      <div className="PageWrapper">
        <div className="PaddingContainer">
          {props.generalError && (
            <div className="ErrorContainer">
              {props.generalError && (
                <p className="ErrorMessageCenter ErrorMessageResponsive">
                  {props.generalError}
                </p>
              )}
              {props.showBackToQR && (
                <div
                  className="Btn BtnFullWidth"
                  onClick={() => {
                    props.setCurrentRegistrationStep &&
                      props.setCurrentRegistrationStep("qr");
                    props.setGeneralError && props.setGeneralError("");
                  }}
                >
                  Re-enter QR code
                </div>
              )}
            </div>
          )}
          <h1>Personal Information</h1>
          <div className="PersonalInfoForm">
            <div className="FormRow">
              <div className="FormBox MultiInput">
                <ChunkyInput
                  name="title"
                  id="title"
                  placeholder="Title"
                  optional={true}
                  maxLength={10}
                />
                <ErrorMessage name="title">
                  {message => (
                    <span className="ErrorMessageLeft">{message}</span>
                  )}
                </ErrorMessage>
              </div>

              <div className="FormBox MultiInput">
                <ChunkyInput
                  type="first_name"
                  name="first_name"
                  id="first_name"
                  placeholder="First Name"
                  maxLength={50}
                />
                <ErrorMessage name="first_name">
                  {message => (
                    <span className="ErrorMessageLeft">{message}</span>
                  )}
                </ErrorMessage>
              </div>

              <div className="FormBox MultiInput">
                <ChunkyInput
                  type="last_name"
                  name="last_name"
                  id="last_name"
                  placeholder="Last name"
                  maxLength={50}
                />
                <ErrorMessage name="last_name">
                  {message => (
                    <span className="ErrorMessageLeft">{message}</span>
                  )}
                </ErrorMessage>
              </div>
            </div>
            <div className="FormBox">
              <PhoneNumberField
                name={"telephone"}
                label={"Phone number"}
                optional
              />
              <ErrorMessage name="telephone">
                {message => <span className="ErrorMessageLeft">{message}</span>}
              </ErrorMessage>
              {props.errorType === "Telephone" && (
                <div className="ErrorMessageLeft">{props.inputError}</div>
              )}
            </div>

            <div className="FormBox">
              <PhoneNumberField name={"mobile"} label={"Mobile number"} />
              <ErrorMessage name="mobile">
                {message => <span className="ErrorMessageLeft">{message}</span>}
              </ErrorMessage>
              {props.errorType === "Mobile" && (
                <div className="ErrorMessageLeft">{props.inputError}</div>
              )}
            </div>

            <div className="FormBox">
              <ChunkyInput
                type="email_address"
                name="email_address"
                id="email_address"
                placeholder="Email address"
                maxLength={75}
              />
              <ErrorMessage name="email_address">
                {message => <span className="ErrorMessageLeft">{message}</span>}
              </ErrorMessage>
              {props.errorType === "EmailAddress" && (
                <div className="ErrorMessageLeft">{props.inputError}</div>
              )}
            </div>
            <>
              <div className="FormBox">
                <ChunkyInput
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  maxLength={20}
                />
                <ErrorMessage name="password">
                  {message => (
                    <span className="ErrorMessageLeft">{message}</span>
                  )}
                </ErrorMessage>
                <div className="FormHint">At least 8 characters long</div>
                {props.errorType === "Password" && (
                  <div className="ErrorMessageLeft">{props.inputError}</div>
                )}
              </div>

              <div className="FormBox">
                <div className="RememberForgot">
                  <div className="Checkbox">
                    <Field
                      name="bTermsConditions"
                      type="checkbox"
                      id="bTermsConditions"
                      checked={props.values.bTermsConditions}
                    />
                    <label className="CustomInput" htmlFor="bTermsConditions">
                      I agree with the &nbsp;
                      <a
                        href="https://www.skippy.farm/terms-conditions-privacy-and-cookies/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        terms and conditions
                      </a>
                    </label>
                  </div>
                  <ErrorMessage name="bTermsConditions">
                    {message => (
                      <span className="ErrorMessageLeft">{message}</span>
                    )}
                  </ErrorMessage>
                </div>
              </div>
            </>

            <div className="FormBottom">
              <button
                className={`Btn BtnFullWidth ${
                  props.password ? "" : "BtnMargin"
                }`}
                type="submit"
                // disabled={props.isSubmitting || !props.isValid}
                disabled={props.isSubmitting}
              >
                {props.isSubmitting ? (
                  <Spinner
                    name="three-bounce"
                    className="BtnSpinner"
                    color="white"
                    fadeIn="none"
                  />
                ) : (
                  "Register"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { PersonalInformationFormAlreadyAccountRegistrationInner };
