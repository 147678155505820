import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../reducers/rootReducer";
import { Redirect } from "@reach/router";
import Helmet from "react-helmet";
import {
  Dropzone,
  DropzoneProps,
} from "components/FormComponents/Dropzone/Dropzone";
import { uploadFarmFile } from "./uploadField";
import { isSuccessResponse } from "api";
import Spinner from "react-spinkit";
import { useFetch } from "helpers/loading";
import { endpoints } from "serverDetails";
import { useHandleMultipleFileUploads } from "./useHandleMultipleFileUploads";
import { MultipleDropzone } from "components/FormComponents/Dropzone/MultiDropzone";

const errorResults = "/content/images/Icon_Error.png";

export interface UploadFieldsProps {}

const initialFormErrors = {
  farm_id: "",
  file: "",
};

const parseMessageCode = (code: string) => {
  switch (code.toLowerCase()) {
    case "invalid_farm_id":
      return "Please provide a valid farm";
    case "invalid_file":
      return "Please provide a field file";
    default:
      return "Sorry, something went wrong. Please try again later.";
  }
};

const UploadFields = (props: UploadFieldsProps) => {
  const dispatch = useDispatch();
  const subscriptionStatus = useSelector(
    (state: AppState) => state.auth.subscriptionStatus
  );
  const [loading, setLoading] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState<{
    [k: string]: string;
  }>(initialFormErrors);
  const [farmId, setFarmId] = React.useState<number>(0);
  const [farmName, setFarmName] = React.useState("");
  const handleSetFarm = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFarmId(parseInt(e.currentTarget.value, 10));
  };
  const [
    { uploadedFiles, uploadErrors },
    { handleDrop, clearFile },
  ] = useHandleMultipleFileUploads();

  const handleFileUpload: DropzoneProps["onDrop"] = (...params) => {
    setFormErrors(p => ({ ...p, file: "" }));
    handleDrop(...params);
  };

  const handleSubmit = async () => {
    if ((uploadedFiles && Array.isArray(uploadedFiles) && farmId) || farmName) {
      setLoading(true);
      const result = await uploadFarmFile(farmId, farmName, uploadedFiles);
      if (isSuccessResponse(result)) {
        // success
        dispatch({
          type: "SHOW_TOAST",
          message: "Successfully uploaded farm fields",
        });
        setFarmId(0);
        clearFile();
      } else {
        // handle error
        const error = result.errors[0];
        if (error) {
          setFormErrors(p => ({
            ...p,
            [error.field_name]: parseMessageCode(error.message_code),
          }));
        } else {
          dispatch({
            type: "SHOW_TOAST",
            message: "Sorry, something went wrong. Please try again later",
          });
        }
      }
      setLoading(false);
    } else {
      if (
        uploadedFiles &&
        Array.isArray(uploadedFiles) &&
        uploadedFiles.length === 0
      ) {
        setFormErrors(p => ({ ...p, file: "Please provide a field file" }));
      }
      if (!farmId) {
        setFormErrors(p => ({ ...p, farm_id: "Please provide a farm name" }));
      }
    }
  };

  const { loading: pending, error, data } = useFetch(
    endpoints.fieldUploads.uploadFieldDetails
  );

  const dropzoneOptions: DropzoneProps["dropzoneOptions"] = React.useMemo(
    () => ({}),
    []
  );

  const handleToast = (message: string) => {
    dispatch({ type: "SHOW_TOAST", message: message });
    window.setTimeout(() => {
      dispatch({ type: "HIDE_TOAST" });
    }, 3000);
  };

  React.useEffect(() => {
    if (subscriptionStatus !== "Valid" && subscriptionStatus !== "Initial") {
      handleToast(
        "Please renew your subscription to access the 'Upload fields' section"
      );
    }
  }, [subscriptionStatus]);

  if (subscriptionStatus !== "Valid" && subscriptionStatus !== "Initial") {
    return <Redirect to="/" noThrow={true} />;
  }

  if (pending) {
    return (
      <span>
        <Spinner
          name="three-bounce"
          className="NotificationsSpin"
          fadeIn="none"
        />
      </span>
    );
  }

  if (error) {
    return (
      <div className="ErrorResults">
        <div className="NoResults">
          <img src={errorResults} alt={"Error"} />
          <p>Error</p>
        </div>
      </div>
    );
  }

  if (data) {
    let farmList =
      data.details &&
      data.details.farms &&
      data.details.farms.map((option: any) => ({
        label: option.farm_name,
        key: option.farm_id,
      }));

    return (
      <>
        <Helmet>
          <title>Skippy Scout: Upload fields</title>
        </Helmet>
        <div className="PageWrapper">
          <div className="UploadFields">
            <h1>Upload fields</h1>
            <div className="FormBox">
              <div
                className={
                  formErrors["farm_id"] ? "FormLabel Error" : "FormLabel"
                }
              >
                <label htmlFor="farm_id">Farm Name</label>
                <Required />
              </div>
              <div
                className={
                  formErrors["farm_id"] ? "FormField Error" : "FormField"
                }
              >
                {farmList && farmList.length > 0 ? (
                  <>
                    {!farmId && (
                      <div className="FormBox">
                        <div className="FormSideBySide">
                          <div className="FormField">
                            <input
                              placeholder="Enter a new farm name"
                              className={`EditBox`}
                              name="farmName"
                              type="text"
                              id={"uploadFieldsFarmName"}
                              value={farmName}
                              onChange={e => {
                                setFarmName(e.target.value);
                              }}
                              maxLength={100}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {!farmName && (
                      <select className="DropDown" onChange={handleSetFarm}>
                        <option defaultValue="">Select an existing farm</option>
                        {farmList.map((opt: any, i: number) => (
                          <option key={opt.key} value={opt.key}>
                            {opt.label}
                          </option>
                        ))}
                      </select>
                    )}
                  </>
                ) : (
                  <div className="FormBox">
                    <div className="FormSideBySide">
                      <div className="FormField">
                        <input
                          placeholder="Enter a new farm name"
                          className={`EditBox`}
                          name="farmName"
                          type="text"
                          id={"uploadFieldsFarmName"}
                          value={farmName}
                          onChange={e => {
                            setFarmName(e.target.value);
                          }}
                          maxLength={100}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {formErrors["farm_id"] && <span>{formErrors["farm_id"]}</span>}
              </div>
            </div>
            <div className="FormBox">
              <div
                className={formErrors["file"] ? "FormLabel Error" : "FormLabel"}
              >
                <label htmlFor="farm_file" className="InputContainerLabels">
                  Field File
                </label>
                <Required />
              </div>
              <div className="FormField">
                <MultipleDropzone
                  onDrop={handleFileUpload}
                  name="farm_file"
                  uploadedFiles={uploadedFiles}
                  warningMessage={
                    uploadErrors && uploadErrors.length > 0
                      ? uploadErrors[0]
                      : null
                  }
                  dropzoneOptions={dropzoneOptions}
                />
                {formErrors["file"] && <span>{formErrors["file"]}</span>}

                <ClearButton
                  hide={
                    uploadedFiles &&
                    Array.isArray(uploadedFiles) &&
                    uploadedFiles.length === 0
                  }
                  onClick={clearFile}
                />
              </div>
            </div>
            <button
              className="Btn"
              onClick={handleSubmit}
              disabled={
                loading ||
                (uploadedFiles &&
                  Array.isArray(uploadedFiles) &&
                  uploadedFiles.length === 0) ||
                (!farmId && !farmName)
              }
            >
              {loading ? (
                <Spinner name="three-bounce" fadeIn="none" color="white" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </>
    );
  }

  return null;
};

export default UploadFields;

export type ClearButtonProps = {
  hide?: boolean;
} & JSX.IntrinsicElements["button"];

const ClearButton: React.FC<ClearButtonProps> = ({
  hide,
  className: additionalClassNames,
  ...buttonProps
}) => {
  if (hide) {
    return null;
  }
  const defaultClassName = "Btn Reset";
  const className = additionalClassNames
    ? `${defaultClassName} ${additionalClassNames}`
    : defaultClassName;

  return (
    <button className={className} {...buttonProps}>
      Clear
    </button>
  );
};

const Required: React.FC<{ hide?: boolean }> = ({ hide }) =>
  hide ? null : <span className="Required">*</span>;
