import React from "react";
import PaymentCardAddForm from "../Forms/PaymentCardAddForm/PaymentCardAddForm";

export interface PaymentCardUpdate {
  class?: string;
}

const PaymentCardUpdate = (props: PaymentCardUpdate) => {
  return (
    <div className={`PaymentCardUpdate ${props.class}`}>
      <PaymentCardAddForm
        submit={(values) => console.log(values)}
      />
    </div>
  ) 
};

export default PaymentCardUpdate;
