import React from "react";
//import { useSelector } from "react-redux";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Credentials, AuthStateStatus } from "../../../types/AuthTypes";
import { ErrorResponse } from "../../../types/ApiResponseTypes";
import ValidationError from "../../FormComponents/ValidationError/ValidationError";
import classNames from "classnames";

import { selectAuthErrors } from "reducers/auth"
import { Link } from "@reach/router";

import ChunkyInput from "../ChunkyInput/ChunkyInput";
import { useSelector } from "react-redux";

import Spinner from "react-spinkit";

interface SignInFormInnerProps {
  isValid: boolean;
  isSubmitting: boolean;
  setSubmitting: (val: boolean) => void
  error: boolean;
  status: AuthStateStatus;
  errors: ErrorResponse[];
  values: any
}

interface SignInFormProps {
  submit: (values: Credentials) => void
  status: AuthStateStatus;
  error: boolean;
  errors: ErrorResponse[];
}

const validationSchema = Yup.object({
  user_name: Yup.string().required("Username is required"),
  //password: Yup.string().matches(passwordRegex, "Password must be 8-20 characters and contain 1 uppercase character, 1 lowercase character, 1 number, 1 letter and 1 special character").required("Password is required"),
  password: Yup.string().required("Password is required"),
});

const parseError = (submitError: string) => {
  switch (submitError) {
    case "Email_UnConfirmed":
      return "You need to confirm your email address."
    case "Temporary_Locked":
      return "Your account is temporarily locked. Please wait 10 minutes or use the forgotten password page."
    case "Client_Suppressed":
      return "This account is suppressed, please contact Skippy Scout Admin"
    case "Suppressed":
      return "Your account is suppressed. Please contact Skippy Scout Admin"
    case "Invalid":
      return "We couldn't sign you in with those details. Please check they are correct and try again."
    default:
      return "Something went wrong, please try again."
  }
}

const SignInFormInner = (props: SignInFormInnerProps) => {
  const errorObject = useSelector(selectAuthErrors)
  const message = errorObject.length > 0 ? parseError(errorObject[0].messageCode) : parseError("")

  const { setSubmitting, isSubmitting } = props

  React.useEffect(() => {
    if (message) {
      setSubmitting(false)
    }

  }, [message, isSubmitting, setSubmitting])

  return (
    <Form>
      <div className="FormBox FormBoxWithIcon">
        <ChunkyInput
          name="user_name"
          id="user_name"
          placeholder="Email Address"
          icon="/content/images/Icon_Field_01.png"
          autoComplete="username"
          maxLength={200}
        />
        <ValidationError name="user_name" errors={props.errors} />
      </div>

      <div className="FormBox FormBoxWithIcon">
        <ChunkyInput
          type="password"
          name="password"
          placeholder="Password"
          id="password"
          icon="/content/images/Icon_Field_02.png"
          autoComplete="password"
          maxLength={20}
        />
        <ValidationError name="password" errors={props.errors} />
      </div>

      <div className="RememberForgot">
        <div className="Checkbox">
          <Field
            name="bRememberMe"
            type="checkbox"
            id="bRememberMe"
          />
          <label className="CustomInput" htmlFor="bRememberMe">
            Remember me
          </label>
        </div>
        <Link to="/forgotpassword" className="Link">
          Forgotten your Password?
        </Link> 
      </div>

      {props.error && <p className="Error">{message}</p>}

      <div className="FormBottom">
        <div className="FormBox">
          <button
            className={classNames("Btn BtnFullWidth", {
              Submitting: props.status === "authenticating",
            })}
            type="submit"
            disabled={props.isSubmitting}
          >
            {props.isSubmitting ? <Spinner name="three-bounce" className="BtnSpinner" color="white" fadeIn="none" /> : 'Log in'}
          </button>
        </div>

        <div className="RegisterTagline">
          {"No account? "}
          <Link to="/register" className="LinkGreen">
            Register
          </Link> 
        </div>
      </div>

    </Form>
  );
};

const signInForm = (props: SignInFormProps) => {
  return (
    <Formik
      initialValues={{ user_name: "", password: "", bRememberMe: false }}
      validationSchema={validationSchema}
      //onSubmit={values => {
      //  props.submit(values);
      //}}
      onSubmit = {(values) => {
        props.submit(values)
        //props.submit(values);
      }}
      render={({ isValid, isSubmitting, values, setSubmitting }) => (
        <SignInFormInner
          values={values}
          status={props.status}
          errors={props.errors}
          isValid={isValid}
          isSubmitting={isSubmitting}
          setSubmitting={setSubmitting}
          error={props.error}
        />
      )}
    />
  );
};

export default signInForm;
