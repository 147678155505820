export const isntNull = (x: string | null) => {
  if (x !== null) {
    return true;
  } else {
    return false;
  }
};

export const trendsIsNull = (x: string | null) => {
  if (x && typeof x === "string") {
    return x !== null ? x : "+0";
  } else {
    return "+0";
  }
};

export const isPositive = (x: string | null) => {
  if (x && typeof x === "string") {
    return x !== null && !x.includes("-") ? "+" : "";
  } else {
    return "";
  }
};

export const insectDamageDetected = (x: string | null) => {
  if (x && (x.includes("Not") || x.includes("not"))) {
    return false;
  } else {
    return true;
  }
};

export const insectDamageImproved = (x: string | null) => {
  if (x && (x.includes("Better") || x.includes("better"))) {
    return true;
  } else {
    return false;
  }
};

export const hasImproved = (x: string | null, inverse: boolean) => {
  let result = null;
  if (x && typeof x === "string") {
    if (x !== null && x !== undefined) {
      if (!inverse) {
        !x.includes("-") ? (result = true) : (result = false);
      } else {
        x.includes("-") ? (result = true) : (result = false);
      }
    } else {
      result = true;
    }
  } else {
    result = true;
  }
  return result;
};
