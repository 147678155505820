import React, { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

type ImprovedTextEditorProps = {
  text: string;
  convertTextFromHTML: any;
  additionalOnChangeHandler: (args: any) => void;
  errors: string[];
  className: string;
  type: string;
  maxLength?: number;
};

const ImprovedTextEditor = ({
  text,
  convertTextFromHTML,
  additionalOnChangeHandler,
  errors,
  className,
  type,
  maxLength,
}: ImprovedTextEditorProps) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const prevText = useRef("");

  const getErrors = (errors: string[]) =>
    errors.map((error: string) => (
      <span className="ValidationInfo" key={error}>
        {error}
      </span>
    ));

  const convertTextToDraft = (text: string) => {
    return EditorState.createWithContent(ContentState.createFromText(text));
  };

  const convertHTMLToDraft = (html: string) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    return EditorState.createWithContent(contentState);
  };

  const initialiseEditor = () => {
    const blankEditorState = EditorState.createEmpty();
    setEditorState(blankEditorState);
  };

  const onChange = (editorState: any) => {
    setEditorState(editorState);
    if (additionalOnChangeHandler) {
      additionalOnChangeHandler(editorState);
    }
  };

  const handleBeforeInput = (input: string) => {
    if (maxLength) {
      if (
        draftToHtml(
          convertToRaw(editorState.getCurrentContent())
        ).replace(/<br>(?=(?:\s*<[^>]*>)*$)|(<br>)|<[^>]*>/gi, (x, y) =>
          y ? " & " : ""
        ).length >=
        maxLength + 1
      ) {
        return "handled";
      }
    }
  };

  const handlePastedText = () => {
    if (maxLength) {
      if (
        draftToHtml(convertToRaw(editorState.getCurrentContent())).length >=
        maxLength + 1
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  useEffect(() => {
    if (convertTextFromHTML) {
      onChange(convertHTMLToDraft(text));
    } else {
      onChange(convertTextToDraft(text));
    }
  }, []);

  useEffect(() => {
    if (text !== prevText.current && text === "") {
      prevText.current = text;
      const newEditorState = EditorState.push(
        editorState,
        ContentState.createFromText(""),
        "insert-fragment"
      );
      setEditorState(newEditorState);
    }
  }, [text]);

  const isError = errors && errors.length !== 0;
  const notesToolbarOptions = ["inline", "list"];
  const defaultToolbarOptions = [
    "inline",
    "blockType",
    "fontSize",
    "fontFamily",
    "link",
    "list",
    "textAlign",
    "history",
  ];

  return (
    <div
      className={`${className ? className : ""} ${
        type === "NOTES" ? "Notes" : ""
      } TextEditor`}
    >
      <div className={`${isError ? "HighlightedValidation" : ""}`}>
        <Editor
          editorState={editorState}
          editorStyle={{ fontSize: "12px" }}
          wrapperClassName="TextEditor__Outer"
          editorClassName="TextEditor__Inner"
          toolbarClassName="TextEditor__Toolbar"
          onEditorStateChange={onChange}
          toolbar={{
            options:
              type === "NOTES" ? notesToolbarOptions : defaultToolbarOptions,
          }}
          handleBeforeInput={handleBeforeInput}
          handlePastedText={handlePastedText}
        />
        {maxLength ? <p>Max length is: {maxLength} characters</p> : ""}
        {isError && getErrors(errors)}
      </div>
    </div>
  );
};

export default ImprovedTextEditor;
