import * as React from "react";
import Helmet from "react-helmet";
import TopLogo from "./assets/icons/skippy-icon-circle.png";
import LeafLogoGreen from "./assets/icons/SVG/Asset-11.svg";
import AltLeafLogoGreen from "./assets/icons/SVG/Asset-13.svg";
import CountRed from "./assets/icons/SVG/Asset-1.svg";
import CountGreen from "./assets/icons/SVG/Asset-14.svg";
import InsectLogoGreen from "./assets/icons/SVG/Asset-16.svg";
import LeafLogoRed from "./assets/icons/SVG/Asset-18.svg";
import AltLeafLogoRed from "./assets/icons/SVG/Asset-20.svg";
import SatelliteLogoGreen from "./assets/icons/SVG/Asset-22.svg";
import GridLogoGreen from "./assets/icons/SVG/Asset-24.svg";
import LeavesLogoGreen from "./assets/icons/SVG/Asset-26.svg";
import InsectLogoRed from "./assets/icons/SVG/Asset-3.svg";
import SatelliteLogoRed from "./assets/icons/SVG/Asset-5.svg";
import GridLogoRed from "./assets/icons/SVG/Asset-7.svg";
import LeavesLogoRed from "./assets/icons/SVG/Asset-9.svg";
import FlowerRatioRed from "./assets/icons/SVG/Asset-28.svg";
import FlowerRatioGreen from "./assets/icons/SVG/Asset-27.svg";
import NitrogenRec from "./assets/icons/SVG/nitrogenRec.svg";
import OSRGrowthStage from "./assets/icons/SVG/OSRGrowthStage.svg";
import RipeningRatio from "./assets/icons/1x/RipeningRatio.png";
import PodDevelopmentRatio from "./assets/icons/1x/PodDevelopmentRatio.png";
import SenescenceRatio from "./assets/icons/1x/SenescenceRatio.png";
import { DataSection } from "./DataSection";
import {
  hasImproved,
  insectDamageDetected,
  insectDamageImproved,
  isntNull,
  isPositive,
} from "./helpers";
import { ImagesSection } from "./ImagesSection";
import { Item, ScoutPointPageProps } from "./models";

const ScoutPointPage: React.FC<ScoutPointPageProps> = ({
  data,
  fieldName,
  farmData,
  isKrpano,
  isPdf,
  isCardReport,
  scoutPointPositionX,
  scoutPointPositionY,
  finalScoutPoint,
  loadStatus,
  setLoadStatus,
}) => {
  const eitherOrDataPoints = (
    dataOne: string | null,
    dataTwo: string | null
  ) => {
    if (dataOne) {
      return isntNull(dataOne) ? dataOne : null;
    }
    if (dataTwo) {
      return isntNull(dataTwo) ? dataTwo : null;
    }
    return isntNull(dataOne) ? dataOne : null;
  };

  const statsData: Item[] = [
    {
      icon: LeavesLogoGreen,
      value: isntNull(data.cropCover) ? data.cropCover : null,
      description: "Crop Cover",
    },
    {
      icon: CountGreen,
      doubleValue:
        data.plantCountSquared && data.plantCount
          ? { first: data.plantCountSquared, second: data.plantCount }
          : null,
      doubleDescription: {
        first: `Plants per ${farmData.bUseAcres ? "acre" : "sqm"}`,
        second: "Total plants",
      },
    },
    {
      icon: GridLogoGreen,
      value: isntNull(data.gai) ? data.gai : null,
      description: "Crop GAI",
    },
    {
      icon: SatelliteLogoGreen,
      value: isntNull(data.ndvi) ? data.ndvi : null,
      description: "Field NDVI",
    },
    {
      icon: AltLeafLogoRed,
      value: isntNull(data.weedCover) ? data.weedCover : null,
      description: "Weeds",
    },
    {
      icon: LeafLogoRed,
      value: isntNull(data.unhealthyCrop) ? data.unhealthyCrop : null,
      description: "Unhealthy Crop",
    },
    {
      icon: LeafLogoRed,
      value: isntNull(data.yellowLeaves) ? data.yellowLeaves : null,
      description: "Yellow Leaves",
    },
    {
      icon: LeafLogoRed,
      value: isntNull(data.purpleLeaves) ? data.purpleLeaves : null,
      description: "Purple Leaves",
    },
    {
      icon: FlowerRatioGreen,
      value: isntNull(data.floweringRatio) ? data.floweringRatio : null,
      description: "Flowering Ratio",
    },
    {
      icon: insectDamageDetected(data.insectDamage)
        ? InsectLogoRed
        : InsectLogoGreen,
      value: isntNull(data.insectDamage) ? data.insectDamage : null,
      description: "Insect Damage",
    },
    {
      icon: NitrogenRec,
      value: isntNull(data.nitrogenRec) ? data.nitrogenRec : null,
      description: "Required N (EST)",
    },
    {
      icon: OSRGrowthStage,
      value: isntNull(data.averageGrowthStageAAB)
        ? `GS${data.averageGrowthStageAAB}`
        : null,
      description: "AAB",
    },
    {
      icon: OSRGrowthStage,
      value: isntNull(data.averageGrowthStageBBCH)
        ? `GS${data.averageGrowthStageBBCH}`
        : null,
      description: "BBCH",
    },
    {
      icon: RipeningRatio,
      value: isntNull(data.ripening_ratio) ? data.ripening_ratio : null,
      description: "Ripening Ratio",
    },
    {
      icon: PodDevelopmentRatio,
      value: isntNull(data.pod_development_ratio)
        ? data.pod_development_ratio
        : null,
      description: "Pod Development Ratio",
    },
    {
      icon: SenescenceRatio,
      value: isntNull(data.senescence_ratio) ? data.senescence_ratio : null,
      description: "Senescence Ratio",
    },
  ];

  const trendsData: Item[] = [
    {
      icon: hasImproved(data.trendCropCover, false)
        ? LeavesLogoGreen
        : LeavesLogoRed,
      value: isntNull(data.trendCropCover)
        ? data.trendCropCover
        : isntNull(data.cropCover)
        ? "N/A"
        : null,
      description: "Crop Cover since last scout",
    },
    {
      icon: hasImproved(data.trendGai, false) ? GridLogoGreen : GridLogoRed,
      value: isntNull(data.trendGai)
        ? data.trendGai
        : isntNull(data.gai)
        ? "N/A"
        : null,
      description: "Crop GAI since last scout",
    },
    {
      icon: hasImproved(data.trendNdvi, false)
        ? SatelliteLogoGreen
        : SatelliteLogoRed,
      value: isntNull(data.trendNdvi)
        ? isPositive(data.trendNdvi) + data.trendNdvi
        : isntNull(data.ndvi)
        ? "N/A"
        : null,
      description: "Field NDVI since last scout",
    },
    {
      icon: hasImproved(data.trendWeedCover, true)
        ? AltLeafLogoGreen
        : AltLeafLogoRed,
      value: isntNull(data.trendWeedCover)
        ? data.trendWeedCover
        : isntNull(data.weedCover)
        ? "N/A"
        : null,
      description: "Weeds since last scout",
    },
    {
      icon: hasImproved(data.trendUnhealthyCrop, true)
        ? LeafLogoGreen
        : LeafLogoRed,
      value: isntNull(data.trendUnhealthyCrop)
        ? data.trendUnhealthyCrop
        : isntNull(data.unhealthyCrop)
        ? "N/A"
        : null,
      description: "Unhealthy Crop since last scout",
    },
    {
      icon: hasImproved(data.trendYellowLeaves, true)
        ? LeafLogoGreen
        : LeafLogoRed,
      value: isntNull(data.trendYellowLeaves)
        ? data.trendYellowLeaves
        : isntNull(data.yellowLeaves)
        ? "N/A"
        : null,
      description: "Yellow Leaves since last scout",
    },
    {
      icon: hasImproved(data.trendPurpleLeaves, true)
        ? LeafLogoGreen
        : LeafLogoRed,
      value: isntNull(data.trendPurpleLeaves)
        ? data.trendPurpleLeaves
        : isntNull(data.purpleLeaves)
        ? "N/A"
        : null,
      description: "Purple Leaves since last scout",
    },
    {
      icon: hasImproved(data.trendFloweringRatio, false)
        ? FlowerRatioGreen
        : FlowerRatioRed,
      value: isntNull(data.trendFloweringRatio)
        ? data.trendFloweringRatio
        : isntNull(data.floweringRatio)
        ? "N/A"
        : null,
      description: "Flowering Ratio since last scout",
    },
    {
      icon: insectDamageImproved(data.trendInsectDamage)
        ? InsectLogoGreen
        : InsectLogoRed,
      value: isntNull(data.trendInsectDamage)
        ? data.trendInsectDamage
        : isntNull(data.insectDamage)
        ? "N/A"
        : null,
      description: "Insect Damage since last scout",
    },
    {
      icon: hasImproved(data.trendAABChange, true)
        ? OSRGrowthStage
        : OSRGrowthStage,
      value: isntNull(data.trendAABChange)
        ? data.trendAABChange
        : isntNull(data.averageGrowthStageAAB)
        ? "N/A"
        : null,
      description: "AAB Growth Stage change since last scout",
    },
    {
      icon: hasImproved(data.trendBBCHChange, true)
        ? OSRGrowthStage
        : OSRGrowthStage,
      value: isntNull(data.trendBBCHChange)
        ? data.trendBBCHChange
        : isntNull(data.averageGrowthStageBBCH)
        ? "N/A"
        : null,
      description: "BBCH Growth Stage change since last scout",
    },

    {
      icon: hasImproved(data.trendPodDevelopmentRatio, true)
        ? PodDevelopmentRatio
        : PodDevelopmentRatio,
      value: isntNull(data.trendPodDevelopmentRatio)
        ? data.trendPodDevelopmentRatio
        : isntNull(data.pod_development_ratio)
        ? "N/A"
        : null,
      description: "Pod Development Ratio since last scout",
    },
    {
      icon: hasImproved(data.trendRipeningRatio, true)
        ? RipeningRatio
        : RipeningRatio,
      value: isntNull(data.trendRipeningRatio)
        ? data.trendRipeningRatio
        : isntNull(data.ripening_ratio)
        ? "N/A"
        : null,
      description: "Ripening Ratio since last scout",
    },
    {
      icon: hasImproved(data.trendSenescenceRatio, true)
        ? SenescenceRatio
        : SenescenceRatio,
      value: isntNull(data.trendSenescenceRatio)
        ? data.trendSenescenceRatio
        : isntNull(data.senescence_ratio)
        ? "N/A"
        : null,
      description: "Senescence Ratio since last scout",
    },
  ];
  let heatmapInfo = null;
  if (farmData.heatmapInfo) {
    heatmapInfo = {
      id: data.scoutPointId,
      min: farmData.heatmapInfo.main_gradient_min,
      max: farmData.heatmapInfo.main_gradient_max,
      type: farmData.heatmapInfo.main_heatmap_type,
    };
  }

  const noAIImageData: Item[] = [
    {
      icon: LeavesLogoGreen,
      value: isntNull(farmData.averageCropCover)
        ? farmData.averageCropCover
        : null,
      description: "Crop Cover",
    },
    {
      icon: CountGreen,
      value: isntNull(farmData.averagePlantCount)
        ? farmData.averagePlantCount
        : null,
      description: `Plants / ${farmData.bUseAcres ? "acre" : "sqm"}`,
    },
    {
      icon: GridLogoGreen,
      value: isntNull(farmData.averageGai) ? farmData.averageGai : null,
      description: "Crop GAI",
    },
    {
      icon: SatelliteLogoGreen,
      value: isntNull(farmData.averageNdvi) ? farmData.averageNdvi : null,
      description: `Field NDVI`,
    },
    {
      icon: AltLeafLogoRed,
      value: isntNull(farmData.averageWeedCover)
        ? farmData.averageWeedCover
        : null,
      description: "Weeds",
    },
    {
      icon: LeafLogoRed,
      value: isntNull(farmData.averageUnhealthyCrop)
        ? farmData.averageUnhealthyCrop
        : null,
      description: "Unhealthy Crop",
    },
    {
      icon: LeafLogoRed,
      value: isntNull(farmData.averageYellowLeaves)
        ? farmData.averageYellowLeaves
        : null,
      description: "Yellow Leaves",
    },
    {
      icon: LeafLogoRed,
      value: isntNull(farmData.averagePurpleLeaves)
        ? farmData.averagePurpleLeaves
        : null,
      description: "Purple Leaves",
    },
    {
      icon: FlowerRatioGreen,
      value: isntNull(farmData.averageFloweringRatio)
        ? farmData.averageFloweringRatio
        : null,
      description: "Flowering Ratio",
    },
    {
      icon: insectDamageDetected(farmData.insectDamage)
        ? InsectLogoRed
        : InsectLogoGreen,
      value: isntNull(farmData.insectDamage) ? farmData.insectDamage : null,
      description: "Insect Damage",
    },
  ];

  const editNoAIData = noAIImageData
    .filter(item => item.value !== null)
    .map(item => {
      if (item.description !== "Field NDVI") {
        return { ...item, value: "--" };
      } else return item;
    });
  return (
    <>
      <Helmet>
        <title>Skippy Scout: Field Reports</title>
      </Helmet>
      <div
        className={`FieldReportsContainer ${
          isKrpano || isCardReport ? "KrpanoReport" : ""
        }
        ${isPdf ? "PdfReport" : ""}
        `}
        style={{ pageBreakBefore: "always", breakBefore: "always" }}
      >
        <img src={TopLogo} alt="logo" className="TopLogo"></img>
        {farmData.client_logo && (
          <img
            src={farmData.client_logo}
            alt="logo"
            className="TopLogo AgriiLogo"
          ></img>
        )}

        <div className="PageContentBox">
          <div className="PageTop">
            <div className="PDFHeaderContainer">
              <h2>
                Scout Point {data.scoutPointLabel} <span>//</span> ID{" "}
                {data.scoutPointId}
              </h2>
              <h3>
                {fieldName} <span>//</span> {farmData.cropName} <span>//</span>{" "}
                {data.scoutDate ? data.scoutDate : null}
                {data.scoutDate ? <span> // </span> : null}
                {farmData.nWeeksSincePlant
                  ? `Week: ${farmData.nWeeksSincePlant}`
                  : null}
              </h3>
            </div>
            <div className="DataSectionsContainer"></div>
            {}
            <DataSection
              title={"Scout Point Stats"}
              items={data.bNoAgrohixAIData ? editNoAIData : statsData}
              stats={true}
            />
            {data.bShowTrends ? (
              <DataSection
                title={"Trend Stats"}
                items={trendsData}
                stats={true}
              />
            ) : null}
          </div>

          <ImagesSection
            aiImageUrl={data.aiImageUrl}
            mapImageUrl={data.scoutPointMapImageUrl}
            renderedImage={data.base_mapbox_image}
            scoutPointImageUrl={data.scoutPointImageUrl}
            iconImage={data.icon_image}
            scoutPointPositionX={scoutPointPositionX}
            scoutPointPositionY={scoutPointPositionY}
            scoutPointNumber={data.scoutPointLabel}
            finalScoutPoint={finalScoutPoint}
            loadStatus={loadStatus}
            setLoadStatus={setLoadStatus}
          />
        </div>
      </div>
    </>
  );
};

export default ScoutPointPage;
