import { Maybe, Just, Nothing } from "purify-ts/Maybe";
import { find, findIndex } from "ramda";

export const rightVal = <T, S>(_left: T, right: S): T | S => right;

export const rightmostVal = <T, S, R>(
  _left: T,
  _middle: S,
  right: R
): T | S | R => right;

export const leftVal = <T, S>(left: T, _right: S): T | S => left;

export const findReplace = <T>(array: T[], item: T, newItem: T): T[] => {
  let newArray = [...array];
  const itemIndex = newArray.indexOf(item);
  newArray[itemIndex] = newItem;
  return newArray;
};

export const leftmostVal = <T, S, R>(
  left: T,
  _middle: S,
  _right: R
): T | S | R => left;

export const wrapInMaybe = <T extends any[], S>(
  func: (...args: T) => S | undefined | null
) => (...args: T): Maybe<S> => Maybe.fromNullable(func(...args));

export const optionFind = wrapInMaybe(find);

const defaultToNull = <T extends any[], S>(defaultVal: S) => (
  func: (...args: T) => S
) => (...args: T) => (func(...args) == defaultVal ? null : func(...args));

const nullFindIndex: <T>(
  fn: (a: T) => boolean,
  list: T[]
) => number | null = defaultToNull(-1)(findIndex);
export const maybeFindIndex = wrapInMaybe(nullFindIndex);

export const maybeParseInt = (s: string, radix?: number) =>
  Maybe.fromFalsy(parseInt(s, radix));
export const maybeParseFloat = (s: string) => Maybe.fromFalsy(parseFloat(s));
