import React, { useState } from "react";
import ResetPasswordForm from "components/SignInComponents/ResetPasswordForm/ResetPasswordForm";
import ForgotPasswordPage from "pages/ForgotPasswordPage/ForgotPasswordPage";
import Page from "components/PageComponents/Page/PageContainer";
import Toast from "components/PageComponents/Toast/Toast";
import { NavBar } from "components/PageComponents/NavBar/NavBar";
import { Router, RouteComponentProps, Redirect } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import ChangePassword from "pages/Categories/ChangePassword/ChangePassword";
import PreLoginPageStructure from "../../components/PageComponents/PreLoginPageStructure/PreLoginPageStructure";
import Registration from "../../pages/Registration/Registration";
import PaymentCardsPage from "../../pages/PaymentCards/PaymentCardsPage";
import { RightMenu } from "../../components/PageComponents/RightMenu/rightMenu";
import { AppState } from "../../reducers/rootReducer";
import TransactionHistoryPage from "../../pages/TransactionHistory/TransactionHistoryPage";
import { RightPopOut } from "../../components/PageComponents/RightPopOut/RightPopOut";
import PaymentCardAdd from "../../components/RightPopOutComponents/PaymentCardAdd";
import PaymentCardView from "../../components/RightPopOutComponents/PaymentCardView";
import PaymentCardUpdate from "../../components/RightPopOutComponents/PaymentCardUpdate";
import TransactionHistoryView from "../../components/RightPopOutComponents/TransactionHistoryView";
import { Modal } from "../../components/PageComponents/Modal/Modal";
import SubscriptionUpgrade from "../../components/RightPopOutComponents/SubscriptionUpgrade";
import PersonalInformation from "../../pages/PersonalInformation/PersonalInformation";
import ActivateNewDevice from "../../pages/ActivateNewDevice/ActivateNewDevice";
import UploadFields from "../../pages/UploadFields/UploadFields";
//import NotificationSettings from "../../pages/NotificationSettings/NotificationSettings";
import PauseSubscription from "../../components/ModalComponents/PauseSubscription";
import AccountDelete from "../../components/ModalComponents/AccountDelete";
import CardDelete from "../../components/ModalComponents/CardDelete";
import SubscriptionPageLoggedIn from "../../pages/Subscription/SubscriptionPageLoggedIn";
import { useFetch } from "../../helpers/loading";
import { endpoints } from "../../serverDetails";
import YourQRCode from "../../pages/YourQRCode/YourQRCode";
import { loadAuthState } from "reducers/auth";
import Helmet from "react-helmet";
import Footer from "../../components/PageComponents/Footer/Footer";
import CMSGeneratedContentPage from "../../pages/CMSGeneratedContentPage/CMSGeneratedContentPage";
import SiteMap from "../../pages/Sitemap/Sitemap";
import YourUserQRCode from "pages/YourUserQRCode/YourUserQRCode";
import UsersViewDetails from "pages/UsersViewDetails/UsersViewDetails";
import UsersPage from "../../pages/Users/UsersPage";
import UsersAdd from "../../components/RightPopOutComponents/UsersAdd";
import UsersDelete from "components/ModalComponents/UsersDelete";
import UsersUpdateDetails from "../../components/RightPopOutComponents/UsersUpdateDetails";
import UsersUpdatePermissions from "../../components/RightPopOutComponents/UsersUpdatePermissions";
import UsersFilter from "components/RightPopOutComponents/UsersFilter";
import IndividualAccountDelete from "components/ModalComponents/IndividualAccountDelete";
import ThirdPartyIntegrations from "pages/ThirdPartyIntegrations/ThirdPartyIntegrations";
import IntegrationLoading from "pages/IntegrationLoading/IntegrationLoading";
import ViewPanoramas from "pages/ViewPanoramasPage/ViewPanoramas";
import {
  ViewPanoramasDetails,
  ViewPanoramasProps,
} from "pages/ViewPanoramasPage/models";
import { FieldReportsSectionPage } from "pages/FieldReportsSection/FieldReportsSectionPage";
import ApplicationMaps from "pages/ApplicationMapsPage/ApplicationMaps";
import AddReportNotes from "pages/FieldReportsSection/AddReportNotes";
import DeleteNotes from "pages/FieldReportsSection/DeleteNotes";

export interface MainRouterProps {
  isAuthenticated: boolean;
}

const CMSGeneratedContent = (props: RouteComponentProps & MainRouterProps) => {
  if (
    window.location.pathname === "/site-map" ||
    window.location.pathname === "/sitemap"
  ) {
    return props.isAuthenticated ? (
      <Page>
        <SiteMap />
      </Page>
    ) : (
      <PreLoginPageStructure
        showAsideButtons
        isAuthenticated={props.isAuthenticated}
      >
        <SiteMap />
      </PreLoginPageStructure>
    );
  }
  return props.isAuthenticated ? (
    <Page>
      <CMSGeneratedContentPage />
    </Page>
  ) : (
    <PreLoginPageStructure
      showAsideButtons
      isAuthenticated={props.isAuthenticated}
    >
      <CMSGeneratedContentPage />
    </PreLoginPageStructure>
  );
};

const ForgotPassword = (props: RouteComponentProps & MainRouterProps) => (
  <PreLoginPageStructure
    showAsideButtons
    isAuthenticated={props.isAuthenticated}
  >
    <ForgotPasswordPage />
  </PreLoginPageStructure>
);

const ResetPassword = (props: RouteComponentProps & MainRouterProps) =>
  props.isAuthenticated ? (
    <>
      <Helmet>
        <title>Skippy Scout: Reset Password</title>
      </Helmet>
      <Page>
        <ResetPasswordForm location={props.location} />
      </Page>
    </>
  ) : (
    <>
      <Helmet>
        <title>Skippy Scout: Reset password</title>
      </Helmet>
      <PreLoginPageStructure
        showAsideButtons
        isAuthenticated={props.isAuthenticated}
      >
        <ResetPasswordForm location={props.location} />
      </PreLoginPageStructure>
    </>
  );

const RegisterPage = (props: RouteComponentProps & MainRouterProps) => {
  if (props.location) {
    return props.isAuthenticated ? (
      <Redirect noThrow={true} to="/home" />
    ) : (
      <>
        <Helmet>
          <title>Skippy Scout: Register</title>
        </Helmet>
        <Registration location={props.location} navigate={props.navigate} />
      </>
    );
  }

  return props.isAuthenticated ? (
    <Redirect noThrow={true} to="/" />
  ) : (
    <Registration />
  );
};

const Users = (props: RouteComponentProps) => {
  return (
    <Page>
      <UsersPage />
    </Page>
  );
};

const SubscriptionPage = (props: RouteComponentProps) => {
  return (
    <Page>
      <SubscriptionPageLoggedIn />
    </Page>
  );
};

const PersonalInformationPage = (props: RouteComponentProps) => {
  return (
    <Page>
      <PersonalInformation />
    </Page>
  );
};

// const Invoices = (props: RouteComponentProps) => {
//   return (
//     <InvoicesPage {...props} />
//   )
// };
// const InvoicesFooter = (props: RouteComponentProps) => {
//   return (
//     <InvoicesFooterPage />
//   )
// };

const PaymentCards = (props: RouteComponentProps) => {
  return (
    <Page>
      <PaymentCardsPage />
    </Page>
  );
};

const TransactionHistory = (props: RouteComponentProps) => {
  return (
    <Page>
      <TransactionHistoryPage />
    </Page>
  );
};

const FieldReportsPage = (props: RouteComponentProps) => {
  return (
    <Page>
      <FieldReportsSectionPage />
    </Page>
  );
};

const ActivateNewDevicePage = (props: RouteComponentProps) => {
  return (
    <Page>
      <ActivateNewDevice {...props} />
    </Page>
  );
};

const UploadFieldsPage = (props: RouteComponentProps) => {
  return (
    <Page>
      <UploadFields {...props} />
    </Page>
  );
};
const ThirdPartyIntegrationsPage = (props: RouteComponentProps) => {
  return (
    <Page>
      <ThirdPartyIntegrations {...props} />
    </Page>
  );
};
const ViewPanoramasPage = (props: RouteComponentProps) => {
  return (
    <Page>
      <ViewPanoramas {...props} />
    </Page>
  );
};

const ApplicationMapsPage = (props: RouteComponentProps) => {
  return (
    <Page>
      <ApplicationMaps {...props} />
    </Page>
  );
};

//const NotificationSettingsPage = (props: RouteComponentProps) => {
//  return (
//    <Page>
//      <NotificationSettings />
//    </Page>
//  )
//}

const YourQRCodePage = (props: RouteComponentProps & MainRouterProps) => {
  if (!props.isAuthenticated) {
    return (
      <PreLoginPageStructure
        showAsideButtons
        isAuthenticated={props.isAuthenticated}
      >
        <YourQRCode location={props.location} />
      </PreLoginPageStructure>
    );
  } else {
    return (
      <Page>
        <YourQRCode location={props.location} />
      </Page>
    );
  }
};
const YourUserQRCodePage = (props: RouteComponentProps & MainRouterProps) => {
  if (!props.isAuthenticated) {
    return (
      <PreLoginPageStructure
        showAsideButtons
        isAuthenticated={props.isAuthenticated}
      >
        <YourUserQRCode location={props.location} />
      </PreLoginPageStructure>
    );
  } else {
    return (
      <Page>
        <YourUserQRCode location={props.location} />
      </Page>
    );
  }
};

const MainRouter = (props: MainRouterProps & RouteComponentProps) => {
  const isTier3or4Sub = useSelector(
    (state: AppState) =>
      state.subscriptionsReducer.nextSubscriptionDetails.isTier3or4
  );
  const tableState = useSelector((state: AppState) => state.tableReducer);
  const rightPopOutState = useSelector(
    (state: AppState) => state.rightPopOutReducer
  );
  const modalState = useSelector((state: AppState) => state.modalReducer);
  const activeSubscriptionStatus = useSelector(
    (state: AppState) =>
      state.subscriptionsReducer.subscriptionDetails.current_subscription_status
  );

  const currentSubscPrice = useSelector(
    (state: AppState) =>
      state.subscriptionsReducer.subscriptionDetails.monthly_price
  );
  const nextSubscPrice = useSelector(
    (state: AppState) =>
      state.subscriptionsReducer.nextSubscriptionDetails.monthly_price
  );

  const dispatch = useDispatch();
  const rightMenuStatus = useSelector(
    (state: AppState) => state.rightPopOutReducer.rightPopOutOpen
  );
  const toastStatus = useSelector((state: AppState) => state.toastReducer);
  const shouldDisplayApp = useSelector(
    (state: AppState) => state.auth.appVisible
  );
  const [mount, setMount] = useState<boolean>(false);
  const { data } = useFetch(endpoints.cms.leftPanel);

  if (data && !mount) {
    dispatch({
      type: "SET_LEFT_PANEL",
      message: data.details.page_content,
    });
    setMount(true);
  }

  const rightMenuVisible = () => {
    if (tableState.selectedCardIds.length > 0 && tableState.showMenu) {
      return true;
    } else if (
      tableState.selectedTransactionIds.length > 0 &&
      tableState.showMenu
    ) {
      return true;
    } else if (
      tableState.selectedUsersPageIds.length > 0 &&
      tableState.showMenu
    ) {
      return true;
    }
    return false;
  };

  const rightPopOutChildren = () => {
    switch (rightPopOutState.rightPopOutOpen) {
      case "USERS_ADD_NEW":
        return <UsersAdd />;
      case "USERS_UPDATE_DETAILS":
        return <UsersUpdateDetails />;
      case "USERS_UPDATE_PERMISSIONS":
        return <UsersUpdatePermissions />;
      case "USERS_VIEW":
        return <UsersViewDetails />;
      case "PAYMENT_CARD_ADD_NEW":
        return <PaymentCardAdd />;
      case "PAYMENT_CARD_VIEW":
        return <PaymentCardView />;
      case "PAYMENT_CARD_UPDATE":
        return <PaymentCardUpdate />;
      // case "USERS_FILTER":
      //   return <UsersFilter />
      //case "PAYMENT_CARD_FILTER":
      //  return <PaymentCardFilter />
      case "TRANSACTION_HISTORY_VIEW":
        return <TransactionHistoryView />;
      //case "TRANSACTION_HISTORY_FILTER":
      //  return <TransactionHistoryFilter />
      case "SUBSCRIPTION_UPGRADE":
        return <SubscriptionUpgrade />;
      case "ADD_REPORT_NOTES":
        return <AddReportNotes />;
      case "EDIT_REPORT_NOTES":
        return <AddReportNotes />;
      default:
        return null;
    }
  };

  const rightPopOutTitle = () => {
    switch (rightPopOutState.rightPopOutOpen) {
      case "USERS_ADD_NEW":
        return "Add new user";
      case "USERS_UPDATE_DETAILS":
        return "Update user details";
      case "USERS_UPDATE_PERMISSIONS":
        return "Update user permissions";
      case "USERS_VIEW":
        return "View user";
      case "PAYMENT_CARD_ADD_NEW":
        return "Add new payment card";
      case "PAYMENT_CARD_VIEW":
        return "Card details";
      case "PAYMENT_CARD_UPDATE":
        return "Update card";
      //case "PAYMENT_CARD_FILTER":
      //  return "Filter payment card";
      case "TRANSACTION_HISTORY_VIEW":
        return "View transaction";
      //case "TRANSACTION_HISTORY_FILTER":
      //  return "Transaction history filter";
      case "SUBSCRIPTION_UPGRADE":
        let downgrading = false;
        if (nextSubscPrice !== null && currentSubscPrice !== null)
          downgrading = nextSubscPrice < currentSubscPrice;
        return activeSubscriptionStatus === "Auto-Renewal-Failure" ||
          downgrading ||
          isTier3or4Sub
          ? "Subscription payment"
          : "Subscription upgrade";
      case "ADD_REPORT_NOTES":
        return "Add report notes";
      case "EDIT_REPORT_NOTES":
        return "Edit report notes";
      default:
        return "";
    }
  };

  //const rightPopOutAction = () => {
  //  switch (rightPopOutState.rightPopOutOpen) {
  //    case "USERS_ADD_NEW":
  //      return (
  //        <button className="Btn" onClick={() => {
  //          dispatch({ type: "CLOSE_RIGHT_POP_OUT" })
  //          handleToast("User successfully added")
  //        }}>
  //          Add
  //        </button>
  //      )
  //    case "USERS_UPDATE_DETAILS":
  //      return (
  //        <button className="Btn" onClick={() => {
  //          dispatch({ type: "CLOSE_RIGHT_POP_OUT" })
  //          handleToast("User successfully updated")
  //        }}>
  //          Update
  //        </button>
  //      )
  //    case "USERS_UPDATE_PERMISSIONS":
  //      return (
  //        <button className="Btn" onClick={() => {
  //          dispatch({ type: "CLOSE_RIGHT_POP_OUT" })
  //          handleToast("Permissions successfully saved")
  //        }}>
  //          Save
  //        </button>
  //      )
  //    case "PAYMENT_CARD_ADD_NEW":
  //      return (
  //        <button className="Btn" onClick={() => {
  //          dispatch({ type: "CLOSE_RIGHT_POP_OUT" })
  //          handleToast("Payment card successfully added")
  //        }}>
  //          Add
  //        </button>
  //      )
  //    case "PAYMENT_CARD_UPDATE":
  //      return (
  //        <button className="Btn" onClick={() => {
  //          dispatch({ type: "CLOSE_RIGHT_POP_OUT" })
  //          handleToast("Payment card successfully updated")
  //        }}>
  //          Update
  //        </button>
  //      )
  //    case "PAYMENT_CARD_FILTER":
  //      return (
  //        <button className="Btn" onClick={() => {
  //          dispatch({ type: "CLOSE_RIGHT_POP_OUT" })
  //        }}>
  //          Filter
  //        </button>
  //      )
  //    case "TRANSACTION_HISTORY_FILTER":
  //      return (
  //        <button className="Btn" onClick={() => {
  //          dispatch({ type: "CLOSE_RIGHT_POP_OUT" })
  //        }}>
  //          Filter
  //        </button>
  //      )
  //    case "SUBSCRIPTION_UPGRADE":
  //      return (
  //        <button className="Btn" onClick={() => {
  //          dispatch({ type: "CLOSE_RIGHT_POP_OUT" })
  //          handleToast("Subscription successfully upgraded")
  //        }}>
  //          Upgrade
  //        </button>
  //      )
  //    default:
  //      return <></>
  //  }
  //}

  const modalChildren = () => {
    switch (modalState.modalOpen) {
      case "USERS_DELETE":
        return <UsersDelete />;
      case "REPORT_NOTES_DELETE":
        return <DeleteNotes />;
      case "SUBSCRIPTION_PAUSE":
        return <PauseSubscription />;
      case "ACCOUNT_DELETE":
        return <AccountDelete />;
      case "INDIVIDUAL_ACCOUNT_DELETE":
        return <IndividualAccountDelete />;
      case "CARD_DELETE":
        return <CardDelete />;
      default:
        return null;
    }
  };

  const modalTitle = () => {
    switch (modalState.modalOpen) {
      case "USERS_DELETE":
        return "Delete user";
      case "REPORT_NOTES_DELETE":
        return "Delete notes";
      case "SUBSCRIPTION_PAUSE":
        return "Pause subscription";
      case "ACCOUNT_DELETE":
        return "Delete account";
      case "INDIVIDUAL_ACCOUNT_DELETE":
        return "Delete individual account";
      case "CARD_DELETE":
        return "Delete card";
      default:
        return "";
    }
  };

  React.useEffect(() => {
    rightMenuStatus
      ? document.body.classList.add("RightOpen")
      : document.body.classList.remove("RightOpen");
  }, [rightMenuStatus]);

  React.useEffect(() => {
    dispatch(loadAuthState());
  }, [dispatch]);

  if (!shouldDisplayApp) {
    return null;
  }

  return (
    <>
      <Toast message={toastStatus.message} visible={toastStatus.visible} />
      <NavBar isAuthenticated={props.isAuthenticated} />
      <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
        <Router className={`RouterComponent`}>
          <SubscriptionPage path="/" />
          {/* temporarily hide data sharing page */}
          <Users path="users" />
          <ForgotPassword
            path="/forgotpassword"
            isAuthenticated={props.isAuthenticated}
          />
          <RegisterPage
            path="/register/*"
            isAuthenticated={props.isAuthenticated}
          />
          <ResetPassword
            path="/reset-password"
            isAuthenticated={props.isAuthenticated}
          />
          <ChangePassword path="/change-password" />
          <YourQRCodePage
            path="/your-qr-code"
            isAuthenticated={props.isAuthenticated}
          />
          <YourUserQRCodePage
            path="/your-user-qr-code"
            isAuthenticated={props.isAuthenticated}
          />
          <CMSGeneratedContent
            isAuthenticated={props.isAuthenticated}
            default
          />

          <PaymentCards path="/payment-cards" />
          <TransactionHistory path="/transaction-history" />
          <FieldReportsPage path="/view-field-reports" />
          <PersonalInformationPage path="/personal-information" />
          <ActivateNewDevicePage path="/activate-new-device" />
          <UploadFieldsPage path="/upload-fields" />
          <ThirdPartyIntegrationsPage path="/third-party-integrations" />
          <ViewPanoramasPage path="/view-scout-spheres" />
          {/* <Invoices path="/template/subscription-invoice" />
          <InvoicesFooter path="/template/subscription-invoice-footer" /> */}
          {/*<NotificationSettingsPage path="/notification-settings" />*/}
          {/*<TermsConditions path="/terms-and-conditions" isAuthenticated={props.isAuthenticated} />*/}
          <ApplicationMapsPage path="/application-maps" />
        </Router>
        <RightMenu
          visible={rightMenuVisible()}
          menuItems={tableState.rightMenuItems.menuItems}
          alternateMenuItems={tableState.rightMenuItems.alternateMenuItems}
          toggleAltMenuItems={tableState.toggleAltMenuItems}
        />
      </div>
      <RightPopOut
        children={rightPopOutChildren()}
        open={rightPopOutState.rightPopOutOpen !== null}
        closeModal={() => dispatch({ type: "CLOSE_RIGHT_POP_OUT" })}
        title={rightPopOutTitle()}
        //actionButton={rightPopOutAction()}
      />
      <Modal
        children={modalChildren()}
        open={modalState.modalOpen !== null}
        closeModal={() => dispatch({ type: "CLOSE_MODAL" })}
        title={modalTitle()}
      />

      <Footer
        isAuthenticated={props.isAuthenticated}
        location={props.location}
      />
    </>
  );
};

export default MainRouter;
