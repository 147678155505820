import React from "react";
import { usePrevious } from "@avamae/hooks";
import { TableInfo } from "api";

type SelectedDataProps<T> = {
  selectedIDs: (string | number)[];
  data: T[];
  idColumn: string;
};

export const useSelectedData = function<SelectedData>({
  selectedIDs,
  data,
  idColumn,
}: SelectedDataProps<SelectedData>): SelectedData[] {
  const [selectedData, setSelectedData] = React.useState<SelectedData[]>([]);

  const prevIds = usePrevious<any>(selectedIDs);
  React.useEffect(() => {
    const selectedIdsLength = selectedIDs ? selectedIDs.length : 0;
    const prevIdsLength = prevIds ? prevIds.length : 0;
    if (
      !idColumn ||
      selectedIdsLength == 0 ||
      data.length == 0 ||
      prevIdsLength === selectedIdsLength
    ) {
      return;
    }

    const keepListData = selectData(selectedIDs, data, idColumn);

    setSelectedData(keepListData);
  }, [selectedIDs, data, idColumn]);

  return selectedData;
};

export const selectData = (
  selectedIDs: (string | number)[],
  data: any,
  idColumn: string
) => {
  const keepListData =
    data && data.filter((row: any) => selectedIDs.includes(row[idColumn]));
  return keepListData;
};
