import React from "react";
import { RouteComponentProps, Link } from "@reach/router";
import Helmet from "react-helmet";
import { useFetch } from "../../helpers/loading";
import { endpoints } from "../../serverDetails";
import Spinner from "react-spinkit"

const Logo = "/content/images/Icon_NoResult.png"
type SiteMapProps = {
};

type Props = SiteMapProps & RouteComponentProps;

const SiteMap: React.FC<Props> = () => {
  const { data, loading, error } = useFetch(endpoints.sitemap.list)

  if (loading) {
    return (
      <div className="CMSContent WidthContent HeightContent">
        <span>
          <Spinner name="three-bounce" fadeIn="none" />
        </span>
      </div>
    )
  }

  if (error) return (
    <div className="ErrorPageContainer">
      <img src={Logo} alt="Skippy Scout" style={{ height: '80px', width: '80px' }} />
      <h1>Sorry, that page doesn't exist</h1>

      <Link to="/" className="Btn" >Return home</Link>

    </div>
  )

  if (data) {
    return (
      <>
        <Helmet>
          <title>Skippy Scout: Site Map</title>
        </Helmet>
        <div className="SiteMap WidthContent HeightContent">

          <h1>Site Map</h1>

          <div className="SiteMapList">
            <ul>
              {
                data.details.list.map((link: any, i: number) => <li key={i}><Link to={link.url}>{link.link_name}</Link></li>)
              }
            </ul>
          </div>

        </div>
      </>
    );
  }
  return null
  
};

export default SiteMap;
