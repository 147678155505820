import { Either, Right, Left } from "purify-ts/Either";
import { ApiRequest } from "../types/ApiSubmissionTypes";
import { AxiosResponse, AxiosError } from "axios";
import api from "api";

export const oldauthHeaders = (token: string) => ({
  headers: { Authorization: `Bearer ${token}` },
});

export const authHeaders = () => {
  try {
    const tokenDetails = localStorage.getItem("TOKEN_DETAILS");
    if (tokenDetails == null) {
      return {
        headers: {},
      };
    }
    const token = JSON.parse(tokenDetails);
    if (token == null) {
      throw new Error("Token not present on saved token details.");
    }
    return {
      headers: { Authorization: `Bearer ${token.accessToken}` },
    };
  } catch (error) {
    throw error;
  }
};

type QueryResponse<T> = Promise<Either<AxiosError, AxiosResponse<T>>>;

export const request = <T, P, S, A extends { accessToken: string }>(
  body: ApiRequest<T, P>,
  details?: A
): QueryResponse<S> => {
  const headers = details ? authHeaders() : {};
  const config = { ...body, ...headers };
  return api
    .request(config)
    .then(r => Right(r))
    .catch(e => Left(e));
};
