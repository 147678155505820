import React from "react";

interface SignInBoxProps {
  title: string;
  children: string | JSX.Element;
}

const signInBox = (props: SignInBoxProps) => {
  return (
    <div className="LoginBox">
      {/* <img src={process.env.PUBLIC_URL + '/content/images/Manoto/logoEmail.gif'} alt="manoto logo" className={styles.Logo} /> */}
      <h1 className="LoginBoxTitle">{props.title}</h1>
      <div>{props.children}</div>
    </div>
  );
};

export default signInBox;
