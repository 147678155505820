import React, { useRef, useState } from "react";
import { useFetch } from "../../helpers/loading";
import { endpoints } from "../../serverDetails";
import Spinner from "react-spinkit";
import { useSelector, useDispatch } from "react-redux";
import { formatUTCDateString } from "../../helpers/dates";
import ImprovedTextEditor from "components/ImprovedTextEditor/ImprovedTextEditor";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import { useFetchReportNotes } from "./useFetchReportNotes";
import axios from "api";
import { selectHasNotes, setHasNotes } from "reducers/reportSection";

const errorResults = "/content/images/Icon_Error.png";

export interface AddReportNotes {
  class?: string;
}

type Data = {
  details: {
    id: number;
    name_on_card: string;
    card_type: string;
    card_end_digits: string;
    expiry_date: string;
    title: string;
    first_name: string;
    last_name: string;
    email: string;
    telephone: string;
    address_line_1: string;
    address_line_2: string | null;
    town_city: string;
    county_state: string | null;
    postcode: string;
    country: string;
    add_date_utc: string;
    edit_date_utc: string | null;
    added_by: string;
  };
};

const AddReportNotes: React.FC<AddReportNotes> = (props: AddReportNotes) => {
  const dispatch = useDispatch();
  const reportsWithNotes = useSelector(selectHasNotes);

  const handleToast = (message: string) => {
    dispatch({ type: "SHOW_TOAST", message: message });
    window.setTimeout(() => {
      dispatch({ type: "HIDE_TOAST" });
    }, 3000);
  };

  const id = useSelector<any, any>(
    state => state.rightPopOutReducer.rightPopOutId.selectedId
  );
  const savedNotesSet = useRef(false);
  const [text, setText] = useState("");

  //   const requestConfig = React.useMemo(() => {
  //     return { params: { Id: state.selectedCardIds[0] } };
  //     //eslint-disable-next-line
  //   }, []);

  React.useEffect(() => {
    return () => {};
  }, []);

  const { data, loading, error } = useFetchReportNotes(id);

  if (loading) {
    return (
      <span>
        <Spinner name="three-bounce" fadeIn="none" />
      </span>
    );
  }

  if (error)
    return (
      <div className="ErrorResults">
        <div className="NoResults">
          <img src={errorResults} alt={"Error"} />
          <p>Something went wrong, please try again</p>
        </div>
      </div>
    );

  if (!savedNotesSet.current && data) {
    setText(data);
    savedNotesSet.current = true;
  }

  const handleTabsNoteChange = (text: string) => {
    const convertDraftToHTML = (rawText: any) => {
      const rawContentState = convertToRaw(rawText.getCurrentContent());
      return draftToHtml(rawContentState);
    };
    const value = convertDraftToHTML(text);
    setText(value);
  };

  const handleSubmit = async () => {
    try {
      const res = await axios.post(endpoints.reportsModule.updatereportnotes, {
        reportsId: id,
        notes: text,
      });
      if (res.status === 1 || res.status === 200) {
        if (!reportsWithNotes.includes(id)) {
          dispatch(setHasNotes(id));
        }
        dispatch({ type: "CLOSE_RIGHT_POP_OUT" });
        handleToast("Regenerate report to view your new note");
        return {
          response: res.data.details,
          errors: null,
        };
      } else {
        handleToast("Note failed to be added, please try again");

        return {
          response: null,
          errors: res.data.errors,
        };
      }
    } catch (error) {
      return {
        response: null,
        errors: error,
      };
    }
  };

  return (
    <>
      <div className={`AddReportNotes ${props.class}`}>
        <ImprovedTextEditor
          className=""
          errors={[]}
          text={text}
          additionalOnChangeHandler={handleTabsNoteChange}
          convertTextFromHTML={true}
          type="NOTES"
          maxLength={1750}
        />
      </div>
      <div className="Bottom">
        <button
          className="Btn BtnWhite"
          onClick={() => dispatch({ type: "CLOSE_RIGHT_POP_OUT" })}
        >
          Cancel
        </button>
        <button
          className={`Btn`}
          type="submit"
          onClick={handleSubmit}
          disabled={false}
        >
          {false ? (
            <Spinner
              name="three-bounce"
              className="BtnSpinner BtnSpinnerSmall"
              color="white"
              fadeIn="none"
            />
          ) : (
            "Save"
          )}
        </button>
      </div>
    </>
  );
  return null;
};

export default AddReportNotes;
