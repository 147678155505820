import React from "react";
import { Field, FieldProps } from "formik";
import ValidationError, {
  ServerError,
} from "../ValidationError/ValidationError";

type SelectOption = { label: string; key: string | number; tax?: boolean };

export function Dropdown(
  props: FieldProps & {
    label: string;
    errors: ServerError[];
    options: SelectOption[];
    sideBySide?: boolean;
    disabled?: boolean;
  }
) {
  const { field, form, options } = props;

  const setValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
    form.setFieldValue(field.name, e.currentTarget.value);
    let dataset = e.target.options[e.target.selectedIndex].dataset;
    if (form.values["country_tax"] !== undefined) {
      form.setFieldValue("country_tax", dataset.tax);
    }
  };

  return (
    <>
      <div
        data-testid="container"
        className={`${props.sideBySide ? "FormSideBySide" : "FormBox"}`}
      >
        <div className={`FormLabel ${props.disabled ? "Disabled" : ""}`}>
          <label data-testid="thelabel" htmlFor={props.field.name}>
            {props.label}
          </label>
        </div>
        <div className={`FormField`}>
          <select
            className={`DropDown ${props.disabled ? "Disabled" : ""}`}
            value={field.value}
            onChange={setValue}
          >
            <option defaultValue="Please select one">Please select one</option>{" "}
            {/*needed a default value so it didnt auto select United Kingdom and break the form*/}
            {options.map((opt: any, i: number) => (
              <option
                data-testid="opt"
                data-tax={opt.tax || ""}
                key={opt.key}
                value={opt.key}
              >
                {opt.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <ValidationError name={props.field.name} errors={props.errors} />
    </>
  );
}

export function DropdownField(props: {
  label: string;
  name: string;
  errors: ServerError[];
  children: SelectOption[];
  sideBySide?: boolean;
  disabled?: boolean;
}) {
  const { label, name, errors, children, sideBySide, disabled } = props;
  return (
    <Field
      name={name}
      render={(rprops: FieldProps) => (
        <Dropdown
          {...rprops}
          label={label}
          errors={errors}
          options={children}
          sideBySide={sideBySide}
          disabled={disabled}
        />
      )}
    />
  );
}
