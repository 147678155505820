import { BoolCheckboxField } from "components/FormComponents/BoolCheckbox/BoolCheckbox";
import { CalendarDatePickerField } from "components/FormComponents/CalendarDatePicker/CalendarDatePicker";
import { PhoneNumberField } from "components/FormComponents/PhoneNumber/PhoneNumber";
import RowInput from "components/SignInComponents/RowInput/RowInput";
import { Form, ErrorMessage, Field } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import Spinner from "react-spinkit";
import { Data } from "./NewUserAddForm";

interface NewUserAddFormInnerProps {
  data?: Data;
  values: any;
  handleSubmit: () => void;
  isSubmitting: boolean;
  sharingUser: boolean | null;
}

const NewUserAddFormInner = (props: NewUserAddFormInnerProps) => {
  const dispatch = useDispatch();
  return (
    <>
      <Form className="NewUserAddForm">
        {props.sharingUser === null ? null : (
          <>
            <div className="FormBox">
              <RowInput
                type="title"
                name="title"
                id="title"
                placeholder="Title"
                optional={true}
                maxLength={10}
              />

              <ErrorMessage name="title">
                {message => <span className="ErrorMessage">{message}</span>}
              </ErrorMessage>
            </div>
            <div className="FormBox">
              <RowInput
                name="first_name"
                id="first_name"
                placeholder="First name"
                maxLength={50}
              />
              <ErrorMessage name="first_name">
                {message => <span className="ErrorMessage">{message}</span>}
              </ErrorMessage>
            </div>
            <div className="FormBox">
              <RowInput
                type="last_name"
                name="last_name"
                id="last_name"
                placeholder="Last name"
                maxLength={50}
              />
              <ErrorMessage name="last_name">
                {message => <span className="ErrorMessage">{message}</span>}
              </ErrorMessage>
            </div>
            <div className="FormBox">
              <RowInput
                type="company_name"
                name="company_name"
                id="company_name"
                placeholder="Company name"
                maxLength={75}
                readOnly={true}
                disabled={true}
              />
              <ErrorMessage name="company_name">
                {message => <span className="ErrorMessage">{message}</span>}
              </ErrorMessage>
            </div>
            <div className="FormBox">
              <div className="FormSideBySide">
                <PhoneNumberField name={"telephone"} label={"Phone number"} />
              </div>
              <ErrorMessage name="telephone">
                {message => <span className="ErrorMessage">{message}</span>}
              </ErrorMessage>
            </div>
            <div className="FormBox">
              <div className="FormSideBySide">
                <PhoneNumberField name={"mobile"} label={"Mobile number"} />
              </div>
              <ErrorMessage name="mobile">
                {message => <span className="ErrorMessage">{message}</span>}
              </ErrorMessage>
            </div>
            <div className="FormBox">
              <RowInput
                type="email_address"
                name="email_address"
                id="email_address"
                placeholder="Email address"
                maxLength={75}
              />
              <ErrorMessage name="email_address">
                {message => <span className="ErrorMessage">{message}</span>}
              </ErrorMessage>
            </div>
            <BoolCheckboxField name="b_suppressed" label="Suppressed" />
            <>
              <h3>Permission Level</h3>

              <div className="FormBox">
                <div className="RememberForgot">
                  <div className="Checkbox">
                    <Field
                      name={"b_access_all_farms"}
                      type="checkbox"
                      id={"b_access_all_farms"}
                    />
                    <label
                      className="CustomInput"
                      htmlFor={"b_access_all_farms"}
                    >
                      Access all farms
                    </label>
                  </div>
                </div>
              </div>
              <>
                {props.data &&
                  props.data.details &&
                  props.data.details.permissions.map((p: any, i: any) => {
                    return (
                      <div
                        className={`FormBox ${
                          !props.values.b_access_all_farms ? "Show" : "Hide"
                        }`}
                        key={"farm" + i}
                      >
                        <div className="RememberForgot">
                          <div className="Checkbox FirstLayer">
                            <Field
                              name={"farm " + p.id.toString()}
                              type="checkbox"
                              id={"farm " + p.id.toString()}
                            />
                            <label
                              className="CustomInput"
                              htmlFor={"farm " + p.id.toString()}
                            >
                              {p.farm_name}
                            </label>
                          </div>
                          {p.fields.map((f: any, x: any) => {
                            return (
                              <React.Fragment key={`farm ${i} field ${x}`}>
                                {x === 0 && (
                                  <div className="FormBox">
                                    <div className="RememberForgot">
                                      <div
                                        className={`Checkbox SecondLayer ${
                                          props.values[
                                            "farm " + p.id.toString()
                                          ]
                                            ? "Show"
                                            : "Hide"
                                        }`}
                                      >
                                        <Field
                                          name={
                                            "b_access_all_fields " +
                                            p.id.toString()
                                          }
                                          type="checkbox"
                                          id={
                                            "b_access_all_fields " +
                                            p.id.toString()
                                          }
                                        />
                                        <label
                                          className="CustomInput"
                                          htmlFor={
                                            "b_access_all_fields " +
                                            p.id.toString()
                                          }
                                        >
                                          Access all fields
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div>
                                  <div
                                    key={"farm" + i + "field" + x}
                                    className={`Checkbox SecondLayer ${
                                      props.values["farm " + p.id.toString()] &&
                                      !props.values[
                                        "b_access_all_fields " + p.id.toString()
                                      ]
                                        ? "Show"
                                        : "Hide"
                                    }`}
                                  >
                                    <Field
                                      name={"field " + f.id.toString()}
                                      type="checkbox"
                                      id={"field " + f.id.toString()}
                                    />
                                    <label
                                      className="CustomInput"
                                      htmlFor={"field " + f.id.toString()}
                                    >
                                      {f.field_name}
                                    </label>
                                  </div>
                                  {f.scout_points.map((s: any, n: any) => {
                                    return (
                                      <React.Fragment
                                        key={
                                          "farm" + i + "field" + x + "point" + n
                                        }
                                      >
                                        {n === 0 && (
                                          <div className="FormBox">
                                            <div className="RememberForgot">
                                              <div
                                                className={`Checkbox ThirdLayer ${
                                                  props.values[
                                                    "farm " + p.id.toString()
                                                  ] &&
                                                  props.values[
                                                    "field " + f.id.toString()
                                                  ] &&
                                                  !props.values[
                                                    "b_access_all_fields " +
                                                      p.id.toString()
                                                  ]
                                                    ? "Show"
                                                    : "Hide"
                                                }`}
                                              >
                                                <Field
                                                  name={
                                                    "b_access_all_scout_points " +
                                                    f.id.toString()
                                                  }
                                                  type="checkbox"
                                                  id={
                                                    "b_access_all_scout_points " +
                                                    f.id.toString()
                                                  }
                                                />
                                                <label
                                                  className="CustomInput"
                                                  htmlFor={
                                                    "b_access_all_scout_points " +
                                                    f.id.toString()
                                                  }
                                                >
                                                  Access all scout points
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        <div
                                          className={`Checkbox ThirdLayer ${
                                            props.values[
                                              "farm " + p.id.toString()
                                            ] &&
                                            props.values[
                                              "field " + f.id.toString()
                                            ] &&
                                            !props.values[
                                              "b_access_all_fields " +
                                                p.id.toString()
                                            ] &&
                                            !props.values[
                                              "b_access_all_scout_points " +
                                                f.id.toString()
                                            ]
                                              ? "Show"
                                              : "Hide"
                                          }`}
                                        >
                                          <Field
                                            name={"point " + s.id.toString()}
                                            type="checkbox"
                                            id={"point " + s.id.toString()}
                                          />
                                          <label
                                            className="CustomInput"
                                            htmlFor={"point " + s.id.toString()}
                                          >
                                            {s.scout_point}
                                          </label>
                                        </div>
                                        <div
                                          className={`Checkbox FourthLayer ${
                                            props.values[
                                              "farm " + p.id.toString()
                                            ] &&
                                            props.values[
                                              "field " + f.id.toString()
                                            ] &&
                                            !props.values[
                                              "b_access_all_fields " +
                                                p.id.toString()
                                            ] &&
                                            !props.values[
                                              "b_access_all_scout_points " +
                                                f.id.toString()
                                            ] &&
                                            props.values[
                                              "point " + s.id.toString()
                                            ]
                                              ? "Show"
                                              : "Hide"
                                          }`}
                                        >
                                          <div>
                                            <Field
                                              name={
                                                "point share " + s.id.toString()
                                              }
                                              type="checkbox"
                                              id={
                                                "point share " + s.id.toString()
                                              }
                                            />
                                            <label
                                              className="CustomInput MarginBottom"
                                              htmlFor={
                                                "point share " + s.id.toString()
                                              }
                                            >
                                              Share for a day
                                            </label>
                                          </div>
                                          <div
                                            className={`Checkbox ${
                                              props.values[
                                                "farm " + p.id.toString()
                                              ] &&
                                              props.values[
                                                "field " + f.id.toString()
                                              ] &&
                                              !props.values[
                                                "b_access_all_fields " +
                                                  p.id.toString()
                                              ] &&
                                              !props.values[
                                                "b_access_all_scout_points " +
                                                  f.id.toString()
                                              ] &&
                                              props.values[
                                                "point " + s.id.toString()
                                              ] &&
                                              props.values[
                                                "point share " + s.id.toString()
                                              ]
                                                ? "Show"
                                                : "Hide"
                                            }`}
                                          >
                                            <CalendarDatePickerField
                                              name={
                                                "point date " + s.id.toString()
                                              }
                                              placeholder="Share"
                                              inputPlaceholderText="DD/MM/YYYY"
                                              excludeDay={false}
                                            />
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    );
                                  })}
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                {props.data &&
                  props.data.details &&
                  props.data.details.permissions && (
                    <ErrorMessage name="permissions">
                      {message => (
                        <span className="ErrorMessageLeft">{message}</span>
                      )}
                    </ErrorMessage>
                  )}
              </>
            </>
          </>
        )}
      </Form>
      <div className="Bottom">
        <button
          className="Btn BtnWhite"
          onClick={() => dispatch({ type: "CLOSE_RIGHT_POP_OUT" })}
        >
          Cancel
        </button>
        <button
          className="Btn"
          type="submit"
          onClick={() => {
            props.handleSubmit();
            dispatch({
              type: "REFRESH_TABLE",
            });
          }}
          disabled={props.isSubmitting}
        >
          {props.isSubmitting ? (
            <Spinner
              name="three-bounce"
              className="BtnSpinner BtnSpinnerSmall"
              color="white"
              fadeIn="none"
            />
          ) : (
            "Add"
          )}
        </button>
      </div>
    </>
  );
};

export { NewUserAddFormInner };
