export const capitaliseWord = (word: string): string => {
  return word
    .slice(0, 1)
    .toUpperCase()
    .concat(word.slice(1));
};

type NullableString = string | null;

export const parseNullableInt = (x: NullableString): null | number =>
  x == null ? null : parseInt(x);

export const removeTrailingChars = (char: string) => (str: string): string =>
  str[str.length - 1] === char
    ? removeTrailingChars(char)(str.slice(0, str.length - 1))
    : str;

export const removeTrailingSlashes = removeTrailingChars("/");


export const validatePassword = (password: string) => {
  const passwordReg = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,20})");

  return passwordReg.test(password)
}
