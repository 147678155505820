import React from "react";
import UpdateUserForm from "../Forms/UpdateUserForm/UpdateUserForm";
import { useFetch } from "../../helpers/loading";
import { endpoints } from "../../serverDetails";
import Spinner from "react-spinkit";
import { useSelector, useDispatch } from "react-redux";
import axios from "api";

const errorResults = "/content/images/Icon_Error.png"

export interface UsersUpdateDetails {
  class?: string;
}

const UsersUpdateDetails = (props: UsersUpdateDetails) => {
  const state = useSelector<any, any>(state => state.tableReducer)
  const dispatch = useDispatch()

  const selectedUser = React.useMemo(() => {
    return state.selectedUsersPageIds[0]
    //eslint-disable-next-line
  }, []);

  const parseError = (submitError: string) => {
    switch (submitError) {
      case "Account_In_Use":
        return "Account already in use, please use a different email address"
      case "Invalid_Email_Address":
        return "Invalid email address"
      case "Invalid_Telephone_Number":
        return "Invalid telephone number"
      case "Company_Already_Exist":
        return "This company name is already in use"
      default:
        return "Something went wrong, please refresh and try again"
    }
  }

  const handleToast = (message: string) => {
    dispatch({ type: 'SHOW_TOAST', message: message })
    window.setTimeout(() => {
      dispatch({ type: 'HIDE_TOAST' })
    }, 3000);
  }

  const handleSubmit = async (
    payload: any,
    setFieldError: (field: string, val: string) => void,
    setSubmitting: any
  ) => {

    if (payload) {

      let postObject = {
        id: selectedUser,
        title: payload.title,
        first_name: payload.first_name,
        last_name: payload.last_name,
        company_name: payload.company_name,
        email_address: payload.email_address,
        telephone: payload.telephone,
        mobile: payload.mobile,
        b_suppressed: payload.b_suppressed,
      }


      setSubmitting(true)

      axios
        .post(endpoints.users.update, postObject)
        .then(res => {
          if (res.data.status === "1") {
            setSubmitting(false)
            dispatch({ type: "CLOSE_RIGHT_POP_OUT" })
            handleToast("User successfully updated")
            dispatch({
              type: "REFRESH_TABLE"
            })
          }
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors.length > 0) {
            setFieldError(err.response.data.errors[0].field_name, parseError(err.response.data.errors[0].message_code))
            setSubmitting(false)
          }
        });
    }

  };


  const requestConfig = React.useMemo(() => {
    return { params: { Id: state.selectedUsersPageIds[0] } };
    //eslint-disable-next-line
  }, []);

  const { data, loading, error } = useFetch(endpoints.users.updateDetails, requestConfig)


  if (loading) {
    return <span><Spinner name="three-bounce" fadeIn="none" /></span>;
  }

  if (error) return (
    <div className="ErrorResults">
      <div className="NoResults">
        <img src={errorResults} alt={"Error"} />
        <p>Error</p>
      </div>
    </div>
  )

  if (data) {
    return (
      <div className={`UsersUpdateDetails ${props.class}`}>
        <UpdateUserForm
          submit={(values: any, setFieldError: (field: string, val: string) => void, setSubmitting: (val: boolean) => void) => handleSubmit(values, setFieldError, setSubmitting)}
          data={data}
        />
      </div>
    )
  }

  return (
    <div className={`UsersUpdateDetails ${props.class}`}>
      <UpdateUserForm
        submit={(values: any, setFieldError: (field: string, val: string) => void, setSubmitting: (val: boolean) => void) => handleSubmit(values, setFieldError, setSubmitting)}
      />
    </div>
  )

};

export default UsersUpdateDetails;
