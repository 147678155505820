import React, { useState } from "react";
import {
  //RESET_LINK_URL,
  USERS_PUBLIC_URL,
  //API_CLIENT_URL,
  endpoints,
} from "serverDetails";
import { Formik, Form } from "formik";
import { Link } from "@reach/router";
import styles from "./ForgotPasswordForm.module.css";
import * as Yup from "yup";
import api from "api";

import ChunkyInput from "../ChunkyInput/ChunkyInput";

//const getDetails = async () => api.post(API_CLIENT_URL + endpoints.forgottenPassword.sendEmail, {user_name:});
const url = USERS_PUBLIC_URL + endpoints.forgottenPassword.sendEmail;

interface ForgotPasswordProps { }

interface ForgotPasswordInnerProps {
  isValid: boolean;
}

const validationSchema = Yup.object({
  email: Yup.string()
    .email()
    .required("A valid email address is required."),
});

export const ForgotPasswordFormInner = ({
  isValid,
}: ForgotPasswordInnerProps) => {
  return (
    <Form className={styles.forgotPasswordForm}>
      <div className="FormBox FormBoxWithIcon">
        <ChunkyInput
          type="email"
          name="email"
          placeholder="Email address"
          icon="/content/images/Icon_Field_01.png"
          maxLength={200}
        />
        <label />
      </div>
      <div className="RememberForgot">
        <Link className="Link" to='/'>Retry your log in?</Link>
      </div>
      <div className="FormBottom">
        <button type="submit" disabled={!isValid} className="Btn BtnFullWidth">
          Reset password
        </button>
      </div>
    </Form>
  );
};

const ForgotPasswordForm = (props: ForgotPasswordProps) => {
  const [submitStatus, setStatus] = useState<"notSent" | "error" | "sent">(
    "notSent"
  );

  const submitForm = async (emailAddress: string) => {
    try {
      //const metadata = await getDetails();
      //const { licenseesID, accountType } = metadata.data;
      //const data = {
      //  licenseesID,
      //  accountType,
      //  emailAddress,
      //  resetLinkUrl: RESET_LINK_URL,
      //};
      await api.post(url, { user_name: emailAddress });
      setStatus("sent");
    } catch (_) {
      setStatus("error");
    }
  };

  const form = (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={validationSchema}
      onSubmit={values => submitForm(values.email)}
      render={({ isValid }) => <ForgotPasswordFormInner isValid={isValid} />}
    />
  );

  const success = (
    <div className={styles.success}>
      <h3>Succeeded!</h3>
      <p>
        If we found an account associated with your credentials you will receive
        a reset password link.
      </p>
      <div className={styles.goBack}>
        <Link to="/" className="Btn BtnFull">
          Go back
        </Link>
      </div>
    </div>
  );

  const error = (
    <div className={styles.failed}>
      <h3>
        We couldn't issue a reset password link to that address. Please try
        again later.
      </h3>

      <Link to="/" className="Btn BtnFull">
        Go back
      </Link>
    </div>
  );

  switch (submitStatus) {
    case "sent":
      return success;
    case "error":
      return error;
    default:
      return form;
  }
};

export default ForgotPasswordForm;
