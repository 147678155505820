import React, { useState } from "react";
import { FieldReportsFilterProps } from "./FieldReportsFilter";

export const FieldReportsSubFilter = ({
  subFilterTitle,
  subFilterItems,
  selectedSubFilter,
  setSelectedSubFilter,
  setFilterChanged,
}: Pick<FieldReportsFilterProps, "subFilterTitle" | "subFilterItems"> & {
  selectedSubFilter: string;
  setSelectedSubFilter: React.Dispatch<React.SetStateAction<string>>;
  setFilterChanged: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const handleSelect = (item: string) => {
    setSelectedSubFilter(item);
  };

  return (
    <div className="FieldReportsFilter__subFilter">
      <div className="FieldReportsFilter__subFilterTitle">{subFilterTitle}</div>
      <div className="FieldReportsFilter__subFilterItems">
        {subFilterItems &&
          subFilterItems.map((item, idx) => {
            return (
              <React.Fragment key={idx}>
                <span
                  onClick={() => {
                    handleSelect(item);
                    setFilterChanged(prev => !prev);
                  }}
                  className={`FieldReportsFilter__subFilterItem
              ${item === selectedSubFilter ? "Selected" : ""}
              `}
                >
                  {item}
                </span>
                <span>{`${
                  idx === subFilterItems.length - 1 ? "" : " | "
                }`}</span>
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};
