import { addDays, format, isSameDay } from "date-fns";
import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { FieldReportsSubFilter } from "./FieldReportsSubFilter";

const FieldReportsFilterItem = ({
  name,
  id,
  setSelectedItems,
  selectedItems,
  singleSelect,
  setFilterChanged,
  returnName,
}: {
  name: string;
  id: number;
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
  setFilterChanged: React.Dispatch<React.SetStateAction<boolean>>;
  selectedItems: string[] | undefined;
  singleSelect: boolean;
  returnName: boolean;
}) => {
  let isSelected = "";
  if (selectedItems) {
    isSelected =
      returnName && selectedItems.includes(name)
        ? "Selected"
        : selectedItems.includes(id.toString())
        ? "Selected"
        : "";
  }

  const onClickItem = () => {
    const latestSelect = !isSelected;
    if (singleSelect) {
      const item = returnName ? name : id.toString();
      setSelectedItems([item]);
      setFilterChanged(prev => !prev);
    } else {
      setSelectedItems(prev => {
        if (latestSelect) return [...prev, id.toString()];
        if (!latestSelect)
          return prev.filter(idItem => idItem !== id.toString());
        return prev;
      });
      setFilterChanged(prev => !prev);
    }
  };

  return (
    <div
      onClick={onClickItem}
      className={`FieldReportsFilterItem ${isSelected}`}
    >
      {name}
    </div>
  );
};

export enum FieldReportsFilterTypes {
  ITEMS = "ITEMS",
  DATE = "DATE",
}

export type FieldReportsFilterProps = {
  heading: string;
  items?: { name: string; id: number }[];
  type: FieldReportsFilterTypes;
  includeDates?: Date[];
  subFilterTitle?: string;
  subFilterItems?: string[];
  setSelectedItems?: React.Dispatch<React.SetStateAction<string[]>>;
  selectedItems?: string[];
  setSelectedSubFilter?: React.Dispatch<React.SetStateAction<string>>;
  selectedSubFilter?: string;
  singleSelect?: boolean;
  returnName?: boolean;
  setFilterChanged: React.Dispatch<React.SetStateAction<boolean>>;
};

export const FieldReportsFilter: React.FC<FieldReportsFilterProps> = ({
  heading,
  items,
  type,
  includeDates,
  subFilterTitle,
  subFilterItems,
  selectedItems,
  setSelectedItems,
  setSelectedSubFilter,
  selectedSubFilter,
  singleSelect = false,
  setFilterChanged,
  returnName = false,
}) => {
  // Add a type for items and date and add conditional rendering
  const [selected, setSelected] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    type === FieldReportsFilterTypes.DATE ? new Date() : null
  );

  const onClickHeader = () => {
    setSelected(prev => !prev);
  };

  const setTypeStyle = () => {
    switch (type) {
      case FieldReportsFilterTypes.DATE:
        return "DATE";
      case FieldReportsFilterTypes.ITEMS:
        return "ITEMS";
      default:
        break;
    }
  };

  let selectedNames = "";

  if (items && items.length > 0 && selectedItems && selectedItems.length > 0) {
    let match = [];
    if (returnName) {
      match = items.filter(({ id, name }) => selectedItems.includes(name));
    } else {
      match = items.filter(({ id, name }) =>
        selectedItems.includes(id.toString())
      );
    }

    if (match) {
      selectedNames = match.map(({ name }) => name).join(", ");
    }
  }

  return (
    <div className="FieldReportsFilterOuter">
      <div
        className={`FieldReportsFilter ${
          selected ? "Selected" : ""
        } ${setTypeStyle()}`}
      >
        <div onClick={onClickHeader} className="FieldReportsFilter__header">
          <div className="FieldReportsFilter__headerImage" />
          {`${heading}: ${
            selectedNames
              ? selectedNames
              : type === FieldReportsFilterTypes.DATE &&
                selectedItems &&
                selectedItems.length > 0 &&
                selectedSubFilter !== "All dates"
              ? format(new Date(selectedItems[0]), "do MMM yyyy")
              : "ALL"
          }`}
        </div>
        <div className="FieldReportsFilter__expandableDrawer">
          <div className="FieldReportsFilter__content">
            {type === FieldReportsFilterTypes.ITEMS &&
              setSelectedItems &&
              items &&
              items.map(({ id, name }) => (
                <FieldReportsFilterItem
                  key={id}
                  name={name}
                  id={id}
                  setSelectedItems={setSelectedItems}
                  selectedItems={selectedItems}
                  singleSelect={singleSelect}
                  setFilterChanged={setFilterChanged}
                  returnName={returnName}
                />
              ))}
            {type === FieldReportsFilterTypes.DATE && setSelectedItems && (
              <ReactDatePicker
                inline
                selected={
                  selectedItems && selectedItems.length > 0
                    ? new Date(selectedItems[0])
                    : new Date()
                }
                onChange={date => {
                  setSelectedItems([date ? date.toISOString() : ""]);
                  setSelectedDate(date);
                  setFilterChanged(prev => !prev);

                  if (
                    date &&
                    (selectedSubFilter === "All dates" ||
                      selectedSubFilter === "All prior dates")
                  ) {
                    const dateFilterInterval = isSameDay(date, new Date())
                      ? "All dates"
                      : "All prior dates";
                    setSelectedSubFilter &&
                      setSelectedSubFilter(dateFilterInterval);
                  }
                }}
                formatWeekDay={nameOfDay => {
                  if (typeof nameOfDay === "string") {
                    return (nameOfDay as string).substring(0, 1);
                  } else return "";
                }}
                forceShowMonthNavigation={true}
                open={true}
                includeDates={includeDates}
              />
            )}
          </div>
        </div>
      </div>
      {subFilterTitle &&
        subFilterItems &&
        setSelectedSubFilter &&
        selectedSubFilter !== undefined &&
        selectedSubFilter !== null && (
          <FieldReportsSubFilter
            subFilterTitle={subFilterTitle}
            subFilterItems={subFilterItems}
            selectedSubFilter={selectedSubFilter}
            setSelectedSubFilter={setSelectedSubFilter}
            setFilterChanged={setFilterChanged}
          />
        )}
    </div>
  );
};
