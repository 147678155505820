import axios from "api";
import { AxiosRequestConfig } from "axios";

type DownloadFile = {
  url: string;
  fileName?: string;
  fileType?: string;
  reqConfig?: AxiosRequestConfig;
};

export async function downloadFile(params: DownloadFile) {
  const { url, fileName, fileType, reqConfig } = params;
  let type = "";
  switch (fileType) {
    case "PDF":
      type = ".pdf";
      break;
    case "JPG":
      type = ".jpg";
      break;
    case "JPEG":
      type = ".jpeg";
      break;
    case "PNG":
      type = ".png";
      break;
    case "DOC":
      type = ".doc";
      break;
    case "DOCX":
      type = ".docx";
      break;
    case "XLS":
      type = ".xls";
      break;
    case "XLSX":
      type = ".xlsx";
      break;
    case "DWG":
      type = ".dwg";
      break;
    case "DXF":
      type = ".dxf";
      break;
  }

  try {
    const headers = reqConfig ? reqConfig.headers : {};
    const { data } = await axios.get(url, {
      responseType: "blob",
      ...reqConfig,
      headers: {
        "Cache-Control": "max-age=0",
        ...headers,
      },
    });

    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.setAttribute("style", "opacity:0;z-index:-1;position:absolute");
    link.href = downloadUrl;
    link.setAttribute("download", `${fileName}${type}`);
    document.body.appendChild(link);
    link.click();
    setTimeout(function() {
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    }, 100);
    if (data) {
      return true;
    }
  } catch (err) {
    throw err;
  }
}
