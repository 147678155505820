import { RouteComponentProps } from "@reach/router";
import React, { useState } from "react";
import Spinner from "react-spinkit";
import queryString from "query-string";
import { getAddFieldMarginFarm } from "pages/ThirdPartyIntegrations/ThirdPartyIntegrationsApi";

const IntegrationLoading = (props: RouteComponentProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [success, setSuccess] = useState<boolean>(false);

  React.useEffect(() => {
    const code = props.location
      ? queryString.parse(props.location.search).code
      : "";
    const client_guid = props.location
      ? queryString.parse(props.location.search).state
      : "";

    console.log({ code, client_guid });
    if (code && client_guid) {
      (async () => {
        setLoading(true);
        const result = await getAddFieldMarginFarm(code, client_guid);
        if (result.response) {
          console.log(result.response);
          setSuccess(true);
          setLoading(false);
        }
        if (result.errors) {
          console.log(result.errors);
          setSuccess(false);
          setLoading(false);
        }
        setLoading(false);
      })();
    }
  }, [props.location]);

  if (loading) {
    return (
      <div className="IntegrationLoading">
        <div className="CenterContent">
          <Spinner
            name="three-bounce"
            fadeIn="none"
            overrideSpinnerClassName="Spinner"
          />
          <p>Please wait whilst we connect your account...</p>
        </div>
      </div>
    );
  }
  if (!loading && success) {
    return (
      <div className="IntegrationLoading">
        <div className="CenterContent">
          <p>Your account has been successfully connected!</p>
          <div
            onClick={() => {
              window.close();
            }}
            className="Btn"
          >
            Close this window
          </div>
        </div>
      </div>
    );
  }
  if (!loading && !success) {
    return (
      <div className="IntegrationLoading">
        <div className="CenterContent">
          <p>
            Unfortunately something went wrong with your account integration,
            Please try again.
          </p>
          <div
            onClick={() => {
              window.close();
            }}
            className="Btn"
          >
            Close this window
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default IntegrationLoading;
