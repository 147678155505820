import React from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import RowInput from "../../SignInComponents/RowInput/RowInput";
//import { phoneNumberRegex } from "../../../helpers/regex";
import { CardElement } from "react-stripe-elements";
import { useDispatch } from "react-redux";
import { DropdownField } from "../../FormComponents/Dropdown/Dropdown";
import { isValidPhoneNumber } from "react-phone-number-input";
import Spinner from "react-spinkit";
import { PhoneNumberField } from "components/FormComponents/PhoneNumber/PhoneNumber";

type Data = {
  details: ExistingCardForm;
};

type CountryObject = {
  country_id: number;
  country_name: string;
};

type ExistingCardForm = {
  title: string;
  first_name: string;
  last_name: string;
  //email_address: string,
  phone_number: string;
  address_line_1: string;
  address_line_2: string;
  town_city: string;
  county_state: string;
  //postcode: string,
  country_id: number;
  list_countries: CountryObject[];
};

interface PaymentCardAddFormInnerProps {
  showCVV?: boolean;
  data?: Data;
  stripe?: any;
  countryList?: any;
  isSubmitting?: boolean;
  handleSubmit: () => void;
  saveCard?: boolean;
}

interface PaymentCardAddFormProps {
  showCVV?: boolean;
  submit: (values: any, countryName: string, token: any) => void;
  stripe?: any;
  data?: Data;
  saveCard?: boolean;
  ref?: any;
}

const validationSchema = Yup.object({
  card_name: Yup.string().required("Name on card is required"),
  //card_number: Yup.number().required("Card number is required").test('len', 'Card number must be 16 characters', val => val && val.toString().length === 16),
  //card_number: Yup.string(),//.matches(/^[0-9 ]*$/, "Please enter a valid card number").required("Card number is required"),
  //expiry_date: Yup.date(),//.required("Expiry date is required"),
  card_details: Yup.object()
    .shape({
      brand: Yup.string(),
      complete: Yup.boolean().oneOf(
        [true],
        "Card details must all be completed"
      ),
      elementType: Yup.string(),
      empty: Yup.boolean().oneOf([true], "Card details must all be completed"),
      error: Yup.mixed(),
      value: Yup.object(),
    })
    .required("Card details are required"),
  title: Yup.string(),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  phone_number: Yup.mixed()
    .required("A telephone number is required")
    .test(
      "phoneTest",
      "Please enter a valid telephone number",
      (value) => {
        if (!value) return false
        return isValidPhoneNumber(value)
      },
    ),
  //email_address: Yup.string().required("Email address is required"),
  //phone_number: Yup.string(),
  //email_address: Yup.string(),
  address_line_1: Yup.string().required("Address is required"),
  address_line_2: Yup.string(),
  city_town: Yup.string().required("City/town is required"),
  county_state: Yup.string().required("County/state is required"),
  //postcode: Yup.string().required("Postcode is required"),
  country_id: Yup.string().required("Country is required"),
  b_save_card: Yup.boolean(),
});

const validationSchemaWithCVV = Yup.object({
  card_name: Yup.string().required("Name on card is required"),
  //card_number: Yup.string().matches(/([0-9])/g).min(16, "Must be 16 digits").max(16, "Must be 16 digits").required("Card number is required"),
  card_number: Yup.string(), //.matches(/^[0-9 ]*$/, "Please enter a valid card number").required("Card number is required"),
  //expiry_date: Yup.date(),//.required("Expiry date is required"),
  cvv: Yup.number().test(
    "len",
    "CVV must be 3 characters",
    (val) => {
      if (val && val.toString().length === 3) return true
      return false
    }
  ),
  title: Yup.string(),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  phone_number: Yup.mixed()
    .required("A telephone number is required")
    .test(
      "phoneTest",
      "Please enter a valid telephone number",
      value => !value || isValidPhoneNumber(value),
    ),
  //phone_number: Yup.string().matches(phoneNumberRegex, "Please enter a valid phone number"),
  //email_address: Yup.string().required("Email address is required"),
  //phone_number: Yup.string(),
  //email_address: Yup.string(),
  address_line_1: Yup.string().required("Address is required"),
  address_line_2: Yup.string(),
  city_town: Yup.string().required("City/town is required"),
  county_state: Yup.string(),
  //postcode: Yup.string().required("Postcode is required"),
  country_id: Yup.string().required("Country is required"),
});

const PaymentCardAddFormInner = (props: PaymentCardAddFormInnerProps) => {
  const dispatch = useDispatch();

  return (
    <>
      <Form className="PaymentCardAddForm">
        <div className="FormBox">
          <RowInput
            type="card_name"
            name="card_name"
            id="card_name"
            placeholder="Name on card"
            maxLength={200}
          />

          <ErrorMessage name="card_name">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>

        <div className="FormBox">
          <div className="FormSideBySide">
            <div className="FormLabel">
              <label htmlFor="card_details">Card details</label>
            </div>
            <div className="FormField">
              <Field>
                {({ field, form }: any) => (
                  <CardElement
                    className={`EditBox`}
                    id={"card_details"}
                    onChange={val =>
                      form.setErrors({
                        ...form.errors,
                        card_details: val.error && val.error.message,
                      })
                    }
                  />
                )}
              </Field>
              <ErrorMessage name="card_details">
                {message => <span className="ErrorMessageLeft">{message}</span>}
              </ErrorMessage>
            </div>
          </div>
        </div>

        {/*<div className="FormBox FormSideBySide ExpiryDate">
          <CalendarDatePickerField
            name="expiry_date"
            label="expiry_date"
            placeholder="Expiry date"
            excludeDay
            inputPlaceholderText="MM/YY"
            rowDisplay
          />
          <ErrorMessage name="expiry_date">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>

        {
          props.showCVV &&
          <div className="FormBox FormSideBySide CVV">
            <RowInput
              name="cvv"
              id="cvv"
              placeholder="CVV"
            />
            <ErrorMessage name="cvv">
              {message => <span className="ErrorMessage">{message}</span>}
            </ErrorMessage>
          </div>
        }
        */}

        <h3>Billing Address</h3>

        <div className="FormBox">
          <RowInput
            type="title"
            name="title"
            id="title"
            placeholder="Title"
            optional
            maxLength={10}
          />
          <ErrorMessage name="title">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>

        <div className="FormBox">
          <RowInput
            type="first_name"
            name="first_name"
            id="first_name"
            placeholder="First name"
            maxLength={50}
          />
          <ErrorMessage name="first_name">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>

        <div className="FormBox">
          <RowInput
            type="last_name"
            name="last_name"
            id="last_name"
            placeholder="Last name"
            maxLength={50}
          />
          <ErrorMessage name="last_name">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>
        <div className="FormBox">
          <div className="FormSideBySide">
            <PhoneNumberField name={"phone_number"} label={"Phone number"} />
            <ErrorMessage name="phone_number">
              {message => <span className="ErrorMessage">{message}</span>}
            </ErrorMessage>
          </div>
        </div>
        {/*


        <div className="FormBox">
          <RowInput
            type="email_address"
            name="email_address"
            id="email_address"
            placeholder="Email address"
            maxLength={200}
          />
          <ErrorMessage name="email_address">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>
        */}
        <div className="FormBox">
          <RowInput
            type="address_line_1"
            name="address_line_1"
            id="address_line_1"
            placeholder="Address line 1"
            maxLength={50}
          />
          <ErrorMessage name="address_line_1">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>

        <div className="FormBox">
          <RowInput
            type="address_line_2"
            name="address_line_2"
            id="address_line_2"
            placeholder="Address line 2"
            optional={true}
            maxLength={50}
          />
          <ErrorMessage name="address_line_2">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>

        <div className="FormBox">
          <RowInput
            type="city_town"
            name="city_town"
            id="city_town"
            placeholder="City/town"
            maxLength={50}
          />
          <ErrorMessage name="city_town">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>

        <div className="FormBox">
          <RowInput
            type="county_state"
            name="county_state"
            id="county_state"
            placeholder="County/state"
            maxLength={50}
          />
          <ErrorMessage name="county_state">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>
        {/*
        <div className="FormBox">
          <RowInput
            type="postcode"
            name="postcode"
            id="postcode"
            placeholder="Postcode"
          />
          <ErrorMessage name="postcode">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>

        <div className="FormBox">
          <RowInput
            type="country"
            name="country"
            id="country"
            placeholder="Country"
          />
          <ErrorMessage name="country">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>*/}
        {props.countryList && (
          <div className="FormBox">
            <DropdownField
              name="country_id"
              label="Country"
              errors={[]}
              sideBySide
            >
              {props.countryList}
            </DropdownField>
            <ErrorMessage name="postcode">
              {message => <span className="ErrorMessageLeft">{message}</span>}
            </ErrorMessage>
          </div>
        )}
        {props.saveCard && (
          <div className="RememberForgot">
            <div className="Checkbox">
              <Field
                name="bSaveCard"
                type="checkbox"
                id="bSaveCard"
                // checked={save}
                // onChange={() => setSave(!save)}
              />
              <label className="CustomInput" htmlFor="bSaveCard">
                Save card
              </label>
            </div>
          </div>
        )}
      </Form>
      {!props.saveCard && (
        <div className="Bottom">
          <button
            className="Btn BtnWhite"
            onClick={() => dispatch({ type: "CLOSE_RIGHT_POP_OUT" })}
          >
            Cancel
          </button>
          <button
            className={`Btn`}
            type="submit"
            onClick={props.handleSubmit}
            disabled={props.isSubmitting}
          >
            {props.isSubmitting ? (
              <Spinner
                name="three-bounce"
                className="BtnSpinner BtnSpinnerSmall"
                color="white"
                fadeIn="none"
              />
            ) : (
              "Add"
            )}
          </button>
        </div>
      )}
    </>
  );
};

const PaymentCardAddForm = React.forwardRef(
  (props: PaymentCardAddFormProps, ref: any) => {
    const stripeToken = async (
      values: any,
      setFieldError: (label: string, message: string) => void,
      setSubmitting: (status: boolean) => void
    ) => {
      let { token, error } = await props.stripe.createToken({
        name: values.card_name,
      });

      let countryIdObj = props.data!.details.list_countries.find(
        c => c.country_id === parseInt(values.country_id)
      );
      let countryName = countryIdObj ? countryIdObj.country_name : "";
      if (token) {
        props.submit(values, countryName, token);
      } else if (error) {
        setFieldError("card_details", error.message);
        setSubmitting(false);
      }
    };

    if (props.data) {
      const initialValues = {
        card_name: "",
        card_details: {},
        //expiry_date: null,
        title: props.data.details.title,
        first_name: props.data.details.first_name,
        last_name: props.data.details.last_name,
        phone_number: "0000",
        //email_address: props.data.details.email_address,
        address_line_1: props.data.details.address_line_1,
        address_line_2: props.data.details.address_line_2,
        city_town: props.data.details.town_city,
        county_state: props.data.details.county_state,
        //postcode: props.data.details.postcode,
        country_id: props.data.details.country_id,
      };

      return (
        <Formik
          ref={ref}
          initialValues={initialValues}
          //isInitialValid={false}
          validationSchema={validationSchema}
          onSubmit={(values, { setFieldError, setSubmitting }) => {
            //props.submit(values);
            stripeToken(values, setFieldError, setSubmitting);
          }}
          render={formik => (
            <>
              <PaymentCardAddFormInner
                showCVV={props.showCVV}
                data={props.data}
                countryList={props.data!.details.list_countries.map(c => ({
                  label: c.country_name,
                  key: c.country_id,
                }))}
                isSubmitting={formik.isSubmitting}
                saveCard={props.saveCard}
                handleSubmit={formik.handleSubmit}
              />
            </>
          )}
        />
      );
    }

    const initialValues = {
      card_name: "",
      //card_number: "",
      card_details: null,
      //expiry_date: null,
      title: "",
      first_name: "",
      last_name: "",
      //phone_number: "",
      //email_address: "",
      address_line_1: "",
      address_line_2: "",
      city_town: "",
      county_state: "",
      //postcode: "",
      country_id: null,
      b_save_card: false,
    };

    const initialValuesWithCVV = {
      card_name: "",
      card_number: "",
      expiry_date: null,
      cvv: null,
      title: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      //email_address: "",
      address_line_1: "",
      address_line_2: "",
      city_town: "",
      county_state: "",
      //postcode: "",
      country_id: null,
    };

    return (
      <Formik
        ref={ref}
        initialValues={props.showCVV ? initialValuesWithCVV : initialValues}
        validationSchema={
          props.showCVV ? validationSchemaWithCVV : validationSchema
        }
        onSubmit={(values, { setFieldError, setSubmitting }) => {
          //props.submit(values);
          stripeToken(values, setFieldError, setSubmitting);
        }}
        render={({ isValid, values, submitForm, handleSubmit }) => (
          <PaymentCardAddFormInner
            showCVV={props.showCVV}
            saveCard={props.saveCard}
            handleSubmit={handleSubmit}
          />
        )}
      />
    );
  }
);

export default PaymentCardAddForm;
