import React from "react";
import { RouteComponentProps } from "@reach/router";
import Logo from "assets/images/Logo_Light.png";
import { Link } from "@reach/router";
import { useSelector } from "react-redux";
import { AppState } from "../../../reducers/rootReducer";
import { RegistrationStepActions } from "pages/Registration/Registration";

export interface PreLoginPageStructureProps {
  children: string | JSX.Element | (string | JSX.Element)[];
  showAsideButtons?: boolean;
  isAuthenticated?: boolean;
}

const PreLoginPageStructure = (props: PreLoginPageStructureProps & RouteComponentProps) => {
  //const { children } = props;

  //React.useEffect(() => {
  //  window.scrollTo(0, 0);
  //}, [children])

  const leftText = useSelector((state: AppState) => state.pageReducer.leftPanelReducer)
  ///*<h2 className="SignInAsideTitle" dangerouslySetInnerHTML={{ __html: leftText }} style={{whiteSpace: 'pre-line'}}>{/*Fast, Simple <br /> Field Scouting <br /> With Your Drone*/}</h2>*/}
  return (
    <>
      <div className="SignInPageAside">
        <img className="SignInPageLogo" src={Logo} alt="SkippyScout" />
        <div className="SignInAsideTagline">

          <h2 className="SignInAsideTitle" dangerouslySetInnerHTML={{ __html: leftText }} style={{ whiteSpace: 'pre-line' }}></h2>
        </div>
        {
          props.showAsideButtons &&
          <div className="SignInAsideButtons">
            <Link to="/" className="Btn BtnTransparent BtnFullWidth">
              Log in
              </Link>
            <Link to="/register" className="Btn BtnFullWidth">
              Register
              </Link>
          </div>
        }
        {
          !props.showAsideButtons && <div className="SignInAsideButtons"></div>
        }
      </div>
      <div className="SignInPageContainer">

        <div className="SignInFormSection">
          {props.children}
          {/*<Footer />*/}
        </div>
      </div>
    </>
  );
};

export default PreLoginPageStructure;
