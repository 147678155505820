import React from "react"
import { FieldProps, Field } from "formik";
import { format }  from "date-fns";
import DatePicker from "react-datepicker";

export type Operator =
  | "EQ"
  | "NEQ"
  | "SW"
  | "EW"
  | "CONTAINS"
  | "GT"
  | "GEQ"
  | "LT"
  | "LEQ"
  | "INSTRINGARRAY"
  | "BETWEEN";
export type Filter =
  | {
    columnKey: string;
    operator: Omit<Operator, "INSTRINGARRAY">;
    value: string;
  }
  | {
    columnKey: string;
    operator: "INSTRINGARRAY";
    value: string[];
  };
export type Filters = {
  columnKey: string;
  filters: Filter[];
}[];

export function DateTimeRangeField(props: { name: string, label: string }) {
  return (
    <Field name={props.name}>
      {
        (field: any) => <DateTimeRange name={props.name} label={props.label} {...field} />  
      }
      </Field>
  )
}

function DateTimeRange(props: FieldProps & {
  name: string;
  label: string;
}) {

  const { filters } = props.field.value || { filters: [] };
  const value = filters[0] ? filters[0].value : "";
  let values = value.split("TO");
  if (values.length !== 2) values = ["NULL", "NULL"];

  function handleChangeFirst(date: Date | null) {
    const filters: Filter[] = [];
    const formattedDate = date ? format(date, "yyyy/MM/dd") : "NULL";
    filters.push({
      columnKey: props.name,
      operator: "BETWEEN",
      value: `${formattedDate}TO${values[1]}`,
    });
    props.form.setFieldValue(props.field.name, {
      columnKey: props.name,
      filters,
    });
  }

  function handleChangeSecond(date: Date | null) {
    const filters: Filter[] = [];

    const formattedDate = date ? (format(date, "yyyy/MM/dd")) : "NULL";
    filters.push({
      columnKey: props.name,
      operator: "BETWEEN",
      value: `${values[0]}TO${formattedDate}`,
    });
    props.form.setFieldValue(props.field.name, {
      columnKey: props.name,
      filters,
    });
  }

  const startDate = values[0] === "NULL" ? null : new Date(values[0]);
  const endDate = values[1] === "NULL" ? null : new Date(values[1]);

  return (
    <>
      <div className="FormLabel FormLabelFilters">
        <label>{props.label}</label>
      </div>
      <div className="FormField FormFieldFilters DatePickerOne">
        <DatePicker
          className="EditBox"
          placeholderText="Starting at..."
          selected={startDate}
          onChange={handleChangeFirst}
          startDate={startDate}
          endDate={endDate}
          dateFormat={"dd/MM/yyyy"}
        />
      </div>
      <div className="FormField FormFieldFilters DatePickerTwo">
        <DatePicker
          className="EditBox"
          placeholderText="Ending at..."
          selected={endDate}
          onChange={handleChangeSecond}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          dateFormat={"dd/MM/yyyy"}
        />
      </div>
    </>
  );
}
