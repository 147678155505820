import { useState, useEffect, useRef } from "react";
import api from "api";
import { endpoints } from "serverDetails";

// Custom hook for fetching fields
export function useFetchFields(
  FieldIds: { fieldID: number }[],
  FarmsId: number,
  Crops: {
    cropName: string;
  }[],
  SortBy: number,
  DateRange: { DateFrom: string; DateTo: string }[],
  SortDirection: string,
  PageNumber: string,
  PageSize: string,
  FilterChanged: boolean,
  setDateFilter: React.Dispatch<React.SetStateAction<string[]>>
) {
  // State variables for fields data, loading status and error
  const [fields, setFields] = useState([]);
  const [validFieldList, setValidFieldList] = useState([]);
  const [validCropTypeList, setValidCropTypeList] = useState([]);
  const [validDateList, setValidDateList] = useState([]);
  const [validSortByList, setValidSortByList] = useState<
    { name: string; value: string; id: number }[]
  >([]);
  const [noResults, setNoResults] = useState(false);
  const [resultsCount, setResultsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const timeoutRef = useRef<number>();

  // Effect hook for fetching fields when parameters change
  useEffect(() => {
    // Set loading status to true

    let sortByValue = "Alphabetically";
    if (validSortByList && validSortByList.length > 0) {
      const matchingSort = validSortByList.find(({ id }) => id === SortBy);
      if (matchingSort) {
        sortByValue = matchingSort.value;
      }
    }

    const payload = {
      FieldIds,
      FarmsId,
      Crops,
      DateRange,
      SortBy: sortByValue,
      SortDirection,
      PageNumber,
      PageSize,
      setDateFilter,
    };

    async function fetchData() {
      setLoading(true);

      try {
        const response = await api.post(
          endpoints.reportsModule.getReportsPageInfo,
          payload
        );
        if (
          response &&
          response.data &&
          response.data.details &&
          response.data.details.ReportInfo
        ) {
          setNoResults(false);

          setFields(response.data.details.ReportInfo);
          setValidFieldList(
            response.data.details.FieldNameFilterList.map(
              ({
                FieldsId,
                FieldName,
              }: {
                FieldsId: number;
                FieldName: string;
              }) => ({ id: FieldsId, name: FieldName })
            )
          );
          setValidCropTypeList(
            response.data.details.CropTypeFilterList.map(
              ({ CropId, CropName }: { CropId: number; CropName: string }) => ({
                id: CropId,
                name: CropName,
              })
            )
          );
          setValidDateList(
            response.data.details.FlightDateFilterList.map(
              ({ FlightDate }: { FlightsId: number; FlightDate: string }) =>
                new Date(`${FlightDate}`)
            )
          );
          setValidSortByList(
            response.data.details.SortByList.map(
              (
                { Label, Value }: { Label: string; Value: string },
                idx: number
              ) => ({
                name: Label,
                value: Value,
                id: idx,
              })
            )
          );
          setResultsCount(response.data.details.ResultsCount);
        } else {
          setNoResults(true);
        }

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }

    if (FarmsId) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => {
        fetchData();
      }, 150);
    }
  }, [FilterChanged, FarmsId]);

  return {
    fields,
    validFieldList,
    validCropTypeList,
    validDateList,
    validSortByList,
    resultsCount,
    noResults,
    loading,
    error,
  };
}
