import React from "react";
import { RouteComponentProps } from "@reach/router";
import { endpoints } from "serverDetails";
import { useFetch } from "helpers/loading";
import { CMSGeneratedContent } from "pages/CMSGeneratedContentPage/CMSGeneratedContentPage";
import { useSelector } from "react-redux";
import { selectAddons } from "reducers/register";

type SummaryPageProps = {
  values: any;
  promoCodeType: any;
  promoCodeValue: any;
  fullDiscount: any;
  errorType: any;
  displayError: any;
  isStepComplete: any;
  setCurrentRegistrationStep: any;
  personalDetails: any;
  usersPromoCode: any;
  promoCodeVisible: any;
  subscriptionName: any;
  subscriptionPrice: any;
  setValidationSchema: any;
  subscriptionGross: any;
  disableCardInput: any;
  personalInfoValidationSchema: any;
  paymentValidationSchema: any;
  paymentDetails: any;
  setOnLastStep: any;
  setActivePage: any;
  billingDetails: any;
};

const SummaryPage = (props: RouteComponentProps & SummaryPageProps) => {
  const {
    fullDiscount,
    promoCodeType,
    promoCodeValue,
    errorType,
    displayError,
    isStepComplete,
    setCurrentRegistrationStep,
    personalDetails,
    usersPromoCode,
    promoCodeVisible,
    personalInfoValidationSchema,
    paymentValidationSchema,
    subscriptionName,
    subscriptionPrice,
    subscriptionGross,
    disableCardInput,
    paymentDetails,
    setValidationSchema,
    setOnLastStep,
    setActivePage,
    billingDetails,
  } = props;
  // if (alreadyHasAccount === null || !personalDetails) {
  //   return <Redirect to="/" noThrow={true} />;
  // }

  const selectedAddons = useSelector(selectAddons);
  const { data } = useFetch(endpoints.register.registerDetails);

  const selectedTier =
    data && data.details && Array.isArray(data.details.subscription_list)
      ? data.details.subscription_list.find((item: any) => {
          return item.subscription_name === subscriptionName;
        })
      : null;
  const netPrice =
    selectedTier && selectedTier.yearly_price
      ? selectedTier.yearly_price
      : selectedTier && selectedTier.monthly_price
      ? selectedTier.monthly_price
      : 0;

  const finalPrice = (
    grossPrice: number,
    promoValue: number | null,
    typeOfDiscount: string | null
  ) => {
    let fullDiscountPrice = 0;
    if (!fullDiscount) {
      if (netPrice) {
        let tax = data.details.tax_rate ? data.details.tax_rate : 0;
        if (typeOfDiscount === "Percentage_Off" && promoValue) {
          let total =
            (netPrice - netPrice * (promoValue / 100)) * (1 + tax / 100);
          return total > 0 ? total : 0;
        } else if (typeOfDiscount === "Amount_Off" && promoValue) {
          let amountOffFinal = (netPrice - promoValue) * (1 + tax / 100);
          return amountOffFinal > 0 ? amountOffFinal : 0;
        }
        return !tax ? netPrice : grossPrice;
      }
    }
    return fullDiscountPrice; // hard code final price due to not having access to country_tax due to them not supplying billing address info
  };

  let promoValueToDisplay;
  if (promoCodeType === "Percentage_Off") {
    promoValueToDisplay = `-${promoCodeValue}%`;
  } else if (promoCodeType === "Amount_Off") {
    promoValueToDisplay = `-${String.fromCharCode(0x00a3)}${promoCodeValue}`;
  } else {
    promoValueToDisplay = null;
  }

  return (
    <>
      <div className="Register Summary FormBox">
        <h3 className="Uppercase">Summary</h3>
        {errorType === "General" && (
          <div className="ErrorMessage ErrorMessageCenter">{displayError}</div>
        )}
        <div className="FlexContainerNoMargin">
          <h4>Personal Information</h4>
          {/* {isStepComplete("personalInfo") && (
          <div
            className="Btn BtnCopy"
            onClick={() => {
              // setOnLastStep(true);
              // setActivePage("PersonalInfo");
              setCurrentRegistrationStep("personalInfo");
              setValidationSchema(personalInfoValidationSchema);
              window.scrollTo(0, 0);

            }}
          >
            Edit
          </div>
        )} */}
        </div>
        {isStepComplete("personalInfo") && personalDetails && (
          <div className="Details">
            <p>
              {personalDetails!.first_name} {personalDetails!.last_name}
            </p>
            <p>{personalDetails!.company_name}</p>
            {personalDetails!.vat_number && (
              <p>VAT number: {personalDetails!.vat_number}</p>
            )}
            <p>{personalDetails!.email_address}</p>
            {errorType === "Personal" && (
              <div className="ErrorMessageLeft">{displayError}</div>
            )}
          </div>
        )}
        <div className="FlexContainerNoMargin">
          <h4>Subscription</h4>
          {/* {isStepComplete("subscription") && (
          <div
            className="Btn BtnCopy"
            onClick={() => {
              setCurrentRegistrationStep("subscription");
              setValidationSchema(paymentValidationSchema);
              window.scrollTo(0, 0)
            }}
          >
            Edit
          </div>
        )} */}
        </div>
        {isStepComplete("subscription") && (
          <div className="Details">
            {isStepComplete("subscription") && (
              <div className="SubscriptionSummary">
                <p>{subscriptionName}</p>
                <p>{subscriptionPrice}</p>
              </div>
            )}
            {usersPromoCode && promoCodeVisible && (
              <div className="SubscriptionSummary">
                <p>Promo code: </p>
                <p>{usersPromoCode}</p>
                <p className="PromoCodeValue">{promoValueToDisplay}</p>
              </div>
            )}
            {isStepComplete("subscription") && subscriptionPrice !== "Free" && (
              <div className="SubscriptionSummary">
                <p className="Bold">Final price after tax: </p>
                <p className="Bold">
                  {`${String.fromCharCode(0x00a3)}`}
                  {finalPrice(subscriptionGross, promoCodeValue, promoCodeType)}
                </p>
              </div>
            )}
          </div>
        )}
        {isStepComplete("addons") && selectedAddons.length > 0 ? (
          <>
            <div className="FlexContainerNoMargin">
              <h4>Add-ons</h4>
            </div>
            <div className="Details">
              {selectedAddons.map(({ gross_price, addon_name, price }) => (
                <div className="SubscriptionSummary">
                  <p>{addon_name}</p>
                  <p>{`${String.fromCharCode(0x00a3)}${price} (+VAT)`}</p>
                </div>
              ))}
              <div className="SubscriptionSummary">
                <p className="Bold">Final price after tax: </p>
                <p className="Bold">
                  {`${String.fromCharCode(0x00a3)}`}
                  {selectedAddons
                    .map(({ gross_price }) => gross_price)
                    .reduce((partialSum, price) => partialSum + price, 0)}
                </p>
              </div>
            </div>
            <div className="FlexContainerNoMargin">
              <h4>Total</h4>
            </div>
            <div className="Details">
              <div className="SubscriptionSummary">
                <p className="Bold">Price after tax</p>
                {/* Add subscription total price to add-ons total price */}
                <p className="Bold">{`${String.fromCharCode(
                  0x00a3
                )}${finalPrice(
                  subscriptionGross,
                  promoCodeValue,
                  promoCodeType
                ) +
                  selectedAddons
                    .map(({ gross_price }) => gross_price)
                    .reduce((partialSum, price) => partialSum + price, 0)}`}</p>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        <div className="FlexContainerNoMargin">
          <h4>Payment</h4>
        </div>
        {!disableCardInput && paymentDetails && billingDetails ? (
          <>
            <div className="Details">
              <p>{paymentDetails!.card_name}</p>
              <p>**** **** **** {paymentDetails!.card_number}</p>

              <p>
                Expires {paymentDetails!.expire_month}/
                {paymentDetails!.expire_year}
              </p>
              {errorType === "Card" && (
                <div className="ErrorMessageLeft">{displayError}</div>
              )}
            </div>
            <div className="FlexContainerNoMargin">
              <h3 className="Uppercase">Billing Address</h3>
              <div
                className="Btn BtnCopy"
                onClick={() => {
                  setOnLastStep(true);
                  // setActivePage("BillingAddress");
                  // props.navigate && props.navigate("/register/billing-address");
                }}
              >
                Edit
              </div>
            </div>
            <div className="Details">
              <p>
                {billingDetails!.title && billingDetails!.title}{" "}
                {billingDetails!.first_name} {billingDetails!.last_name}
              </p>
              <p>
                {billingDetails!.address_line_1},{" "}
                {billingDetails!.address_line_2 &&
                  billingDetails!.address_line_2}
              </p>
              <p>
                {billingDetails!.town_city},{" "}
                {billingDetails!.county_state && billingDetails!.county_state}
              </p>
              <p>{billingDetails!.postcode}</p>
              <p>{billingDetails!.country}</p>
            </div>{" "}
          </>
        ) : (
          <> </>
        )}
      </div>
      <div className="CMSTextField">
        <CMSGeneratedContent />
      </div>
    </>
  );
};

export { SummaryPage };
