import React from 'react';
import PaymentCardForm from '../../components/SignInComponents/PaymentCardForm/PaymentCardForm';
import { RouteComponentProps, Redirect } from '@reach/router';
import { RegistrationSteps } from './Registration';
import { injectStripe } from 'react-stripe-elements';

export interface PaymentCardProps {
  setPaymentDetails: (val: any) => void;
  alreadyHasAccount: boolean | null;
  setActivePage: (val: string) => void;
  onLastStep: boolean;
  setDisplayError: (v: string) => void;
  promoCodeValue: number;
  isSubmitting: boolean;
  promoCodeVisible: boolean;
  promoCodeType: string;
  subscriptionGross?: number;
  fullDiscount?: boolean;
  usersPromoCode?: string;
  setFullDiscount: (val: boolean) => void;
  disableCardInput?: boolean;
  setDisableCardInput: React.Dispatch<React.SetStateAction<boolean>>;
  registrationStep?: RegistrationSteps;
  skipPaymentOption: boolean;
  setRegistrationStep?: React.Dispatch<React.SetStateAction<RegistrationSteps>>;
  submit: () => void;
  setValidationSchema?: (val: any) => void;
  paymentValidationSchema?: any;
  validationSchema?: any;
  noPaymentDetailsValidationSchema?: any;
  submitError: string;
}

const StripeRegistration = (props: PaymentCardProps & RouteComponentProps) => {
  // if (props.alreadyHasAccount === null) {
  //   return <Redirect to="/registration" noThrow={true} />;
  // }
  return (
    <div className="Register">
      <div className="FlexContainer FlexContainerSpace">
        <h1>Payment card</h1>
        {/* <p className="StepCount">Step 3/4</p> */}
      </div>
      <PaymentCardForm
        promoCodeValue={props.promoCodeValue}
        promoCodeVisible={props.promoCodeVisible}
        promoCodeType={props.promoCodeType}
        subscriptionGross={props.subscriptionGross}
        isSubmitting={props.isSubmitting}
        fullDiscount={props.fullDiscount}
        setFullDiscount={props.setFullDiscount}
        disableCardInput={props.disableCardInput}
        submitError={props.submitError}
        setDisableCardInput={props.setDisableCardInput}
        submit={props.submit}
        skipPaymentOption={props.skipPaymentOption}
        setValidationSchema={props.setValidationSchema}
        validationSchema={props.validationSchema}
        paymentValidationSchema={props.paymentValidationSchema}
        noPaymentDetailsValidationSchema={props.noPaymentDetailsValidationSchema}
      />
    </div>
  )
}


export default injectStripe(StripeRegistration)