import * as React from "react";
import Select from "react-select";
import { FieldProps, Field } from "formik";
import ValidationError, {
  ServerError,
} from "../../FormComponents/ValidationError/ValidationError";

export type SelectOptionItem = {
  value: number | string,
  label: string,
}

export interface SelectInputProps {
  options: SelectOptionItem[];
  name: string;
  label?: string;
  placeholder: string
}

export function SelectInput(
  props: FieldProps & {
    label?: string;
    errors: ServerError[];
    options: SelectOptionItem[];
    placeholder: string;
    name: string
  }
) {
  const { field, form, options, placeholder, name } = props;

  //const setValue = (e: any) => {
  //  console.log(e)
  //  form.setFieldValue(field.name, e.value);
  //}

  const [selectedOption, setSelectOption] = React.useState(null);

  const handleChange = (e: any) => {
    setSelectOption(e);
    form.setFieldValue(field.name, e.value);
  };


  return (
    <>
      <div data-testid="container" className={'FormBox'}>
        {
          props.label &&
          <div className="FormLabel">
            <label data-testid="thelabel" htmlFor={props.field.name}>
              {props.label}
            </label>
          </div>
        }
        
        <div className={"FormField"}>
          <Select
            value={selectedOption}
            onChange={handleChange}
            options={options}
            className="CustomSelect"
            classNamePrefix="react-select"
            hideSelectedOptions={true}
            placeholder={placeholder}
            name={name}
          />
        </div>
      </div>
      <ValidationError name={props.field.name} errors={props.errors} />
    </>
  );
}

export function SelectInputField(props: {
    label?: string;
    errors: ServerError[];
    options: SelectOptionItem[];
    placeholder: string;
    name: string
}) {
  const { label, name, placeholder, errors, options } = props;
  return (
    <Field
      name={name}
      render={(rprops: FieldProps) => (
        <SelectInput
          {...rprops}
          label={label}
          errors={errors}
          options={options}
          placeholder={placeholder}
          name={name}
        />
      )}
    />
  );
}
