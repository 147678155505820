import { connect } from "react-redux";
import { RouteComponentProps } from "@reach/router";
import compose from "recompose/compose";
import { AppState } from "../../../reducers/rootReducer";
import {
  selectAuthStatus,
  selectIsAuthenticated,
  selectAuthErrors,
  selectAccountType,
} from "reducers/auth";
import {
  signIn,
  getAccountTypeDetails,
  loadAuthState,
  tfaSignIn,
} from "reducers/auth";

import { Credentials } from "../../../types/AuthTypes";
import { Auth_login_tfa } from "../../../types/ApiSubmissionTypes";

import SignIn, { SignInProps } from "./SignIn";

export const mapStateToProps = (state: AppState) => {
  return {
    status: selectAuthStatus(state),
    authenticated: selectIsAuthenticated(state),
    errors: selectAuthErrors(state),
    accountType: selectAccountType(),
  };
};

export const mapDispatchToProps = (dispatch: (x: any) => void) => {
  return {
    checkAuth: () => {
      dispatch(loadAuthState());
    },
    signIn: (values: Credentials) => {
      dispatch(signIn(values));
    },
    tfaSignIn: (values: Auth_login_tfa) => {
      dispatch(tfaSignIn(values));
    },
    getAccountType: () => {
      dispatch(getAccountTypeDetails());
    },
  };
};

export default compose<SignInProps & RouteComponentProps, {}>(
  connect(mapStateToProps, mapDispatchToProps)
)(SignIn);
