import React from "react";
import { Field } from "formik";

interface ChunkyInputInterface {
  name: string;
  placeholder?: string;
  type?: string;
  id?: string;
  icon?: string;
  optional?: boolean;
  autoComplete?: string;
  maxLength?: number;
  disabled?: boolean;
  inputPlaceholder?: string;
  required?: boolean;
}

const chunkyInput = (props: ChunkyInputInterface) => {
  return (
    <div>
      <div className={`FormLabel ${props.disabled ? "Disabled" : ""}`}>
        <label htmlFor={props.name} className="InputContainerLabels">
          {props.placeholder}{" "}
          {props.optional && <span className="Optional">- optional</span>}
        </label>
        {props.required && <span className="Required">*</span>}
      </div>
      <div className="FormField">
        <Field
          className={`EditBox ${!props.icon ? "ChunkyInputNoIcon" : ""} ${
            props.disabled ? "Disabled" : ""
          }`}
          name={props.name}
          type={props.type ? props.type : "text"}
          id={props.id}
          autoComplete={props.autoComplete}
          maxLength={props.maxLength}
          disabled={props.disabled}
          placeholder={props.inputPlaceholder}
        />
        {props.icon && (
          <img
            src={process.env.PUBLIC_URL + props.icon}
            alt={props.name}
            className="InputLabelLogos"
          />
        )}
      </div>
    </div>
  );
};

export default chunkyInput;
