import React from "react";
import { Item } from "./item";
import { Transition } from "react-transition-group";

export type RightMenuProps = {
  //selectedUserIds?: number[]; //these exist in redux state
  //selectedCardIds?: number[];
  //selectedTransactionIds?: number[];
  visible: boolean;
  menuItems: RightMenuItem[];
  alternateMenuItems: AlternateRightMenuItems;
  toggleAltMenuItems: boolean;
};

export type RightMenuItem = {
  icon: string;
  name: string;
  imgAlt: string;
  onClick(): void;
  hidden?: boolean;
};

export type RightMenuItems = {
  menuItems: RightMenuItem[];
  alternateMenuItems: AlternateRightMenuItems;
};

export type AlternateRightMenuItems = {
  menuItems: RightMenuItem[];
};

type Props = RightMenuProps;

const RightMenu: React.FC<Props> = ({
  //selectedUserIds,
  //selectedCardIds,
  //selectedTransactionIds,
  visible,
  menuItems,
  alternateMenuItems,
  toggleAltMenuItems
}) => {
  const MenuItems = () => {
    if (toggleAltMenuItems) {
      return (
        <>
          {alternateMenuItems.menuItems.map((item, key) => (
            <Item
              key={key}
              onClick={item.onClick}
              icon={item.icon}
              name={item.name}
              imgAlt={item.imgAlt}
            />
          ))}
        </>
      );
    } else {
      return (
        <>
          {menuItems.filter(item => !item.hidden).map((item, key) => (
            <Item
              key={key}
              onClick={item.onClick}
              icon={item.icon}
              name={item.name}
              imgAlt={item.imgAlt}
            />
          ))}
        </>
      );
    }
  };

  return (
    <Transition in={visible} timeout={0} mountOnEnter>
      {transitionState => (
        <div className={"RightMenu " + transitionState}>
          <div className="ItemsContainer">
            <MenuItems />
          </div>
        </div>
      )}
    </Transition>
  );
};

export { RightMenu };
