import * as React from "react";
import Helmet from "react-helmet";
import { ScoutPointPagesProps } from "./models";
import ScoutPointPage from "./ScoutPointPage";

const ScoutPointPages: React.FC<ScoutPointPagesProps> = ({
  data,
  isKrpano,
  isPdf,
  isCardReport,
  loadStatus,
  setLoadStatus,
}) => {
  return (
    <>
      <Helmet>
        <title>Skippy Scout: Invoice</title>
      </Helmet>

      {data.scoutPoints.map((scoutPoint, index) => {
        const finalScoutPoint = index === data.scoutPoints.length - 1;
        return (
          <>
            {scoutPoint.bNoScoutPoint ? null : (
              <ScoutPointPage
                data={scoutPoint}
                fieldName={data.fieldName}
                farmData={data}
                isKrpano={isKrpano}
                isPdf={isPdf}
                isCardReport={isCardReport}
                scoutPointPositionX={scoutPoint.pixel_x}
                scoutPointPositionY={scoutPoint.pixel_y}
                finalScoutPoint={finalScoutPoint}
                loadStatus={loadStatus}
                setLoadStatus={setLoadStatus}
              />
            )}
          </>
        )
      })}
    </>
  );
};

export default ScoutPointPages;
