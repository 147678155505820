import React from "react";
//import styles from "./QRCode.module.css";
import { FieldError } from "types/BasicTypes";
import { useFetch } from "helpers/loading";
import Spinner from "react-spinkit";
import { endpoints } from "serverDetails";
import { useWhyDidYouUpdate } from "@avamae/hooks";

const errorResults = "/content/images/Icon_Error.png"

const Heading = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="">
      <h1>{children}</h1>
    </div>
  );
};

export const url = endpoints.qrCode.generateQR;
export const newUserUrl = endpoints.qrCode.generateNewUserQR;

export interface QRProps {
  loading: any;
  data: any;
  error: any;
}

export const QRCode = (props: QRProps) => {
  const { loading, error, data } = props;

  const content = () => {
    if (loading) {
      return <Spinner name="three-bounce" fadeIn="none" className="QRSpin" />;
    }
    if (error) return (
      <div className="ErrorResults">
        <div className="NoResults">
          <img src={errorResults} alt={"Error"} />
          <p>Error</p>
        </div>
      </div>
    )
    if (data) {
      if (data.errors.length > 0) {
        return (
          <div className="ErrorResults">
            <div className="NoResults">
              <img src={errorResults} alt={"Error"} />
              <p>Error</p>
            </div>
          </div>
        )
      } else {
        return (
          <img
            data-testid="data"
            className="QRImage"
            alt="QR Code"
            src={data.details.base64_qrcode}
          />
        );
      }
    }
    return null;
  };

  return (
    <>
      <div className="QRContainer">
        {content()}
      </div>
      <div>
      </div>
    </>
  );
};

export const QRCodeNewUser = (props: QRProps) => {
  const { loading, error, data } = props;

  const content = () => {
    if (loading) {
      return <Spinner name="three-bounce" fadeIn="none" className="QRSpin" />;
    }
    if (error) return (
      <div className="ErrorResults">
        <div className="NoResults">
          <img src={errorResults} alt={"Error"} />
          <p>Error</p>
        </div>
      </div>
    )
    if (data) {
      if (data.errors.length > 0) {
        return (
          <div className="ErrorResults">
            <div className="NoResults">
              <img src={errorResults} alt={"Error"} />
              <p>Error</p>
            </div>
          </div>
        )
      } else {
        return (
          <img
            data-testid="data"
            className="QRImage"
            alt="QR Code"
            src={data.details.base64_qrcode}
          />
        );
      }
    }
    return null;
  };

  return (
    <>
      <div className="QRContainer">
        {content()}
      </div>
      <div>
      </div>
    </>
  );
};