import * as React from "react";
import { RouteComponentProps, Redirect, Link } from "@reach/router";
import { TableOptions } from "../../helpers/tables/tableOptions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../reducers/rootReducer";
import { RightMenuItems } from "../../components/PageComponents/RightMenu/rightMenu";

import Action01 from "assets/images/Icon_Action_01.png";
import Action02 from "assets/images/Icon_Action_02.png";
import Action03 from "assets/images/Icon_Action_03.png";
import { endpoints } from "../../serverDetails";
import DataTable from "../../components/DataTable";
import Pagination from "../../components/Pagination";
import { TableInfo, useTable } from "../../api";
import { useTableSelection } from "@avamae/table";
import Helmet from "react-helmet";
import { selectData, useSelectedData } from "helpers/tables/useSelectedData";
import UsersFilter from "components/RightPopOutComponents/UsersFilter";
const Logo = "/content/images/Icon_NoResult.png";

const FilterIcon = "/content/images/Icon_Btn_03.png";

//export type PaymentCardItem = {
//  PaymentCards__Id: number;
//  PaymentCards__NameOnCard: string;
//  PaymentCards__CardEndDigits: string;
//  PaymentCards__CardType: string;
//  //expiry_date: Date;
//  PaymentCards__AddDateUTC: string;
//}

export type UsersItem = {
  id: number;
  initials: string;
  title: string;
  first_name: string;
  last_name: string;
  email_address: string;
  company_name: string;
  telephone: string;
  mobile: string;
  b_primary_user: false;
  //permissions: FarmObject[]; // remove when connecting to endpoint
};

export type UsersItems = UsersItem[];

export interface UsersProps {}
const idColumn = "ClientUsers__Id";

const selectBox = (
  select: () => void,
  selected: boolean,
  id: number | string
) => {
  return (
    <div>
      <input
        type="checkbox"
        checked={selected}
        onChange={select}
        id={id.toString()}
        name={id.toString()}
      />
      <label className="CustomInput" htmlFor={id.toString()} />
    </div>
  );
};

const UsersPage = (props: RouteComponentProps & UsersProps) => {
  const dispatch = useDispatch();

  const useCleanUp = (cb: () => void) => {
    React.useEffect(() => {
      return () => cb();
    }, []);
  };

  const handleToast = (message: string) => {
    dispatch({ type: "SHOW_TOAST", message: message });
    window.setTimeout(() => {
      dispatch({ type: "HIDE_TOAST" });
    }, 3000);
  };

  /* Redux state */
  const tableState = useSelector((state: AppState) => state.tableReducer);

  /* Local state */
  const [visibleUserOptions, setVisibleUserOptions] = React.useState<
    number | null
  >(null);
  const [showMenu, setShowMenu] = React.useState<boolean>(true);
  const cleanUp = () => {
    dispatch({ type: "SET_SELECTED_USERSPAGE", selectedUsersPageIds: [] });
    dispatch({
      type: "SET_RIGHT_MENU_ITEMS",
      rightMenuItems: { menuItems: [], alternateMenuItems: { menuItems: [] } },
    });
  };
  useCleanUp(cleanUp);

  const toggleUserOptions = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const targetId = parseInt(e.currentTarget.id, 10);
    if (targetId) {
      visibleUserOptions !== targetId
        ? setVisibleUserOptions(targetId)
        : setVisibleUserOptions(null);
    }
  };
  const table = useTable({ url: endpoints.users.listUsers, pageSize: 9 });
  const reload = table && table.reload;
  const refreshStatus = useSelector(
    (state: AppState) => state.tableReducer.refreshData
  );
  const selectedUsersPageIds = useSelector(
    (state: AppState) => state.tableReducer.selectedUsersPageIds
  );
  const [mustAddUsers, setMustAddUsers] = React.useState<boolean>(true);
  const subscriptionStatus = useSelector(
    (state: AppState) => state.auth.subscriptionStatus
  );
  const isPrimaryUser = useSelector((state: any) => state.auth.primary_user);

  React.useEffect(() => {
    if (reload && refreshStatus) {
      reload();
      if (selectedUsersPageIds.length < 1) {
        rowSelection.selectAll([]);
      }
      dispatch({
        type: "REFRESH_TABLE",
      });
    }
    //eslint-disable-next-line
  }, [refreshStatus]);

  React.useEffect(() => {
    if (mustAddUsers) {
      if (
        table &&
        table.data &&
        table.data.details.filters.length === 0 &&
        table.data.details.listData.length === 0
      ) {
        dispatch({
          type: "OPEN_RIGHT_POP_OUT",
          rightPopOutOpen: "USERS_ADD_NEW",
        });
        setMustAddUsers(false);
      }
    }
  }, [table, mustAddUsers, dispatch]);

  const rowSelection = useTableSelection<any>(idColumn, selectBox);
  const { selectedIDs } = rowSelection;

  const selectedData = useSelectedData<any>({
    selectedIDs,
    data: table && table.data ? (table.data.details.listData as any) : [],
    idColumn,
  });

  const tableOptionsList = (id: number) => {
    const dataId = selectData(
      [id],
      table && table.data ? (table.data.details.listData as any) : [],
      idColumn
    );
    const optionsList = [
      {
        name: "View user",
        onClick: (user_id: number) => {
          dispatch({
            type: "SET_SELECTED_USERSPAGE_NO_MENU",
            selectedUsersPageIds: [user_id],
          });
          dispatch({
            type: "OPEN_RIGHT_POP_OUT",
            rightPopOutId: [user_id],
            rightPopOutOpen: "USERS_VIEW",
          });
        },
      },
      {
        name: "Update user details",
        onClick: (user_id: number) => {
          dispatch({
            type: "SET_SELECTED_USERSPAGE_NO_MENU",
            selectedUsersPageIds: [user_id],
          });
          dispatch({
            type: "OPEN_RIGHT_POP_OUT",
            rightPopOutId: [user_id],
            rightPopOutOpen: "USERS_UPDATE_DETAILS",
          });
        },
      },
      {
        name: "Update user permissions",
        hidden: false,
        onClick: (user_id: number) => {
          dispatch({
            type: "SET_SELECTED_USERSPAGE_NO_MENU",
            selectedUsersPageIds: [user_id],
          });
          dispatch({
            type: "OPEN_RIGHT_POP_OUT",
            rightPopOutId: [user_id],
            rightPopOutOpen: "USERS_UPDATE_PERMISSIONS",
          });
        },
      },
      {
        name: "Delete user",
        onClick: (user_id: number) =>
          dispatch({
            type: "OPEN_MODAL",
            modalId: [user_id],
            modalOpen: "USERS_DELETE",
          }),
      },
    ];
    return optionsList;
  };

  React.useEffect(() => {
    const rightMenuItems: RightMenuItems = {
      menuItems: [
        {
          icon: Action01,
          name: "View user",
          imgAlt: "View user",
          onClick: () =>
            dispatch({
              type: "OPEN_RIGHT_POP_OUT",
              rightPopOutOpen: "USERS_VIEW",
            }),
        },
        {
          icon: Action02,
          name: "Update user details",
          imgAlt: "Update user",
          onClick: () =>
            dispatch({
              type: "OPEN_RIGHT_POP_OUT",
              rightPopOutOpen: "USERS_UPDATE_DETAILS",
            }),
        },
        {
          icon: Action02,
          name: "Update user permissions",
          imgAlt: "Update user permissions",
          onClick: () =>
            dispatch({
              type: "OPEN_RIGHT_POP_OUT",
              rightPopOutOpen: "USERS_UPDATE_PERMISSIONS",
            }),
          hidden: false,
        },
        {
          icon: Action03,
          name: "Delete user",
          imgAlt: "Delete user",
          onClick: () =>
            dispatch({
              type: "OPEN_MODAL",
              modalOpen: "USERS_DELETE",
            }),
        },
      ],
      alternateMenuItems: {
        menuItems: [
          {
            icon: Action03,
            name: "Delete users",
            imgAlt: "Delete users",
            onClick: () =>
              dispatch({
                type: "OPEN_MODAL",
                modalOpen: "USERS_DELETE",
              }),
          },
        ],
      },
    };

    dispatch({ type: "SET_RIGHT_MENU_ITEMS", rightMenuItems: rightMenuItems });
    dispatch({
      type: "SET_SELECTED_USERSPAGE",
      selectedUsersPageIds: [...selectedIDs],
    });
    selectedIDs.length > 0 ? setShowMenu(false) : setShowMenu(true);
  }, [dispatch, selectedIDs, selectedData]);

  React.useEffect(() => {
    if (subscriptionStatus !== "Valid" && subscriptionStatus !== "Initial") {
      handleToast("Please renew your subscription to access the Users section");
    }
  }, [subscriptionStatus]);

  if (subscriptionStatus !== "Valid" && subscriptionStatus !== "Initial") {
    return <Redirect to="/" noThrow={true} />;
  }

  return (
    <>
      <Helmet>
        <title>Skippy Scout: Users</title>
      </Helmet>
      {isPrimaryUser ? (
        <div className="PaymentCards WidthContent HeightContent">
          <div className="PaymentCardsHeader">
            <h1>Users</h1>
            <div className="HeaderActionsContainer">
              <div className="ButtonContainer">
                <button
                  className={`Btn BtnClear ${
                    tableState.selectedUsersPageIds.length > 0
                      ? ""
                      : "BtnHidden"
                  }`}
                  onClick={() => {
                    rowSelection.selectAll([]);
                    console.log("userspage - inside clear selection onclick");
                    dispatch({
                      type: "SET_SELECTED_USERSPAGE",
                      selectedUsersPageIds: [],
                      toggleAltMenuItems: true,
                    });
                  }}
                >
                  <img
                    src={"/content/images/Icon_Btn_06.png"}
                    alt="Clear"
                    className="ClearIcon"
                  />
                  <span>Clear selection</span>
                </button>

                <button
                  className="Btn BtnAdd"
                  onClick={() =>
                    dispatch({
                      type: "OPEN_RIGHT_POP_OUT",
                      rightPopOutOpen: "USERS_ADD_NEW",
                    })
                  }
                >
                  <img
                    src={"/content/images/Icon_Btn_01.png"}
                    alt="Add"
                    className="AddIcon"
                  />
                  <span>Add new user</span>
                </button>
              </div>
            </div>
          </div>
          <DataTable
            table={table}
            filterAction={() => {
              table && table.data && table.data.details.filters.length > 0
                ? table && table.data && table.data.actions.setFilters([])
                : dispatch({
                    type: "OPEN_RIGHT_POP_OUT",
                    rightPopOutOpen: "USERS_FILTER",
                  });
            }}
            rowSelection={rowSelection}
            rowOptionComponent={(id: any) => (
              <>
                <img
                  alt="Filter"
                  id={id.toString()}
                  className={`FilterIcon ${showMenu ? "" : "Hidden"}`}
                  onClick={toggleUserOptions}
                  src={FilterIcon}
                />
                <TableOptions
                  className="I"
                  id={id[0]}
                  visibleOptions={visibleUserOptions}
                  setVisibleOptions={setVisibleUserOptions}
                  optionList={tableOptionsList(id[0])}
                />
              </>
            )}
          />
          <Pagination table={table} />
          <UsersFilter table={table} />
        </div>
      ) : (
        <div className="ErrorPageContainer">
          <img
            src={Logo}
            alt="Skippy Scout"
            style={{ height: "80px", width: "80px" }}
          />
          <h1>Sorry, that page doesn't exist</h1>
          <Link to="/home" className="Btn">
            Return home
          </Link>
        </div>
      )}
    </>
  );
};

export default UsersPage;
