import React, { useState, useEffect } from "react";
//import { useDispatch, useSelector } from "react-redux";
//import { AppState } from "../../../reducers/rootReducer";
import SignInPage from "../../../pages/SignInPage/SignInPage";
import { CSSTransition } from "react-transition-group";
import LocationThread from "../../BaseUIComponents/LocationThread/LocationThread";
import { RouteComponentProps, navigate } from "@reach/router";
import Spinner from "react-spinkit";
import { NormalizedNavItems } from "../../../types/AuthTypes";
import { useScrollToTop } from "../../../helpers/hooks";
import PreLoginPageStructure from "../PreLoginPageStructure/PreLoginPageStructure";

export interface PageProps {
  isAuthenticated: boolean;
  children: string | JSX.Element | (string | JSX.Element)[];
  navItems: NormalizedNavItems;
  loadingScreen?: boolean;
}


const loadingScreen = (
  <div>
    <Spinner name="double-bounce" fadeIn="none" />
    <div />
  </div>
);

const Page = (props: PageProps & RouteComponentProps) => {
  const [showLoading, setShowLoading] = useState(true);


  //const dispatch = useDispatch();

  useScrollToTop();

  // if you just use the loadingScreen prop directly, when going back
  // to a previously loaded screen which will refresh on mount, it'll flash the
  // content, show the loading screen, then flash back. With this effect hook, the content
  // only gets shown once the loadingScreen prop has *changed* to visible.
  useEffect(() => {
    if (props.loadingScreen === false) {
      setShowLoading(false);
    }
    return () => { };
  }, [props.loadingScreen]);

  useEffect(() => {
    if (!props.isAuthenticated) {
      setShowLoading(true);
    }
  }, [props.isAuthenticated]);

  return (
    <CSSTransition in={props.isAuthenticated} timeout={150} classNames="Page page">
      {!props.isAuthenticated ? (
        <PreLoginPageStructure showAsideButtons>
          <SignInPage />
        </PreLoginPageStructure>
      ) : (
          <div
          >
            <div className="Content">
              <div>
                <LocationThread>
                  {props.location && props.location.pathname}
                </LocationThread>
              </div>
              <CSSTransition
                in={showLoading && props.loadingScreen != null} // <- Don't show the loading screen if the prop isn't set at all.
                unmountOnExit
                timeout={200}
                classNames="modal"
              >
                {loadingScreen}
              </CSSTransition>
              {props.children}
            </div>
          </div>
        )}
    </CSSTransition>
  );
};

export default Page;
