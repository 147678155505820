import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import Helmet from "react-helmet";
import { getFieldReports, getFieldReportsFooter } from "./FieldReportsApi";
import { FieldReportsData, FieldReportsDto } from "./models";
import queryString from "query-string";
import { format } from "date-fns";
import { useState } from "react";
import { ReportFooter } from "./ReportFooter";

declare global {
  interface Window {
    selectpdf?: any;
  }
}

const FieldReportsFooterPage = (props: RouteComponentProps) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [tokenError, setTokenError] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loadStatus, setLoadStatus] = useState<boolean>(false);

  const search = props.location ? props.location.search : "";
  const parsed = queryString.parse(search);
  const id = parsed.flight_id as string;
  const token = parsed.token;

  React.useEffect(() => {
    if (parsed) {
      (async () => {
        if (id && token) {
          setLoading(true);
          const result = await getFieldReportsFooter(id, token);
          if (result.response) {
            const scoutPointData = result.response.details;
            setData(scoutPointData);
          }
          if (result.errors) {
            switch (result.errors.response.data.errors[0].message_code) {
              case "Invalid_Token":
                setGeneralError(true);
                setErrorMessage(
                  "An  invalid token has been used. Please try again."
                );
                break;
              case "Invalid_Field_ID":
                setGeneralError(true);
                setErrorMessage(
                  "An  invalid invoice ID has been used. Please try again."
                );
                break;
              default:
                setGeneralError(true);
                setErrorMessage(
                  "Oops, something went wrong. Please try again."
                );
            }
          }
        } else {
          // handle missing id / token
          if (!id) {
            setGeneralError(true);
            setErrorMessage("Invalid ID. Please try again.");
          } else if (!token) {
            setGeneralError(true);
            setErrorMessage("Invalid token. Please try again.");
          } else {
            setGeneralError(true);
            setErrorMessage("Oops, something went wrong. Please try again.");
          }
        }
        setLoading(false);
      })();
    }
  }, []);

  React.useEffect(() => {
    if (data && loadStatus) {
      if (typeof (window.selectpdf) == "object") {
        console.log('Start pdf conversion');
        window.selectpdf.start();
      }
    }
  }, [loadStatus]);

  return (
    <>
      <Helmet>
        <title>Skippy Scout: Field Report</title>
      </Helmet>

      {data && (
        <ReportFooter data={data} setLoadStatus={setLoadStatus} />
      )}
    </>
  );
};

export default FieldReportsFooterPage;
