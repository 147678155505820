import React from "react";
import { DataItemProps } from "./models";

const DataItem: React.FC<DataItemProps> = ({
  icon,
  value,
  doubleValue,
  description,
  doubleDescription,
}) => {
  return (
    <>
      {(value || doubleValue) && (
        <div className="ItemContainer">
          {icon && <img alt="Icon" src={icon}></img>}
          <div className="ItemContent">
            {value && <h5>{value}</h5>}
            {doubleValue && (
              <h5>
                {doubleValue.first} <span>//</span> {doubleValue.second}
              </h5>
            )}
            {description && <p>{description}</p>}
            {doubleDescription && (
              <p>
                {doubleDescription.first} <span>//</span>{" "}
                {doubleDescription.second}
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export { DataItem };
