import React from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { BoolCheckboxField } from "../../FormComponents/BoolCheckbox/BoolCheckbox";
import RowInput from "../../SignInComponents/RowInput/RowInput";
import { useDispatch } from "react-redux";
import { CalendarDatePickerField } from "../../FormComponents/CalendarDatePicker/CalendarDatePicker";
import { PhoneNumberField } from "../../FormComponents/PhoneNumber/PhoneNumber";
import { isValidPhoneNumber } from "react-phone-number-input";
import Spinner from "react-spinkit";
import { NewUserAddFormInner } from "./NewUserAddFormInner";

export type Data = {
  details: PreFilledUserDetails;
};

export type PreFilledUserDetails = {
  company_name: string;
  permissions: FarmPermission[];
};

export type FarmPermission = {
  id: number;
  farm_name: string;
  fields: FieldPermission[];
};

export type FieldPermission = {
  id: number;
  field_name: string;
  scout_points: ScoutPointPermission[];
};

export type ScoutPointPermission = {
  id: number;
  scout_point: string;
};
interface NewUserAddFormProps {
  //submit: (values: any) => void;
  submit: (
    values: any,
    setFieldError: (field: string, val: string) => void,
    setSubmitting: (val: boolean) => void
  ) => void;
  data?: Data;
  sharingUser: boolean | null;
}

const validationSchema = Yup.object({
  title: Yup.string(),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  company_name: Yup.string()
    .max(75, "Company name must be no longer than 75 characters")
    .required("Company name is required"),
  email_address: Yup.string()
    .max(75, "Email address must be no longer than 75 characters")
    .required("Email address is required"),
  telephone: Yup.mixed().test(
    "phoneTest",
    "Please enter a valid telephone number",
    value => {
      if (!value) return false;
      return isValidPhoneNumber(value);
    }
  ),
  mobile: Yup.string().test(
    "mobileTest",
    "Please enter a valid mobile number",
    value => {
      if (!value) return false;
      return isValidPhoneNumber(value);
    }
  ),
  b_suppressed: Yup.boolean(),
  b_access_all_farms: Yup.boolean(),
  permissions: Yup.array().of(Yup.object()),
});
const NewUserAddForm = (props: NewUserAddFormProps) => {
  if (props.data) {
    const initialValues = {
      title: "",
      first_name: "",
      last_name: "",
      company_name: props.data.details.company_name,
      email_address: "",
      telephone: "",
      mobile: "",
      b_suppressed: false,
      b_access_all_farms: false,
      permissions: props.data.details.permissions,
    };
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setFieldError, setSubmitting }) => {
          props.submit(values, setFieldError, setSubmitting);
          //props.submit(values);
        }}
        render={({ isValid, isSubmitting, values, handleSubmit }) => (
          <NewUserAddFormInner
            sharingUser={props.sharingUser}
            values={values}
            data={props.data}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
        )}
      />
    );
  }

  const initialValues = {
    title: "",
    first_name: "",
    last_name: "",
    company_name: "",
    email_address: "",
    telephone: "",
    mobile: "",
    b_suppressed: false,
    b_access_all_farms: false,
    permissions: [],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setFieldError, setSubmitting }) => {
        props.submit(values, setFieldError, setSubmitting);
        //props.submit(values);
      }}
      render={({ isValid, isSubmitting, values, handleSubmit }) => (
        <NewUserAddFormInner
          sharingUser={props.sharingUser}
          values={values}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      )}
    />
  );
};

export default NewUserAddForm;
