import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ChunkyInput from "../ChunkyInput/ChunkyInput";
import Spinner from "react-spinkit"

interface QRCodeFormInnerProps {
  isValid: boolean;
  isSubmitting: boolean;
  result: string;
  setFieldValue: any;
  handleSubmit: any;
}

interface QRCodeFormProps {
  submit: (values: any, setFieldError: (field: string, val: string) => void, setSubmitting: (val: boolean) => void) => void;
  result: string;
}

const validationSchema = Yup.object({
  code: Yup.string().required("Please scan a QR image or type in your activation code"),
});

const QRCodeFormInner = (props: QRCodeFormInnerProps) => {

  const { result, setFieldValue, handleSubmit } = props

  React.useEffect(() => {
    setFieldValue("code", result)
    if (result && result.length > 0) {
      handleSubmit()
    }
  }, [result, setFieldValue, handleSubmit])

  return (
    <Form>
      <div className="FormBox">
        <ChunkyInput
          name="code"
          id="code"
          placeholder="Code"
        />
        <ErrorMessage name="code">
          {message => <span className="ErrorMessageLeft">{message}</span>}
        </ErrorMessage>
      </div>
      <div className="FormBottom">
        <button
          className={"Btn BtnFullWidth"}
          type="submit"
          disabled={!props.isValid || props.isSubmitting}
        >
          {props.isSubmitting ? <Spinner name="three-bounce" className="BtnSpinner" color="white" fadeIn="none" /> : 'Save and continue'}
        </button>
      </div>

    </Form>
  );
};

const QRCodeForm = (props: QRCodeFormProps) => {
  const initialValues = {
    code: ""
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setFieldError, setSubmitting }) => {
        props.submit(values, setFieldError, setSubmitting)
        //props.submit(values);
      }}
      render={({ isValid, isSubmitting, setFieldValue, submitForm }) => (
        <QRCodeFormInner isValid={isValid} isSubmitting={isSubmitting} setFieldValue={setFieldValue} result={props.result} handleSubmit={submitForm}/>
      )}
    />
  );
};

export default QRCodeForm;
