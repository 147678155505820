import { produce } from "immer";
import { AxiosRequestConfig } from "axios";
import { AppState } from "reducers/rootReducer";
import { createSelector } from "reselect";
import { Maybe } from "purify-ts/Maybe";
import { AuthState } from "./rootReducer";
import {
  AuthStateStatus,
  NormalizedNavItems,
  Credentials,
} from "types/AuthTypes";
import { AddOn } from "pages/Registration/AddOnsStep";

/* Constants */

const SET_ADD_ONS = "app/register/SET_ADD_ONS";
const REMOVE_ADD_ONS = "app/register/REMOVE_ADD_ONS";

type ValueOf<T> = T[keyof T];

type RegisterState = {
  addons: Pick<AddOn, "addon_id" | "price" | "gross_price" | "addon_name">[];
};

type RegisterAction =
  | {
      type: typeof SET_ADD_ONS;
      payload: Pick<AddOn, "addon_id" | "price" | "gross_price" | "addon_name">;
    }
  | {
      type: typeof REMOVE_ADD_ONS;
      payload: ValueOf<Pick<AddOn, "addon_id">>;
    };

/* Reducer */

const initialRegisterState: RegisterState = {
  addons: [],
};

export const registerReducer = (
  state: RegisterState = initialRegisterState,
  action: RegisterAction
): RegisterState =>
  produce(state, draft => {
    switch (action.type) {
      case SET_ADD_ONS:
        draft.addons.push(action.payload);
        break;
      case REMOVE_ADD_ONS:
        draft.addons = draft.addons.filter(
          ({ addon_id }) => addon_id !== action.payload
        );
        break;
    }
  });

/* Action Creators */

export const setAddons = (
  payload: Pick<AddOn, "addon_id" | "price" | "gross_price" | "addon_name">
): RegisterAction => ({
  type: SET_ADD_ONS,
  payload,
});

export const removeAddons = (
  payload: ValueOf<Pick<AddOn, "addon_id">>
): RegisterAction => ({
  type: REMOVE_ADD_ONS,
  payload,
});

/* Selectors */

export const selectAddons = (state: AppState) => state.register.addons;
