import { useReducer, useState, useEffect, useCallback, Reducer } from "react";
import api from "api";
import { AxiosRequestConfig } from "axios";

type State<T, E> =
  | {
      loading: true;
      error: undefined;
      data: undefined;
    }
  | { loading: boolean; error: E; data: undefined }
  | { loading: boolean; error: undefined; data: T };

type Action<T, E = Error> =
  | { type: "loading" }
  | { type: "error"; payload: E }
  | { type: "data"; payload: T }
  | { type: "reset" };

function initialState<T, E>(initialData?: T): State<T, E> {
  return initialData == null
    ? { loading: true, error: undefined, data: undefined }
    : { loading: false, error: undefined, data: initialData };
}

export function useFetch<T = any, E = Error>(
  url: string,
  config?: AxiosRequestConfig,
  initialData?: T,
  debug?: boolean
) {
  const [store, dispatch] = useReducer<
    Reducer<State<T, E>, Action<T, E>>,
    T | undefined
  >(reducer, initialData, initialState);

  const { loading } = store;

  const [flag, setFlag] = useState(0);

  const reload = useCallback(() => {
    if (!loading) {
      setFlag(prev => prev + 1);
    }
  }, [loading]);

  useEffect(() => {
    let cancelled = false;
    dispatch({ type: "loading" });
    debug && console.log("[useFetch]res.data - fetching...");
    api
      .get(url, config)
      .then(res => {
        if (!cancelled) {
          debug && console.log("[useFetch]res.data:", res.data);
          dispatch({ type: "data", payload: res.data });
        }
      })
      .catch(err => {
        if (!cancelled) {
          dispatch({ type: "error", payload: err });
        }
      });
    return () => {
      cancelled = true;
    };
  }, [url, config, flag]);

  return {
    loading: store.loading,
    error: store.error,
    data: store.data,
    reload,
  } as { reload: typeof reload } & (
    | { loading: true; error: undefined; data: undefined }
    | { loading: boolean; error: E; data: undefined }
    | { loading: boolean; error: undefined; data: T }
  );
}

function reducer<T, E>(
  prevState: State<T, E>,
  action: Action<T, E>
): State<T, E> {
  switch (action.type) {
    case "loading":
      return { ...prevState, loading: true };
    case "error":
      return { loading: false, data: undefined, error: action.payload };
    case "data":
      return { loading: false, data: action.payload, error: undefined };
    case "reset":
      return initialState();
    default:
      throw new Error(
        `Impossible reducer state from following action: ${action}`
      );
  }
}
