import { Router } from "@reach/router";
import FieldReportsFooterPage from "pages/FieldReportsPage/FieldReportsFooterPage";
import FieldReportsPage from "pages/FieldReportsPage/FieldReportsPage";
import IntegrationLoading from "pages/IntegrationLoading/IntegrationLoading";
import InvoicesFooterPage from "pages/InvoicesPage/InvoicesFooterPage";
import InvoicesPage from "pages/InvoicesPage/InvoicesPage";
import React from "react";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createEpicMiddleware } from "redux-observable";
import thunk from "redux-thunk";
import { rootEpic, rootReducer } from "./reducers/rootReducer";
import MainRouter from "./routes/MainRouter/MainRouterContainer";
import "./styles/Main.scss";

const isProduction = process.env.REACT_APP_BUILD_TYPE === "production";

const composeEnhancers = isProduction
  ? compose
  : composeWithDevTools({ trace: true });

const epicMiddleware = createEpicMiddleware();

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, epicMiddleware))
);

epicMiddleware.run(rootEpic);

const App: React.FC = () => {
  return (
    <>
      <Provider store={store}>
        <Router primary={false} className="PreRouter">
          <InvoicesPage path="/template/subscription-invoice" />
          <InvoicesFooterPage path="/template/subscription-invoice-footer" />
          <FieldReportsPage path="/template/field-report" />
          <FieldReportsFooterPage path="/template/field-report-footer" />
          <IntegrationLoading path="/template/integration-loading" />
          <MainRouter default />
        </Router>
      </Provider>
    </>
  );
};

export default App;
