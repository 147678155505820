let BASE_URL = "https://skippy-web-clientmanagement.api.avamae.co.uk";

switch (process.env.REACT_APP_BUILD_TYPE) {
  case "production":
    BASE_URL = "https://skippy-web-clientmanagement-api.skippy.farm";
    break;
  case "staging":
    BASE_URL = "https://skippy-web-clientmanagement-api-staging.skippy.farm";
    break;
}

export { BASE_URL };

export const VERSION = process.env.REACT_APP_VERSION;

export const AUTH_URL = "/api/v1/publicrole/authmodule/";
export const RESET_LINK_URL = "";
export const USERS_PUBLIC_URL = "";

export const API_CLIENT_URL = "";

export const STRIPE_KEY =
  process.env.REACT_APP_BUILD_TYPE === "production"
    ? "pk_live_SgllaTvWRNTI2QitJvWRXSnf"
    : "pk_test_AL1tz926LgQ4kxOjA8EYsbXi";

export const MAPBOX_TOKEN =
  "pk.eyJ1IjoibGFyaW5hY29ycmVpYSIsImEiOiJjazF1ajdzM24weWhxM210NmVwNTV5cjV0In0.vLh76D4PUuVhOL5OcdBFhA";

export const endpoints = {
  notificationSettings: {
    listNotifications:
      "/api/v1/clientrole/notificationsettingsmodule/preferences",
    updateNotifications:
      "/api/v1/clientrole/notificationsettingsmodule/updatenotfications",
  },
  fieldReport: {
    reportDetails: "/api/v1/publicrole/fieldreportmodule/fieldreportdetails",
    reportFooterDetails:
      "/api/v1/publicrole/fieldreportmodule/fieldreportfooterdetails",
    getImage: "/api/v1/publicrole/fieldreportmodule/getreportimage",
  },
  paymentCards: {
    listPaymentCards: "/api/v1/clientrole/paymentcardmodule/list",
    viewPaymentCard: "/api/v1/clientrole/paymentcardmodule/details",
    createPaymentCards: "/api/v1/clientrole/paymentcardmodule/create",
    createDetailsPaymentCards:
      "/api/v1/clientrole/paymentcardmodule/createdetails",
    deletePaymentCard: "/api/v1/clientrole/paymentcardmodule/delete",
  },
  users: {
    listUsers: "/api/v1/clientrole/usermodule/list",
    viewUser: "/api/v1/clientrole/usermodule/details",
    createUsers: "/api/v1/clientrole/usermodule/create",
    createDetailsUsers: "/api/v1/clientrole/usermodule/createdetails",
    updateDetails: "api/v1/clientrole/usermodule/updatedetails",
    update: "api/v1/clientrole/usermodule/update",
    deleteUsers: "/api/v1/clientrole/usermodule/delete",
    sendQREmail: "/api/v1/clientrole/usermodule/sendqrcodebyemail",
    sendQRSMS: "/api/v1/clientrole/usermodule/sendqrcodebysms",
    updatePermissionDetails:
      "/api/v1/clientrole/usermodule/updatepermissiondetails",
    updatePermission: "/api/v1/clientrole/usermodule/updatepermission",
  },
  personalInfo: {
    detailsPersonalInfo:
      "/api/v1/clientrole/personalinfomodule/getupdatedetails",
    updatePersonalInfo: "/api/v1/clientrole/personalinfomodule/update",
    deleteAccount: "/api/v1/clientrole/personalinfomodule/deleteaccount",
    deleteUser: "/api/v1/clientrole/personalinfomodule/deleteuser",
    countryList: "/api/v1/clientrole/personalinfomodule/countrylist",
  },
  resetPassword: {
    updatePassword: "/api/v1/clientrole/resetpasswordmodule/update",
  },
  subscription: {
    listSubscriptions: "/api/v1/clientrole/subscriptionmodule/list",
    upgradeSubscription: "/api/v1/clientrole/subscriptionmodule/upgrade",
    upgradeDetailsSubscription:
      "/api/v1/clientrole/subscriptionmodule/upgradedetails",
    upgrade3dSecure:
      "/api/v1/clientrole/subscriptionmodule/updateafter3dsecure",
    pauseSubscription:
      "/api/v1/clientrole/subscriptionmodule/pausesubscription",
    unpauseSubscription:
      "/api/v1/clientrole/subscriptionmodule/unpausesubscription",
    refreshAccessSubscription:
      "/api/v1/clientrole/subscriptionmodule/refreshaccesscodes",
  },
  transactionHistory: {
    listTransactionHistory: "/api/v1/clientrole/transactionhistorymodule/list",
    viewTransactionHistory:
      "/api/v1/clientrole/transactionhistorymodule/details",
    downloadInvoice:
      "/api/v1/clientrole/transactionhistorymodule/downloadinvoice",
  },
  forgottenPassword: {
    sendEmail: "/api/v1/publicrole/forgottenpasswordmodule/sendemail",
    resetPassword: "/api/v1/publicrole/forgottenpasswordmodule/resetpassword",
    validateToken: "/api/v1/publicrole/forgottenpasswordmodule/validatetoken",
  },
  register: {
    registerDetails: "/api/v1/publicrole/registrationmodule/registerdetails",
    validatePromoCode:
      "/api/v1/publicrole/registrationmodule/getpromocodedetails",
    getTransactionInfo:
      "/api/v1/publicrole/registrationmodule/gettransactioninfo",
    register: "/api/v1/publicrole/registrationmodule/register",
    activate: "/api/v1/publicrole/registrationmodule/activate",
    update3dSecure: "/api/v1/publicrole/registrationmodule/updateafter3dsecure",
    activateEnterprise:
      "/api/v1/publicrole/registrationmodule/activateenterprise",
  },
  navigation: {
    navigationLinks: "/api/v1/generalrole/navigationlinksmodule/list",
  },
  cms: {
    leftPanel: "/api/v1/generalrole/cmsmoduleservice/publicleftpaneldetails",
    cmsPageDetails: "/api/v1/generalrole/cmsmoduleservice/details",
    cmsPartialPageDetails: "/api/v1/generalrole/cmsmoduleservice/partdetails",
  },
  yourQrCode: {
    generateQR: "/api/v1/publicrole/yourqrcodemodule/generateqrcode",
    generateUserQR: "/api/v1/publicrole/yourqrcodemodule/generateuserqrcode",
  },
  sitemap: {
    list: "/api/v1/generalrole/sitemapmodule/list",
  },
  subscriptionInvoice: {
    invoiceDetails:
      "/api/v1/publicrole/subscriptioninvoicemodule/invoicedetails",
  },
  qrCode: {
    generateQR: "/api/v1/clientrole/qrcodemodule/generateqrcode",
    generateNewUserQR:
      "/api/v1/clientrole/qrcodemodule/generateqrcodefornewuser",
    sendDeviceQREmail:
      "/api/v1/clientrole/qrcodemodule/senddeviceqrcodebyemail",
    sendDeviceQRSms: "/api/v1/clientrole/qrcodemodule/senddeviceqrcodebysms",
    sendNewUserQREmail:
      "/api/v1/clientrole/qrcodemodule/sendnewuserqrcodebyemail",
    sendNewUserQRSms: "/api/v1/clientrole/qrcodemodule/sendnewuserqrcodebysms",
  },
  fieldUploads: {
    uploadField: "/api/v1/clientrole/uploadfields/uploadfields",
    uploadFieldDetails: "/api/v1/clientrole/uploadfields/uploadfielddetails",
  },
  fieldMargin: {
    addFieldMarginFarm:
      "/api/v1/publicrole/fieldmarginmodule/addfieldmarginfarm",
  },
  integrations: {
    getDetails: "/api/v1/clientrole/integrationmodule/getintegrationdetails",
  },
  reportsModule: {
    getReportsDetails: "/api/v1/clientrole/reportsmodule/reportsdetails",
    getReportsPageInfo: "/api/v1/clientrole/reportsmodule/getreportpageinfo",
    downloadReport: "/api/v1/clientrole/reportsmodule/downloadfieldreport",
    getFieldReportSectionReportDetails:
      "/api/v1/clientrole/reportsmodule/fieldreportdetails",
    getreportnotes: "/api/v1/clientrole/reportsmodule/getreportnotes",
    updatereportnotes: "/api/v1/clientrole/reportsmodule/updatereportnotes",
    deletereportnotes: "/api/v1/clientrole/reportsmodule/deletereportnotes",
    regeneratereport: "/api/v1/clientrole/reportsmodule/regeneratereport",
    getreportgenerationstatus:
      "/api/v1/clientrole/reportsmodule/getreportgenerationstatus",
  },
  applicationMaps: {
    getMapsFieldDetails:
      "/api/v1/clientrole/applicationmaps/applicationmapsdetails",
    uploadMapsDetailsOld:
      "/api/v1/clientrole/applicationmaps/createapplicationmap",
    uploadMapsDetails: "/api/v1/clientrole/applicationmaps/createxmlmap",
  },
};
