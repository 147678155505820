import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { BoolCheckboxField } from "../../FormComponents/BoolCheckbox/BoolCheckbox";
import RowInput from "../../SignInComponents/RowInput/RowInput";
import { useDispatch } from "react-redux";
import { PhoneNumberField } from "../../FormComponents/PhoneNumber/PhoneNumber";
import { isValidPhoneNumber } from "react-phone-number-input";
import Spinner from "react-spinkit";

interface UpdateUserFormInnerProps {
  data?: Data;
  values: any;
  handleSubmit: () => void;
  isSubmitting: boolean;
}

type Data = {
  details: UpdateUserDetails;
};

type UpdateUserDetails = {
  id: number;
  title: string;
  first_name: string;
  last_name: string;
  email_address: string;
  company_name: string;
  telephone: string;
  mobile: string;
  b_suppressed: boolean;
};

interface UpdateUserFormProps {
  data?: Data;
  submit: (
    values: any,
    setFieldError: (field: string, val: string) => void,
    setSubmitting: (val: boolean) => void
  ) => void;
}

const validationSchema = Yup.object({
  title: Yup.string(),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  company_name: Yup.string()
    .max(75, "Company name must be no longer than 75 characters")
    .required("Company name is required"),
  email_address: Yup.string()
    .max(75, "Email address must be no longer than 75 characters")
    .required("Email address is required"),
  //phone_number: Yup.number().required("Phone number is required"),
  //telephone: Yup.string().matches(phoneNumberRegex, "Please enter a valid phone number"),
  //mobile: Yup.string().matches(phoneNumberRegex, "Please enter a valid phone number").required("Mobile number is required"),
  telephone: Yup.mixed().test(
    "phoneTest",
    "Please enter a valid telephone number",
    value => {
      if (!value) return false;
      return isValidPhoneNumber(value);
    }
  ),
  mobile: Yup.mixed().test(
    "mobileTest",
    "Please enter a valid mobile number",
    value => {
      if (!value) return false;
      return isValidPhoneNumber(value);
    }
  ),
  b_suppressed: Yup.boolean(),
});

const UpdateUserFormInner = (props: UpdateUserFormInnerProps) => {
  const dispatch = useDispatch();

  return (
    <>
      <Form className="UpdateUserForm">
        <div className="FormBox">
          <RowInput
            type="title"
            name="title"
            id="title"
            placeholder="Title"
            optional={true}
            maxLength={10}
          />

          <ErrorMessage name="title">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>

        <div className="FormBox">
          <RowInput
            name="first_name"
            id="first_name"
            placeholder="First name"
            maxLength={50}
          />
          <ErrorMessage name="first_name">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>

        <div className="FormBox">
          <RowInput
            type="last_name"
            name="last_name"
            id="last_name"
            placeholder="Last name"
            maxLength={50}
          />
          <ErrorMessage name="last_name">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>

        <div className="FormBox">
          <RowInput
            type="company_name"
            name="company_name"
            id="company_name"
            placeholder="Company name"
            maxLength={75}
            readOnly={true}
            disabled={true}
          />
          <ErrorMessage name="company_name">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>

        <div className="FormBox">
          <RowInput
            type="email_address"
            name="email_address"
            id="email_address"
            placeholder="Email address"
            maxLength={75}
          />
          <ErrorMessage name="email_address">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>

        <div className="FormBox">
          <div className="FormSideBySide">
            <PhoneNumberField name={"telephone"} label={"Phone number"} />
          </div>
          <ErrorMessage name="telephone">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>

        {/*<div className="FormBox">
          <RowInput
            type="telephone"
            name="telephone"
            id="telephone"
            placeholder="Phone number"
            maxLength={30}
            optional
          />
          <ErrorMessage name="telephone">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>

        <div className="FormBox">
          <RowInput
            type="mobile"
            name="mobile"
            id="mobile"
            placeholder="Mobile"
          />
          <ErrorMessage name="mobile">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>*/}

        <div className="FormBox">
          <div className="FormSideBySide">
            <PhoneNumberField name={"mobile"} label={"Mobile number"} />
          </div>
          <ErrorMessage name="mobile">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>

        <BoolCheckboxField name="b_suppressed" label="Suppressed" />
      </Form>
      <div className="Bottom">
        <button
          className="Btn BtnWhite"
          onClick={() => dispatch({ type: "CLOSE_RIGHT_POP_OUT" })}
        >
          Cancel
        </button>
        <button
          className="Btn"
          type="submit"
          onClick={props.handleSubmit}
          disabled={props.isSubmitting}
        >
          {props.isSubmitting ? (
            <Spinner
              name="three-bounce"
              className="BtnSpinner"
              color="white"
              fadeIn="none"
            />
          ) : (
            "Update"
          )}
        </button>
      </div>
    </>
  );
};

const UpdateUserForm = (props: UpdateUserFormProps) => {
  if (props.data) {
    const initialValues = {
      title: props.data.details.title,
      first_name: props.data.details.first_name,
      last_name: props.data.details.last_name,
      company_name: props.data.details.company_name,
      email_address: props.data.details.email_address,
      telephone: props.data.details.telephone,
      mobile: props.data.details.mobile,
      b_suppressed: props.data.details.b_suppressed,
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setFieldError, setSubmitting }) => {
          props.submit(values, setFieldError, setSubmitting);
          //props.submit(values);
        }}
        render={({ isValid, values, handleSubmit, isSubmitting }) => (
          <UpdateUserFormInner
            values={values}
            data={props.data}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
        )}
      />
    );
  }

  const initialValues = {
    title: "",
    first_name: "",
    last_name: "",
    company_name: "",
    email_address: "",
    telephone: "",
    mobile: "",
    b_suppressed: true,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setFieldError, setSubmitting }) => {
        props.submit(values, setFieldError, setSubmitting);
        //props.submit(values);
      }}
      render={({ isValid, values, handleSubmit, isSubmitting }) => (
        <UpdateUserFormInner
          values={values}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      )}
    />
  );
};

export default UpdateUserForm;
