import React, { useState } from "react";
import axios from "api";
import { useDispatch } from "react-redux";
import { endpoints } from "../../serverDetails";
import Spinner from "react-spinkit";

export interface AccountDelete {
  class?: string;
}



const AccountDelete = (props: AccountDelete) => {
  const [confirm, setConfirm] = useState<string>("");
  const dispatch = useDispatch()
  const [error, setError] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [required, setRequired] = useState<boolean>(false);

  const handleToast = (message: string) => {
    dispatch({ type: 'SHOW_TOAST', message: message })
    window.setTimeout(() => {
      dispatch({ type: 'HIDE_TOAST' })
    }, 3000);
  }

  const handleSubmit = (
    payload: string
  ) => {
    setRequired(false)
    if (payload) {
      setSubmitting(true);
      axios
        .post(endpoints.personalInfo.deleteAccount, { password: payload })
        .then(res => {
          if (res.data.status === "1") {
            dispatch({ type: "CLOSE_MODAL" })
            handleToast("Account successfully deleted")
            dispatch({ type: "app/auth/SIGN_OUT" })
            setSubmitting(false)
          }
        })
        .catch(err => {
          setError(true)
          setSubmitting(false)
        });
    } else {
      setRequired(true)
    }
    
  };

  return (
    <>
      <div className={`Delete ${props.class || ''}`}>
        {submitting && <Spinner name="three-bounce" fadeIn="none" />}
        <h3>Permanently Delete</h3>

        <p>Are you sure you want to permanently delete your account?</p>

        <p className="BoldText">You'll permanently lose all data related to your account:</p>

        <ul className="InformationList">
          <li>- Users</li>
          <li>- Account details</li>
        </ul>

        <div className="WarningText">
          <img src="/content/images/Icon_Warning.png" alt="Warning" />
          <p>This action cannot be undone</p>
        </div>

        <div className="FormBox">
          <div className="FormLabel">
            <label>Enter password to confirm</label>
          </div>
          <div className="FormField">
            <input type="password" className="EditBox" value={confirm} onChange={(e) => setConfirm(e.target.value)} />
            <div className={`${error || required ? 'ErrorMessageLeft' : ''}`}>{error && 'Error, please try again'}{required && 'Please confirm your password'}</div>
          </div>
        </div>
      </div>
      <div className="Bottom">
        <button className="Btn BtnWhite" onClick={() => dispatch({ type: "CLOSE_MODAL" })}>
          Cancel
        </button>
        <button className="Btn BtnRed" onClick={() => {
          handleSubmit(confirm)
        }}>
          Delete
        </button>
      </div>
    </>
  ) 
};

export default AccountDelete;
