import axios, { AxiosError } from "axios";
import { store } from "./App";
import { signOut } from "reducers/auth";
import { BASE_URL } from "./serverDetails";
import { createUseTable } from "@avamae/table";

/**
 * Custom axios instance with base url set, and some interceptors to
 * handle auth headers.
 *
 */
const instance = axios.create({
  baseURL: BASE_URL,
});

/**
 * A function that, given an access token, will modify an axios request
 * config and re-run the request.
 */
type RequestCallback = (token: string) => void;

/**
 * Some mutable state for a queue of requests. If we're already in the middle of
 * requesting a new token, we want to hold other requests in a queue, then make
 * them with the new token once we get it.
 */
class RequestHandler {
  isFetchingToken: boolean;
  private pendingRequests: RequestCallback[];

  constructor() {
    this.isFetchingToken = false;
    this.pendingRequests = [];
  }

  addToQueue = (callback: RequestCallback) => {
    this.pendingRequests.push(callback);
  };

  onTokenFetched = (token: string) => {
    this.pendingRequests.forEach(cb => cb(token));
    this.clearQueue();
  };

  clearQueue = () => {
    this.pendingRequests = [];
    this.isFetchingToken = false;
  };
}

const reqHandler = new RequestHandler();

// Before each request, attach the stored token if it exists.
instance.interceptors.request.use(request => {
  try {
    const tokenDetailsString = localStorage.getItem("TOKEN_DETAILS");
    if (tokenDetailsString === null) throw new Error();
    const tokenDetails = JSON.parse(tokenDetailsString);
    if (tokenDetails.access_token == null) throw new Error();
    const authHeader = { Authorization: `Bearer ${tokenDetails.access_token}` };
    return { ...request, headers: { ...request.headers, ...authHeader } };
  } catch (err) {
    return request;
  }
});

// After each request, if it comes back with a 401 error, refresh token and then retry.
instance.interceptors.response.use(
  fulfilled => fulfilled,
  (rejected: AxiosError) => {
    const status = rejected.response ? rejected.response.status : null;

    if (status === 401 /*&& has the expired token header*/) {
      // Refresh access token.
      return refreshAccessToken(rejected);
    }

    // It's not an authorisation issue, just pass on the rejection.
    return Promise.reject(rejected);
  }
);

/**
 * Intercept the error, and replace it with a promise (awaitingNewToken), which
 * on construction will add a callback to our RequestHandler's queue.
 *
 * The first time the request handler receives one of these additions to its
 * queue, it will attempt to fetch a new access token. It stores up pending
 * requests until the new token arrives. To the initial callers it looks like
 * their request is waiting for a response from the server.
 *
 * Once the request handler has fetched a new access token, it will run every
 * callback in its queue. The callback takes an original request's config,
 * changes it to use the new access token, and remakes the request. It then
 * resolves the promise made in awaitingNewToken. The response to this new
 * request is thereby delivered to the original caller. The queue is then cleared.
 *
 * To that initial caller, it just looks like its original request took a while
 * to resolve.
 */
const refreshAccessToken = async (rejected: AxiosError) => {
  try {
    const { response: errorResponse } = rejected;

    // Extract the refresh token from LS and make sure it's there.
    // Otherwise, just return the error.
    const tokenDetailsString = localStorage.getItem("TOKEN_DETAILS");
    if (tokenDetailsString === null) {
      reqHandler.clearQueue();
      store.dispatch(signOut());
      return Promise.reject(rejected);
    }
    const tokenDetails = JSON.parse(tokenDetailsString);
    if (
      tokenDetails.refresh_token == null ||
      tokenDetails.access_token == null
    ) {
      reqHandler.clearQueue();
      store.dispatch(signOut());
      return Promise.reject(rejected);
    }

    // Build a promise that will be handed to the caller instead of the error.
    const awaitingNewToken = new Promise(resolve => {
      reqHandler.addToQueue(token => {
        if (errorResponse) {
          errorResponse.config.headers.Authorization = `Bearer ${token}`;
          resolve(axios(errorResponse.config));
        }
      });
    });

    // Check if we're already trying to fetch a replacement access token.
    if (!reqHandler.isFetchingToken) {
      reqHandler.isFetchingToken = true;
      const data = {
        access_token: tokenDetails.access_token,
        refresh_token: tokenDetails.refresh_token,
      };

      const response = await axios.post(
        BASE_URL + "/api/v1/publicrole/authmodule/refresh",
        data
      );

      if (
        !response.data ||
        (response.data.errors && response.data.errors.length > 0)
      ) {
        // The refresh request failed, reset the queue, sign the
        // user out and return the error.
        reqHandler.clearQueue();
        store.dispatch(signOut());
        return Promise.reject(rejected);
      }

      // Let's be paranoid. Did the endpoint *actually* return a token?
      if (!response.data.access_token || !response.data.refresh_token) {
        reqHandler.clearQueue();
        store.dispatch(signOut());
        return Promise.reject(rejected);
      }

      // The refresh request succeeded, save the token details for future
      // requests and make the queued requests again.
      const { errors, ...newTokenData } = response.data;
      localStorage.setItem("TOKEN_DETAILS", JSON.stringify(newTokenData));
      reqHandler.onTokenFetched(newTokenData.access_token);
    }

    // Return the promise, which will fulfill when we have a new token,
    // instead of the error.
    return awaitingNewToken;
  } catch (error) {
    // Something went generically wrong, return this error.
    store.dispatch(signOut());
    return Promise.reject(error);
  }
};

export type ApiResponse<T = any, E = any> = {
  details: T;
  errors: { field_name: keyof E; message_code: string }[];
  id: number;
  status: "0" | "1";
};

export const isSuccessResponse = (response: ApiResponse) =>
  response.status === "1";

export const useTable = createUseTable(instance);

export type TableInfo = ReturnType<typeof useTable>;

export default instance;
