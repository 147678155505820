import React from "react";
import { Transition } from "react-transition-group";
import { useOnClickOutside } from "@avamae/hooks";
import Cross from "assets/images/Icon_Modal_Cross.png";

type ModalProps = {
  children: React.ReactNode;
  open: boolean;
  closeModal(): void;
  title: string;
};

const Modal: React.FC<ModalProps> = ({
  children,
  open,
  closeModal,
  title,
}) => {
  const bind = useOnClickOutside(closeModal);

  return (
    <Transition in={open} timeout={100} mountOnEnter unmountOnExit>
      {state => (
        <div className={"ModalUnderlay " + state}>
          <div className="Modal" {...bind}>
            <div className="Inner">
              <div className="Top">
                <h2 className="Title">{title}</h2>
                <div className="CloseButton">
                  <img className="CloseButton" alt="Logo" src={Cross} onClick={closeModal} />
                </div>
              </div>
              <div className="Children">{children}</div>
            </div>
          </div>
        </div>
      )}
    </Transition>
  )

}

export { Modal };