import { NotificationsItem } from "../pages/NotificationSettings/NotificationSettings";
import { Reducer, combineReducers } from "redux";

export type NotificationType =
  | "b_email_notification_selected"
  | "b_push_notification_selected";

  //| {
  //  type: "UPDATE_NOTIFICATIONS",
  //  id: number,
  //  bool: boolean;
  //  settingChange: NotificationType
  //};
  
export type NotificationsListState = NotificationsItem[];

type NotificationsAction = {
  type:
  | "GET_NOTIFICATIONS_LIST"
  | "GETTING_NOTIFICATIONS_LIST"
  | "ERROR_NOTIFICATIONS_LIST";
} | { type: "GOT_NOTIFICATIONS_LIST", payload: NotificationsItem[] }
  | { type: "UPDATE_NOTIFICATIONS", id: number, bool: boolean, settingChange: NotificationType }

const list: Reducer = (
  state: NotificationsListState = [],
  action: NotificationsAction
): NotificationsListState => {
  switch (action.type) {
    case "GET_NOTIFICATIONS_LIST":
    case "GETTING_NOTIFICATIONS_LIST":
    case "ERROR_NOTIFICATIONS_LIST":
      return [];
    case "GOT_NOTIFICATIONS_LIST":
      return [...action.payload];
    case "UPDATE_NOTIFICATIONS":
      let copy = [...state]
      let index = copy.findIndex((o) => o.notification_id === action.id)
      let newObj = { ...copy[index] }
      newObj[action.settingChange] = action.bool
      copy.splice(index, 1, newObj)
      return copy;
      //console.log(copy.find(obj => obj.notification_id === action.id))

      //return state
      //return state.find(obj => obj.notification_id === action.id)
    default:
      return state;
  }
};


export const notificationsReducer = combineReducers({
  list
});
