import React, { useReducer } from "react";
import { Link, navigate, RouteComponentProps, Location } from "@reach/router";
import { Transition } from "react-transition-group";
import { selectPanos, signOut as signOutAction } from "reducers/auth";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
//import { VERSION } from "serverDetails";
import Logo from "assets/images/logo.svg";
import { AppState } from "../../../reducers/rootReducer";

import activeHome from "../../../assets/images/Icon_Aside_03_Active.png";
import inactiveHome from "../../../assets/images/Icon_Aside_03.png";
import activeUsers from "../../../assets/images/Icon_Aside_04_Active.png";
import inactiveUsers from "../../../assets/images/Icon_Aside_04.png";
import activeSubscription from "../../../assets/images/Icon_Aside_02_Active.png";
import inactiveSubscription from "../../../assets/images/Icon_Aside_02.png";
import closeMenu from "../../../assets/images/Icon_Mobile_Menu_Cross.png";
import hamburger from "../../../assets/images/Icon_Mobile_Menu.png";
import SmallLogo from "../../../assets/images/Logo_Mobile.png";
import HomeLogo from "./assets/Home.svg";

export interface NavProps {
  isAuthenticated: boolean;
}

type IconObj = {
  active: string;
  inactive: string;
};

type NavItems = {
  path: string;
  label: string;
  icon?: IconObj;
  children?: { path: string; label: string }[];
}[];

//const items: NavItems = [{ path: "/qr-code", label: "QR Code" }];

type State = {
  index: number;
  status: boolean;
  selected: number;
};

const initialState: State = {
  index: 0,
  status: false,
  selected: -1,
};

type Action =
  | { type: "set_index"; payload: number }
  | { type: "set_status"; payload: boolean }
  | { type: "set_selected"; payload: number };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "set_index":
      return { ...state, index: action.payload };
    case "set_status":
      return { ...state, status: action.payload };
    case "set_selected":
      return { ...state, selected: action.payload };
    default:
      throw new Error(`Impossible reducer action: ${action}`);
  }
};

const NavLinks = ({
  store,
  dispatch,
  items,
  enterpriseUser,
}: {
  store: State;
  dispatch: React.Dispatch<Action>;
  items: NavItems;
  enterpriseUser?: boolean;
}) => {
  const { index } = store;
  const setIndex = (i: number) => () =>
    dispatch({ type: "set_index", payload: index === i ? -1 : i });
  const setStatus = (payload: boolean) =>
    dispatch({ type: "set_status", payload });

  let rDispatch = useDispatch();

  const goHome = () => {
    navigate("/");
    rDispatch({
      type: "CLOSE_USER_DETAILS",
    });
  };

  return (
    <Location>
      {({ location }) =>
        items.map((item, i) => {
          const displayChildButton = item.children && item.children.length > 0;
          const displayChildren = displayChildButton && index === i;
          return (
            <div
              className={classNames("NavBarCategoryContainer")}
              key={item.path}
            >
              <div className="NavBarMenuItem">
                <Link
                  onClick={() => {
                    setStatus(false);
                    if (item.label === "Data Sharing") {
                      goHome();
                    }
                  }}
                  to={item.path}
                  className={`${
                    location && location.pathname === item.path ? "Active" : ""
                  }`}
                >
                  {item.icon && (
                    <img
                      src={`${
                        location && location.pathname === item.path
                          ? item.icon.active
                          : item.icon.inactive
                      }`}
                      className="NavBarIcon"
                      alt={item.label}
                    />
                  )}
                  <span
                    className={`${
                      item.icon ? "NavLinkTextLarge" : "NavLinkText"
                    }`}
                  >
                    {item.label}
                  </span>
                </Link>
                {displayChildButton && (
                  <button className={""} onClick={setIndex(i)}>
                    {index === i ? "-" : "+"}
                  </button>
                )}
              </div>
              {displayChildren && (
                <div>
                  {item.children &&
                    item.children.map(c => (
                      <Link
                        key={c.path}
                        to={c.path}
                        className={`${
                          location && location.pathname === item.path
                            ? "Active"
                            : ""
                        }`}
                      >
                        <div>{c.label}</div>
                      </Link>
                    ))}
                </div>
              )}
            </div>
          );
        })
      }
    </Location>
  );
};

export const NavBar = (props: NavProps & RouteComponentProps) => {
  const [store, dispatch] = useReducer(reducer, initialState);
  const panos = useSelector(selectPanos);
  const rightMenuStatus = useSelector(
    (state: AppState) => state.rightPopOutReducer.rightPopOutOpen
  );
  const enterpriseUser = useSelector(
    (state: AppState) => state.auth.client_type
  );
  const primaryUser = useSelector((state: AppState) => state.auth.primary_user);
  const showUsers = useSelector(
    (state: AppState) => state.auth.b_users_feature
  );
  const client_logo = useSelector((state: AppState) => state.auth.client_logo);
  //const { index, status, selected } = store;
  const { status } = store;
  const reduxDispatch = useDispatch();
  const signOut = () => {
    navigate("/");
    dispatch({ type: "set_status", payload: false });
    reduxDispatch(signOutAction());
  };

  const homeItem: NavItems = [
    {
      path: "/home",
      label: "Home",
      icon: { active: activeHome, inactive: inactiveHome },
    },
  ];
  const manageItems: NavItems = [
    {
      path: "/",
      label: "Subscription",
      icon: { active: activeSubscription, inactive: inactiveSubscription },
    },
    ...(showUsers
      ? [
          {
            path: "/users",
            label: "Users",
            icon: { active: activeUsers, inactive: inactiveUsers },
          },
        ]
      : []),
  ];
  const manageItemsNotPrimaryUser: NavItems = [
    // { path: "/users", label: "Users", icon: { active: activeUsers, inactive: inactiveUsers } },
    {
      path: "/",
      label: "Subscription",
      icon: { active: activeSubscription, inactive: inactiveSubscription },
    },
  ];

  const accountItems: NavItems = [
    { path: "/view-field-reports", label: "View field reports" },
    ...(panos && Array.isArray(panos) && panos.length > 0
      ? [{ path: "/view-scout-spheres", label: "View scout spheres" }]
      : []),
    { path: "/personal-information", label: "Personal information" },
    { path: "/change-password", label: "Change password" },
    { path: "/payment-cards", label: "Payment cards" },
    { path: "/transaction-history", label: "Transaction history" },
    //{ path: "/notification-settings", label: "Notification settings" },
    { path: "/activate-new-device", label: "Activate new device" },
    { path: "/upload-fields", label: "Upload fields" },
    { path: "/third-party-integrations", label: "Integrations" },
    { path: "/application-maps", label: "Application maps" },
  ];

  const loggedOutItems: NavItems = [
    { path: "/", label: "Log in" },
    { path: "/register", label: "Register" },
  ];

  return (
    <>
      <nav
        className={`NavBarContainer ${rightMenuStatus ? "RightOpen" : ""} ${
          props.isAuthenticated ? "" : "LoggedOut"
        }`}
      >
        <a className="NavBarLogo" href="http://skippy.farm">
          <img className="NavBarLogoImg" alt="Logo" src={Logo} />
        </a>
        {enterpriseUser === "Enterprise" && (
          <p className="EnterpriseUserTag">Enterprise</p>
        )}
        {client_logo && (
          <a className="NavBarLogo" href="http://skippy.farm">
            <img className="NavBarLogoImg" alt="Logo" src={client_logo} />
          </a>
        )}
        {props.isAuthenticated && (
          <Transition in={status} timeout={300}>
            {(state: string) => (
              <div className={`NavBarContentContainer ${state}`}>
                <div className="NavBarMenu">
                  <div className="MenuBig">
                    <NavLinks
                      store={store}
                      dispatch={dispatch}
                      items={homeItem}
                    />
                  </div>
                  <p>Manage</p>
                  <div className="MenuBig">
                    {primaryUser ? (
                      <NavLinks
                        store={store}
                        dispatch={dispatch}
                        items={manageItems}
                      />
                    ) : (
                      <NavLinks
                        store={store}
                        dispatch={dispatch}
                        items={manageItemsNotPrimaryUser}
                      />
                    )}
                  </div>

                  <p>My account</p>
                  <NavLinks
                    store={store}
                    dispatch={dispatch}
                    items={accountItems}
                    enterpriseUser={enterpriseUser === "Enterprise"}
                  />
                  <div className="NavBarCategoryContainer LogOutMobileNav">
                    <div className="NavBarMenuItem">
                      <div
                        className="LogOutMobileNavText"
                        onClick={() => signOut()}
                      >
                        Log out
                      </div>
                    </div>
                  </div>
                  <div className={`NavBarBottom ${status ? "Active" : ""}`}>
                    <button
                      className="Btn BtnFullWidth BtnGrey BtnNav"
                      onClick={() => signOut()}
                    >
                      Log out
                    </button>
                    <img
                      onClick={() =>
                        dispatch({ type: "set_status", payload: false })
                      }
                      className="BtnNavCross"
                      src={closeMenu}
                      alt="Close"
                    />
                  </div>
                </div>
              </div>
            )}
          </Transition>
        )}

        {!props.isAuthenticated && (
          <Transition in={status} timeout={300}>
            {(state: string) => (
              <div className={`NavBarContentContainer ${state}`}>
                <div className="NavBarMenu">
                  <NavLinks
                    store={store}
                    dispatch={dispatch}
                    items={loggedOutItems}
                  />
                  <div className={`NavBarBottom ${status ? "Active" : ""}`}>
                    <img
                      onClick={() =>
                        dispatch({ type: "set_status", payload: false })
                      }
                      className="BtnNavCross"
                      src={closeMenu}
                      alt="Close"
                    />
                  </div>
                </div>
              </div>
            )}
          </Transition>
        )}
      </nav>
      <div className={`NavBarTrigger ${status ? "Active" : ""}`}>
        <div className="NavLogoContainer">
          <Link to="/home">
            <img
              className="NavSmallLogo"
              src={SmallLogo}
              alt="Skippy Scout Logo"
            />
          </Link>

          {enterpriseUser === "Enterprise" && (
            <div className="EnterpriseUserTag">Enterprise</div>
          )}
        </div>
        <img
          className="BtnNavMenu"
          onClick={() => dispatch({ type: "set_status", payload: true })}
          src={hamburger}
          alt="Open Menu"
        />
      </div>

      {props.isAuthenticated && (
        <Transition in={status} timeout={300}>
          {(state: string) => (
            <div className={`NavBarContentContainerSmall ${state}`}>
              <div className="NavBarMenu">
                <p>Manage</p>
                <div className="MenuBig">
                  <NavLinks
                    store={store}
                    dispatch={dispatch}
                    items={manageItems}
                  />
                </div>

                <p>My account</p>
                <NavLinks
                  store={store}
                  dispatch={dispatch}
                  items={accountItems}
                  enterpriseUser={enterpriseUser === "Enterprise"}
                />
                <div className="NavBarCategoryContainer LogOutMobileNav">
                  <div className="NavBarMenuItem">
                    <div
                      className="LogOutMobileNavText"
                      onClick={() => signOut()}
                    >
                      Log out
                    </div>
                  </div>
                </div>
                <div className={`NavBarBottom ${status ? "Active" : ""}`}>
                  <button
                    className="Btn BtnFullWidth BtnGrey BtnNav"
                    onClick={() => signOut()}
                  >
                    Log out
                  </button>
                  <img
                    onClick={() =>
                      dispatch({ type: "set_status", payload: false })
                    }
                    className="BtnNavCross"
                    src={closeMenu}
                    alt="Close"
                  />
                </div>
              </div>
            </div>
          )}
        </Transition>
      )}

      {!props.isAuthenticated && (
        <Transition in={status} timeout={300}>
          {(state: string) => (
            <div className={`NavBarContentContainerSmall ${state}`}>
              <div className="NavBarMenu">
                <NavLinks
                  store={store}
                  dispatch={dispatch}
                  items={loggedOutItems}
                />
                <div className={`NavBarBottom ${status ? "Active" : ""}`}>
                  <img
                    onClick={() =>
                      dispatch({ type: "set_status", payload: false })
                    }
                    className="BtnNavCross"
                    src={closeMenu}
                    alt="Close"
                  />
                </div>
              </div>
            </div>
          )}
        </Transition>
      )}
    </>
  );
};
