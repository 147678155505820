import React from "react";
import axios from "api";
import { useDispatch, useSelector } from "react-redux";
import { endpoints } from "../../serverDetails";
import { AppState } from "../../reducers/rootReducer";

export interface UsersDelete {
  class?: string;
}

const UsersDelete = (props: UsersDelete) => {
  const dispatch = useDispatch();

  const selectedIds = useSelector((state: AppState) => state.tableReducer.selectedUsersPageIds)
  const selectedIdFromModal = useSelector((state: AppState) => state.modalReducer.modalId)
  const handleToast = (message: string) => {
    dispatch({ type: 'SHOW_TOAST', message: message })
    window.setTimeout(() => {
      dispatch({ type: 'HIDE_TOAST' })
    }, 3000);
  }

  const handleSubmit = (

  ) => {

    let id: number[];
    if (selectedIds.length > 0) {
      id = selectedIds;
    } else if (selectedIdFromModal.length > 0) {
      id = selectedIdFromModal;
    } else {
      id = [];
    }
    if (id) {
      //setSubmitting(true);
      axios
        .post(endpoints.users.deleteUsers, { ids: id })
        .then(res => {
          if (res.data.status === "1") {
            //setSubmitting(false)
            dispatch({ type: "CLOSE_MODAL" })
            dispatch({ type: "CLOSE_USER_DETAILS" })
            handleToast("User successfully deleted")
            console.log("inside delete post call")
            dispatch({ type: "SET_SELECTED_USERSPAGE", selectedUsersPageIds: [] })
            dispatch({
              type: "REFRESH_TABLE"
            })
          }
        })
        .catch(err => {
          //setError(true)
          //setSubmitting(false)
        });
    }
  };

  return (
    <>
      <div className={`Delete ${props.class || ''}`}>
        <h3>Permanently Delete</h3>

        <p>Are you sure you want to permanently delete this user?</p>

        <p className="BoldText">You'll permanently lose all information about this user:</p>

        <ul className="InformationList">
          <li>- First name</li>
          <li>- Last name</li>
          <li>- Company name</li>
          <li>- Email address</li>
        </ul>

        <div className="WarningText">
          <img src="/content/images/Icon_Warning.png" alt="Warning" />
          <p>This action cannot be undone</p>
        </div>
      </div>
      <div className="Bottom">
        <button className="Btn BtnWhite" onClick={() => dispatch({ type: "CLOSE_MODAL" })}>
          Cancel
        </button>
        <button className="Btn BtnRed" onClick={() => {
          handleSubmit()
        }}>
          Delete
        </button>
      </div>
    </>
  )
};

export default UsersDelete;
