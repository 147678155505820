export type State = {
  visible: boolean;
  message: string;
};

export type Action =
  | {
    type: "SHOW_TOAST",
    message: string,
    }
  | {
    type: "HIDE_TOAST"
    };

const initialState: State = {
  visible: false,
  message: ""
};


export const toastReducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case "SHOW_TOAST":
      return {
        ...state,
        visible: true,
        message: action.message
      }
    case "HIDE_TOAST":
      return {
        ...state,
        visible: false,
      };
    default:
      return state;
  }
};