export type RightPopOutType =
  | "USERS_ADD_NEW"
  | "USERS_UPDATE_DETAILS"
  | "USERS_UPDATE_PERMISSIONS"
  | "USERS_VIEW"
  | "USERS_FILTER"
  | "USER_DELETE"
  | "SUBSCRIPTION_UPGRADE"
  | "PAYMENT_CARD_ADD_NEW"
  | "PAYMENT_CARD_VIEW"
  | "PAYMENT_CARD_UPDATE"
  | "PAYMENT_CARD_FILTER"
  | "TRANSACTION_HISTORY_VIEW"
  | "TRANSACTION_HISTORY_FILTER"
  | "ADD_REPORT_NOTES"
  | "EDIT_REPORT_NOTES"
  | null;

export type State = {
  rightPopOutId: {
    rightPopOutType: RightPopOutType;
    selectedId?: number[];
    data?: any;
  };
  rightPopOutOpen: RightPopOutType;
};

export type Action =
  | {
      type: "OPEN_RIGHT_POP_OUT";
      id?: number[];
      rightPopOutOpen: RightPopOutType;
    }
  | {
      type: "CLOSE_RIGHT_POP_OUT";
    }
  | { type: "app/auth/SIGN_OUT" };

const initialState: State = {
  rightPopOutId: { rightPopOutType: null, selectedId: [] },
  rightPopOutOpen: null,
};

export const rightPopOutReducer = (
  state = initialState,
  action: Action
): State => {
  switch (action.type) {
    case "OPEN_RIGHT_POP_OUT":
      return {
        ...state,
        rightPopOutId: {
          rightPopOutType: action.rightPopOutOpen,
          selectedId: action.id || [],
        },
        rightPopOutOpen: action.rightPopOutOpen,
      };
    case "CLOSE_RIGHT_POP_OUT":
    case "app/auth/SIGN_OUT":
      return {
        ...state,
        rightPopOutId: { rightPopOutType: null, selectedId: [] },
        rightPopOutOpen: null,
      };

    default:
      return state;
  }
};
