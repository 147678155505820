import React from "react";
import { Transition } from "react-transition-group";
import { useOnClickOutside } from "@avamae/hooks";
import Cross from "assets/images/Icon_Modal_Cross.png";

type RightPopOutProps = {
  children: React.ReactNode;
  open: boolean;
  closeModal(): void;
  title: string;
};

const RightPopOut: React.FC<RightPopOutProps> = ({
  children,
  open,
  closeModal,
  title
}) => {
  const bind = useOnClickOutside(closeModal);
  let transition = children ? open : false
  return (
    <Transition in={transition} timeout={100} mountOnEnter unmountOnExit>
      {state => (
        <>
          <div className={"RightPopOutUnderlay " + state}>
          </div>

          <div className={"RightPopOut " + state} {...bind}>
            <div className="Inner">
              <div className="Top">
                <h2 className="Title">{title}</h2>
                <div className="CloseButton">
                  <img className="CloseButton" alt="Logo" src={Cross} onClick={closeModal} />
                </div>
              </div>
              <div className="Children">{children}</div>
            </div>
          </div>
        </>
      )}
    </Transition>
  )
}

export { RightPopOut };