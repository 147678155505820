import React from "react";
import { endpoints } from "serverDetails";
import { AxiosRequestConfig } from "axios";
import axios from "api";
import { ApiReturn } from "./models";

const fieldReportUrl = endpoints.fieldReport.reportDetails;
const fieldReportSectionUrl =
  endpoints.reportsModule.getFieldReportSectionReportDetails;
const fieldReportFooterUrl = endpoints.fieldReport.reportFooterDetails;
const getImageUrl = endpoints.fieldReport.getImage;

const getFieldReportsFooter = async (
  id: string | number | string[] | null | undefined,
  token: string | string[] | null | undefined
): Promise<ApiReturn> => {
  const reqConfig: AxiosRequestConfig = {
    params: {
      flight_id: id,
      token,
    },
  };

  try {
    const res = await axios.get(fieldReportFooterUrl, reqConfig);
    if (res.status === 1 || res.status === 200) {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: res.data,
        errors: res.data.errors,
      };
    }
  } catch (error) {
    return {
      response: null,
      errors: error,
    };
  }
};

const getFieldReports = async (
  id: string | number | string[] | null | undefined,
  token: string | string[] | null | undefined
): Promise<ApiReturn> => {
  const reqConfig: AxiosRequestConfig = {
    params: {
      flight_id: id,
      token,
    },
  };

  try {
    const res = await axios.get(fieldReportUrl, reqConfig);
    if (res.status === 1 || res.status === 200) {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: res.data,
        errors: res.data.errors,
      };
    }
  } catch (error) {
    return {
      response: null,
      errors: error,
    };
  }
};

export const getFieldFromReportSection = async (
  id: number
): Promise<ApiReturn> => {
  const reqConfig: AxiosRequestConfig = {
    params: {
      flightId: id,
    },
  };

  try {
    const res = await axios.get(fieldReportSectionUrl, reqConfig);
    if (res.status === 1 || res.status === 200) {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: null,
        errors: null,
      };
    }
  } catch (error) {
    return {
      response: null,
      errors: error,
    };
  }
};

const getKRPanoFieldReports = async (
  id: string | number | string[] | null | undefined,
  guid: string | string[] | null | undefined
): Promise<ApiReturn> => {
  const reqConfig: AxiosRequestConfig = {
    params: {
      flight_id: id,
      guid,
    },
  };

  try {
    const res = await axios.get(fieldReportUrl, reqConfig);
    if (res.status === 1 || res.status === 200) {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: res.data,
        errors: res.data.errors,
      };
    }
  } catch (error) {
    return {
      response: null,
      errors: error,
    };
  }
};

const getImage = async (
  id: string | number | string[] | null | undefined,
  token: string | string[] | null | undefined
): Promise<ApiReturn> => {
  const reqConfig: AxiosRequestConfig = {
    params: {
      image_id: id,
      token,
    },
  };

  try {
    const res = await axios.get(getImageUrl, reqConfig);
    if (res.status === 1 || res.status === 200) {
      return {
        response: res.data,
        errors: null,
      };
    } else {
      return {
        response: res.data,
        errors: res.data.errors,
      };
    }
  } catch (error) {
    return {
      response: null,
      errors: error,
    };
  }
};

export {
  getFieldReports,
  getKRPanoFieldReports,
  getFieldReportsFooter,
  getImage,
};
