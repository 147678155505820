import React from "react";
import ChunkyInput from "../../components/SignInComponents/ChunkyInput/ChunkyInput";
import { PersonalInformationFormInnerProps } from "components/SignInComponents/PersonalInformationForm/PersonalInformationForm";
import { Field, ErrorMessage } from "formik";
import Spinner from "react-spinkit";
import { PhoneNumberField } from "components/FormComponents/PhoneNumber/PhoneNumber";

const PersonalInformationFormRegistrationInner = (
  props: PersonalInformationFormInnerProps
) => {
  const isPersonalInfoStep = props.isCurrentStep && props.isCurrentStep("personalInfo");
  if (isPersonalInfoStep) {
    return (
      <div className="PersonalInfoForm">
        {!props.enterpriseActivation && (
          <div className="FormBox">
            <ChunkyInput
              type="company_name"
              name="company_name"
              id="company_name"
              placeholder="Company name"
              maxLength={75}
            />
            <ErrorMessage name="company_name">
              {message => <span className="ErrorMessageLeft">{message}</span>}
            </ErrorMessage>
          </div>
        )}
        <div className="FormRow">
          <div className="FormBox MultiInput">
            <ChunkyInput
              type="first_name"
              name="first_name"
              id="first_name"
              placeholder="First name"
              maxLength={50}
            />
            <ErrorMessage name="first_name">
              {message => <span className="ErrorMessageLeft">{message}</span>}
            </ErrorMessage>
          </div>

          <div className="FormBox MultiInput">
            <ChunkyInput
              type="last_name"
              name="last_name"
              id="last_name"
              placeholder="Last name"
              maxLength={50}
            />
            <ErrorMessage name="last_name">
              {message => <span className="ErrorMessageLeft">{message}</span>}
            </ErrorMessage>
          </div>
        </div>

        <div className="FormBox">
          <ChunkyInput
            type="email_address"
            name="email_address"
            id="email_address"
            placeholder="Email address"
            maxLength={75}
          />
          <ErrorMessage name="email_address">
            {message => <span className="ErrorMessageLeft">{message}</span>}
          </ErrorMessage>
        </div>
        <div className="FormBox">
          <PhoneNumberField name={"phone_number"} label={"Phone number"} />
          <ErrorMessage name="phone_number">
            {message => <span className="ErrorMessage">{message}</span>}
          </ErrorMessage>
        </div>
        <>
          <div className="FormBox">
            <ChunkyInput
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              maxLength={20}
            />
            <ErrorMessage name="password">
              {message => <span className="ErrorMessageLeft">{message}</span>}
            </ErrorMessage>
            <div className="FormHint">At least 8 characters long</div>
          </div>

          <div className="FormBox">
            <div className="RememberForgot">
              <div className="Checkbox">
                <Field
                  name="bTermsConditions"
                  type="checkbox"
                  id="bTermsConditions"
                  checked={props.values.bTermsConditions}
                />
                <label className="CustomInput" htmlFor="bTermsConditions">
                  I agree with the &nbsp;
                  <a
                    href="https://www.skippy.farm/terms-conditions-privacy-and-cookies/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms and conditions
                  </a>
                </label>
              </div>
              <ErrorMessage name="bTermsConditions">
                {message => <span className="ErrorMessageLeft">{message}</span>}
              </ErrorMessage>
            </div>
          </div>
        </>

        <div className="FormBottom">
          <button
            className={`Btn BtnFullWidth ${props.password ? "" : "BtnMargin"}`}
            type="submit"
            //disabled={props.isSubmitting || !props.isValid}
            disabled={props.isSubmitting}
            id="personal-information-submit"
          >
            {props.isSubmitting ? (
              <Spinner
                name="three-bounce"
                className="BtnSpinner"
                color="white"
                fadeIn="none"
              />
            ) : (
              "Save and continue"
            )}
          </button>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export { PersonalInformationFormRegistrationInner };
