import React, { useState, useContext, createContext } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import ChunkyInput from "../ChunkyInput/ChunkyInput";
import { CardElement, injectStripe } from "react-stripe-elements";
import Spinner from "react-spinkit";
import { any } from "ramda";

export interface PaymentCardFormInnerProps {
  stripe?: any;
  isSubmitting?: boolean;
  isValid?: boolean;
  promoCodeValue?: number;
  promoCodeType?: string;
  subscriptionGross?: number;
  fullDiscount?: boolean;
  setFullDiscount?: (val: boolean) => void;
  disableCardInput?: boolean;
  skipPaymentOption: boolean;
  promoCodeVisible: boolean;
  setDisableCardInput?: React.Dispatch<React.SetStateAction<boolean>>;
  setValidationSchema?: (val: any) => void;
  paymentValidationSchema?: any;
  validationSchema?: any;
  noPaymentDetailsValidationSchema?: any;
  submitError: string;
}

interface PaymentCardFormProps {
  submit: (values?: any, token?: string) => void;
  stripe?: any;
  isSubmitting?: boolean;
  isValid?: boolean;
  promoCodeValue?: number;
  promoCodeVisible: boolean;
  promoCodeType?: string;
  subscriptionGross?: number;
  setValidationSchema?: (val: any) => void;
  validationSchema?: any;
  paymentValidationSchema?: any;
  noPaymentDetailsValidationSchema?: any;
  fullDiscount?: boolean;
  skipPaymentOption: boolean;
  setFullDiscount: (val: boolean) => void;
  disableCardInput?: boolean;
  setDisableCardInput: React.Dispatch<React.SetStateAction<boolean>>;
  submitError: string;
}

//const validationSchema = Yup.object({
//  card_name: Yup.string().required("Card name is required"),
//  //card_number: Yup.number().required("Card number is required").test('len', 'Card number must be 16 characters', val => val && val.toString().length === 16),
//  card_number: Yup.string().matches(/^[0-9 ]*$/, "Please enter a valid card number").required("Card number is required"),
//  expiry_date: Yup.date().typeError("Please enter a valid date").required("Expiry date is required"),
//  //cvv: Yup.number().test('len', 'CVV must be 3 characters', val => val && val.toString().length === 3)
//  cvv: Yup.string().matches(/^[0-9]*$/, "Please enter a valid CVV").max(3, "Must be 3 charatcers").min(3, "Must be 3 characters").required("CVV is required"),
//});

const defaultValidationSchema = Yup.object({
  card_name: Yup.string().required("Name on card is required"),
  card_details: Yup.object()
    .shape({
      brand: Yup.string(),
      complete: Yup.boolean().oneOf(
        [true],
        "Card details must all be completed"
      ),
      elementType: Yup.string(),
      empty: Yup.boolean().oneOf([true], "Card details must all be completed"),
      error: Yup.mixed(),
      value: Yup.object(),
    })
    .required("Card details are required"),
});

export const PaymentCardFormInner = (props: PaymentCardFormInnerProps) => {
  const {
    promoCodeType,
    skipPaymentOption,
    promoCodeValue,
    subscriptionGross,
    isSubmitting,
    submitError,
  } = props;
  const isCodeFullDiscount = React.useCallback(() => {
    if (
      promoCodeType &&
      promoCodeValue &&
      promoCodeValue === 100 &&
      (promoCodeType === "Percentage_Off" ||
        promoCodeType === "Expires_In_Days")
    ) {
      return true;
    } else if (
      promoCodeType &&
      promoCodeValue &&
      subscriptionGross &&
      promoCodeValue >= subscriptionGross &&
      promoCodeType === "Amount_Off"
    ) {
      return true;
    }

    return false;
  }, [promoCodeType, promoCodeValue, subscriptionGross]);

  React.useEffect(() => {
    if (isCodeFullDiscount()) {
      props.setFullDiscount && props.setFullDiscount(true);
    } else {
      props.setFullDiscount && props.setFullDiscount(false);
    }
  }, [isCodeFullDiscount]);

  return (
    <div className="PaymentCardForm">
      <>
        <div
          className={props.disableCardInput ? "FormBox Disabled" : "FormBox"}
        >
          <ChunkyInput
            type="card_name"
            name="card_name"
            id="card_name"
            placeholder="Name on card"
            maxLength={200}
            disabled={props.disableCardInput}
          />

          {!props.disableCardInput && (
            <ErrorMessage name="card_name">
              {message => <span className="ErrorMessageLeft">{message}</span>}
            </ErrorMessage>
          )}
        </div>
        <div
          className={props.disableCardInput ? "FormBox Disabled" : "FormBox"}
        >
          <div className="FormLabel">
            <label htmlFor="card_details">Card details</label>
          </div>
          <div className="FormField">
            <Field>
              {({ field, form }: any) => (
                <CardElement
                  disabled={props.disableCardInput}
                  className={`EditBox`}
                  id={"card_details"}
                  onChange={val =>
                    form.setErrors({
                      ...form.errors,
                      card_details: val.error && val.error.message,
                    })
                  }
                />
              )}
            </Field>
            <ErrorMessage name="card_details">
              {message => <span className="ErrorMessageLeft">{message}</span>}
            </ErrorMessage>
          </div>
        </div>
      </>

      {/* {props.skipPaymentOption && (
        <div className="Checkbox">
          <input
            key={"isPromoVisible"}
            name="full_discount"
            type="checkbox"
            id="full_discount"
            checked={!!props.disableCardInput}
            onChange={() => {
              props.setDisableCardInput && props.setDisableCardInput(d => !d);
              if (props.validationSchema === props.paymentValidationSchema) {
                props.setValidationSchema &&
                  props.setValidationSchema(
                    props.noPaymentDetailsValidationSchema
                  );
              } else {
                props.setValidationSchema &&
                  props.setValidationSchema(props.paymentValidationSchema);
              }
            }}
          />
          <label className="CustomInput" htmlFor="full_discount">
            Skip for now
          </label>
        </div>
      )} */}

      <div className="FormBottom">
        <button
          className={"Btn BtnFullWidth"}
          type="submit"
          disabled={
            submitError === "Registration_Subscription_Failed"
              ? true
              : isSubmitting
          }
          id="payment-submit"
        >
          {submitError === "Registration_Subscription_Failed" ? (
            "Something went wrong, we are working to fix the problem."
          ) : isSubmitting ? (
            <Spinner
              name="three-bounce"
              className="BtnSpinner"
              color="white"
              fadeIn="none"
            />
          ) : (
            "Get Skippy"
          )}
        </button>
      </div>
    </div>
  );
};

const PaymentCardForm = (props: PaymentCardFormProps) => {
  const [validationSchema, setValidationSchema] = React.useState<any>(
    defaultValidationSchema
  );
  //const initialValues = {
  //  card_name: "",
  //  card_number: "",
  //  expiry_date: null,
  //  cvv: "",
  //}

  React.useEffect(() => {
    if (props.fullDiscount) {
      setValidationSchema({});
    }
  }, [props.fullDiscount]);

  const stripeToken = async (
    values: any,
    setFieldError: (label: string, message: string) => void,
    setSubmitting: (status: boolean) => void
  ) => {
    let { token, error } = await props.stripe.createToken({
      name: values.card_name,
    });

    if (token) {
      props.submit(values, token);
    } else if (error) {
      setFieldError("card_details", error.message);
      setSubmitting(false);
    }
  };

  const initialValues = {
    card_name: "",
    card_details: {},
  };

  return (
    <PaymentCardFormInner
      isSubmitting={props.isSubmitting}
      isValid={props.isValid}
      promoCodeType={props.promoCodeType}
      promoCodeValue={props.promoCodeValue}
      promoCodeVisible={props.promoCodeVisible}
      subscriptionGross={props.subscriptionGross}
      fullDiscount={props.fullDiscount}
      setFullDiscount={props.setFullDiscount}
      disableCardInput={props.disableCardInput}
      submitError={props.submitError}
      setDisableCardInput={props.setDisableCardInput}
      skipPaymentOption={props.skipPaymentOption}
      setValidationSchema={props.setValidationSchema}
      paymentValidationSchema={props.paymentValidationSchema}
      noPaymentDetailsValidationSchema={props.noPaymentDetailsValidationSchema}
      validationSchema={props.validationSchema}
    />
  );
};

export default injectStripe(PaymentCardForm);
