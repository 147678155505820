import React, { SetStateAction } from "react";

type UserTypeSelectProps = {
  setSharingUser: React.Dispatch<SetStateAction<boolean | null>>;
  name: string;
  active: boolean;
  selected?: boolean;
  disabled?: boolean;
};

const UserTypeSelect: React.FC<UserTypeSelectProps> = ({
  setSharingUser,
  active,
  name,
  selected,
  disabled,
}) => {
  if (selected) {
    setSharingUser(true);
  }

  const handleClick = () => {
    if (name === "Sharing user") {
      setSharingUser(true);
    } else {
      setSharingUser(false);
    }
  };

  return (
    <>
      <div
        className={`UserTypeSelect ${disabled ? "Disabled" : ""}`}
        onClick={handleClick}
      >
        <div
          className={
            active || selected
              ? "UserTypeSelectInner Active"
              : "UserTypeSelectInner"
          }
        >
          <div className="UserTypeSelector">
            <label className="container">
              <input
                type="radio"
                checked={selected ? true : active}
                name="userType"
              />
              <span className="checkmark"></span>
            </label>
            <p>{name}</p>
          </div>
          <div className="UserTypeDescription">
            {name === "Sharing user" ? (
              <p>
                Add a sharing user to give them access to view your data - they
                will not be able to create or edit data.
              </p>
            ) : (
              <p>
                These users have full access to account data and are able to
                create their own farms, fields and flights.
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { UserTypeSelect };
