import * as React from "react";
import { RouteComponentProps, Redirect } from "@reach/router";
import { TableOptions } from "../../helpers/tables/tableOptions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../reducers/rootReducer";
import { RightMenuItems } from "../../components/PageComponents/RightMenu/rightMenu";

import Action01 from "assets/images/Icon_Action_01.png";
import Action03 from "assets/images/Icon_Action_03.png";
import { endpoints } from "../../serverDetails";
import DataTable from "../../components/DataTable";
import Pagination from "../../components/Pagination";
import { useTable } from "../../api";
import { useTableSelection } from "@avamae/table";

import PaymentCardFilter from "../../components/RightPopOutComponents/PaymentCardFilter";
import Helmet from "react-helmet";

const FilterIcon = "/content/images/Icon_Btn_03.png";

//export type PaymentCardItem = {
//  PaymentCards__Id: number;
//  PaymentCards__NameOnCard: string;
//  PaymentCards__CardEndDigits: string;
//  PaymentCards__CardType: string;
//  //expiry_date: Date;
//  PaymentCards__AddDateUTC: string;
//}

export type PaymentCardItem = {
  payment_card_id: number;
  card_name: string;
  card_end_digits: string;
};

export type PaymentCardItems = PaymentCardItem[];

export interface PaymentCardsProps {}

const PaymentCardsPage = (props: RouteComponentProps & PaymentCardsProps) => {
  const dispatch = useDispatch();
  const tableOptionsList = [
    {
      name: "View card",
      onClick: (user_id: number) => {
        dispatch({
          type: "SET_SELECTED_CARDS_NO_MENU",
          selectedCardIds: [user_id],
        });
        dispatch({
          type: "OPEN_RIGHT_POP_OUT",
          rightPopOutId: [user_id],
          rightPopOutOpen: "PAYMENT_CARD_VIEW",
        });
      },
    },
    {
      name: "Delete card",
      onClick: (user_id: number) =>
        dispatch({
          type: "OPEN_MODAL",
          modalId: [user_id],
          modalOpen: "CARD_DELETE",
        }),
    },
  ];

  /* Redux state */
  const tableState = useSelector((state: AppState) => state.tableReducer);

  /* Local state */
  const [visibleUserOptions, setVisibleUserOptions] = React.useState<
    number | null
  >(null);
  const [showMenu, setShowMenu] = React.useState<boolean>(true);

  React.useEffect(() => {
    const rightMenuItems: RightMenuItems = {
      menuItems: [
        {
          icon: Action01,
          name: "View card",
          imgAlt: "View card",
          onClick: () =>
            dispatch({
              type: "OPEN_RIGHT_POP_OUT",
              rightPopOutOpen: "PAYMENT_CARD_VIEW",
            }),
        },
        {
          icon: Action03,
          name: "Delete card",
          imgAlt: "Delete card",
          onClick: () =>
            dispatch({
              type: "OPEN_MODAL",
              modalOpen: "CARD_DELETE",
            }),
        },
      ],
      alternateMenuItems: {
        menuItems: [
          {
            icon: Action03,
            name: "Delete card",
            imgAlt: "Delete card",
            onClick: () =>
              dispatch({
                type: "OPEN_MODAL",
                modalOpen: "CARD_DELETE",
              }),
          },
        ],
      },
    };

    dispatch({ type: "SET_RIGHT_MENU_ITEMS", rightMenuItems: rightMenuItems });
    return () => {
      dispatch({ type: "SET_SELECTED_CARDS", selectedCardIds: [] });
      dispatch({
        type: "SET_RIGHT_MENU_ITEMS",
        rightMenuItems: {
          menuItems: [],
          alternateMenuItems: { menuItems: [] },
        },
      });
    };
  }, [dispatch]);

  const toggleUserOptions = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const targetId = parseInt(e.currentTarget.id, 10);
    if (targetId) {
      visibleUserOptions !== targetId
        ? setVisibleUserOptions(targetId)
        : setVisibleUserOptions(null);
    }
  };

  const table = useTable({
    url: endpoints.paymentCards.listPaymentCards,
    pageSize: 9,
  });
  const reload = table && table.reload;

  const refreshStatus = useSelector(
    (state: AppState) => state.tableReducer.refreshData
  );
  const selectedCardIds = useSelector(
    (state: AppState) => state.tableReducer.selectedCardIds
  );
  const [mustAddCard, setMustAddCard] = React.useState<boolean>(true);
  const subscriptionStatus = useSelector(
    (state: AppState) => state.auth.subscriptionStatus
  );

  React.useEffect(() => {
    if (reload && refreshStatus) {
      reload();
      if (selectedCardIds.length < 1) {
        rowSelection.selectAll([]);
      }
      dispatch({
        type: "REFRESH_TABLE",
      });
    }
    //eslint-disable-next-line
  }, [refreshStatus]);

  React.useEffect(() => {
    if (mustAddCard) {
      if (
        table &&
        table.data &&
        table.data.details.filters.length === 0 &&
        table.data.details.listData.length === 0
      ) {
        dispatch({
          type: "OPEN_RIGHT_POP_OUT",
          rightPopOutOpen: "PAYMENT_CARD_ADD_NEW",
        });
        setMustAddCard(false);
      }
    }
  }, [table, mustAddCard, dispatch]);

  const idColumn = "PaymentCards__Id";

  const selectBox = (
    select: () => void,
    selected: boolean,
    id: number | string
  ) => {
    return (
      <div>
        <input
          type="checkbox"
          checked={selected}
          onChange={select}
          id={id.toString()}
          name={id.toString()}
        />
        <label className="CustomInput" htmlFor={id.toString()} />
      </div>
    );
  };

  const rowSelection = useTableSelection<any>(idColumn, selectBox);
  const { selectedIDs } = rowSelection;

  React.useEffect(() => {
    dispatch({
      type: "SET_SELECTED_CARDS",
      selectedCardIds: [...selectedIDs],
    });
    selectedIDs.length > 0 ? setShowMenu(false) : setShowMenu(true);
    //eslint-disable-next-line
  }, [selectedIDs]);

  // if (subscriptionStatus !== "Valid" && subscriptionStatus !== "Initial") {
  //   return <Redirect to="/" noThrow={true} />
  // }

  return (
    <>
      <Helmet>
        <title>Skippy Scout: Payment Cards</title>
      </Helmet>
      <div className="PaymentCards WidthContent HeightContent">
        <div className="PaymentCardsHeader">
          <h1>Payment Cards</h1>
          <div className="HeaderActionsContainer">
            <div className="ButtonContainer">
              <button
                className={`Btn BtnClear ${
                  tableState.selectedCardIds.length > 0 ? "" : "BtnHidden"
                }`}
                onClick={() => {
                  rowSelection.selectAll([]);
                  dispatch({
                    type: "SET_SELECTED_CARDS",
                    selectedCardIds: [],
                    toggleAltMenuItems: true,
                  });
                }}
              >
                <img
                  src={"/content/images/Icon_Btn_06.png"}
                  alt="Clear"
                  className="ClearIcon"
                />
                <span>Clear selection</span>
              </button>

              <button
                className="Btn BtnAdd"
                onClick={() =>
                  dispatch({
                    type: "OPEN_RIGHT_POP_OUT",
                    rightPopOutOpen: "PAYMENT_CARD_ADD_NEW",
                  })
                }
              >
                <img
                  src={"/content/images/Icon_Btn_01.png"}
                  alt="Add"
                  className="AddIcon"
                />
                <span>Add new payment card</span>
              </button>
            </div>
          </div>
        </div>
        <DataTable
          table={table}
          filterAction={() => {
            table && table.data && table.data.details.filters.length > 0
              ? table && table.data && table.data.actions.setFilters([])
              : dispatch({
                  type: "OPEN_RIGHT_POP_OUT",
                  rightPopOutOpen: "PAYMENT_CARD_FILTER",
                });
          }}
          rowSelection={rowSelection}
          rowOptionComponent={(id: any) => (
            <>
              <img
                alt="Filter"
                id={id.toString()}
                className={`FilterIcon ${showMenu ? "" : "Hidden"}`}
                onClick={toggleUserOptions}
                src={FilterIcon}
              />
              <TableOptions
                className="I"
                id={id[0]}
                visibleOptions={visibleUserOptions}
                setVisibleOptions={setVisibleUserOptions}
                optionList={tableOptionsList}
              />
            </>
          )}
        />
        <Pagination table={table} />
        <PaymentCardFilter table={table} />
      </div>
    </>
  );
};

export default PaymentCardsPage;
