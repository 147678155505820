import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFetch } from "../../helpers/loading";
import Spinner from "react-spinkit";
import { endpoints } from "../../serverDetails";
import { formatUTCDateString } from "../../helpers/dates";
import axios, { AxiosRequestConfig } from "axios";
import { downloadFile } from "helpers/downloadFile";

const errorResults = "/content/images/Icon_Error.png"
export interface TransactionHistoryView {
  class?: string;
}

type Data = {
  details: {
    id: number,
    title: string,
    gross_amount: number,
    tax_rate: number,
    tax_amount: number,
    add_date_utc: string,
    b_invoice_available: boolean,
    code: string,
  }
}


const TransactionHistoryView = (props: TransactionHistoryView) => {
  const dispatch = useDispatch();
  const state = useSelector<any, any>(state => state.tableReducer)
  const [invoiceError, setInvoiceError] = useState<string | null>(null);

  const requestConfig = React.useMemo(() => {
    return { params: { Id: state.selectedTransactionIds[0] } };
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    return () => {
      // if opened from ... menu (not from input selection => right menu)
      if (!state.showMenu) {
        dispatch({
          type: "SET_SELECTED_TRANSACTION",
          selectedTransactionIds: []
        })
      }
    }
    //eslint-disable-next-line
  }, [])

  const { data, loading, error } = useFetch<Data>(endpoints.transactionHistory.viewTransactionHistory, requestConfig)

  const handleInvoice = () => {
    (async () => {
      const reqConfig: AxiosRequestConfig = {
        params: {
          id: state.selectedTransactionIds[0],
        },
      };

      const name = data ? data.details.code : "";
      const [url, fileName, fileType] = [endpoints.transactionHistory.downloadInvoice, name, "PDF"]
      downloadFile({ url, fileName, fileType, reqConfig })
    })()
  }

  if (loading) {
    return <span><Spinner name="three-bounce" fadeIn="none" /></span>;
  }

  if (error) return (
    <div className="ErrorResults">
      <div className="NoResults">
        <img src={errorResults} alt={"Error"} />
        <p>Error</p>
      </div>
    </div>
  )

  if (data) {
    const d = data.details
    return (
      <div className={`TransactionHistoryView ${props.class}`}>
        <div className="DisplayRow">
          <div className="DisplayLabel">Description</div>
          <div className="DisplayValue">{d.title}</div>
        </div>

        <div className="DisplayRow">
          <div className="DisplayLabel">Amount</div>
          <div className="DisplayValue">{d.gross_amount !== null && String.fromCharCode(0x00A3)}{d.gross_amount === null ? '-' : d.gross_amount}</div>
        </div>

        <div className="DisplayRow">
          <div className="DisplayLabel">Tax Rate</div>
          <div className="DisplayValue">{d.tax_rate}{d.tax_rate && "%"}</div>
        </div>

        <div className="DisplayRow">
          <div className="DisplayLabel">Tax Amount</div>
          <div className="DisplayValue">{d.tax_amount !== null && String.fromCharCode(0x00A3)}{d.tax_amount === null ? '-' : d.tax_amount}</div>
        </div>

        <div className="DisplayRow">
          <div className="DisplayLabel">Payment date</div>
          <div className="DisplayValue">{formatUTCDateString(d.add_date_utc)}</div>
        </div>
        {d.b_invoice_available &&
          <div className="DisplayRow">
            <button className="Btn" onClick={handleInvoice}><span>Download invoice</span></button>
            <div><p>{invoiceError}</p></div>
          </div>
        }
      </div>
    )
  }
  return null
};

export default TransactionHistoryView;
