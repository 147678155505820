import { Reducer, combineReducers } from "redux";
import { UsersItems } from "../pages/Users/UsersPage";

export type UsersState =
  | "create"
  | "view"
  | "none"
  | "update_details"
  | "delete";

export type UsersAction = {
  type:
    | "ADD_USERS"
    | "VIEW_USERS"
    | "CLOSE_USERS"
    | "UPDATE_USERS"
    | "DELETE_USERS";
};

const users_state: Reducer = (
  state: UsersState = "none",
  action: UsersAction
): UsersState => {
  switch (action.type) {
    case "ADD_USERS":
      return "create";
    case "VIEW_USERS":
      return "view";
    case "CLOSE_USERS":
      return "none";
    case "UPDATE_USERS":
      return "update_details";
    case "DELETE_USERS":
      return "delete";
    default:
      return state;
  }
};

//export type UsersListItem = {
//  Users__Id: number,
//  Users__NameOnCard: string,
//  Users__CardEndDigits: string,
//  Users__CardType: string,
//  //expiry_date: Date;
//  Users__AddDateUTC: string;
//}

export type UsersListItem = {
  id: number;
  initials: string;
  title: string;
  first_name: string;
  last_name: string;
  email_address: string;
  company_name: string;
  telephone: string;
  mobile: string;
  b_primary_user: false;
  //permissions: FarmObject[]; // remove when connecting to endpoint
};

export type UsersListState = UsersListItem[];

type UsersListAction =
  | {
      type: "GET_USERS_LIST" | "GETTING_USERS_LIST" | "ERROR_USERS_LIST";
    }
  | { type: "GOT_USERS_LIST"; payload: UsersItems };

const list: Reducer = (
  state: UsersListState = [],
  action: UsersListAction
): UsersListState => {
  switch (action.type) {
    case "GET_USERS_LIST":
    case "GETTING_USERS_LIST":
    case "ERROR_USERS_LIST":
      return [];
    case "GOT_USERS_LIST":
      return [...action.payload];
    default:
      return state;
  }
};

export const usersReducer = combineReducers({
  users_state,
  list,
});
