import React, { useState, useEffect, useRef, SetStateAction } from "react";
import { RouteComponentProps } from "@reach/router";
import PreLoginPageStructure from "../../components/PageComponents/PreLoginPageStructure/PreLoginPageStructure";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useFetch } from "../../helpers/loading";
import { endpoints } from "../../serverDetails";
import { Elements, injectStripe, CardElement } from "react-stripe-elements";
import * as Yup from "yup";
import axios from "api";
import { useDispatch, useSelector } from "react-redux";
import { FormikActions } from "formik";
import { passwordRegex } from "helpers/regex";
import { RegisterPage } from "./RegisterPage";
import { ecommerceGAEvent } from "helpers/gaFuncs";
import { toPairs } from "ramda";
import { selectAddons } from "reducers/register";
const queryString = require("query-string");

export interface RegistrationProps {
  location?: any;
  navigate?: any;
  stripe?: any;
}

export interface PersonalDetailsObj {
  bTermsConditions: boolean;
  company_name?: "";
  vat_number: "";
  email_address: "";
  phone_number: "";
  first_name: "";
  last_name: "";
  password: "";
  telephone: "";
  title: "";
  mobile: "";
  address_line_1: "";
  address_line_2: "";
  town_city: "";
  county_state: "";
  postcode: "";
  country: "";
  country_id: 0;
  bBillingAddress: boolean;
}

export type InitialValuesObj = {
  first_name: string;
  last_name: string;
  mobile: string;
  telephone: string;
  email_address: string;
  password: string;
  bTermsConditions: boolean;
  card_name: string;
  card_details: any;
};

interface AlreadyAccountPersonalDetailsObj {
  activation_token: string;
  title: string;
  first_name: string;
  last_name: string;
  email_address: string;
  telephone: string;
  mobile: string;
  password: string;
  b_agree_tcs: string;
}
interface EnterpriseDetailsObj {
  bTermsConditions: boolean;
  email_address: "";
  first_name: "";
  last_name: "";
  password: "";
  telephone: "";
  title: "";
  mobile: "";
}

export interface PaymentDetailsObj {
  card_name: "";
  card_number: "";
  expire_month: 0;
  expire_year: 0;
  token_id: "";
  card_brand: "";
  address_zip: "";
}

export interface BillingDetailsObj {
  address_line_1: "";
  address_line_2: "";
  town_city: "";
  country: "";
  country_id: "";
  country_tax: true;
  county_state: "";
  email_address: "";
  first_name: "";
  last_name: "";
  telephone: "";
  postcode: "";
  title: "";
}

export type RegistrationStep = {
  name: string;
  isComplete: boolean;
};

export type RegistrationSteps = {
  currentStepName: string;
  steps: RegistrationStep[];
};

export type RegistrationStepActions = {
  setCurrentRegistrationStep: (val: string) => void;
};

type PersonalInformationProps = {
  registrationStep?: RegistrationSteps;
  setRegistrationStep?: React.Dispatch<SetStateAction<RegistrationSteps>>;
  setCurrentRegistrationStep?: (val: string) => void;
};

const steps = [
  {
    name: "personalInfo",
    isComplete: false,
  },
  {
    name: "subscription",
    isComplete: false,
  },
  {
    name: "addons",
    isComplete: false,
  },
  {
    name: "payment",
    isComplete: false,
  },
  {
    name: "qr",
    isComplete: false,
  },
  {
    name: "personalInfoWithAccount",
    isComplete: false,
  },
];

const Registration = (props: RegistrationProps & RouteComponentProps) => {
  const [registrationStep, setRegistrationStep] = useState<RegistrationSteps>({
    currentStepName: "personalInfo",
    steps,
  });
  const setCurrentRegistrationStep = (
    stepName: string,
    completeStep?: boolean
  ) => {
    if (completeStep) {
      const { steps } = registrationStep;
      const newSteps = steps.map(step => {
        if (step.name === registrationStep.currentStepName) {
          //complete
          return {
            ...step,
            isComplete: true,
          };
        }
        return step;
      });
      setRegistrationStep(s => ({ ...s, steps: newSteps }));
    }
    setRegistrationStep(s => ({ ...s, currentStepName: stepName }));
  };

  const isStepComplete = (stepName: string): boolean => {
    const { steps } = registrationStep;
    const foundStep = steps.find(step => step.name === stepName);
    if (foundStep) {
      return foundStep.isComplete;
    }
    return false;
  };
  const [skipPaymentOption, setSkipPaymentOption] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<string>("personalInfo");
  const [alreadyHasAccount, setAlreadyHasAccount] = useState<boolean | null>(
    null
  );
  const [subscription, setSubscription] = useState<number | null>(null);
  const [subscriptionName, setSubscriptionName] = useState<string>("");
  const [subscriptionPrice, setSubscriptionPrice] = useState<number>(0); //e.g. this will be £60
  const [subscriptionGross, setSubscriptionGross] = useState<number>(0); // then this will be £72
  const [subscriptionType, setSubscriptionType] = useState<string>("");
  const [usersPromoCode, setUsersPromoCode] = useState<string>("");
  const [promoCodeVisible, setPromoCodeVisible] = useState(false);
  const [promoCodeValue, setPromoCodeValue] = useState<number>(0); //promo code value needed
  const [promoCodeType, setPromoCodeType] = useState<string>(""); //type of promo code (Amount or Percentage)
  const [taxRate, setTaxRate] = useState<number>(0);

  const [onLastStep, setOnLastStep] = useState<boolean>(false);
  const [
    personalDetails,
    setPersonalDetails,
  ] = useState<PersonalDetailsObj | null>(null);
  const [
    paymentDetails,
    setPaymentDetails,
  ] = useState<PaymentDetailsObj | null>(null);
  const [
    billingDetails,
    setBillingDetails,
  ] = useState<BillingDetailsObj | null>(null);
  const [submitError, setSubmitError] = useState<string>("");
  const [generalError, setGeneralError] = useState<string>("");
  const [inputError, setInputError] = useState<string>("");
  const [showBackToQR, setShowBackToQR] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState<string>("");
  const [errorType, setErrorType] = useState<
    | "Personal"
    | "Promo"
    | "Card"
    | "General"
    | "EmailAddress"
    | "Password"
    | "Telephone"
    | "Mobile"
    | ""
  >("");
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [qrCodeString, setQrCodeString] = useState<string>("");
  const [refresher, setRefresher] = useState(0);
  const [bQueryStringPromoCode, setbQueryStringPromoCode] = useState(false);
  const refreshErrors = () => setRefresher(p => p + 1);
  const addon_ids = useSelector(selectAddons).map(({ addon_id }) => addon_id);

  const dispatch = useDispatch();

  useEffect(() => {
    const parseQuery = queryString.parse(props.location.search);
    if (parseQuery && parseQuery.code) {
      if (parseQuery.code.includes("<") || parseQuery.code.includes(">")) {
        setUsersPromoCode("");
        setPromoCodeVisible(false);
      } else {
        setUsersPromoCode(parseQuery.code);
        setPromoCodeVisible(true);
        setbQueryStringPromoCode(true);
      }
    }
  }, [props.location]);

  useEffect(() => {
    if (submitError !== "") {
      switch (submitError) {
        case "Account_In_Use":
          setErrorType("Personal");
          setDisplayError(
            "Account already in use, please use a different email address"
          );
          break;
        case "Invalid_Email_Address":
        case "Invalid_Email":
          setErrorType("Personal");
          setDisplayError("Invalid email address");
          break;
        case "Invalid_Telephone_Number":
          setErrorType("Personal");
          setDisplayError("Invalid telephone number");
          break;
        case "Invalid_Promo_Code":
          setErrorType("Promo");
          setDisplayError(
            "There was a problem with your promo code, please update and try again"
          );
          break;
        case "Payment_Failed":
          setErrorType("Card");
          setDisplayError(
            "There was a problem with your payment card, please update and try again"
          );
          break;
        case "Registration_Subscription_Failed":
          setErrorType("General");
          setDisplayError(
            "Something went wrong, we are working to fix the problem."
          );
          break;
        case "Missing_Postcode":
          setErrorType("General");
          setDisplayError("Please enter a valid postcode");
          break;
        case "Invalid_Password_Length":
          setErrorType("Personal");
          setDisplayError(
            "Password must be 8-20 characters, contain at least one letter, one number, one uppercase character one non alpa-numeric character"
          );
          break;
        case "Company_Already_Exist":
          setErrorType("Personal");
          setDisplayError("This company name is already in use");
          break;
        case "Card_Set_Up_Failed":
          setErrorType("Card");
          setDisplayError("There was a problem setting up your card");
          break;
        case "Password_Policy_No_Match":
          setErrorType("Personal");
          setDisplayError("Passwords must contain 1 uppercase character");
          break;
        case "Invalid_VAT_Number":
          setErrorType("Personal");
          setDisplayError("Invalid VAT Number");
          break;
        case "Invalid_Length":
          setErrorType("Personal");
          setDisplayError("VAT Number is not the correct length.");
          break;
        default:
          setErrorType("General");
          setDisplayError("Something went wrong, please refresh and try again");
      }
    }
  }, [submitError, refresher]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activePage]);

  const handleToast = (message: string) => {
    dispatch({ type: "SHOW_TOAST", message: message });
    window.setTimeout(() => {
      dispatch({ type: "HIDE_TOAST" });
    }, 3000);
  };

  const parseError = (submitError: string) => {
    switch (submitError) {
      case "expired_card":
        return "This card has expired";
      default:
        return "There was a problem validating your card";
    }
  };

  const handleSubmit = async (
    subscription: number,
    usersPromoCode: string,
    personalDetails: PersonalDetailsObj | null,
    countryList: any,
    paymentDetails?: PaymentDetailsObj | null,
    billingDetails?: BillingDetailsObj | null
  ) => {
    setSubmitError("");
    setDisplayError("");
    let postObject = {};
    if (
      subscription &&
      personalDetails &&
      paymentDetails &&
      countryList &&
      !disableCardInput
    ) {
      postObject = {
        title: personalDetails.title || "",
        company_name: personalDetails.company_name || "",
        first_name: personalDetails.first_name,
        last_name: personalDetails.last_name,
        email_address: personalDetails.email_address,
        telephone: personalDetails.phone_number,
        password: personalDetails.password,
        postcode: paymentDetails.address_zip,
        b_agree_tcs: personalDetails.bTermsConditions,
        subscription_id: subscription,
        promo_code: promoCodeVisible ? usersPromoCode : "",
        new_stripe_token: paymentDetails.token_id,
        new_card_type: paymentDetails.card_brand,
        new_card_holder_name: paymentDetails.card_name,
        new_card_end_digits: paymentDetails.card_number,
        new_exp_date_month: paymentDetails.expire_month,
        new_exp_date_year: paymentDetails.expire_year,
        new_title: personalDetails.title || "",
        new_first_name: personalDetails.first_name,
        new_last_name: personalDetails.last_name,
        new_billing_postcode: paymentDetails.address_zip,
        b_save_card: true,
        addon_ids: addon_ids && subscriptionType === "Yearly" ? addon_ids : [],
      };
      setSubmitting(true);
    } else if (disableCardInput && personalDetails) {
      postObject = {
        title: personalDetails.title || "",
        company_name: personalDetails.company_name || "",
        first_name: personalDetails.first_name,
        last_name: personalDetails.last_name,
        email_address: personalDetails.email_address,
        telephone: personalDetails.phone_number,
        password: personalDetails.password,
        postcode: "", //personalDetails.postcode,
        b_agree_tcs: personalDetails.bTermsConditions,
        subscription_id: subscription,
        promo_code: promoCodeVisible ? usersPromoCode : "",
        new_stripe_token: "",
        new_card_type: "",
        new_card_holder_name: "",
        new_card_end_digits: "",
        new_exp_date_month: "",
        new_exp_date_year: "",
        new_title: "",
        new_first_name: "",
        new_last_name: "",
        new_billing_postcode: "",
        b_save_card: true,
        addon_ids: addon_ids && subscriptionType === "Yearly" ? addon_ids : [],
      };
      setSubmitting(true);
    }
    axios
      .post(endpoints.register.register, postObject)
      .then(res => {
        if (res.data.status === "1") {
          if (res.data.details.status === "requires_action") {
            props.stripe
              .handleCardAction(res.data.details.client_secret)
              .then((result: any) => {
                if (result.error) {
                  setSubmitting(false);
                  setCurrentRegistrationStep("payment");
                  handleToast("There was a problem verifying your card");
                }

                let payload = {
                  t_id: res.data.details.t_id,
                  stripe_payment_intent_id: result.error
                    ? result.error.payment_intent.id
                    : result.paymentIntent.id,
                };

                axios
                  .post(endpoints.register.update3dSecure, payload)
                  .then(res => {
                    if (res.data.status === "1") {
                      setSubmitting(false);
                      if (!result.error) {
                        setQrCodeString(res.data.details.base64_qrcode);
                        setCurrentRegistrationStep("successPage");
                      }
                    }
                  })
                  .catch(err => {
                    let message = "There was a problem with your card";
                    if (err.response && err.response.data) {
                      message = parseError(
                        err.response.data.errors[0].message_code
                      );
                    }

                    setSubmitting(false);
                    setCurrentRegistrationStep("payment");
                    handleToast(message);
                  });
              });
          } else {
            let items;
            if (res.data.details.ecommerce.items) {
              items = res.data.details.ecommerce.items.map((p: any) => ({
                ...p,
                quantity: 1,
              }));
            } else if (res.data.details.ecommerce.products) {
              items = res.data.details.ecommerce.products.map(
                (product: any) => ({
                  name: product.item_name,
                  id: product.item_id,
                  price: product.price,
                  quantity: 1,
                })
              );
            }
            ecommerceGAEvent(
              {
                ...res.data.details.ecommerce,
                items,
              },
              "purchase"
            );
            setQrCodeString(res.data.details.base64_qrcode);
            setSubmitting(false);
            setCurrentRegistrationStep("successPage");
          }
        } else {
          setSubmitError(res.data.errors[0].message_code);
        }
      })
      .catch(err => {
        setSubmitting(false);
        refreshErrors();
        setSubmitError(
          err.response
            ? err.response.data.errors[0].message_code
            : "Something went wrong"
        );
      });
  };

  const { data, loading, error } = useFetch(endpoints.register.registerDetails);

  const [result, setResult] = useState<string>("");
  const [noCamera, setNoCamera] = useState<boolean>(false);
  const [scannerLoading, setScannerLoading] = useState<boolean>(true);
  const [legacyMode, setLegacyMode] = useState<boolean>(false);
  const [QRError, setQRError] = useState<boolean>(false);
  const [activationToken, setActivationToken] = useState<string>("");
  const [fullDiscount, setFullDiscount] = useState<boolean>(false);
  const [disableCardInput, setDisableCardInput] = useState<boolean>(false);
  const QRRef = useRef<any>(null);

  const hideScanner = {
    display: "none",
  };

  const uploadQRImage = () => {
    QRRef.current && QRRef.current.openImageDialog();
  };

  const handleError = (err: Error) => {
    setNoCamera(true);
    setLegacyMode(true);
  };

  const handleLoad = () => {
    window.setTimeout(() => {
      setScannerLoading(false);
    }, 500);
  };
  const handleScan = (data: any) => {
    if (data) {
      const regex = /activation_token=([\s\S]*)/;
      const t = data.match(regex);
      if (t) {
        setQRError(false);
        setResult(t[1]);
      } else {
        setQRError(true);
      }
    }
  };

  //validation schema is needed based on which form is submitted.

  const [validationSchema, setValidationSchema] = useState<any>(
    personalInfoValidationSchema
  );

  const handleFormSubmit = async (
    values?: any,
    formikActions?: FormikActions<any>
  ) => {
    const lastStep = registrationStep.currentStepName === "payment";
    if (!lastStep) {
      setSubmitting(true);
      if (registrationStep.currentStepName === "personalInfo") {
        setPersonalDetails(values);
        if (isStepComplete("subscription")) {
          setCurrentRegistrationStep("payment");
          if (disableCardInput) {
            setValidationSchema(noPaymentDetailsValidationSchema);
          } else {
            setValidationSchema(paymentValidationSchema);
          }
        } else {
          setCurrentRegistrationStep("subscription", true);
        }
        setSubmitting(false);
      } else if (registrationStep.currentStepName === "subscription") {
        setValidationSchema(paymentValidationSchema);

        setCurrentRegistrationStep(
          subscriptionType === "Yearly" ? "addons" : "payment",
          true
        );
        setSubmitting(false);
      } else if (registrationStep.currentStepName === "addons") {
        // setValidationSchema(paymentValidationSchema);
        setCurrentRegistrationStep("payment", true);
        setSubmitting(false);
      } else if (
        registrationStep.currentStepName === "personalInfoWithAccount"
      ) {
        setPersonalDetails(values);
        const payload = {
          activation_token: activationToken,
          title: values.title || "",
          first_name: values.first_name,
          last_name: values.last_name,
          email_address: values.email_address,
          telephone: values.telephone || "",
          mobile: values.mobile,
          password: values.password,
          b_agree_tcs: values.bTermsConditions,
        };
        handleAlreadyAccountSubmit(payload);
      }
    } else {
      //final submit
      // stripe create token
      setSubmitting(true);

      if (!disableCardInput) {
        let { token } = await props.stripe.createToken({
          name: values.card_name,
        });
        if (token) {
          const obj = {
            token_id: token.id,
            card_brand: token.card.brand,
            card_name: token.card.name,
            card_number: token.card.last4,
            expire_month: token.card.exp_month,
            expire_year: token.card.exp_year,
            address_zip: token.card.address_zip,
          };
          setPaymentDetails(obj);
          handleFinalSubmit(obj);
        } else if (error && !disableCardInput) {
          formikActions &&
            formikActions.setFieldError("card_details", error.message);
          setSubmitting(false);
        } else {
          setSubmitting(false);
        }
        //then submit form
      } else {
        handleFinalSubmit();
      }
    }
  };

  const handleAlreadyAccountSubmit = (
    payload: AlreadyAccountPersonalDetailsObj
  ) => {
    axios
      .post(endpoints.register.activateEnterprise, payload)
      .then(res => {
        if (res.data.status === "1") {
          setSubmitting(false);
          setQrCodeString(res.data.details.base64_qrcode);
          setCurrentRegistrationStep("successPage");
        }
      })
      .catch(err => {
        switch (err.response.data.errors[0].message_code) {
          case "Invalid_Email_Address":
          case "Invalid_Email":
            setErrorType("EmailAddress");
            setInputError("This email address is invalid");
            setGeneralError("");
            setShowBackToQR(false);
            break;
          case "Account_In_Use":
            setErrorType("EmailAddress");
            setInputError("This email address is already in use");
            setGeneralError("");
            setShowBackToQR(false);
            break;
          case "Invalid_Telephone_Number":
            setErrorType("Telephone");
            setInputError("This telephone number is invalid");
            setGeneralError("");
            setShowBackToQR(false);
            break;
          case "Invalid_Number":
            setErrorType("Mobile");
            setInputError("This mobile number is invalid");
            setGeneralError("");
            setShowBackToQR(false);
            break;
          case "Invalid_Password_Length":
            setErrorType("Password");
            setInputError("Password must be 8-20 characters");
            setGeneralError("");
            setShowBackToQR(false);
            break;
          case "Password_Policy_No_Match":
            setErrorType("Password");
            setInputError(
              "Password must be 8-20 characters, contain at least one letter, one number, one uppercase character one non alpa-numeric character"
            );
            setGeneralError("");
            setShowBackToQR(false);
            break;
          case "Invalid_User":
            setGeneralError(
              "The QR code you are trying to register with is invalid, or the primary account for this business no longer exists. If you're sure this account is active, try requesting the QR code again."
            );
            setShowBackToQR(true);
            break;
          case "Client_Invalid_Type":
            setGeneralError(
              "The account you are trying to register with does not have permissions to create associated users"
            );
            setShowBackToQR(false);
            break;
          case "Invalid_Activation_Token":
            setGeneralError("There was a problem with your activation code");
            setShowBackToQR(true);
            break;
          default:
            setGeneralError(
              "Something went wrong, please contact Skippy Support"
            );
            setShowBackToQR(false);
        }

        setSubmitting(false);
      });
  };

  const handleFinalSubmit = (paymentDetails?: any) => {
    !disableCardInput
      ? handleSubmit(
          subscription as number,
          usersPromoCode,
          personalDetails,
          data.details.country_list,
          paymentDetails,
          billingDetails
        )
      : handleSubmit(
          subscription as number,
          usersPromoCode,
          personalDetails,
          data.details.country_list
        );
  };

  const isCurrentStep = (step: string) =>
    registrationStep.currentStepName === step;
  return (
    <PreLoginPageStructure
      showAsideButtons={activePage === "Success" ? true : false}
    >
      <div className="SignInBox">
        <RegisterPage
          registrationStep={registrationStep}
          setCurrentRegistrationStep={setCurrentRegistrationStep}
          inputError={inputError}
          initialValues={initialValues}
          validationSchema={validationSchema}
          handleFormSubmit={handleFormSubmit}
          isCurrentStep={isCurrentStep}
          submitting={isSubmitting}
          activationToken={activationToken}
          setSubmitting={setSubmitting}
          isStepComplete={isStepComplete}
          data={data}
          setTaxRate={setTaxRate}
          alreadyHasAccount={alreadyHasAccount}
          setAlreadyHasAccount={setAlreadyHasAccount}
          QRError={QRError}
          QRRef={QRRef}
          noCamera={noCamera}
          uploadQRImage={uploadQRImage}
          scannerLoading={scannerLoading}
          hideScanner={hideScanner}
          handleError={handleError}
          handleScan={handleScan}
          handleLoad={handleLoad}
          legacyMode={legacyMode}
          setGeneralError={setGeneralError}
          generalError={generalError}
          showBackToQR={showBackToQR}
          result={result}
          setActivationToken={setActivationToken}
          setActivePage={setActivePage}
          subscription={subscription}
          setSubscription={setSubscription}
          setSubscriptionName={setSubscriptionName}
          setSubscriptionGross={setSubscriptionGross}
          setSubscriptionPrice={setSubscriptionPrice}
          setSubscriptionType={setSubscriptionType}
          subscriptionType={subscriptionType}
          usersPromoCode={usersPromoCode}
          setUsersPromoCode={setUsersPromoCode}
          promoCodeVisible={promoCodeVisible}
          promoCodeValue={promoCodeValue}
          setPromoCodeType={setPromoCodeType}
          setPromoCodeValue={setPromoCodeValue}
          setPromoCodeVisible={setPromoCodeVisible}
          onLastStep={onLastStep}
          setDisplayError={setDisplayError}
          disableCardInput={disableCardInput}
          setDisableCardInput={setDisableCardInput}
          setFullDiscount={setFullDiscount}
          setOnLastStep={setOnLastStep}
          promoCodeType={promoCodeType}
          subscriptionGross={subscriptionGross}
          subscriptionName={subscriptionName}
          subscriptionPrice={subscriptionPrice}
          setPaymentDetails={setPaymentDetails}
          fullDiscount={fullDiscount}
          qrCodeString={qrCodeString}
          personalDetails={personalDetails}
          errorType={errorType}
          submitError={submitError}
          displayError={displayError}
          paymentDetails={paymentDetails}
          billingDetails={billingDetails}
          setValidationSchema={setValidationSchema}
          personalInfoValidationSchema={personalInfoValidationSchema}
          alreadyAccountPersonalInfoValidationSchema={
            alreadyAccountPersonalInfoValidationSchema
          }
          paymentValidationSchema={paymentValidationSchema}
          noPaymentDetailsValidationSchema={noPaymentDetailsValidationSchema}
          setSkipPaymentOption={setSkipPaymentOption}
          skipPaymentOption={skipPaymentOption}
          bQueryStringPromoCode={bQueryStringPromoCode}
        />
      </div>
    </PreLoginPageStructure>
  );
};

const paymentValidationSchema = Yup.object({
  card_name: Yup.string().required("Name on card is required"),
  card_details: Yup.object()
    .shape({
      brand: Yup.string(),
      complete: Yup.boolean().oneOf(
        [true],
        "Card details must all be completed"
      ),
      elementType: Yup.string(),
      empty: Yup.boolean().oneOf([true], "Card details must all be completed"),
      error: Yup.mixed(),
      value: Yup.object(),
    })
    .required("Card details are required"),
});
const noPaymentDetailsValidationSchema = Yup.object({
  card_name: Yup.string(),
  card_details: Yup.object().shape({
    brand: Yup.string(),
    complete: Yup.boolean().oneOf([true], "Card details must all be completed"),
    elementType: Yup.string(),
    empty: Yup.boolean().oneOf([true], "Card details must all be completed"),
    error: Yup.mixed(),
    value: Yup.object(),
  }),
});

const personalInfoValidationSchema = Yup.object({
  company_name: Yup.string()
    .max(75, "Company name must be no longer than 75 characters")
    .required("Company name is required"),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email_address: Yup.string().required("Email address is required"),
  phone_number: Yup.mixed()
    .required("A telephone number is required")
    .test(
      "phoneTest",
      "Please enter a valid telephone number",
      (value) => {
        if (!value) return false
        return isValidPhoneNumber(value)
      },
    ),
  password: Yup.string()
    .matches(
      passwordRegex,
      "Password must be 8-20 characters and contain at least one 1 letter and 1 number"
    )
    .required("Password is required"),
  bTermsConditions: Yup.bool().test(
    "bTermsConditions",
    "Please agree to the terms and conditions",
    val => val === true
  ),
});
const alreadyAccountPersonalInfoValidationSchema = Yup.object({
  title: Yup.string(),
  company_name: Yup.string()
    .max(75, "Company name must be no longer than 75 characters")
    .required("Company name is required"),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  vat_number: Yup.string(),
  telephone: Yup.mixed(),
  email_address: Yup.string()
    .max(75, "Email address must be no longer than 75 characters")
    .required("Email address is required"),
  mobile: Yup.string().test(
    "mobileTest",
    "Please enter a valid mobile number",
    (value) => {
      if (!value) return false
      return isValidPhoneNumber(value)
    },
  ),
  password: Yup.string()
    .matches(
      passwordRegex,
      "Password must be 8-20 characters and contain at least one 1 letter and 1 number"
    )
    .required("Password is required"),
  bTermsConditions: Yup.bool().test(
    "bTermsConditions",
    "Please agree to the terms and conditions",
    val => val === true
  ),
});

const initialValues = {
  first_name: "",
  last_name: "",
  mobile: "",
  telephone: "",
  email_address: "",
  password: "",
  bTermsConditions: false,
  card_name: "",
  card_details: {},
};

//export default Registration;

const RegistrationWithStripe = injectStripe(Registration);

export default (props: RegistrationProps) => (
  <Elements>
    <RegistrationWithStripe {...props} />
  </Elements>
);
