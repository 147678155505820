import { connect } from "react-redux";
import MainRouter from "../../routes/MainRouter/MainRouter";
import { AppState } from "reducers/rootReducer";
import { selectIsAuthenticated } from "reducers/auth";

export const mapStateToProps = (
  state: AppState
): { isAuthenticated: boolean } => ({
  isAuthenticated: selectIsAuthenticated(state),
});

export default connect(mapStateToProps)(MainRouter);
