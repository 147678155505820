
export type State = {
  accessibilityMode: boolean;
};

export type Action =
  | {
    type: "TURN_ON_ACCESSIBILITY",
    }
  | {
    type: "TURN_OFF_ACCESSIBILITY"
    };

const initialState: State = {
  accessibilityMode: false
};


export const accessibilityReducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case "TURN_ON_ACCESSIBILITY":
      return {
        ...state,
        accessibilityMode: true
      }
    case "TURN_OFF_ACCESSIBILITY":
      return {
        ...state,
        accessibilityMode: false
      };

    default:
      return state;
  }
};