import format from "date-fns/format";
import React from "react";
import { DataItem } from "./DataItem";
import { DataSectionProps } from "./models";

const DataSection: React.FC<DataSectionProps> = ({
  title,
  items,
  stats,
  date,
}) => {
  const noValues =
    (title === "Field Average Stats" || title === "Scout Point Stats") &&
    items &&
    Array.isArray(items) &&
    items.every(({ value }) => value === "--" || value === null);

  return (
    <div className={stats ? "DataSection" : "DataSection SmallSection"}>
      <h4>
        {title}
        {date ? (
          <span className="SubTitle">
            {" Since your last scout on " + format(new Date(date), "MMM do")}
          </span>
        ) : (
          ""
        )}
      </h4>
      <div
        className={
          stats ? "DataItemContainer" : "DataItemContainer SmallSection"
        }
      >
        {noValues ? (
          <span className="NoValues">Sorry! Analysis is not available</span>
        ) : (
          items.map(item => (
            <DataItem
              icon={item.icon}
              value={item.value}
              doubleValue={item.doubleValue}
              description={item.description}
              doubleDescription={item.doubleDescription}
            />
          ))
        )}
      </div>
    </div>
  );
};

export { DataSection };
