/*
 Date object to string in format 'dd/mm/yyyy', or original input if not a Date object
 */
export const dateObjectToString = (d: Date | any): string =>
  d instanceof Date ? [d.getDate(), d.getMonth() + 1, d.getFullYear()]
    .map(n => n < 10 ? `0${n}` : `${n}`).join('/') : d


/*
 UTC Date string to string in format 'dd/mm/yyyy' or original input if not a string

"2020-01-27T14:05:31.117"  ==> "27/01/2020"
 */

export const formatUTCDateString = (d: string): string => {
  let date = d.split('T')[0].split('-')
  return date[2] + "/" + date[1] + "/" + date[0]
}
