import { RouteComponentProps } from "@reach/router";
import React from "react";
import Helmet from "react-helmet";
import { useSelector } from "react-redux";
import { selectPanos } from "reducers/auth";
import PanoramaItem from "./PanoramaItem";

const ViewPanoramas: React.FC<RouteComponentProps> = () => {
  const details = useSelector(selectPanos);
  return (
    <>
      <div className="PageWrapper">
        <div className="ViewPanoramas WidthContent HeightContent">
          <h1>View Scout Spheres</h1>
          {details!.map(({ farm_name, pano_url }, idx) => (
            <PanoramaItem title={farm_name} link={pano_url} key={idx} />
          ))}
        </div>
      </div>
    </>
  );
};

export default ViewPanoramas;
