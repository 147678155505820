import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { RouteComponentProps, Redirect, navigate } from "@reach/router";
import { ErrorResponse } from "../../../types/ApiResponseTypes";

import SignInBox from "../SignInBox/SignInBox";
import SignInForm from "../SignInForm/SignInForm";
import TwoFactorForm from "../TwoFactorForm/TwoFactorForm";
import { AuthStateStatus } from "../../../types/AuthTypes";
import {
  Credentials,
  TwoFactorCredentials,
  AccountTypeDetails,
} from "../../../types/AuthTypes";
import { Maybe } from "purify-ts/Maybe";

export interface SignInProps {
  status: AuthStateStatus;
  authenticated: boolean;
  signIn(x: Credentials): void;
  tfaSignIn(x: TwoFactorCredentials): void;
  tfaGetNewCode(x: Credentials): void;
  checkAuth(): void;
  getAccountType: () => void;
  accountType: Maybe<AccountTypeDetails>;
  errors: ErrorResponse[];
}

type Details = { user_name: string; password: string; bRememberMe: boolean };

const SignIn = (props: SignInProps & RouteComponentProps) => {
  const [details, setDetails] = useState<Details>({
    user_name: "",
    password: "",
    bRememberMe: false,
  });

  const { status, location, accountType, getAccountType, checkAuth } = props;
  useEffect(() => {
    setDetails({ user_name: "", password: "", bRememberMe: false });
    if (status === "start") checkAuth();
  }, [status, checkAuth]);

  useEffect(() => {
    if (accountType.isNothing()) {
      getAccountType();
    }
  }, [accountType, getAccountType]);

  const submitTwoFactor = (code: string) => {
    props.tfaSignIn({
      ...details,
      twoFactorAuthCode: code,
    });
  };

  const queryPath = location ? queryString.parse(location.search).redirect : "/home";
  const originalSearch = location
    ? queryString.parse(location.search).origSearch
    : "/home";
  const returnPath =
    typeof queryPath === "string" && queryPath != null ? queryPath : "/home";

  const boxes = (
    <>
      <div className="SignInBox">
        <SignInBox title={"Log In"}>
          <SignInForm
            errors={props.errors}
            status={props.status}
            submit={(vals: Credentials) => {
              setDetails(vals);
              props.signIn(vals);
            }}
            error={status === "error"}
          />
        </SignInBox>
      </div>
    </>
  );

  const twoFactorForm = (
    <div>
      <SignInBox title={"Enter your Two-Factor Authentication code"}>
        <div>
          <p>The username is {details.user_name}</p>
          <TwoFactorForm
            submit={submitTwoFactor}
            errors={props.errors}
            resubmit={() => props.tfaGetNewCode(details)}
          />
        </div>
      </SignInBox>
    </div>
  );
  switch (props.status) {
    case "authenticated":
      if (props.authenticated) {
        const search = typeof originalSearch === "string" ? originalSearch : "/home";
        const path = returnPath + "?" + search;
        return <Redirect to={path} />;
      } else return null;
    case "require2FA":
      return twoFactorForm;
    case "error2FA":
      return twoFactorForm;
    case "authenticating":
      return null;
    case "start":
      return boxes;
    default:
      return boxes;
  }
};

export default SignIn;
