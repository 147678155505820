import { useState, useEffect, useRef } from "react";
import { endpoints } from "serverDetails";
import axios from "api";

// Custom hook for fetching fields
export function useFetchReportNotes(ReportId: number) {
  // State variables for fields data, loading status and error
  const [data, setData] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  // Effect hook for fetching fields when parameters change
  useEffect(() => {
    // Set loading status to true

    const payload = {
      params: { reportsId: ReportId },
    };

    fetchData();

    async function fetchData() {
      setLoading(true);

      try {
        const response = await axios.get(
          endpoints.reportsModule.getreportnotes,
          payload
        );
        if (response && response.data && response.data.details) {
          setData(response.data.details.notes);
        } else {
        }

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }
  }, [ReportId]);

  return {
    data,
    loading,
    error,
  };
}
