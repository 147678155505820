import { RightMenuItems } from "../components/PageComponents/RightMenu/rightMenu";

export type State = {
  selectedUserIds: number[];
  selectedCardIds: number[];
  selectedTransactionIds: number[];
  rightMenuItems: RightMenuItems;
  toggleAltMenuItems: boolean;
  showMenu: boolean;
  refreshData: boolean;
  selectedUsersPageIds: number[];
};

export type Action =
  | {
      type: "SET_SELECTED_USERS";
      selectedUserIds: number[];
    }
  | {
      type: "REFRESH_TABLE";
    }
  | {
      type: "CLOSE_MODAL";
    }
  | {
      type: "SET_RIGHT_MENU_ITEMS";
      rightMenuItems: RightMenuItems;
    }
  | {
      type: "SET_LEFT_MENU_HIDDEN";
      leftMenuHidden: boolean;
    }
  | {
      type: "SET_SELECTED_CARDS";
      selectedCardIds: number[];
    }
  | {
      type: "SET_SELECTED_CARDS_NO_MENU";
      selectedCardIds: number[];
    }
  | {
      type: "SET_SELECTED_TRANSACTION";
      selectedTransactionIds: number[];
    }
  | {
      type: "SET_SELECTED_TRANSACTION_NO_MENU";
      selectedTransactionIds: number[];
    }
  | {
      type: "SET_SELECTED_USERSPAGE";
      selectedUsersPageIds: number[];
    }
  | {
      type: "SET_SELECTED_USERSPAGE_NO_MENU";
      selectedUsersPageIds: number[];
    }
  | {
      type: "OPEN_MOBILE_MENU";
    }
  | {
      type: "CLOSE_MOBILE_MENU";
    };

const initialState: State = {
  selectedUserIds: [],
  selectedCardIds: [],
  selectedTransactionIds: [],
  selectedUsersPageIds: [],
  rightMenuItems: { menuItems: [], alternateMenuItems: { menuItems: [] } },
  toggleAltMenuItems: false,
  showMenu: false,
  refreshData: false,
};

export const tableReducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case "SET_SELECTED_USERS":
      return {
        ...state,
        selectedUserIds: action.selectedUserIds,
      };
    case "SET_SELECTED_USERSPAGE":
      return {
        ...state,
        selectedUsersPageIds: action.selectedUsersPageIds,
        toggleAltMenuItems: action.selectedUsersPageIds.length > 1,
        showMenu: true,
      };
    case "SET_RIGHT_MENU_ITEMS":
      return {
        ...state,
        rightMenuItems: action.rightMenuItems,
      };
    case "SET_SELECTED_CARDS":
      return {
        ...state,
        selectedCardIds: action.selectedCardIds,
        toggleAltMenuItems: action.selectedCardIds.length > 1,
        showMenu: true,
      };
    case "SET_SELECTED_CARDS_NO_MENU":
      return {
        ...state,
        selectedCardIds: action.selectedCardIds,
        showMenu: false,
      };
    case "SET_SELECTED_USERSPAGE_NO_MENU":
      return {
        ...state,
        selectedUsersPageIds: action.selectedUsersPageIds,
        showMenu: false,
      };
    case "SET_SELECTED_TRANSACTION":
      return {
        ...state,
        selectedTransactionIds: action.selectedTransactionIds,
        toggleAltMenuItems: action.selectedTransactionIds.length > 1,
        showMenu: true,
      };
    case "SET_SELECTED_TRANSACTION_NO_MENU":
      return {
        ...state,
        selectedTransactionIds: action.selectedTransactionIds,
        showMenu: false,
      };
    case "REFRESH_TABLE":
      return {
        ...state,
        refreshData: !state.refreshData,
      };
    default:
      return state;
  }
};
