import React, { useEffect, useMemo, useState } from "react";
import Helmet from "react-helmet";
import {
  FieldReportsFilter,
  FieldReportsFilterTypes,
} from "./FieldReportsFilter";
import { FieldReportsFilterSort } from "./FieldReportsFilterSort";
import {
  FieldReportSectionCardDetails,
  FieldReportsSectionCard,
} from "./FieldReportsSectionCard";
import FieldImage from "../../assets/images/DummyFieldReportsCardImage.png";
import Spinner from "react-spinkit";
import { endpoints } from "serverDetails";
import api from "api";
import { FieldReportsPagination } from "./FieldReportsPagination";
import {
  addHours,
  addDays,
  addMonths,
  subDays,
  subYears,
  addYears,
  subHours,
  subMonths,
  endOfDay,
  isSameDay,
} from "date-fns";
import { useFetchFields } from "./useFetchFieldReports";
import axios from "api";

export type getReportDetailsReponse = {
  id: number;
  details: Details;
  status: string;
  errors: any[];
};

export type Details = {
  farms: Farm[];
  crop_types: CropType[];
};

export type CropType = {
  crop_id: number;
  crop_name: string;
};

export type Farm = {
  farm_id: number;
  farm_name: string;
  fields: Field[];
};

export type Field = {
  field_id: number;
  field_name: string;
};

const getReportStatuses = async (ReportIds: number[]) => {
  const payload = {
    reportIds: ReportIds,
  };

  if (ReportIds.length === 0) return [];

  try {
    const response = await axios.post(
      endpoints.reportsModule.getreportgenerationstatus,
      ReportIds
    );
    if (response && response.data && response.data.details) {
      return response.data.details.reports;
    } else {
    }
  } catch (error) {}
};

export const FieldReportsSectionPage = ({}: { title?: string }) => {
  const [farmList, setFarmList] = useState<
    { name: string; id: number; fields: Field[] }[]
  >([]);
  const [reportStatuses, setReportStatuses] = useState<
    {
      reports_id: number;
      b_generate_report: boolean;
    }[]
  >([]);
  const [selectedFarm, setSelectedFarm] = useState(0);
  const [cropTypeList, setCropTypeList] = useState<
    { name: string; id: number }[]
  >([]);
  const [orderBy, setOrderByList] = useState<string[]>(["0"]);
  const [cropTypeFilter, setCropTypeFilter] = useState<string[]>([]);
  const [fieldTypeFilter, setFieldTypeFilter] = useState<string[]>([]);
  const [dateFilter, setDateFilter] = useState<string[]>([
    new Date().toISOString(),
  ]);
  const [dateFilterInterval, setDateFilterInterval] = useState<string>(
    "All dates"
  );
  const [sortDirection, setSortDirection] = useState<string>("Highest first");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState("10");
  const [filterChanged, setFilterChanged] = useState(true);

  const addReportToRegeneratingList = (reportId: number) => {
    setReportStatuses([
      ...reportStatuses.filter(({ reports_id }) => reports_id !== reportId),
      {
        reports_id: reportId,
        b_generate_report: true,
      },
    ]);
  };

  const getFarmFieldList = async () => {
    const url = endpoints.reportsModule.getReportsDetails;
    const results = await api.get<getReportDetailsReponse>(url, {});
    if (results && results.data && results.data.details) {
      if (Array.isArray(results.data.details.farms)) {
        setFarmList(
          results.data.details.farms
            .filter(({ fields }) => fields !== null)
            .map(({ farm_name, farm_id, fields }) => ({
              name: farm_name,
              id: farm_id,
              fields,
            }))
        );
      }
      if (Array.isArray(results.data.details.crop_types)) {
        setCropTypeList(
          results.data.details.crop_types.map(({ crop_id, crop_name }) => ({
            name: crop_name,
            id: crop_id,
          }))
        );
      }
    }
  };

  useEffect(() => {
    getFarmFieldList();
  }, []);

  const clearFiltersAndUpdate = () => {
    clearFilters();
    setFilterChanged(prev => !prev);
  };

  const clearFilters = () => {
    setFieldTypeFilter([]);
    setCropTypeFilter([]);
    setDateFilter([new Date().toISOString()]);
    setPageNumber(0);
    setDateFilterInterval("All dates");
  };

  useEffect(() => {
    clearFiltersAndUpdate();
  }, [selectedFarm]);

  const fieldList: { name: string; id: number }[] = useMemo(() => {
    const matchingFarm =
      farmList && farmList.find(({ id }) => id === selectedFarm);
    if (matchingFarm && matchingFarm.fields) {
      return matchingFarm.fields.map(({ field_id, field_name }) => ({
        name: field_name,
        id: field_id,
      }));
    } else {
      return [];
    }
  }, [selectedFarm, farmList.length]);

  const selectedFarmName = useMemo(() => {
    if (farmList && Array.isArray(farmList)) {
      const match = farmList.find(({ id }) => id === selectedFarm);
      if (match) {
        return match.name;
      } else {
        return "";
      }
    }
  }, [selectedFarm, farmList.length]);

  type TimeInterval = "24 hrs" | "3 days" | "1 week" | "1 month" | "1 year";
  const getDateRange = (date: Date, interval: TimeInterval) => {
    let dateFrom: Date;
    let dateTo: Date;

    switch (interval) {
      case "24 hrs":
        dateFrom = subHours(date, 24);
        dateTo = addHours(date, 24);
        break;
      case "3 days":
        dateFrom = subDays(date, 3);
        dateTo = addDays(date, 3);
        break;
      case "1 week":
        dateFrom = subDays(date, 7);
        dateTo = addDays(date, 7);
        break;
      case "1 month":
        dateFrom = subMonths(date, 1);
        dateTo = addMonths(date, 1);
        break;
      case "1 year":
        dateFrom = subYears(date, 1);
        dateTo = addYears(date, 1);
        break;
      default:
        dateFrom = subYears(date, 10);
        dateTo = endOfDay(date);
    }

    return [{ DateFrom: dateFrom.toISOString(), DateTo: dateTo.toISOString() }];
  };

  let cropFilterNames;
  if (cropTypeFilter.length > 0 && cropTypeList) {
    const cropFilterIds = cropTypeFilter.map(id => parseInt(id));
    const matchingCropFilterDetails = cropTypeList.filter(({ id, name }) =>
      cropFilterIds.includes(id)
    );
    if (matchingCropFilterDetails) {
      cropFilterNames = matchingCropFilterDetails.map(({ id, name }) => ({
        cropName: name,
      }));
    }
  }

  const {
    fields,
    validFieldList,
    validCropTypeList,
    validDateList,
    validSortByList,
    noResults,
    resultsCount,
    loading,
  } = useFetchFields(
    fieldTypeFilter.length > 0
      ? fieldTypeFilter.map(id => ({ fieldID: parseInt(id) }))
      : fieldList.map(({ id, name }) => ({ fieldID: id })),
    selectedFarm,
    cropFilterNames
      ? cropFilterNames
      : cropTypeList.map(({ id, name }) => ({ cropName: name })),
    parseInt(orderBy[0]),
    getDateRange(new Date(dateFilter[0]), dateFilterInterval as TimeInterval),
    sortDirection === "Lowest first" ? "ASC" : "DESC",
    pageNumber.toString(),
    pageSize,
    filterChanged,
    setDateFilter
  );

  // const { data, error } = useGetFieldReportStatus(
  //   fields.map(({ ReportsId }) => ReportsId)
  // );

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (fields) {
      (async () => {
        const statuses = await getReportStatuses(
          fields.map(({ ReportsId }) => ReportsId)
        );
        setReportStatuses(statuses);
      })();
      intervalId = setInterval(async () => {
        const statuses = await getReportStatuses(
          fields.map(({ ReportsId }) => ReportsId)
        );
        setReportStatuses(statuses);
      }, 60000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [fields]);

  if (!farmList || (Array.isArray(farmList) && farmList.length === 0)) {
    return (
      <Spinner
        name="three-bounce"
        className="BtnSpinner"
        fadeIn="none"
        style={{
          height: "100vh",
          width: "calc(100vw - 240px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <div className="FieldReportsSectionPage WidthContent HeightContent">
      <h1 className="FieldReportsSectionPage__title">
        {selectedFarmName ? `${selectedFarmName} Reports` : "Field Reports"}
      </h1>
      <div
        className={`FieldReportsSectionPage__Filters ${
          loading ? "Disabled" : ""
        }`}
      >
        <div className="FieldReportsSectionPage__FarmSelectWrapper">
          <FieldReportsFilterSort
            heading="Select a farm"
            items={farmList}
            selectedItem={selectedFarm}
            setSelectedItem={setSelectedFarm}
          />
          <div
            className={`Btn ${!selectedFarm || loading ? "Disabled" : ""}`}
            onClick={clearFiltersAndUpdate}
          >
            Clear filters
          </div>
        </div>
        {selectedFarm ? (
          <>
            <FieldReportsFilter
              type={FieldReportsFilterTypes.ITEMS}
              heading="Filter by Field"
              items={noResults ? [] : validFieldList}
              setSelectedItems={setFieldTypeFilter}
              selectedItems={fieldTypeFilter}
              setFilterChanged={setFilterChanged}
            />
            <FieldReportsFilter
              type={FieldReportsFilterTypes.ITEMS}
              heading="Filter by Crop Type"
              items={noResults ? [] : validCropTypeList}
              setSelectedItems={setCropTypeFilter}
              selectedItems={cropTypeFilter}
              setFilterChanged={setFilterChanged}
            />
            <FieldReportsFilter
              type={FieldReportsFilterTypes.DATE}
              heading="Filter by Flight Date"
              subFilterTitle="Within:"
              subFilterItems={[
                "24 hrs",
                "3 days",
                "1 week",
                "1 month",
                "1 year",
                isSameDay(new Date(dateFilter[0]), new Date())
                  ? "All dates"
                  : "All prior dates",
              ]}
              includeDates={noResults ? [] : validDateList}
              setSelectedSubFilter={setDateFilterInterval}
              selectedSubFilter={dateFilterInterval}
              setSelectedItems={setDateFilter}
              selectedItems={dateFilter}
              setFilterChanged={setFilterChanged}
            />
            <FieldReportsFilter
              type={FieldReportsFilterTypes.ITEMS}
              heading="Order by"
              items={validSortByList}
              selectedItems={orderBy}
              setSelectedItems={setOrderByList}
              singleSelect
              subFilterTitle="Starting with:"
              subFilterItems={["Lowest first", "Highest first"]}
              setSelectedSubFilter={setSortDirection}
              selectedSubFilter={sortDirection}
              setFilterChanged={setFilterChanged}
            />
          </>
        ) : (
          ""
        )}
        {noResults && !loading && (
          <div className="FieldReportsSectionPage__NoResults">No results</div>
        )}
      </div>
      {loading && (
        <Spinner
          name="three-bounce"
          className="BtnSpinner"
          fadeIn="none"
          style={{
            height: "500px",
            width: "calc(100vw - 240px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      )}
      {!loading &&
      selectedFarm &&
      !noResults &&
      fields &&
      Array.isArray(fields) ? (
        <>
          <div className="FieldReportsSectionPage__Fields">
            {fields.map(
              ({
                CropName,
                FieldsId,
                FieldName,
                FieldReportDate,
                ReportLink,
                FieldValues,
                ThumbnailLink,
                FlightsId,
                ReportsId,
                bHasNotes,
              }) => (
                <FieldReportsSectionCard
                  key={ReportsId}
                  FieldId={FieldsId}
                  FlightsId={FlightsId}
                  FieldName={FieldName}
                  FieldCrop={CropName}
                  FieldReportDate={FieldReportDate}
                  FieldValues={FieldValues}
                  FieldReportLink={ReportLink}
                  FieldImage={ThumbnailLink}
                  ReportId={ReportsId}
                  fieldReportStatus={reportStatuses}
                  hasNotes={bHasNotes}
                  addReportToRegeneratingList={addReportToRegeneratingList}
                />
              )
            )}
          </div>
        </>
      ) : (
        ""
      )}
      {selectedFarm ? (
        <FieldReportsPagination
          pageNumber={pageNumber}
          pageSize={parseInt(pageSize)}
          setPageNumber={number => {
            setPageNumber(number);
            setFilterChanged(prev => !prev);
          }}
          resultsCount={resultsCount}
        />
      ) : (
        ""
      )}
    </div>
  );
};
