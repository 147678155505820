import { DropzoneProps } from "components/FormComponents/Dropzone/Dropzone";
import React from "react";

export type UseUploadFile = [
  {
    uploadedFiles: File[];
    uploadErrors: string[] | null;
  },
  {
    handleDrop: DropzoneProps["onDrop"];
    clearFile: () => void;
  }
];

export const useHandleMultipleFileUploads = (): UseUploadFile => {
  const [uploadedFiles, setUploadedFiles] = React.useState<File[]>([]);
  const [uploadErrors, setUploadErrors] = React.useState<string[] | null>(null);

  const handleDrop: DropzoneProps["onDrop"] = React.useCallback(
    (acceptedFiles, rejectedFiles, event) => {
      setUploadErrors([]);
      if (rejectedFiles && rejectedFiles.length > 0) {
        rejectedFiles.forEach(rej => {
          if (rej.errors) {
            rej.errors.forEach(error => {
              if (error.message) {
                setUploadErrors(p =>
                  p ? [...new Set(p.concat([error.message]))] : p
                );
              } else if (error.code) {
                setUploadErrors(p =>
                  p ? [...new Set(p.concat([error.code]))] : p
                );
              }
            });
          }
        });
      }

      if (acceptedFiles && acceptedFiles.length > 0) {
        setUploadedFiles(prev => [...prev, ...acceptedFiles]);
      }

      setTimeout(() => {
        setUploadErrors([]);
      }, 5000);
    },
    []
  );

  const clearFile = React.useCallback(() => setUploadedFiles([]), []);

  const data = React.useMemo(
    () => ({
      uploadedFiles,
      uploadErrors,
    }),
    [uploadedFiles, uploadErrors]
  );

  const actions = React.useMemo(
    () => ({
      handleDrop,
      clearFile,
    }),
    [handleDrop, clearFile]
  );

  return [data, actions];
};
