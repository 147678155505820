import { AxiosRequestConfig } from "axios";
import { ApiReturn } from "pages/InvoicesPage/InvoicesApi";
import { endpoints } from "serverDetails";
import axios from "api";

export const postPauseSubscription = async (
    postObject: { months: number; subscription_id: number | null; }
): Promise<ApiReturn> => {

    try {
        const res = await axios.post(endpoints.subscription.pauseSubscription, postObject);
        if (res.status === 1 || res.status === 200) {
            return {
                response: res.data,
                errors: null,
            };
        } else {
            return {
                response: res.data,
                errors: res.data.errors,
            };
        }
    } catch (error) {
        return {
            response: null,
            errors: error,
        };
    }
};