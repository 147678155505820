import * as React from "react";
import { RouteComponentProps, Redirect } from "@reach/router";
import { TableOptions } from "../../helpers/tables/tableOptions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../reducers/rootReducer";
import { RightMenuItems } from "../../components/PageComponents/RightMenu/rightMenu";

import Action01 from "assets/images/Icon_Action_01.png";
import { endpoints } from "../../serverDetails";
import DataTable from "../../components/DataTable";
import { useTable } from "../../api";
import { useTableSelection } from "@avamae/table";
import Pagination from "../../components/Pagination";
import TransactionHistoryFilter from "../../components/RightPopOutComponents/TransactionHistoryFilter";
import Helmet from "react-helmet";

const FilterIcon = "/content/images/Icon_Btn_03.png";

export type TransactionHistoryItem = {
  SubscriptionTransactionHistory__Id: number;
  SubscriptionTransactionHistory__Title: string;
  SubscriptionTransactionHistory__GrossAmount: number;
  SubscriptionTransactionHistory__AddDateUTC: string;
};

export type TransactionHistoryItems = TransactionHistoryItem[];

type Data = {
  details: {
    listData: TransactionHistoryItems;
  };
};

//const transactionHistoryItems: TransactionHistoryItems = [
//  { SubscriptionTransactionHistory__Id: 101, SubscriptionTransactionHistory__Title: "Farmer subscription", SubscriptionTransactionHistory__GrossAmount: 12, SubscriptionTransactionHistory__AddDateUTC: "2020-01-02T16:41:36.49" },
//  { SubscriptionTransactionHistory__Id: 102, SubscriptionTransactionHistory__Title: "Farmer subscription", SubscriptionTransactionHistory__GrossAmount: 12, SubscriptionTransactionHistory__AddDateUTC: "2020-01-02T16:35:43.887" },
//  { SubscriptionTransactionHistory__Id: 103, SubscriptionTransactionHistory__Title: "Farmer subscription", SubscriptionTransactionHistory__GrossAmount: 12, SubscriptionTransactionHistory__AddDateUTC: "2020-01-02T16:35:43.887" },
//  { SubscriptionTransactionHistory__Id: 104, SubscriptionTransactionHistory__Title: "Farmer subscription", SubscriptionTransactionHistory__GrossAmount: 12, SubscriptionTransactionHistory__AddDateUTC: "2020-01-02T16:35:43.887" },
//];

export interface TransactionHistoryProps {}

const TransactionHistoryPage = (
  props: RouteComponentProps & TransactionHistoryProps
) => {
  const dispatch = useDispatch();

  /* Redux */
  const table = useTable({
    url: endpoints.transactionHistory.listTransactionHistory,
    pageSize: 9,
  });
  const tableState = useSelector((state: AppState) => state.tableReducer);
  const reduxListState = useSelector(
    (state: AppState) => state.transactionHistoryReducer.list
  );
  const reduxViewState = useSelector(
    (state: AppState) =>
      state.transactionHistoryReducer.selected_transaction_state
  );
  const idColumn = "SubscriptionTransactionHistory__Id";

  const data = table.data;

  React.useEffect(() => {
    if (data) {
      dispatch({
        type: "GOT_TRANSACTION_HISTORY_LIST",
        payload: data.details.listData,
      });
    }
  }, [dispatch, data]);
  //const allTransactions = listState.map((transaction: TransactionHistoryItem) => transaction.SubscriptionTransactionHistory__Id)
  /* Local state */
  const [
    visibleTransactionOptions,
    setVisibleTransactionOptions,
  ] = React.useState<number | null>(null);
  const [showMenu, setShowMenu] = React.useState<boolean>(true);
  const subscriptionStatus = useSelector(
    (state: AppState) => state.auth.subscriptionStatus
  );

  React.useEffect(() => {
    const rightMenuItems: RightMenuItems = {
      menuItems: [
        {
          icon: Action01,
          name: "View transaction",
          imgAlt: "View transaction",
          onClick: () =>
            dispatch({
              type: "OPEN_RIGHT_POP_OUT",
              rightPopOutOpen: "TRANSACTION_HISTORY_VIEW",
            }),
        },
        {
          icon: Action01,
          name: "View stripe receipt",
          imgAlt: "View stripe receipt",
          onClick: () =>
            dispatch({
              type: "EMAIL_STRIPE_RECEIPT",
            }),
        },
      ],
      alternateMenuItems: {
        menuItems: [
          {
            icon: Action01,
            name: "View stripe receipts",
            imgAlt: "View stripe receipts",
            onClick: () =>
              dispatch({
                type: "EMAIL_STRIPE_RECEIPT",
              }),
          },
        ],
      },
    };

    dispatch({ type: "SET_RIGHT_MENU_ITEMS", rightMenuItems: rightMenuItems });
    return () => {
      dispatch({
        type: "SET_SELECTED_TRANSACTION",
        selectedTransactionIds: [],
      });
      dispatch({
        type: "SET_RIGHT_MENU_ITEMS",
        rightMenuItems: {
          menuItems: [],
          alternateMenuItems: { menuItems: [] },
        },
      });
    };
  }, [dispatch]);

  const ids = tableState.selectedTransactionIds;

  React.useEffect(() => {
    const handleToast = (message: string) => {
      dispatch({ type: "SHOW_TOAST", message: message });
      window.setTimeout(() => {
        dispatch({ type: "HIDE_TOAST" });
      }, 3000);
    };

    if (reduxViewState === "email") {
      if (ids.length > 1) {
        let receiptsOnFile = false;
        for (const s of ids) {
          let transaction = reduxListState.find(
            (t: any) => t.SubscriptionTransactionHistory__Id === s
          );

          if (
            transaction &&
            transaction.SubscriptionTransactionHistory__ReceiptUrl
          ) {
            receiptsOnFile = true;
            window.open(
              transaction.SubscriptionTransactionHistory__ReceiptUrl,
              `Stripe Receipt ${s}`
            );
          }
        }
        // if all requests failed
        if (!receiptsOnFile) {
          handleToast("No Stripe receipts on file");
        }
      } else {
        let transaction = reduxListState.find(
          (t: any) => t.SubscriptionTransactionHistory__Id === ids[0]
        );

        if (
          transaction &&
          transaction.SubscriptionTransactionHistory__ReceiptUrl
        ) {
          window.open(
            transaction.SubscriptionTransactionHistory__ReceiptUrl,
            "Stripe Receipt"
          );
        } else {
          handleToast("No Stripe receipt on file");
        }
      }
      dispatch({
        type: "CLEAR_TRANSACTION_HISTORY",
      });
      dispatch({
        type: "SET_SELECTED_TRANSACTION",
        selectedTransactionIds: [],
      });
    }
  }, [reduxViewState, dispatch, reduxListState, ids]);

  const toggleUserOptions = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const targetId = parseInt(e.currentTarget.id, 10);
    if (targetId) {
      visibleTransactionOptions !== targetId
        ? setVisibleTransactionOptions(targetId)
        : setVisibleTransactionOptions(null);
    }
  };
  const tableOptionsList = [
    {
      name: "View transaction",
      onClick: (card_id: number) => {
        dispatch({
          type: "SET_SELECTED_TRANSACTION_NO_MENU",
          selectedTransactionIds: [card_id],
        });
        dispatch({
          type: "OPEN_RIGHT_POP_OUT",
          rightPopOutId: [card_id],
          rightPopOutOpen: "TRANSACTION_HISTORY_VIEW",
        });
      },
    },
    {
      name: "View stripe receipt",
      onClick: (user_id: number) => {
        dispatch({
          type: "SET_SELECTED_TRANSACTION_NO_MENU",
          selectedTransactionIds: [user_id],
        });
        dispatch({
          type: "EMAIL_STRIPE_RECEIPT",
        });
      },
    },
  ];

  const selectBox = (
    select: () => void,
    selected: boolean,
    id: number | string
  ) => {
    return (
      <div>
        <input
          type="checkbox"
          checked={selected}
          onChange={select}
          id={id.toString()}
          name={id.toString()}
        />
        <label className="CustomInput" htmlFor={id.toString()} />
      </div>
    );
  };

  const rowSelection = useTableSelection<any>(idColumn, selectBox);
  const { selectedIDs } = rowSelection;

  React.useEffect(() => {
    dispatch({
      type: "SET_SELECTED_TRANSACTION",
      selectedTransactionIds: [...selectedIDs],
    });
    selectedIDs.length > 0 ? setShowMenu(false) : setShowMenu(true);
    //eslint-disable-next-line
  }, [selectedIDs]);

  // if (subscriptionStatus !== "Valid" && subscriptionStatus !== "Initial") {
  //   return <Redirect to="/" noThrow={true} />
  // }

  return (
    <>
      <Helmet>
        <title>Skippy Scout: Transaction History</title>
      </Helmet>
      <div className="TransactionHistory WidthContent HeightContent">
        <div className="TransactionHistoryHeader">
          <h1>Transaction History</h1>
          <div className="HeaderActionsContainer">
            <div className="ButtonContainer">
              <button
                className={`Btn BtnClear ${
                  tableState.selectedTransactionIds.length > 0 &&
                  tableState.showMenu
                    ? ""
                    : "BtnHidden"
                }`}
                onClick={() => {
                  rowSelection.selectAll([]);
                  dispatch({
                    type: "SET_SELECTED_TRANSACTION",
                    selectedTransactionIds: [],
                    toggleAltMenuItems: true,
                  });
                }}
              >
                <img
                  src={"/content/images/Icon_Btn_06.png"}
                  alt="Clear"
                  className="ClearIcon"
                />
                <span>Clear selection</span>
              </button>
            </div>
          </div>
        </div>

        <DataTable
          table={table}
          filterAction={() => {
            table && table.data && table.data.details.filters.length > 0
              ? table && table.data && table.data.actions.setFilters([])
              : dispatch({
                  type: "OPEN_RIGHT_POP_OUT",
                  rightPopOutOpen: "TRANSACTION_HISTORY_FILTER",
                });
          }}
          rowSelection={rowSelection}
          rowOptionComponent={(id: any) => (
            <>
              <img
                alt="Filter"
                id={id.toString()}
                className={`FilterIcon ${showMenu ? "" : "Hidden"}`}
                onClick={toggleUserOptions}
                src={FilterIcon}
              />
              <TableOptions
                className="I"
                id={id[0]}
                visibleOptions={visibleTransactionOptions}
                setVisibleOptions={setVisibleTransactionOptions}
                optionList={tableOptionsList}
              />
            </>
          )}
        />
        <Pagination table={table} />
        <TransactionHistoryFilter table={table} />
      </div>
    </>
  );
};

export default TransactionHistoryPage;
