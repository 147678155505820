import React from "react";
import { Maybe } from "purify-ts/Maybe";
import { find, propEq } from "ramda";
import { translateError, toPascal } from "../../../helpers/errors";
import { ErrorMessage } from "formik";
import styles from "./ValidationError.module.css";

export type ServerError = { fieldName: string; messageCode: string };

const validationError = (props: { errors: ServerError[]; name: string }) => {
  const pascalisedName = toPascal(props.name);
  const validate = Maybe.fromNullable(
    props.errors
      ? find(propEq("fieldName", pascalisedName), props.errors)
      : null
  );
  const serverError = validate.caseOf({
    Just: e =>
      e ? translateError(e.messageCode).orDefault(e.messageCode) : "Error",
    Nothing: () => null,
  });

  return (
    <>
      <div className={styles.ErrorMessage}>
        <ErrorMessage name={props.name} component="div">
          {msg => <div>{msg}</div>}
        </ErrorMessage>
        <div>{serverError}</div>
      </div>
    </>
  );
};

export default validationError;
