import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import Back from "assets/images/Icon_Btn_07.png";
import Spinner from "react-spinkit";
import axios from "api";

const errorResults = "/content/images/Icon_Error.png"

type ShareQREmailOrIMessageProps = {
    QREmail: string;
    QRSms: string;
    data: any;
    error: any;
}

const ShareQREmailOrIMessage: React.FC<RouteComponentProps & ShareQREmailOrIMessageProps> = (props) => {

    const { data, error } = props;
    const dispatch = useDispatch();
    const [submittingEmail, setSubmittingEmail] = React.useState<boolean>(false)
    const [submittingSMS, setSubmittingSMS] = React.useState<boolean>(false)

    const handleToast = (message: string) => {
        dispatch({ type: 'SHOW_TOAST', message: message })
        window.setTimeout(() => {
            dispatch({ type: 'HIDE_TOAST' })
        }, 3000);
    }

    const sendQR = (method: string) => {

        let url = method === "Email" ? props.QREmail : props.QRSms

        let message = method === "Email" ? "Email sent" : "SMS sent"

        method === "Email" ? setSubmittingEmail(true) : setSubmittingSMS(true)

        axios
            .post(url)
            .then(res => {
                if (res.data.status === "1") {
                    setSubmittingEmail(false)
                    setSubmittingSMS(false)
                    handleToast(message)
                }
                if (res.data.errors) {
                    console.log(res.data.errors)
                }
            })
            .catch(err => {
                if (err.response.data.errors) {
                    switch (err.response.data.errors[0].message_code) {
                        case "Invalid_Mobile_Number":
                            handleToast("Please provide a mobile number on personal information page");
                            break;
                        default:
                            handleToast("There was a problem, please try again");
                            break;
                    }
                }
                setSubmittingEmail(false)
                setSubmittingSMS(false)
            });
    }

    if (error) return (
        <div className="ErrorResults">
            <div className="NoResults">
                <img src={errorResults} alt={"Error"} />
                <p>Error</p>
            </div>
        </div>
    )

    if (data) {

        return (
            <div className="QRCodeDetails">
                <div className="QRCodeDetailsContent">
                    <div className="QRContainer">
                        <div className="ActionButtons">
                            <button
                                className="Btn BtnGreen"
                                onClick={() => sendQR("Email")}
                                disabled={submittingEmail}
                            >
                                Send by email
            </button>
                            <button
                                className="Btn BtnGreen"
                                onClick={() => sendQR("SMS")}
                                disabled={submittingSMS}
                            >
                                Send by iMessage
              </button>
                        </div>
                        <div className="CopyLink">
                            <p className="LinkText">{data.details.qrcode_url_link}</p>

                            <button
                                className="Btn BtnSmall BtnCopy"
                                onClick={() => {
                                    let t = document.createElement("textarea");
                                    document.body.appendChild(t);
                                    t.value = data.details.qrcode_url_link;
                                    t.select()
                                    document.execCommand("copy")
                                    document.body.removeChild(t);
                                }}
                            >Copy</button>
                        </div>
                        <div className="DownloadLink"></div>
                    </div>
                </div>
            </div>
        );
    }
    return null
}


export default ShareQREmailOrIMessage;
