import React from "react";
import { RouteComponentProps } from "@reach/router";
import { useDispatch } from "react-redux";
import { signIn } from "reducers/auth";

type SuccessPageProps = {
  personalDetails: any;
  qrCodeString: any;
};

const SuccessPage = (props: RouteComponentProps & SuccessPageProps) => {
  const dispatch = useDispatch();
  const { personalDetails, qrCodeString } = props;
  // if (!personalDetails || !qrCodeString || alreadyHasAccount === null) {
  //   return <Redirect to="/register/registration" noThrow={true} />;
  // }
  return (
    <div className="Register Success">
      <h1>Congratulations!</h1>
      <p>Download the app from the App Store to get started:</p>
      <a
        className="Btn BtnMargin BtnMarginTop BtnRegister"
        target="_blank"
        rel="noopener noreferrer"
        href="https://apps.apple.com/us/app/skippy-scout/id1453997684 "
      >
        Get the app
      </a>
      <p>Scan this QR code with the app to activate your subscription.</p>
      <p className="SubText">
        (The QR code has also been sent to you by email and SMS, and can also be
        accessed after logging in.)
      </p>
      <img src={qrCodeString} className="RegisterQR" alt={"QRCode"} />
      <p>Or access the website by clicking on the button below.</p>
      <div
        className="Btn BtnTransparent BtnRegister BtnMarginTop"
        onClick={() => {
          let loginDetails = {
            user_name: personalDetails!.email_address,
            password: personalDetails!.password,
            bRememberMe: false,
          };
          dispatch(signIn(loginDetails));
        }}
      >
        Log in now
      </div>
    </div>
  );
};

export { SuccessPage };
